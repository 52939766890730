import { createSlice } from '@reduxjs/toolkit'
import { formatDefault } from 'data/helpers/dates'
import { ItineraryTab } from 'pages/home/reservations/[id]/trip-schedule'
import { RootState } from 'store'

export interface TripScheduleState {
  selectedDay: string | null
  mobile: boolean
  addServiceModalOpen: boolean
  pendingServicesModalOpen: boolean | string
  activeTab: ItineraryTab
  selectedService: any
  selectedServiceRequest: any
}

const initialState: TripScheduleState = {
  selectedDay: null,
  mobile: false,
  addServiceModalOpen: false,
  pendingServicesModalOpen: false,
  activeTab: ItineraryTab.CalendarView,
  selectedService: null,
  selectedServiceRequest: null,
}

export const tripScheduleSlice = createSlice({
  name: 'tripSchedule',
  initialState: initialState,
  reducers: {
    setSelectedDay: (state, action) => {
      if (action.payload) {
        state.selectedDay = formatDefault(action.payload)
      } else {
        state.selectedDay = null
      }
    },
    setMobile: (state, action) => {
      state.mobile = !!action.payload
    },
    setAddServiceModalOpen: (state, action) => {
      state.addServiceModalOpen = !!action.payload
    },
    setPendingServicesModalOpen: (state, action: { payload: string | boolean }) => {
      state.pendingServicesModalOpen = action.payload
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload
    },
    setSelectedService: (state, action) => {
      state.selectedService = action.payload
    },
    setSelectedServiceRequest: (state, action) => {
      state.selectedServiceRequest = action.payload
    },
  },
})

export const { setSelectedDay, setMobile, setAddServiceModalOpen, setPendingServicesModalOpen, setActiveTab, setSelectedService, setSelectedServiceRequest } = tripScheduleSlice.actions

export const selectSelectedDay = (state: RootState) => state.tripSchedule.selectedDay

export default tripScheduleSlice.reducer
