import classNames from 'classnames'
import { EditCustomerProfileSection } from '../modals/EditCustomerProfileModal'
import ProfileImage from 'components/ProfileImage'
import useAuth from 'data/hooks/User/useAuth'
import { formatEnum } from '@ama-selections/ui'
import { useState } from 'react'
import { EditProfileImageModal } from '../modals/EditProfileImageModal'

export const EditProfileImage = () => {
  const { user } = useAuth()

  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      <button
        type="button"
        onClick={() => setIsModalOpen(true)}
        className={classNames(
          'flex gap-x-21 py-10 mb-10 mt-20 px-2 focus:outline-none items-center',
          'tracking-1/2 leading-28 group',
        )}
      >
        <ProfileImage
          user={user}
          className="min-w-[80px] max-w-[80px] min-h-[80px] max-h-[80px]"
          textClassName="text-28"
        />

        <div className="flex flex-col pb-5 text-left">
          <h3 className="text-blue-1000 text-[17px]">
            {formatEnum(EditCustomerProfileSection.ProfileImage)}
          </h3>

          <p className="underline text-14 text-primary-fresh group-hover:text-primary-active focus:outline-none focus:text-primary-active">
          Edit
          </p>
        </div>
      </button>

      <EditProfileImageModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  )
}
