import LoginSection from './LoginSection'
import { useRouter } from 'next/router'
import RegisterSection from './RegisterSection'
import { useEffect, useState } from 'react'
import { AuthSectionPanelProps } from './AuthSectionPanel'
import AuthSectionLayout from './AuthSectionLayout'

interface AuthSectionProps extends Pick<AuthSectionPanelProps, 'buttonStyle'> {
  showLogo?: boolean
  placeholderPanel?: JSX.Element
  initialSlide?: 'login' | 'register'
}

const AuthSection = ({
  showLogo,
  placeholderPanel,
  initialSlide,
  ...props
}: AuthSectionProps) => {
  const router = useRouter()

  const [selectedIndex, setSelectedIndex] = useState(0)

  useEffect(() => {
    if (initialSlide) {
      setSelectedIndex(headers.findIndex((header) => header.href === `/${initialSlide}`))
    } else {
      setSelectedIndex(headers.findIndex((header) => router.pathname === header.href))
    }
  }, [router.pathname, initialSlide])

  const headers = [
    {
      title: 'Join',
      href: '/register',
    },
    {
      title: 'Sign In',
      href: '/login',
    },
  ]

  return (
    <AuthSectionLayout
      showLogo={showLogo}
      selectedIndex={selectedIndex}
      setSelectedIndex={setSelectedIndex}
      headers={headers}
    >
      <RegisterSection {...props} />

      <LoginSection {...props} placeholderPanel={placeholderPanel} />
    </AuthSectionLayout>
  )
}

export default AuthSection
