export default class PropertyCoordinatesApiClient {
  constructor (http) {
    this.http = http
  }

  /**
   * Get a single address from Lat/Lng
   *
   * @param {(number)} lat
   * @param {(number)} lng
   * @returns {Address}
   */
  async getPropertyAddress (lat, lng) {
    const result = await this.http.get(`/properties/search/${lat}/${lng}`)
    return result.data.data
  }

  /**
   * Get property coordinates from address
   *
   * @param {(string)} address
   * @returns {Address}
   */
  async getPropertyCoordinates (address) {
    const result = await this.http.get(`/properties/search/${address}`)
    return result.data.data
  }
}
