import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { notificationType } from 'data/enums/notification-types'
import Link from 'components/Link'
import InquiryOverviewModal from 'components/modals/InquiryOverviewModal'
import DashboardServiceRequestModal from 'components/modals/DashboardServiceRequestModal'
import UserServiceRequestModal from 'components/modals/UserServiceRequestModal'
import UserSurveyBankInfoModal from 'components/modals/UserSurveyBankInfoModal'
import DashboardSurveyBankInfoModal from 'components/modals/DashboardSurveyBankInfoModal'
import { getCurrencySign } from 'data/helpers/currency'

const DashboardNotificationContent = ({ notification }) => {
  const buttonClasses = 'underline text-primary-fresh hover:text-primary-active'
  const [isModalOpen, setIsModalOpen] = useState(false)

  const openModal = () => {
    setIsModalOpen(true)
  }

  const renderNotificationContent = () => {
    switch (notification.namespace) {
      case (notificationType.INQUIRY_CALLBACK):
        return (
          <div><button className={buttonClasses} onClick={() => openModal()}>Callback Request</button> - {notification.data.name}!</div>
        )
      case (notificationType.INQUIRY_GENERAL):
        return (
          <div><button className={buttonClasses} onClick={() => openModal()}>General Inquiry</button> - {notification.data.arrival} to {notification.data.departure}!</div>
        )
      case (notificationType.INQUIRY_PROPERTY):
        return (
          <div><button className={buttonClasses} onClick={() => openModal()}>Inquiry</button> for <Link className={buttonClasses} href={`/${notification.data.property_url}`} target="_blank">{notification.data.property_title}</Link> - {notification.data.arrival} to {notification.data.departure}!</div>
        )
      case (notificationType.INQUIRY_AGENT_ASSIGNED):
        return (
          <div>{notification.data.agent_name}, you&apos;ve been assigned a New <button className={buttonClasses} onClick={() => openModal()}>{notification.data.type}</button></div>
        )
      case (notificationType.SERVICE_REQUEST_ADMIN):
        return (
          <div>{notification.data.service_title} <button className={buttonClasses} onClick={() => openModal()}>service request</button> received from <Link className={buttonClasses} href={`/${notification.data.booking_uri}`} target="_blank">#{notification.data.booking_id}</Link> - <Link className={buttonClasses} href={`/${notification.data.client_uri}`} target="_blank">{notification.data.client_name}</Link></div>
        )
      case (notificationType.SERVICE_REQUEST_USER):
        return (
          <div><button className={buttonClasses} onClick={() => openModal()}>{notification.data.service_title}</button>, service request sent! We will be in touch shortly.</div>
        )
      case (notificationType.SURVEY_BANK_INFO_USER):
        return (
          <div><button className={buttonClasses} onClick={() => openModal()}>Bank Information</button> sent! We will be in touch shortly.</div>
        )
      case (notificationType.SURVEY_BANK_INFO_ADMIN):
        return (
          <div><button className={buttonClasses} onClick={() => openModal()}>Bank information</button> received from <Link className={buttonClasses} href={`/${notification.data.booking_uri}`} target="_blank">#{notification.data.booking_id}</Link> - <Link className={buttonClasses} href={`/${notification.data.client_uri}`} target="_blank">{notification.data.client_name}</Link></div>
        )
      case (notificationType.TIP_SENT_TO_CONCIERGE):
        return (
          <div>{notification.data.concierge}, {notification.data.first_name} {notification.data.last_name} gave you a {notification.data.tip + getCurrencySign(notification.data.currency)} tip! Res. <Link target="_blank" href={`/dashboard/notifications?surveyBankInfo=${notification.data.bank_information_id}`} className={buttonClasses}>#B{String(notification.data.bank_information_id)?.padStart(6, '0')}</Link></div>
        )
      case (notificationType.PAYMENT_RECEIVED_USER):
        return (
          <div>{notification.data.payment_type} received today. <Link target="_blank" href={`/home/reservations/${notification.data.booking_id}/payments-and-invoices`}>View Here</Link></div>
        )
    }
  }

  const renderNotificationModal = () => {
    switch (notification.namespace) {
      case (notificationType.INQUIRY_CALLBACK):
      case (notificationType.INQUIRY_GENERAL):
      case (notificationType.INQUIRY_PROPERTY):
      case (notificationType.INQUIRY_AGENT_ASSIGNED):
        return <InquiryOverviewModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          inquiryId={notification.data.inquiry_id}
        />
      case (notificationType.SERVICE_REQUEST_ADMIN):
        return <DashboardServiceRequestModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          serviceRequestId={notification.data.service_request_id}
        />
      case (notificationType.SERVICE_REQUEST_USER):
        return <UserServiceRequestModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          serviceRequestId={notification.data.service_request_id}
        />
      case (notificationType.SURVEY_BANK_INFO_ADMIN):
        return <DashboardSurveyBankInfoModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          surveyBankInfoId={notification.data.bank_information_id}
        />
      case (notificationType.SURVEY_BANK_INFO_USER):
        return <UserSurveyBankInfoModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          surveyBankInfoId={notification.data.bank_information_id}
        />
    }
  }

  return (
    <div className="whitespace-pre-line notification-content">
      {Array.isArray(notification.data)
        ? <div className="font-normal notification-content leading-[24px]" dangerouslySetInnerHTML={{ __html: notification.data }} />
        : <>
          {renderNotificationContent()}
          {renderNotificationModal()}
        </>}
    </div>
  )
}

DashboardNotificationContent.propTypes = {
  notification: PropTypes.object,
}

export default DashboardNotificationContent
