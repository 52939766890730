import { Combobox } from '@headlessui/react'
import PropTypes from 'prop-types'
import Link from 'components/Link'
import { MapPinIcon } from '../../../../icons'

const DestinationSearchItem = ({
  href,
  value,
  onClick,
  children,
}) => {
  return (
    <Combobox.Option
      as={Link}
      href={href}
      value={value}
      onClick={onClick}
    >
      <div className="flex items-center gap-10 px-10 py-5 rounded-full hover:bg-primary-light">
        <div className="flex flex-col rounded-full bg-grey-100 w-30 h-30">
          <MapPinIcon className="mx-auto my-auto h-13 text-grey-1100" />
        </div>

        <span className="font-bold text-14 leading-16 text-grey-800">{children}</span>
      </div>
    </Combobox.Option>
  )
}

DestinationSearchItem.propTypes = {
  href: PropTypes.string,
  value: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
}

export default DestinationSearchItem
