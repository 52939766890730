import PublicMobileModal from 'components/PublicMobileModal'
import { useRef } from 'react'
import { LoadingIcon, MapPinIcon } from 'components/icons'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { Controller, useForm, useFormContext } from 'react-hook-form'
import { DashboardSearchFormFields } from 'data/types/home/search'
import ControlledDestinationsSelection from './ControlledDestinationsSelection'
import { useRouter } from 'next/router'
import { joinBy } from '@ama-selections/ui'

interface MobileDestinationsModalProps {
  isOpen: boolean
  onClose: () => void
  redirect?: boolean
}

export default function MobileDestinationsModal ({
  isOpen,
  onClose,
  redirect,
}: MobileDestinationsModalProps) {
  const { control } = useFormContext<DashboardSearchFormFields>() ?? useForm<DashboardSearchFormFields>()
  const router = useRouter()

  const searchInput = useRef<HTMLInputElement>(null)

  const { searchingDestinations } = useSelector((state: RootState) => ({
    searchingDestinations: state.searchbar.searchingDestinations,
  }))

  return (
    <PublicMobileModal
      isOpen={isOpen}
      onClose={onClose}
      className={{
        panel: '!max-h-screen-safe h-full',
      }}
    >
      <div className="flex flex-col h-full min-h-0 overflow-y-auto">
        <div className="sticky top-0 z-20 flex flex-col gap-10 px-24 pt-20 bg-white border-b-2 border-grey-100">
          <button
            type="button"
            className="flex items-center flex-1 gap-12 text-grey-650"
            onClick={() => searchInput.current?.focus()}
          >
            <MapPinIcon className="text-primary-fresh stroke-[0.5px]" />
            <span>Where to next?</span>
          </button>

          <div className="relative border-b border-transparent focus-within:border-primary-fresh-light">
            <Controller
              name="destination_search"
              control={control}
              render={({ field: { onChange, value } }) => (
                <input
                  ref={searchInput}
                  type="text"
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  className="w-full font-bold border-none focus:outline-none focus:ring-0 text-grey-900 leading-20 text-16"
                />
              )}
            />

            {
              searchingDestinations && (
                <div className="absolute inset-y-0 flex items-center justify-center right-10">
                  <LoadingIcon className="w-16 h-16" />
                </div>
              )
            }
          </div>
        </div>

        <ControlledDestinationsSelection
          pause={!isOpen}
          onSelect={(data) => {
            if (redirect) {
              router.push(
                '/' + joinBy([
                  data.country?.slug,
                  data.region?.slug,
                  data.subregion?.slug,
                ], '/'),
              )
            }
            onClose?.()
          }}
          redirect={redirect}
          padding="md"
        />
      </div>
    </PublicMobileModal>
  )
}
