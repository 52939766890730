import { Fragment, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ArrowLeftIcon, CrossIcon } from './icons'
import classNames from 'classnames'
import IconButton from './IconButton'
import { ReactChild } from 'data/types/types'
import Headers from './home/common/typography/Headers'
import { ModalProps } from 'data/types/modal'
import { ReactTag } from '@headlessui/react/dist/types'

export interface PublicMobileModalProps extends ModalProps {
  onOpen?: () => void
  children?: ReactChild
  transition?: 'left' | 'top' | 'right' | 'bottom' | 'center'
  className?: {
    backdrop?: string
    container?: string
    panel?: string
    closeButton?: string
    backButton?: string
  }
  hasFocusTrap?: boolean
  title?: string,
}

const PublicMobileModal = ({
  isOpen,
  onClose,
  onOpen,
  backIcon,
  children,
  transition = 'bottom',
  className,
  hasFocusTrap = true,
  title,
  isChildModalOpen,
}: PublicMobileModalProps) => {

  useEffect(() => {
    if (isOpen) {
      onOpen?.()
    }
  }, [isOpen])

  const enterFrom = classNames({
    'left translate-x-[-100%]': transition === 'left',
    'top translate-y-[-100%]': transition === 'top',
    'right translate-x-[100%]': transition === 'right',
    'bottom translate-y-[100%]': transition === 'bottom',
    'bottom translate-y-[100%] md:bottom-[initial] md:translate-y-0 md:opacity-0 md:scale-95': transition === 'center',
  })

  const enterTo = classNames({
    'translate-x-0': transition === 'left' || 'right',
    'translate-y-0': transition === 'top' || 'bottom',
    'translate-y-0 md:opacity-100 md:scale-100': transition === 'center',
  })

  const leaveFrom = classNames({
    'left': transition === 'left',
    'top': transition === 'top',
    'right': transition === 'right',
    'bottom': transition === 'bottom',
    'bottom md:bottom-[initial] md:opacity-100 md:scale-100': transition === 'center',
  })

  const leaveTo = classNames({
    'translate-x-[-100%]': transition === 'left',
    'translate-y-[-100%]': transition === 'top',
    'translate-x-[100%]': transition === 'right',
    'translate-y-[100%]': transition === 'bottom',
    'translate-y-[100%] md:translate-y-[0] md:opacity-0 md:scale-95': transition === 'center',
  })

  return (
    <Transition
      as={Fragment as ReactTag}
      appear
      show={isOpen}
    >
      <Dialog
        onClose={() => onClose?.()}
        className="relative z-[60]"
      >
        <div>
          {/* The backdrop, rendered as a fixed sibling to the panel container */}
          <Transition.Child
            as={Fragment as ReactTag}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className={classNames('fixed inset-0 bg-black bg-opacity-10', className?.backdrop)} />
          </Transition.Child>


          {/* Full-screen container to center the panel */}
          <Transition.Child
            className={classNames('fixed inset-x-0 bottom-0 top-safe flex items-end lg:items-center justify-center z-[59]', className?.container)}
            as="div"
            enter="ease-out duration-300"
            enterFrom={enterFrom}
            enterTo={enterTo}
            leave="ease-in duration-200"
            leaveFrom={leaveFrom}
            leaveTo={leaveTo}
          >
            {/* The actual dialog panel */}
            <Dialog.Panel className={classNames(
              'relative w-full mx-auto bg-white rounded-t-24 lg:h-min lg:rounded-24 overflow-y-auto max-h-full lg:max-h-[92.5vh] max-w-modal pb-safe',
              className?.panel, {
                'transition-all scale-50 opacity-0 duration-50': isChildModalOpen,
              },
            )}>
              {/* Focus Trap */}
              {
                hasFocusTrap && (
                  <div className="focus:outline-none" tabIndex={0} />
                )
              }
              {
                backIcon && (
                  <IconButton
                    className={classNames(
                      'absolute top-14 left-14 z-50',
                      className?.backButton,
                    )}
                    onClick={() => onClose?.()}
                    variant="modal-close"
                  >
                    <ArrowLeftIcon
                      className={classNames(
                        'text-blue-1000 stroke-2',
                        className?.backButton,
                      )}
                    />
                  </IconButton>
                )
              }

              {title && (
                <Headers.H1 className="w-full pt-16 text-center">
                  {title}
                </Headers.H1>
              )}

              {children}

              <IconButton
                className={classNames(
                  'absolute top-14 right-14 z-50',
                  className?.closeButton,
                )}
                onClick={() => onClose?.()}
                variant="modal-close"
              >
                <CrossIcon
                  className={classNames(
                    'text-blue-1000',
                    className?.closeButton,
                  )}
                />
              </IconButton>
            </Dialog.Panel>
          </Transition.Child>
        </div>

      </Dialog>
    </Transition>
  )
}

export default PublicMobileModal
