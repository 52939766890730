import PropTypes from 'prop-types'
import DestinationModalCountryCard from './DestinationModalCountryCard'

const DestinationModalViewPropertiesCard = ({
  data = {},
  dataType = 'all',
}) => {
  switch (dataType) {
    case 'country':
      return (
        <DestinationModalCountryCard
          image={data?.country?.random_region?.main_media}
          href={`/${data?.country?.slug}`}
          propertyCount={data?.country?.property_count}
          text={data?.country?.value}
        />
      )
    case 'all':
    default:
      return (
        <DestinationModalCountryCard
          image={data?.randomRegion?.main_media}
          href="/search"
          propertyCount={data?.propertyCount}
        />
      )
  }
}

DestinationModalViewPropertiesCard.propTypes = {
  data: PropTypes.object,
  dataType: PropTypes.oneOf(['country', 'all']),
}

export default DestinationModalViewPropertiesCard
