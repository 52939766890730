import { Switch } from '@headlessui/react'
import classNames from 'classnames'
import { FieldError, Noop } from 'react-hook-form'
import Error from 'components/form/Error'
import Checkbox, { CheckboxOption } from './Checkbox'
import Paragraphs from 'components/home/common/typography/Paragraphs'

export type CheckboxGroupOptions = {
  title?: string
  items: CheckboxOption[]
}

export interface CheckboxGroupProps {
  className?: {
    container?: string
    options?: {
      container?: string
      title?: string
      checkbox_container?: string
      checkbox?: string
    }
    error?: string
  }
  disabled?: boolean
  value: string[]
  onChange: (...event: any[]) => void
  options: CheckboxGroupOptions[]
  onBlur: Noop
  error?: FieldError | undefined
  isChild?: boolean
}

export default function CheckboxGroup ({
  disabled,
  value,
  onChange,
  options,
  onBlur,
  error,
  className,
}: CheckboxGroupProps) {
  return (
    <Switch.Group as="div" className={classNames(
      className?.container,
      'flex flex-col gap-21',
    )}>
      {
        options.map((option, index) => (
          <div className={classNames('flex flex-col gap-10', className?.options?.container)}>
            {option.title && (
              <Paragraphs.LG className={classNames('leading-23 text-grey-800', className?.options?.title)}>
                {option.title}
              </Paragraphs.LG>
            )}

            <div className={classNames('flex flex-col gap-5 ml-10', className?.options?.checkbox_container)}>
              {
                option.items?.map((item) => (
                  <Checkbox
                    key={`checkbox-group-option-${index}-${item.value}`}
                    disabled={disabled}
                    value={value}
                    onChange={onChange}
                    option={item}
                    onBlur={onBlur}
                  />
                ))
              }
            </div>
          </div>
        ))
      }
      <Error className="-mt-10" error={error?.message} />
    </Switch.Group>
  )
}
