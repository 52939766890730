import { DocumentNode } from 'graphql'
import { AnyVariables, OperationContext, TypedDocumentNode, useClient } from 'urql'

type Variables = AnyVariables

interface UseQueryWithPromise<T> {
  query: DocumentNode | TypedDocumentNode<T, Variables> | string
  context?: Partial<OperationContext>
}

type QueryWithPromise = {
  refetchQuery: (variables: AnyVariables) => Promise<unknown>
}

const useQueryWithPromise = function <T> ({
  query,
  context,
}: UseQueryWithPromise<T>): QueryWithPromise {
  const client = useClient()

  const refetchQuery = async (variables: AnyVariables) => {
    return client.query(query, variables, context)
      .toPromise()
      .then((result) => result.data)
      .catch((error) => error)
  }

  return { refetchQuery }
}

export { useQueryWithPromise }
