import React, { forwardRef, useEffect } from 'react'
import { useState } from 'react'
import classNames from 'classnames'

interface TextareaPropTypes extends Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'dangerouslySetInnerHTML'|'children'|'className'|'style'> {
  className?: string
  textareaClassName?: string
  error?: string
  label?: string
  style?: 'admin' | 'customer'
}

const Textarea = forwardRef<HTMLTextAreaElement, TextareaPropTypes>(function Textarea ({
  className = '',
  textareaClassName = '',
  onChange,
  onBlur,
  error,
  name,
  label,
  placeholder,
  value,
  readOnly = false,
  rows = 5,
  style = 'admin',
  disabled = false,
  ...props
}, ref) {
  const [focused, setFocused] = useState(false)
  const [hasContent, setHasContent] = useState(!!value)

  const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    setFocused(false)
    setHasContent(Boolean(e.target.value))
    if (onBlur) {onBlur(e)}
  }

  useEffect(() => {
    setHasContent(!!value)
  }, [value])

  return (
    <div className={`w-full relative ${className.split(' ').filter(c => c.match(/^!?(w-|h-|basis-)/)).join(' ')}`}>
      <textarea
        ref={ref}
        className={classNames(
          'border-none w-full px-20 py-10 focus:outline-none',
          {
            'pt-15': label,

            'ring-red focus:ring-red ring-opacity-50': error,
            'ring-primary focus:ring-primary ring-opacity-8': !error && style === 'admin',
            'ring-input-border focus:ring-input-border ring-opacity-100': !error && style === 'customer',

            'rounded-8 ring-2': style === 'admin',
            'rounded-4 ring-1': style === 'customer',

            'bg-grey-100': disabled && style === 'admin',
          },
          className,
          'transition placeholder-grey-500 focus:ring-opacity-50',
          textareaClassName,
        )}
        name={name}
        onChange={onChange}
        onBlur={handleBlur}
        onFocus={() => setFocused(true)}
        placeholder={placeholder}
        value={value}
        rows={rows}
        readOnly={readOnly}
        disabled={disabled}
        {...props}
      />

      <div className="absolute flex items-center px-20 pointer-events-none top-15">
        <span className={classNames(
          'transform transition-all text-grey-900',
          {
            'text-opacity-100 text-12 -translate-y-14': focused || hasContent,
            'text-opacity-50': !focused && !hasContent,
          },
          (!focused && !hasContent) ? className.match(/(text-\d*)/) : 'text-16',
        )}>
          {label}
        </span>
      </div>

      {
        error
          ? <span className="text-red text-14">{error}</span>
          : null
      }
    </div>
  )
})

export default Textarea
