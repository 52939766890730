import Image from 'components/Image'
import PropTypes from 'prop-types'
import placeholderImage from 'assets/images/placeholder.png'
import ReadMoreButton from 'components/ReadMoreButton'

const DestinationModalCountryCard = ({
  image = {},
  href,
  propertyCount = 0,
  text = 'All',
}) => {
  return (
    <div className="flex-col max-w-[230px] w-full hidden xl:flex pb-20">
      <Image
        src={image?.url ?? placeholderImage}
        alt={image?.file_name}
        width={230}
        height={180}
        className="object-cover rounded-4"
      />

      <h2 className="font-serif font-bold mt-14 text-22 leading-28 text-grey-900">
        View {text}
      </h2>

      <p className="mt-5 font-normal text-[13px] leading-23 text-grey-650">
        Choose from our curated selection of {propertyCount} properties
      </p>

      <ReadMoreButton
        href={href}
        icon={null}
        variant="primary"
        className="w-full mt-16 !text-12 justify-center"
      >
        View properties
      </ReadMoreButton>
    </div>
  )
}

DestinationModalCountryCard.propTypes = {
  image: PropTypes.object,
  href: PropTypes.string,
  propertyCount: PropTypes.number,
  text: PropTypes.string,
}

export default DestinationModalCountryCard
