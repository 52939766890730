import classNames from 'classnames'
import { ReactChild } from 'data/types/types'

interface LabelProps {
  children?: ReactChild
  className?: {
    label?: string
  }
  variant?: 'grey' | 'dark-grey'
}

const Label = ({
  children,
  className,
  variant = 'grey',
}: LabelProps) => {
  return (
    <label className={classNames(
      'inline-block mt-10 text-center',
      'font-poppins tracking-1/4 text-12 leading-22 font-medium',
      {
        'text-grey-650': variant === 'grey',
        'text-grey-800': variant === 'dark-grey',
      },
      className?.label,
    )}>
      {children}
    </label>
  )
}

export { Label }
