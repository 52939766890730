import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CrossIcon } from './icons'
import classNames from 'classnames'
import { ReactChild } from 'data/types/types'
import { ModalProps } from 'data/types/modal'
import IconButton from './IconButton'
import Headers from './home/common/typography/Headers'
import { ReactTag } from '@headlessui/react/dist/types'

interface PublicMobileModalDrawerProps extends ModalProps {
  children: ReactChild,
  position: 'left' | 'right' | 'left-right-icon',
  icon?: ReactChild,
  className?: {
    backdrop?: string
    container?: string
    panel?: string
    closeButton?: string
    title?: string
  }
  hasFocusTrap?: boolean
  title?: string | ReactChild,
  isChildModalOpen?: boolean,
}

const PublicMobileModalDrawer = ({
  isOpen,
  onClose,
  className,
  position = 'right',
  children,
  icon = <CrossIcon />,
  hasFocusTrap = true,
  title,
  isChildModalOpen,
}: PublicMobileModalDrawerProps) => {
  return (
    <Transition appear show={isOpen} as={Fragment as ReactTag}>
      <Dialog
        onClose={onClose}
        className="relative z-[60]"
      >
        <div>
          {/* The backdrop, rendered as a fixed sibling to the panel container */}
          <Transition.Child
            as={Fragment as ReactTag}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className={classNames(
                'fixed inset-0 bg-black bg-opacity-10',
                className?.backdrop,
                { 'ease-in-out duration-200 opacity-0': isChildModalOpen },
              )}
            />
          </Transition.Child>

          {/* Full-screen container to align the panel */}
          <Transition.Child
            as="div"
            className={classNames(
              'fixed inset-y-0 flex z-[59] max-w-md w-full',
              {
                'right-0': position === 'right',
                'left-0': position === 'left' || position === 'left-right-icon',
              },
              className?.container,
            )}
            enter="ease-out duration-200"
            enterFrom={position === 'right' ? 'translate-x-[100%]' : 'translate-x-[-100%]'}
            enterTo="translate-x-0"
            leave="ease-in duration-200"
            leaveFrom="translate-x-0"
            leaveTo={position === 'right' ? 'translate-x-[100%]' : 'translate-x-[-100%]'}
          >
            <Dialog.Panel
              className={classNames(
                'bg-white w-full',
                className?.panel,
                {
                  'ease-in-out duration-300 opacity-0 transition-all translate-x-[100%]': isChildModalOpen,
                },
              )}
            >
              {/* Focus Trap */}
              {
                hasFocusTrap && (
                  <div className="focus:outline-none" tabIndex={0} />
                )
              }

              {title && (
                typeof title === 'string'
                  ? (
                    <Headers.H1 className={classNames(
                      'w-full text-center pt-safe-offset-24',
                      className?.title,
                    )}>
                      {title}
                    </Headers.H1>
                  )
                  : title
              )}

              {children}

              <IconButton
                className={classNames(
                  'absolute top-safe-offset-20',
                  {
                    'left-20': position === 'left',
                    'right-20': position === 'right' || position === 'left-right-icon',
                  },
                  className?.closeButton,
                )}
                onClick={onClose}
                variant="modal-close"
              >
                {icon}
              </IconButton>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

export default PublicMobileModalDrawer
