import { Controller, FieldError } from 'react-hook-form'
import CheckboxGroup from './CheckboxGroup'
import { useEffect } from 'react'

export default function ControlledCheckboxGroup ({
  disabled,
  name,
  control,
  options,
  error,
  isChild,
  isShouldReturnArray = true,
}: {
  disabled?: boolean,
  name: string,
  control: any,
  options: {
    value: string,
    label?: string,
    component?: JSX.Element,
  }[]
  error: FieldError | undefined,
  isChild?: boolean,
  isShouldReturnArray?: boolean
}) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, onBlur } }) => {
        useEffect(() => {
          if (!Array.isArray(value) && isShouldReturnArray) {
            onChange([])
          }
        }, [])

        const handleOnChange = (newValue: string) => {
          if (isShouldReturnArray) {
            if (!Array.isArray(value)) {
              return onChange([newValue])
            }

            if (!value) {
              return onChange([newValue])
            }

            if (value.includes(newValue)) {
              onChange(value.filter((item: string) => item !== newValue))
            } else {
              // Ensure that value always has the same order the options are defined
              onChange(options
                .map((option) => option.value)
                .filter((item) => (item === newValue) || value.includes(item)),
              )
            }
          } else {
            if (value === newValue) {
              onChange(undefined)
            } else {
              onChange(newValue)
            }
          }
        }

        return (
          <CheckboxGroup
            disabled={disabled}
            value={value}
            onChange={handleOnChange}
            onBlur={onBlur}
            options={options}
            error={error}
            isChild={isChild}
          />
        )}}
    />
  )
}
