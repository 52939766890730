import Button, { ButtonProps } from 'components/home/common/Button'
import { useHasScrolled } from 'data/hooks/useHasScrolled'
import { ReactChild } from 'data/types/types'
import UserDashboardNavbar from './UserDashboard/Navbar'
import { useUserDashboard } from 'data/hooks/useUserDashboard'
import { useMobileApp } from 'data/hooks/MobileApp/useMobileApp'
import classNames from 'classnames'
import { useRouter } from 'next/router'

export interface ActionFooterProps {
  scrollDelay?: number // Appear after X pixels
  showNavbar?: boolean
  button?: ButtonProps
  element?: ReactChild
}

const ActionFooter = ({
  button,
  element,
  showNavbar = false,
  scrollDelay,
}: ActionFooterProps) => {
  const hasScrolled = useHasScrolled(scrollDelay)
  const { isOnUserDashboard } = useUserDashboard()
  const { isMobileApp } = useMobileApp()
  const { pathname } = useRouter()

  if (scrollDelay && !hasScrolled) {
    return null
  }

  return (
    <div className={classNames(
      'sticky inset-x-0 bottom-0 z-10 flex flex-col justify-center pt-10 bg-white gap-15 pb-safe-or-10',
      {
        'mt-auto': pathname !== '/home/inbox',
      },
    )}>
      {element}

      {
        button && (
          <div className="w-full px-20">
            <Button
              block
              {...button}
            >
              {button?.children}
            </Button>
          </div>
        )
      }

      {/* Only show UserDashboardNavbar in /home/* unless otherwise specified */}
      {
        (isMobileApp || isOnUserDashboard || showNavbar) && (
          <UserDashboardNavbar />
        )
      }
    </div>
  )
}

export default ActionFooter
