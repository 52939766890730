import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

const parseTime = (time, format = false) => {
  const day = dayjs(time, 'HH:mm')

  if (format) {
    return day.format('h:mm A')
  }

  return day.format('H:mm')
}

export {
  parseTime,
}
