import React from 'react'
import { Switch as HeadlessSwitch } from '@headlessui/react'
import classNames from 'classnames'

export interface SwitchProps {
  checked: boolean
  propagate: boolean
  onChange: (checked: boolean) => void
}

const Switch = ({
  checked = false,
  propagate = true,
  onChange = () => { },
}: SwitchProps) => {
  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    propagate ? null : e.stopPropagation()
  }

  return (
    <div onClick={(e) => handleClick(e)}>
      <HeadlessSwitch
        checked={checked}
        onChange={onChange}
        as="label"
        className={classNames(
          'relative cursor-pointer inline-flex items-center h-25 rounded-full w-50 transition ease-in-out duration-200',
          'focus:outline-none focus:ring ring-primary ring-opacity-30',
          {
            'bg-grey': !checked,
            'bg-primary': checked,
          },
        )}
      >
        <span
          className={classNames(
            'inline-block cursor-pointer w-[19px] h-[19px] transform bg-white rounded-full transition ease-in-out duration-200',
            {
              'translate-x-[27px]': checked,
              'translate-x-[3px]': !checked,
            },
          )}
        />
      </HeadlessSwitch>
    </div>
  )
}

export default Switch
