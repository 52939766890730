import Button from 'components/home/common/Button'
import { GetHelpFormProps, InquiryDetailsFormFields } from './GetHelpModal'
import Textarea from 'components/form/Home/Textarea'
import Label from 'components/form/Home/Label'
import { FieldError, useFormContext } from 'react-hook-form'
import Form from 'components/form/Home'
import { countries } from 'countries-list'
import { joinBy } from '@ama-selections/ui'

const GetHelpCallbackForm = ({
  isLoading,
  onSubmit,
}: GetHelpFormProps) => {
  const { control, register, formState: { errors, isSubmitting }, handleSubmit } = useFormContext<InquiryDetailsFormFields>()

  return (
    <form
      id="callback-inquiry-form"
      className="flex flex-col gap-10 lg:gap-20"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex flex-col gap-10 lg:gap-20 sm:flex-row">
        <Form.Input
          variant="grey"
          placeholder="Your Name"
          {...register('first_name')}
          error={errors?.first_name?.message}
        />
        <Form.Input
          variant="grey"
          placeholder="Your Last Name"
          {...register('last_name')}
          error={errors?.last_name?.message}
        />
      </div>

      <div className="flex flex-col gap-10 lg:gap-20 sm:flex-row">
        <div className="relative w-full">
          <Form.ControlledCountryCodeSelect
            renderValue={(value) => {
              const country = countries[value.data?.code as keyof typeof countries]

              return joinBy([
                country?.emoji,
                value.data?.name,
                `(+${value.value})`,
              ], ' ')
            }}
            control={control}
            name="country_code"
            error={errors?.country_code as FieldError}
            placeholder="Country Code"
            className={{
              options: {
                container: '!max-h-[250px]',
              },
            }}
          />
        </div>
        <div className="w-full">
          <Form.Input
            variant="grey"
            placeholder="Your Phone Number"
            {...register('phone_number')}
            error={errors?.phone_number?.message}
          />
        </div>
      </div>
      <div className="flex-1">
        <Label className="mb-[9px] text-grey-800 !text-14">Message</Label>
        <Textarea
          className={{
            container: 'flex-1',
          }}
          placeholder="Let us know when and where you'd like to travel to and what the occasion is ..."
          {...register('message')}
          error={errors?.message}
        />
      </div>

      <Button
        style="modal"
        type="submit"
        isLoading={isSubmitting || isLoading}
        disabled={isSubmitting}
      >
        Request Callback
      </Button>

    </form>
  )
}

export default GetHelpCallbackForm
