const isAdmin = (user) => {
  return user && user.type === 'admin'
}

const isSuperAdmin = (user) => {
  return user && user.type === 'admin' && user?.sub_type === 'admin'
}

const getLink = (user) => {
  if (!user) {
    return '/dashboard/manage/users'
  }

  const userLinks = {
    'admin': `team?id=${user.id}`,
    'customer': `users?id=${user.id}`,
    'owner_partner': `partners?id=${user.id}`,
  }

  return `/dashboard/manage/${userLinks[user.type]}`
}

const getProfileImage = (user) => {
  if (!user) {
    return ''
  }
  return typeof user.profile_image === 'string' ? user.profile_image : user.profile_image?.url
}

export {
  isAdmin,
  isSuperAdmin,
  getLink,
  getProfileImage,
}
