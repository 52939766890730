import { Membership } from './enums/membership-type'

export const config = {
  baseURL: process.env.NEXT_PUBLIC_API_BASE_URL!,
  appURL: process.env.NEXT_PUBLIC_APP_BASE_URL!,
  graphQlURL: process.env.NEXT_PUBLIC_GRAPHQL_BASE_URL!,

  isMobileAppCookie: process.env.NEXT_PUBLIC_MOBILE_APP_COOKIE!,
  awsBucket: process.env.AWS_BUCKET!,

  enableImageLoader: process.env.NEXT_PUBLIC_ENABLE_IMAGE_LOADER === 'true',

  displayAppURL: 'amaselections.com',
  whitelabelURL: process.env.NEXT_PUBLIC_WHITELABEL_BASE_URL!,
  googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY,
  amaSelectionsPhoneNumber: process.env.NEXT_PUBLIC_AMA_SELECTIONS_PHONE_NUMBER?.replaceAll(' ', ''),
  amaSelectionsDisplayPhoneNumber: process.env.NEXT_PUBLIC_AMA_SELECTIONS_PHONE_NUMBER,
  amaSelectionsEmail: process.env.NEXT_PUBLIC_AMA_SELECTIONS_EMAIL,
  partnerInquiryFormLink: process.env.NEXT_PUBLIC_PARTNER_INQUIRIES_LINK!,
  travelAgentInquiryFormLink: process.env.NEXT_PUBLIC_TRAVEL_AGENT_INQUIRIES_LINK!,

  stripePercentageCharge: process.env.NEXT_PUBLIC_STRIPE_PERCENTAGE_CHARGE!,
  stripeDashboardUrl: process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY!.includes('test')
    ? 'https://dashboard.stripe.com/test'
    : 'https://dashboard.stripe.com',
  stripePassportUploadDays: 7,

  bank: {
    name: 'Societe Generale',
    accountName: 'A.M.A Selections',
    iban: 'FR76 3000 3004 9500 0208 0147 242',
    bic: 'SOGEFRPP',
    addressLine1: '809 Boulevard des Écureuils',
    addressLine2: '06210 Mandelieu-la-Napoule',
    addressLine3: 'France',
  },

  company: {
    name: 'A.M.A Selections SAS',
    address_line_1: '14 Rue Merle',
    address_line_2: '06400 Cannes',
    address_line_3: 'France',
    vat_id: 'FR43794870261',
  },

  stripePK: process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY!,
  recaptchaSiteKey: process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY,
  siteName: 'A.M.A Selections Holiday Villas',
  acceptedImageTypes: 'image/jpg,image/jpeg,image/bmp,image/png,image/webp',
  acceptedFileTypes: 'application/pdf,image/jpeg,image/png,image/webp,video/mp4,application/vnd.openxmlformats-officedocument.wordprocessingml.document,doc,docx,application/applefil',
  acceptedInvoiceFileTypes: 'application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,doc,docx,application/applefil',
  wishlistCharacterLimit: 50,
  wishlistCreationLimit: 50,
  homepageVideoHLSUrl: process.env.NEXT_PUBLIC_HOMEPAGE_VIDEO_URL
    ? process.env.NEXT_PUBLIC_HOMEPAGE_VIDEO_URL + '/manifest/video.m3u8'
    : null,
  homepageVideoDashUrl: process.env.NEXT_PUBLIC_HOMEPAGE_VIDEO_URL
    ? process.env.NEXT_PUBLIC_HOMEPAGE_VIDEO_URL + '/manifest/video.mpd'
    : null,
  homepageVideoThumbnailURL: process.env.NEXT_PUBLIC_HOMEPAGE_VIDEO_URL
    ? process.env.NEXT_PUBLIC_HOMEPAGE_VIDEO_URL + '/thumbnails/thumbnail.jpg?height=1400'
    : null,
  homepageVideoId: process.env.NEXT_PUBLIC_HOMEPAGE_VIDEO_ID,

  // Registration
  bookingChannelOptions: [
    { value: 'airbnb', label: 'Airbnb / VRBO' },
    { value: 'facebook', label: 'Facebook' },
    { value: 'google', label: 'Google Search' },
    { value: 'instagram', label: 'Instagram' },
    { value: 'marriott', label: 'Marriott' },
    { value: 'press', label: 'Press' },
    { value: 'referral', label: 'Referral' },
  ],

  // Commission
  vatPercentage: 20,
  totalCommissionPercentageThreshold: 20,
  maximumAgentCommission: 10,

  userDashboard: {
    publicUrls: [
      '/search',
    ],
  },

  membership: {
    [Membership.REGISTERED]: {
      features: [],
    },
    [Membership.CLASSIC]: {
      features: [
        'Access to exclusive offers',
        '1% loyalty credit earned on every stay',
      ],
    },
    [Membership.SILVER]: {
      description: '1% Loyalty credit earned on every stay.<br />10 reservations per booking (250€ value).<br />Zero concierge service fees.',
      yearlyPrice: 500,
      features: [
        'Access to exclusive offers',
        '1% Loyalty credit earned on every stay',
        '10 reservations per booking (250€ value)',
        'Zero concierge service fees',
      ],
    },
    [Membership.GOLD]: {
      description: '2% Loyalty credit earned on every stay.<br />10 reservations per booking (250€ value).<br />Zero concierge service fees.<br />2000€ accidental damage waiver included.',
      yearlyPrice: 1000,
      features: [
        'Access to exclusive offers',
        '2% Loyalty credit earned on every stay',
        '10 reservations per booking (250€ value)',
        'Zero concierge service fees',
        '2000€ accidental damage waiver included',
      ],
    },
  },

  services: {
    service: {
      id: 1,
      searchTerm: 'Service',
    },
    experience: {
      id: 2,
      searchTerm: 'Experience',
    },
  },
}
