export default class WishlistApiClient {
  constructor (http) {
    this.http = http
  }

  /**
  * Get all wishlists for a user
  *
  * @param {string} search the text to search by
  * @returns {Array}
  */
  async getWishlists (search) {
    const result = await this.http.get('/wishlists', {
      params: {
        search: search,
      },
    })
    return result.data.data
  }

  async getWishlist (token) {
    const result = await this.http.get('/wishlist', {
      params: {
        token: token,
      },
    })
    return result.data.data
  }

  async deleteWishlist (id) {
    const result = await this.http.delete(`/wishlists/${id}`)
    return result
  }

  /**
   * Send an invite to the wishlist over email
   *
   * @param {number} id The Id of the wishlist
   * @param {object} details The email and name of the person to invite
   * @returns {object}
   */
  async inviteToWishlist (id, data) {
    const result = await this.http.post(`/wishlists/${id}/invite`, data)
    return result.data.data
  }

  /**
   * Removes a property from a wishlist
   *
   * @param {number} wishlistId
   * @param {number} propertyId the ID of the property you are removing from a wishlist
   * @returns {object}
   */
  async removePropertyFromWishlist (wishlistId, propertyId) {
    const result = await this.http.delete(`/wishlists/${wishlistId}/delete/${propertyId}`)
    return result
  }

  /**
   * This takes a user ID and grabs all invites and users that have a wishlist or have been invited to a wishlist from this user
   *
   * @param {Int} userId The Id of the user we want to get list of users from
   * @returns
   */
  async getWishlistUsers (userId) {
    const result = await this.http.get(`/users/${userId}/wishlist-users`)
    return result.data
  }
}
