function Error ({
  error,
  className = '',
}: {
  error?: string
  className?: string
}) {
  if (!error) {
    return null
  }

  return (
    <div className={`w-full text-red text-14 ${className}`}>
      {error}
    </div>
  )
}

export default Error
