import DestinationModalContinentList from 'components/modals/frontend/Destinations/DestinationModalContinentList'
import { isServer } from 'data/helpers/ssr'
import { graphql } from 'gql'
import { useEffect, useMemo, useState } from 'react'
import { useQuery } from 'urql'
import DestinationCountryAccordion from './DestinationCountryAccordion'
import { LoadingIcon } from 'components/icons'
import { useDestinationSearch } from 'data/hooks/Destinations/useDestinationSearch'
import SectionHeaders from 'components/home/common/typography/SectionHeaders'
import SearchResults from 'components/popovers/DestinationSelectionPopover/_SearchResults'
import { Controller, useForm, useFormContext } from 'react-hook-form'
import { SelectedDestinationData } from 'components/popovers/DestinationSelectionPopover/_types'
import classNames from 'classnames'

const DESTINATION_SELECTION_QUERY = graphql(`
  query DestinationSelectionCountriesData($filters: CountriesFilterInput) {
    countries(first: 100, filters: $filters) {
      data {
        id
        value
        slug
      }
    }
  }
`)

export type ControlledDestinationsSelectionsFormFields = {
  destination: SelectedDestinationData | undefined
  destination_search: string | undefined
}

interface ControlledDestinationsSelectionsProps {
  pause: boolean
  onSelect: (data: SelectedDestinationData) => void
  redirect?: boolean
  padding: 'md' | 'lg'
}

export default function ControlledDestinationsSelections ({
  pause,
  onSelect,
  redirect,
  padding,
}: ControlledDestinationsSelectionsProps) {
  const { control, watch, setValue } = useFormContext<ControlledDestinationsSelectionsFormFields>() ?? useForm<ControlledDestinationsSelectionsFormFields>()

  const [continentId, setContinentId] = useState<string | undefined>(undefined)

  const [{ data, fetching }] = useQuery({
    query: DESTINATION_SELECTION_QUERY,
    pause: pause || isServer,
    variables: {
      filters: {
        continent_id: continentId,
      },
    },
  })

  const { results: destinationSearchResults, isSearchResults } = useDestinationSearch(watch('destination_search'))

  useEffect(() => {
    if (!watch('destination_search')) {
      setValue('destination', undefined)
    }
  }, [watch('destination_search')])

  const searchResultsLength = useMemo(
    () => (destinationSearchResults ?? []).length,
    [destinationSearchResults],
  )

  return (
    <Controller
      name="destination"
      control={control}
      render={({ field: { onChange } }) => (
        <>
          {
            destinationSearchResults && (
              <div className={classNames(
                'flex flex-col',
                {
                  'min-h-[100px]': searchResultsLength === 1,
                  'min-h-[145px]': searchResultsLength === 2,
                  'min-h-[190px]': searchResultsLength === 3,
                  'min-h-[235px]': searchResultsLength >= 4,
                  'mt-35': padding === 'lg',
                },
              )}>
                <SectionHeaders.MD className={classNames(
                  'text-grey-650 py-10 sticky bg-white z-10',
                  {
                    'top-[93px] px-24': padding === 'md',
                    'top-0 px-40': padding === 'lg',
                  },
                )}>
                  {isSearchResults
                    ? 'Suggestions'
                    : 'Popular Destinations'
                  }
                </SectionHeaders.MD>


                <SearchResults
                  className={{
                    container: classNames(
                      'mt-5',
                      {
                        'px-24': padding === 'md',
                        'px-40': padding === 'lg',
                      },
                    ),
                  }}
                  onClick={(data) => {
                    onChange(data)
                    setValue('destination_search', data.value)
                    onSelect(data)
                  }}
                  destinations={destinationSearchResults}
                />
              </div>
            )
          }

          <SectionHeaders.MD className={classNames(
            'text-grey-650 sticky bg-white z-10 py-10',
            {
              'top-[93px] px-24': padding === 'md',
              'top-0 px-40': padding === 'lg',
            },
          )}>
            All Destinations
          </SectionHeaders.MD>

          <DestinationModalContinentList
            pause={pause}
            continentId={continentId}
            setContinentId={setContinentId}
            className={{
              container: 'pt-20 pb-10',
            }}
            slideOffset={padding === 'md' ? 24 : 40}
          />

          {
            (fetching && !data) && (
              <div className="flex justify-center py-50">
                <LoadingIcon />
              </div>
            )
          }

          <div className="flex flex-col pb-safe-offset-15">
            {
              data?.countries.data.map((country) => (
                <DestinationCountryAccordion
                  redirect={redirect}
                  onSelect={(data: SelectedDestinationData) => {
                    onChange(data)
                    setValue('destination_search', data.value)
                    onSelect(data)
                  }}
                  key={country.id}
                  pause={pause}
                  country={country}
                  className={{
                    container: classNames({
                      'px-24': padding === 'md',
                      'px-40': padding === 'lg',
                    }),
                  }}
                />
              ))
            }
          </div>
        </>
      )}
    />
  )
}
