import { AxiosInstance } from 'axios'
import RestReview from 'data/types/review'

export default class ReviewsApiClient {
  http: AxiosInstance

  constructor (http: AxiosInstance) {
    this.http = http
  }

  async getReviews (filters: ReviewsFilters = {}, options: ReviewsOptions = {}): Promise<ReviewsResponse> {
    const result = await this.http.get('/reviews', {
      params: {
        ...filters,
        page: options.page ?? 1,
        per_page: options.per_page ?? 3,
      },
    })
    return result.data
  }
}

type ReviewsFilters = {
  property_id?: number | string
  property_slug?: string
}

type ReviewsOptions = {
  page?: number
  per_page?: number
}

type ReviewsResponse = ReviewPropertySlugResponse | ReviewPropertyIdResponse

type ReviewPropertySlugResponse = {
  reviews: RestReview[]
  totalReviews: number
  average: number
  page: number
}

type ReviewPropertyIdResponse = {
  data: RestReview[]
}

export type {
  ReviewsFilters,
  ReviewsOptions,
  ReviewsResponse,
  ReviewPropertySlugResponse,
  ReviewPropertyIdResponse,
}
