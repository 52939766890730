import { useDispatch, useSelector } from 'react-redux'
import { usePusher } from 'data/hooks/usePusher'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { newMessageEvent, setUnreadCount } from 'store/inbox'
import { useQueryClient } from 'react-query'
import { setVerificationSession } from 'store/auth'

const PusherProvider = ({ children }) => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()

  const user = useSelector(state => state.auth.user)

  const { pusher } = usePusher(user?.id)

  // Listen for Pusher Events
  const [channel, setChannel] = useState(null)

  useEffect(() => {
    if (pusher && pusher?.channel(`private-users.${user?.id}`) === undefined) {
      setChannel(pusher.subscribe(`private-users.${user?.id}`))
    }

    return () => pusher?.unsubscribe(`private-users.${user?.id}`)
  }, [pusher])

  useEffect(() => {
    if (!channel) {return}

    channel.bind('Illuminate\\Notifications\\Events\\BroadcastNotificationCreated', function (data) {
      switch (data.type) {
        case ('App\\Notifications\\User\\NewMessage'):
          dispatch(newMessageEvent({ message: data.message.id }))
          dispatch(setUnreadCount(data.total_unread_messages))

          break
        case ('App\\Notifications\\User\\StripeCheckoutSessionCompleted'):
          queryClient.invalidateQueries('payments')
          queryClient.invalidateQueries('tripScheduleServices')

          break

        case ('App\\Notifications\\Stripe\\Identity\\VerificationSessionUpdate'):
          dispatch(setVerificationSession(data.stripeVerificationSession))

          break
        default:
          return
      }
    })

    return () => channel.unbind('Illuminate\\Notifications\\Events\\BroadcastNotificationCreated')
  }, [channel])

  // Return layout unchanged
  return children
}

PusherProvider.propTypes = {
  children: PropTypes.node,
}

export default PusherProvider
