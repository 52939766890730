import classNames from 'classnames'

function Error ({
  error,
  className = '',
}: {
  error?: string
  className?: string
}) {
  if (!error) {
    return null
  }

  return (
    <div className={classNames('font-bold text-red text-12 pl-4 pt-2', className)}>
      {error}
    </div>
  )
}

export default Error
