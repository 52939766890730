import { React } from 'react'
import PropTypes from 'prop-types'
import { config } from 'data/config'
import TypeformModal from './TypeformModal'

const PartnerInquiryModal = ({
  isOpen,
  onClose = () => { },
}) =>  (
  <TypeformModal
    isOpen={isOpen}
    onClose={onClose}
    type="owner"
    formLink={config.partnerInquiryFormLink}
  />
)

PartnerInquiryModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default PartnerInquiryModal
