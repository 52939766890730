import React from 'react'
import Error from 'components/form/Error'
import ButtonGroupItem from './ButtonGroupItem'
import { FieldError, Noop } from 'react-hook-form'
import { RadioGroup as HeadlessRadioGroup } from '@headlessui/react'

type PropTypes = {
    disabled: boolean
    options: { value: number | string, label: string }[]
    error: FieldError | undefined
    value: string | number
    onChange: (value: string | number) => void
    onBlur: Noop
}

const ButtonGroup: React.FC<PropTypes> = ({
  disabled,
  options,
  error,
  value,
  onChange,
  onBlur,
}) => {
  return (
    <HeadlessRadioGroup
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
    >
      <div className="flex flex-col gap-14">
        <div className="flex flex-wrap gap-20">
          {options.map(option => (
            <HeadlessRadioGroup.Option
              key={option.value}
              value={option.value}
            >
              {({ checked }) => (
                <ButtonGroupItem
                  key={option.value}
                  checked={checked}
                  label={option.label}
                  disabled={disabled}
                  error={error?.message}
                />
              )}
            </HeadlessRadioGroup.Option>
          ))}
        </div>

        <Error error={error?.message}/>
      </div>
    </HeadlessRadioGroup>
  )
}

export default ButtonGroup
