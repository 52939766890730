import { useEffect } from 'react'

const useBodyClasses = (...classes) => {
  useEffect(() => {
    document.querySelector('body').classList.add(...classes.flat())
    return () => {
      document.querySelector('body').classList.remove(...classes.flat())
    }
  }, [])
}

export default useBodyClasses
