import React from 'react'
import DashboardModal from 'components/DashboardModal'
import { gql, useQuery } from 'urql'
import { LoadingIcon } from 'components/icons'
import { SurveyBankInfoModalPropTypes } from 'data/types/service_surveys/bank-info'

const DashboardSurveyBankInfoModal: React.FC<SurveyBankInfoModalPropTypes> = ({
  isOpen = false,
  onClose = () => { },
  surveyBankInfoId,
}) => {

  const [{ data, fetching   }] = useQuery({
    query: gql`
      query getSurveyBankInfoForAdmin($id: ID!) {
        surveyBankInfo(id: $id) {
          id
          user {
            first_name
            last_name
          }
          details {
            question
            answer
          }
        }
      }
    `,
    variables: { id: surveyBankInfoId },
    pause: !isOpen,
  })

  return (
    <DashboardModal
      title={(fetching || !data?.surveyBankInfo)
        ? 'Loading ...'
        : `Bank Info Form - ${data.surveyBankInfo.user.first_name} ${data.surveyBankInfo.user.last_name} - ${data.surveyBankInfo.id}`
      }
      isOpen={isOpen}
      onClose={() => onClose()}
      size="4xl"
      className="lg:min-h-[450px]"
    >
      <div className="h-full lg:p-20">
        {(fetching || !data?.surveyBankInfo)
          ? <div className="flex items-center justify-center w-full h-full"><LoadingIcon/></div>
          : <>
            <div className="flex flex-col gap-25">
              <div
                className="relative w-full h-full px-20 overflow-y-auto max-h-[566px] bg-soft ring-1 lg:ring-2 ring-input-border lg:ring-primary lg:ring-opacity-8 rounded-8">
                <p className="pt-20 antialiased text-grey-800 min-h-[140px] text-14 lg:text-16 whitespace-pre">
                  {(data.surveyBankInfo.details ?? []).map((item: {question: string, answer: string}, index: number) =>
                    <span key={index}>
                      <b>{item.question}</b><br/>{item.answer}<br/><br/>
                    </span>,
                  )}
                </p>
                <div className="absolute inset-0 flex px-20 pointer-events-none top-5">
                  <span className={'text-grey-900 text-12'}>
                    Bank Information
                  </span>
                </div>

              </div>
            </div>
          </>
        }
      </div>
    </DashboardModal>
  )
}

export default DashboardSurveyBankInfoModal
