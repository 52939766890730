import classNames from 'classnames'
import { ReactChild } from 'data/types/types'
import Link from 'components/Link'
import { LinkProps } from 'next/link'
import NotificationDot from 'components/NotificationDot'
import Form from 'components/form/Home'
import { ToggleProps } from 'components/form/Home/Toggle'

export interface NavItemProps {
  children: ReactChild
  icon?: {
    child: ReactChild
    position: 'left' | 'right'
  }
  className?: string
  href?: LinkProps['href']
  onClick?: () => void
  toggle?: ToggleProps
  number?: number
}

export const NavItem = ({
  children,
  icon,
  className,
  href,
  onClick,
  toggle,
  number,
}: NavItemProps) => {
  const styles = classNames(
    'flex items-center py-8 px-12 -mx-10',
    'text-grey-800 text-15 leading-28 tracking-1/2',
    {
      'focus:outline-none lg:focus:text-primary-fresh hover:bg-grey-100 rounded-16': href || onClick,
      'justify-between': icon?.position == 'right',
    },
    className,
  )

  const content = (
    <>
      {icon?.position == 'left' ? <span className="mr-15">{icon?.child}</span> : null}
      {children}
      {icon?.position == 'right' ? <span>{icon?.child}</span> : null}
      {
        toggle
          ? (
            <span className="flex items-center ml-auto">
              <Form.Toggle {...toggle} />
            </span>
          )
          : null
      }
      {
        number
          ? (
            <span className="ml-auto mr-[6px]">
              <NotificationDot
                number={number}
                size="lg"
                position="block"
              />
            </span>
          )
          : null
      }
    </>
  )

  if (href) {
    return (
      <Link href={href} className={styles}>
        {content}
      </Link>
    )
  } else if (onClick) {
    return (
      <button onClick={onClick} className={styles}>
        {content}
      </button>
    )
  } else {
    return (
      <div className={styles}>
        {content}
      </div>
    )
  }
}
