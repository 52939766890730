import axios, { AxiosError } from 'axios'
import { config } from 'data/config'
import UserApiClient from 'data/api/user_api_client'
import AuthApiClient from 'data/api/auth_api_client'
import PropertyApiClient from 'data/api/property_api_client'
import PropertyCoordinatesApiClient from 'data/api/property_coordinates_api_client'
import ReviewsApiClient from 'data/api/reviews_api_client'
import DestinationsApiClient from 'data/api/destinations_api_client'
import ServicesApiClient from './services_api_client'
import ServiceRequestsApiClient from './service_requests_api_client'
import MagazineApiClient from './magazine_api_client'
import CalendarApiClient from './calendar_api_client'
import SearchApiClient from './search_api_client'
import LocationsApiClient from './locations_api_client'
import WishlistApiClient from './wishlist_api_client'
import FieldsApiClient from './fields_api_client'
import FilesApiClient from './files_api_client'
import RatesApiClient from './rates_api_client'
import BookingApiClient from './booking_api_client'
import PaymentApiClient from './payment_api_client'
import InquiriesApiClient from './inquiries_api_client'
import NotificationsApiClient from './notification_api_client'
import PreferencesApiClient from './preferences_api_client'
import TripScheduleApiClient from './trip_schedule_api_client'
import SiteSettingsApiClient from './site_settings'
import { ForbiddenError, NotFoundError, UnauthenticatedError } from 'data/error/errors'
import { stringify } from 'qs'
import { GetServerSidePropsContext } from 'next'

const http = axios.create({
  baseURL: config.baseURL,
  withCredentials: true,
  paramsSerializer: (params) => {
    return stringify(params, { arrayFormat: 'brackets' })
  },
})

http.interceptors.request.use((config) => {
  if (config.params) {
    config.params = Object.fromEntries(
      Object.entries(config.params).map(([key, value]) => {
        if (typeof value === 'boolean') {
          return [key, value ? 1 : 0]
        }
        return [key, value]
      }),
    )
  }
  return config
})

http.interceptors.response.use(function (response) {
  return response
}, function (error: AxiosError) {
  switch (error.response?.status) {
    case 404:
      throw new NotFoundError('Not Found')
    case 403:
      throw new ForbiddenError('Forbidden')
    case 401:
      throw new UnauthenticatedError('Unauthenticated')
  }

  return Promise.reject(error)
})

export default class ApiClient {
  /**
   * Configure the API client to forward cookies and referer for server side rendering
   *
   * @param {Object} req The SSR request object
   * @returns {ApiClient}
   */
  static withReq (req: GetServerSidePropsContext['req']) {
    if (req.headers.referer) {
      http.defaults.headers.referer = req.headers.referer
    }
    if (req.headers.origin) {
      http.defaults.headers.origin = req.headers.origin
    }
    if (req.headers.cookie) {
      http.defaults.headers.cookie = req.headers.cookie
    }
    return this
  }

  static auth = new AuthApiClient(http)
  static bookings = new BookingApiClient(http)
  static calendar = new CalendarApiClient(http)
  static destinations = new DestinationsApiClient(http)
  static fields = new FieldsApiClient(http)
  static files = new FilesApiClient(http)
  static inquiries = new InquiriesApiClient(http)
  static locations = new LocationsApiClient(http)
  static magazine = new MagazineApiClient(http)
  static notifications = new NotificationsApiClient(http)
  static payments = new PaymentApiClient(http)
  static preferences = new PreferencesApiClient(http)
  static properties = new PropertyApiClient(http)
  static propertiesCoordinates = new PropertyCoordinatesApiClient(http)
  static rates = new RatesApiClient(http)
  static reviews = new ReviewsApiClient(http)
  static search = new SearchApiClient(http)
  static services = new ServicesApiClient(http)
  static serviceRequests = new ServiceRequestsApiClient(http)
  static siteSettings = new SiteSettingsApiClient(http)
  static tripSchedule = new TripScheduleApiClient(http)
  static users = new UserApiClient(http)
  static wishlists = new WishlistApiClient(http)
}
