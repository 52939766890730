import { Listbox, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { CheckIcon, ChevronDownFlaredIcon } from 'components/icons'
import { FieldError } from 'react-hook-form'
import Error from 'components/form/Error'
import { Fragment } from 'react'
import { ReactTag } from '@headlessui/react/dist/types'

export default function Select ({
  label,
  value = '',
  placeholder,
  onChange,
  options,
  disabled,
  error,
  variant = 'thick',
}: {
  label?: string
  value: string | number | {label: string, value: string}
  placeholder: string
  onChange: (...event: any[]) => void
  options: string[] | {label: string, value: string}[]
  disabled?: boolean
  error?: FieldError
  variant?: 'thin' | 'thick'
}) {
  return (
    <div className="flex flex-col md:ml-40 gap-21">
      <Listbox
        as={Fragment as ReactTag}
        value={value}
        onChange={onChange}
        disabled={disabled}
      >
        {({ open }) => (
          <div className="flex flex-col">
            {
              label && (
                <Listbox.Label className="mb-10 text-14 leading-20 text-grey-800">
                  {label}
                </Listbox.Label>
              )
            }
            <div className="relative">
              <Listbox.Button
                className={classNames(
                  'flex items-center justify-between w-full text-left border rounded-16 bg-grey-100 focus:outline-none',
                  {
                    'border-red border-opacity-50 focus:border-red': error,
                    'border-grey-100 focus:border-primary focus:border-opacity-50': !error,
                    'p-20': variant === 'thick',
                    'py-10 px-20': variant === 'thin',
                  },
                )}
              >
                <span className={classNames('text-16 leading-20', {
                  'text-placeholder': !value,
                  'text-grey-800': value,
                  'text-15': variant === 'thick',
                  'text-14': variant === 'thin',
                })}>
                  {value
                    ? typeof (value) !== 'object' ? value : value.label
                    : placeholder}
                </span>
                <ChevronDownFlaredIcon className={classNames('text-grey-800 transition', {
                  'transform rotate-180': open,
                  'text-18': variant === 'thick',
                  'text-14': variant === 'thin',
                })} />
              </Listbox.Button>

              <Transition
                show={open}
                as="div"
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 flex flex-col w-full border cursor-pointer bg-grey-100 rounded-16 border-primary-fresh-light focus:outline-none">
                  {
                    options.map((option, index) => (
                      <Listbox.Option
                        key={`listbox-option-${index}`}
                        value={option}
                        className="overflow-hidden first:rounded-t-16 last:rounded-b-16"
                      >
                        {({ active, selected }) => (
                          <div className={classNames(
                            'px-21 py-10 text-15 leading-23 text-grey-800 flex justify-between items-center',
                            {
                              'bg-primary-fresh-light': active,
                            },
                          )}>
                            {typeof(option) !== 'object' ? option : option.label}
                            {
                              selected && (
                                <CheckIcon className="w-14 h-14 text-primary-fresh" />
                              )
                            }
                          </div>
                        )}
                      </Listbox.Option>
                    ))}
                </Listbox.Options>
              </Transition>
            </div>
          </div>
        )}
      </Listbox>

      <Error className="-mt-10" error={error?.message} />
    </div>
  )
}
