import { AxiosInstance } from 'axios'
import { RestArticle, RestArticleAuthor, RestArticlePage, RestArticleTaxonomy } from 'data/types/article'

export default class MagazineApiClient {
  http: AxiosInstance

  constructor (http: AxiosInstance) {
    this.http = http
  }

  async getCategories (): Promise<RestArticleTaxonomy[]> {
    const result = await this.http.get('/magazines/categories')
    return result.data
  }

  async getArticles (data?: Record<string, unknown>): Promise<RestArticlePage> {
    const result = await this.http.get('/magazines', {
      params: data,
    })
    return result.data
  }

  async getArticle (slug: string): Promise<RestArticle> {
    const result = await this.http.get(`/magazines/${slug}`)
    return result.data
  }

  async getAuthor (slug: string): Promise<RestArticleAuthor> {
    const result = await this.http.get(`/magazines/authors/${slug}`)
    return result.data
  }
}
