export default class DestinationsApiClient {
  constructor (http) {
    this.http = http
  }

  /**
   * Fetch a single destination
   *
   * @param {number} id The id of the destination to fetch
   * @returns {Object}
   */
  async getDestination (id) {
    const result = await this.http.get(`/destinations/${id}`)
    return result.data.data
  }

  /**
   * Get a list of al destinations
   *
   * @returns {Array}
   */
  async getDestinations () {
    const result = await this.http.get('/destinations')
    return result.data.data
  }
}
