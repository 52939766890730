import { HTMLAttributeAnchorTarget } from 'react'
import Link from 'components/Link'
import CarouselPropertyImagesSmall from 'components/carousels/CarouselPropertyImagesSmall'
import Paragraphs from 'components/home/common/typography/Paragraphs'
import { propertyPrice } from 'data/helpers/properties'
import { joinBy, pluraliseWithNumber } from 'data/helpers/strings'
import { LinkProps } from 'next/link'
import { graphql } from 'gql'
import { PropertyCard_NoImages_PropertyFragmentFragment, PropertyCard_PropertyFragmentFragment } from 'gql/graphql'
import classNames from 'classnames'
import Headers from 'components/home/common/typography/Headers'

export const PropertyCard_PropertyFragment = graphql(/* GraphQL */ `
  fragment PropertyCard_PropertyFragment on Property {
    id

    title
    reference
    url

    guests
    bedrooms
    bathrooms

    # Price
    price_range {
      min
      max
    }
    currency

    # Images
    main_image {
      id
      url
      file_name
    }
    main_image_alt_text

    images {
      id
      url
      file_name
    }

    # Location
    city {
      id
      value
    }
    region {
      id
      value
    }

    wishlists(first: 1) {
      paginatorInfo {
        total
      }
    }
  }
`)

export const PropertyCard_NoImages_PropertyFragment = graphql(/* GraphQL */ `
  fragment PropertyCard_NoImages_PropertyFragment on Property {
    id

    title
    reference
    url

    guests
    bedrooms
    bathrooms

    # Price
    price_range {
      min
      max
    }
    currency

    # Images
    main_image {
      id
      url
      file_name
    }
    main_image_alt_text

    # Location
    city {
      id
      value
    }
    region {
      id
      value
    }

    wishlists(first: 1) {
      paginatorInfo {
        total
      }
    }
  }
`)

interface PropertyCardProps {
  property: PropertyCard_PropertyFragmentFragment | PropertyCard_NoImages_PropertyFragmentFragment
  mainImageUrlOverride?: string
  target?: HTMLAttributeAnchorTarget
  url?: LinkProps['href']
  variant?: 'show-wishlist' | 'hide-wishlist'
  isMapCard?: boolean
  className?: {
    container?: string
  },
}

const PropertyCard = ({
  property,
  mainImageUrlOverride,
  target = '_self',
  url,
  variant = 'show-wishlist',
  isMapCard,
  className,
}: PropertyCardProps) => {
  return (
    <Link
      className={classNames(
        'cursor-pointer',
        className?.container,
      )}
      href={url ?? `/${property.url}`}
      target={target}
      key={`property-${property.id}`}
    >
      <div className="flex flex-col">
        <div className="relative aspect-h-5 aspect-w-7">
          <CarouselPropertyImagesSmall
            propertyMainImage={{
              media: mainImageUrlOverride ?? property.main_image?.url,
              alt_text: property.main_image_alt_text ?? property.main_image?.file_name ?? property?.title,
            }}
            propertyImages={
              'images' in property
                ? property.images?.map(image => ({
                  media: image.url,
                  alt_text: image?.file_name,
                }))
                : undefined
            }
            propertyId={variant === 'hide-wishlist' ? undefined : property.id}
            numberOfWishlists={property?.wishlists?.paginatorInfo?.total}
            imageWidth="706"
          />
        </div>

        <div className={classNames('flex items-start justify-between leading-20', {
          'mt-5 sm:mt-10': isMapCard,
          'mt-10': !isMapCard,
        })}>
          <Paragraphs.XS className={classNames(
            'text-primary-fresh',
            {
              '!text-12 sm:!text-14': isMapCard,
            },
          )}>
            {joinBy([property.city?.value, property.region?.value])}
          </Paragraphs.XS>
          {
            property.reference && (
              <Paragraphs.XS className={classNames(
                'text-grey-650',
                {
                  '!text-12 sm:!text-14': isMapCard,
                },
              )}>
                Ref. {property.reference}
              </Paragraphs.XS>
            )
          }
        </div>

        <Headers.BaseHeader className={classNames('text-grey-900 lg:font-normal', {
          'text-22 sm:text-26 mt-5 sm:mt-15': isMapCard,
          'text-24 mt-15 lg:mt-18': !isMapCard,
        })}>
          {property.title}
        </Headers.BaseHeader>

        <p className={classNames('leading-20 font-normal text-grey-750', {
          'text-12 sm:text-14 mt-3 sm:mt-[7px]': isMapCard,
          'text-13 mt-[7px] lg:mt-10': !isMapCard,
        })}>
          {joinBy([
            property.guests ? pluraliseWithNumber(property.guests, 'Guest') : null,
            property.bedrooms ? pluraliseWithNumber(property.bedrooms, 'Bedroom') : null,
            property.bathrooms ? pluraliseWithNumber(property.bathrooms, 'Bathroom') : null,
          ], ' - ')}
        </p>
        <p className="px-12 py-5 mt-[7px] lg:mt-10 leading-20 text-12 lg:text-13 bg-grey-150 text-grey-750 rounded-8 w-max">
          {propertyPrice(property)}
        </p>
      </div>
    </Link>
  )
}

export default PropertyCard
