import CarouselImages from 'components/carousels/CarouselImages'
import { ReactChild } from 'data/types/types'
import MobilePublicStickyBanner, { MobileStickyBannerProps } from 'layouts/MobilePublicStickyBanner'
import dynamic from 'next/dynamic'
import { StaticImageData } from 'components/Image'
import { PublicLayoutVariant } from 'layouts/PublicLayout'
import { RestBooking } from 'data/types/booking'
import { useMobileApp } from 'data/hooks/MobileApp/useMobileApp'

const CloudflareStream = dynamic(() => import('components/CloudflareStream'), {
  ssr: false,
})

export interface MobilePublicNavbarProps extends MobileStickyBannerProps {
  visible?: boolean
  useVideo?: boolean
  images?: {
    url: string | StaticImageData
    alt_text: string
  }[]
  imageOverlay?: ReactChild
  className?: {
    container?: string,
    child?: string,
    image?: {
      carousel?: string
      overlay?: string
    },
  }
  booking?: RestBooking
  variant?: PublicLayoutVariant
  hasHeader?: boolean
}

const MobilePublicNavbar = ({
  images,
  visible = false,
  useVideo = false,
  imageOverlay,
  variant,
  hasHeader,
  ...props
}: MobilePublicNavbarProps) => {
  const { isMobileApp } = useMobileApp()

  const getContent = () => {
    return (
      <>
        {visible && (
          <MobilePublicStickyBanner
            {...props}
            variant={variant}
          />
        )}

        {useVideo
          ? (
            <div className="w-full overflow-hidden">
              <CloudflareStream video />
            </div>
          )
          : (images && (
            <CarouselImages
              variant={variant}
              className={{
                image: {
                  container: props.className?.image?.carousel,
                },
                overlay: props.className?.image?.overlay,
              }}
              images={images}
              propertyId={props?.wishlist?.id}
              imageOverlay={imageOverlay}
              hasHeader={hasHeader}
            />
          ))}
      </>
    )
  }

  if (isMobileApp || !props.fade) {
    return getContent()
  }

  return (
    <div className="relative">
      {getContent()}
    </div>
  )
}

export default MobilePublicNavbar
