import { createSlice } from '@reduxjs/toolkit'

export const serviceSearchbarSlice = createSlice({
  name: 'serviceSearchbar',
  initialState: {
    searchTerm: '',
    selectedDestination: { value: '' },
    serviceFilters: [],
  },
  reducers: {
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload
    },
    setSelectedDestination: (state, action) => {
      state.selectedDestination = action.payload
    },
    setServiceFilters: (state, action) => {
      state.serviceFilters = action.payload
    },
  },
})

export const { setSearchTerm, setSelectedDestination, setServiceFilters } = serviceSearchbarSlice.actions

export default serviceSearchbarSlice.reducer
