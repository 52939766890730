import React from 'react'
import PropTypes from 'prop-types'
import StatusPanel from 'components/StatusPanel'
import PublicModal from 'components/PublicModal'

const SuccessModal = ({
  isOpen,
  onClose = () => { },
  icon,
  title,
  description,
}) => {
  return (
    <PublicModal isOpen={isOpen} onClose={onClose} className="max-w-[450px]" innerPadding="!pb-70">
      <StatusPanel
        icon={icon}
        title={title}
        description={description}
      />
    </PublicModal>
  )
}

SuccessModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  icon: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

export default SuccessModal
