import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import Link from 'components/Link'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import ApiClient from 'data/api/api_client'
import { CrossIcon, LoadingIcon, NavbarBell } from 'components/icons'
import NotificationContent from 'components/notifications/NotificationContent'
import classNames from 'classnames'
import SectionHeaders from 'components/home/common/typography/SectionHeaders'
import Paragraphs from 'components/home/common/typography/Paragraphs'
import { ReactTag } from '@headlessui/react/dist/types'
import { RestNotification } from 'data/types/notifications'

const NotificationsPopover = () => {
  const queryClient = useQueryClient()

  const { data: notifications, refetch, isLoading } = useQuery(
    'notificationPopover',
    () => ApiClient.notifications.getNotifications({
      status: 'unread',
    }, {
      paginated: true,
    }),
    {
      keepPreviousData: true,
    },
  )

  const deleteNotificationMutation = useMutation(
    (notification: RestNotification) => ApiClient.notifications.deleteNotification(notification),
    {
      onSuccess: () => {
        refetch()
        queryClient.invalidateQueries(['notifications'])
      },
    },
  )

  return (
    <>
      <Popover className="relative flex">
        {() => (
          <>
            <Popover.Button className="relative px-4 py-5 transition-all duration-100 bg-transparent rounded-4" >
              <NavbarBell className="text-[23px] text-grey-700 stroke-[1.5px] hover:text-grey-800" />

              {
                (notifications?.meta?.total ?? 0) > 0
                  ? <div className="h-[17px] w-[17px] ring-[1.5px] ring-white bg-primary-fresh text-white rounded-full absolute top-0 right-0 text-10">
                    {notifications?.meta.total}
                  </div>
                  : null
              }

            </Popover.Button>

            <Popover.Overlay className="fixed inset-x-0 bottom-0 bg-black bg-opacity-10 top-70" />

            <Transition
              as={Fragment as ReactTag}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                className="absolute right-0 top-[57px] 2xl:top-[59px] h-[400px] w-[360px]"
              >
                <div className="flex flex-col items-center justify-start h-full py-20 bg-white rounded-16">
                  <SectionHeaders.LG className="mb-10 text-center text-grey-800">
                    Notifications
                  </SectionHeaders.LG>

                  {isLoading
                    ? <LoadingIcon />
                    : (
                      notifications?.data?.length
                        ? (
                          <div className="flex flex-col w-full gap-20 px-20 overflow-y-auto text-14">
                            {
                              notifications?.data.map((notification, index) => (
                                <div key={index} className="flex flex-col min-h-[50px] pt-20 gap-20">
                                  <div className="flex items-center justify-between h-full gap-10">
                                    <div className="w-full text-left text-grey-750 md:text-grey-800">
                                      <NotificationContent notification={notification} />
                                    </div>

                                    <button
                                      className="self-start cursor-pointer text-grey-500 text-20"
                                      onClick={() => deleteNotificationMutation.mutate(notification)}
                                    >
                                      <CrossIcon />
                                    </button>
                                  </div>

                                  <hr className="text-grey-200" />
                                </div>
                              ))
                            }
                          </div>
                        )
                        : (
                          <div className={'flex flex-col gap-20 transition-all items-center justify-evenly w-full h-full'}>
                            <Paragraphs.XS className="font-bold text-grey-800">
                              No new notifications
                            </Paragraphs.XS>
                          </div>
                        )
                    )
                  }

                  <Link
                    href="/home/notifications"
                    className={classNames(
                      'text-primary font-bold underline mt-10',
                      {
                        'text-opacity-50': !notifications?.data?.length,
                        'text-opacity-100': notifications?.data?.length,
                      },
                    )}
                  >
                    <Paragraphs.XS>
                      View all
                    </Paragraphs.XS>
                  </Link>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </>
  )
}

export default NotificationsPopover
