import classNames from 'classnames'
import { ReactChild } from 'data/types/types'

interface LabelProps extends React.HTMLProps<HTMLLabelElement> {
  children: ReactChild
}

const Label = ({
  children,
  className,
  ...props
}: LabelProps) => {
  return (
    <label
      className={classNames(
        'inline-block mb-[13px] ml-3',
        'text-15 text-grey-800 tracking-1/4 leading-title',
        className,
      )}
      {...props}
    >
      {children}
    </label>
  )
}

export default Label
