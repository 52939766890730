import React from 'react'
import { MinusIcon, PlusIcon } from 'components/icons'
import { FieldError } from 'react-hook-form'
import Label from '../Label'
import Error from '../Error'
import classNames from 'classnames'

interface NumberInputPropTypes extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'onChange'|'dangerouslySetInnerHTML'> {
  value?: number
  onChange: (value?: number) => void
  placeholder?: string
  label?: string
  min?: number
  max?: number
  text?: {
    singular: string
    plural: string
  }

  error?: FieldError
}

const NumberInput = ({
  value,
  onChange,
  placeholder,
  label,
  min = 0,
  max,
  text,
  error,
  ...props
}: NumberInputPropTypes) => {
  const minusDisabled = min !== undefined ? (value ?? 0) <= min : false
  const addDisabled = max !== undefined ? (value ?? 0) >= max : false

  const handleMinus = () => {
    if (minusDisabled) {return}

    const newValue = (value ?? 0) - 1

    if (min && newValue < min) {
      return
    }
    onChange(newValue)
  }

  const handleAdd = () => {
    if (addDisabled) {return}

    const newValue = (value ?? 0) + 1

    if (max && newValue > max) {
      return
    }
    onChange(newValue)
  }

  const getClassNames = (disabled: boolean) => {
    return classNames(
      'rounded-full group-focus:outline-none group-focus:ring-2 group-focus:ring-primary-fresh group-focus:ring-opacity-30 text-[7px] p-[6px]',
      {
        'text-grey-250 border border-grey-250': disabled,
        'text-white bg-primary-fresh group-hover:bg-primary-dark group-hover:text-white': !disabled,
      },
    )
  }

  return (
    <div className="flex flex-col w-full">
      {label && <Label variant="survey">{label}</Label>}

      <div className={classNames(
        'flex items-center justify-between gap-20 py-20 px-50 ring rounded-16 focus-within:ring-primary-fresh focus-within:ring-opacity-30 bg-grey-100',
        {
          'ring-red ring-opacity-50': error,
          'ring-grey-100': !error,
        },
      )}>
        <button
          type="button"
          onClick={handleMinus}
          className="p-10 -m-10 outline-none group"
          {...props}
        >
          <div className={getClassNames(minusDisabled)}>
            <MinusIcon />
          </div>
        </button>

        <p className={classNames('antialiased text-15', {
          'text-placeholder': value === undefined,
          'text-grey-800': value !== undefined,
        })}>
          {value !== undefined
            ? (
              text
                ? `${value} ${value === 1 ? text.singular : text.plural}`
                : value
            )
            : placeholder
          }
        </p>

        <button
          type="button"
          onClick={handleAdd}
          className="p-10 -m-10 outline-none group"
          {...props}
        >
          <div className={getClassNames(addDisabled)}>
            <PlusIcon />
          </div>
        </button>
      </div>

      <Error className="mt-10" error={error?.message} />
    </div>
  )
}

export default NumberInput
