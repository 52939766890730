import { Disclosure, DisclosureButtonProps } from '@headlessui/react'
import classNames from 'classnames'
import { ChevronRightFlaredIcon } from 'components/icons'
import { ReactChild } from 'data/types/types'

interface AccordionHeaderProps extends DisclosureButtonProps<'button'> {
  children: ReactChild
  open: boolean
  className?: string
}

const AccordionHeader = ({
  children,
  className,
  open,
  ...props
}: AccordionHeaderProps) => {
  return (
    <Disclosure.Button
      className={classNames(
        'relative flex justify-between text-left gap-10 leading-28 w-full focus:outline-none py-12 pr-20 border-b-2 border-grey-100 focus:border-grey-200 hover:border-grey-150',
        className,
      )}
      {...props}
    >
      {children}

      <div className={classNames(
        'transition transform absolute inset-y-0 right-0 flex items-center text-grey-800',
        {
          'hidden': props.disabled,
        },
      )}>
        {
          open
            ? <div className="w-8 h-2 rounded-1 bg-grey-800" />
            : <ChevronRightFlaredIcon className="stroke-2 h-21 w-21" />
        }
      </div>
    </Disclosure.Button>
  )
}

export default AccordionHeader
