import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface SearchbarState {
  destinationsOpen: boolean,
  fetching: boolean,
  searchingDestinations: boolean
}

const initialState: SearchbarState = {
  destinationsOpen: false,
  fetching: false,
  searchingDestinations: false,
}

export const searchbarSlice = createSlice({
  name: 'searchbar',
  initialState: initialState,
  reducers: {
    setDestinationsOpen: (state, action: PayloadAction<boolean>) => {
      state.destinationsOpen = action.payload
    },
    setFetching: (state, action: PayloadAction<boolean>) => {
      state.fetching = action.payload
    },
    setSearchingDestinations: (state, action: PayloadAction<boolean>) => {
      state.searchingDestinations = action.payload
    },
  },
})

export const {
  setDestinationsOpen, setFetching, setSearchingDestinations,
} = searchbarSlice.actions

export default searchbarSlice.reducer
