import { default as AccordionBase } from 'components/home/accordion/Accordion'
import Header from 'components/home/accordion/AccordionHeader'
import Content from 'components/home/accordion/AccordionContent'

type AccordionComponent = typeof AccordionBase & {
  Header: typeof Header;
  Content: typeof Content;
};

const Accordion: AccordionComponent = AccordionBase as AccordionComponent
Accordion.Header = Header
Accordion.Content = Content

export default Accordion
