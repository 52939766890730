import { Controller, FieldError } from 'react-hook-form'
import WishlistSelect, { WishlistAsyncSelectProps } from './WishlistSelect'

export interface ControlledWishlistSelectProps extends WishlistAsyncSelectProps {
  name: string
  control: any
  error?: FieldError
}

const ControlledWishlistSelect = ({
  name,
  control,
  ...props
}: ControlledWishlistSelectProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <WishlistSelect
          onChange={(value, _) => {
            onChange(value
              ? {
                id: value.value,
                ...value,
              }
              : undefined,
            )
          }}
          value={value}
          {...props}
        />
      )}
    />
  )
}

export default ControlledWishlistSelect
