/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from 'react'
import classNames from 'classnames'
import { AsyncPaginate, AsyncPaginateProps } from 'react-select-async-paginate'
import { ChevronDownFlaredIcon, ChevronDownIcon, CrossIcon, LoadingIcon } from 'components/icons'
import { GroupBase } from 'react-select'
import { FieldError } from 'react-hook-form'
import Error from '../Error'
import { ReactChild } from 'data/types/types'

type Additional = {
  page: number
}
export type OptionType = {
  label: string,
  value: string | number,
  extra?: any,
}
type Group = GroupBase<OptionType>

export type AsyncPaginateSelectProps = Omit<
  AsyncPaginateProps<OptionType, Group, Additional, false>,
  'loadOptions'
>

export interface AsyncSelectProps<T> extends AsyncPaginateSelectProps {
  refetchQuery: ({
    newPage,
    search,
  }: {
    newPage: number
    search: string
  }) => Promise<T>
  convertDataToOptions: (data: T) => OptionType[]
  hasMorePagesLocation: string
  block?: boolean
  error?: FieldError
  accentIcon?: ReactChild
  variant?: 'admin' | 'customer'
}

const AsyncSelect = function <T> ({
  refetchQuery,
  convertDataToOptions,
  hasMorePagesLocation,
  block = false,
  error,
  accentIcon,
  variant = 'admin',
  ...props
}: AsyncSelectProps<T>) {
  const getLoadOptions = useCallback(async (
    search: string,
    _: any,
    additional?: Additional,
  ) => {
    const newPage = additional?.page ?? 1

    const data = await refetchQuery({
      newPage: newPage,
      search: search,
    })

    const paginatorInfo = data
      ? hasMorePagesLocation.split('.').reduce((a: any, b) => a?.[b], data)
      : null

    return {
      options: convertDataToOptions(data),
      hasMore: paginatorInfo?.hasMorePages ?? false,
      additional: {
        page: newPage + 1,
      },
    }
  }, [refetchQuery])

  const shouldLoadMore = useCallback((
    scrollHeight: number,
    clientHeight: number,
    scrollTop: number,
  ) => (scrollHeight - clientHeight - scrollTop) < 200, [])

  return (
    <div className={classNames(
      'relative flex flex-col gap-3 w-full',
      {
        'max-w-[300px]': !block,
      },
    )}>
      <AsyncPaginate
        {...props}
        loadOptions={getLoadOptions}
        shouldLoadMore={shouldLoadMore}
        additional={{
          page: 1,
        }}
        debounceTimeout={200}
        classNames={{
          indicatorSeparator: (_) => classNames({
            'h-0': variant === 'customer',
          }),
          indicatorsContainer: (_) => classNames({
            'mr-[11px]': variant === 'customer',
          }),
          container: (_) => classNames('w-full min-w-[200px] group'),
          control: (state) => classNames(
            'w-full pl-10 !border-none ring-2',
            {
              // Base
              '!rounded-8 h-50': variant === 'admin',
              '!rounded-16 !bg-grey-100 h-[56px]': variant === 'customer',

              // Color
              'ring-primary': !error,
              'ring-red': error,

              // Focus
              '!ring-2': state.isFocused,
              '!ring-opacity-20': state.isFocused && !error,
              '!ring-opacity-70': state.isFocused && error,

              // Hover
              'ring-opacity-8 hover:ring-opacity-20': !error,
              'ring-opacity-50 hover:ring-opacity-70': error,
            },
          ),
          menuList: (_) => classNames(
            {
              '!py-0': variant === 'customer',
            },
            props.classNames?.menuList?.(_),
          ),
          menu: (_) => classNames({
            '!bg-primary-light !rounded-8 !border-primary-fresh-light !shadow-none border': variant === 'admin',
            '!bg-grey-100 !my-0 overflow-hidden !rounded-16 !shadow-none !mt-10': variant === 'customer',
          },
          ),
          option: (state) => classNames(
            '!px-15 !py-5 rounded-3 !text-16 leading-23 flex justify-between items-center',
            {
              '!bg-primary-fresh-light': state.isFocused,
              '!bg-primary !text-white ': state.isSelected && !state.isFocused,
              '!text-primary': variant === 'customer' && (state.isFocused && state.isSelected),
              '!text-grey-800': !state.isDisabled,
            },
          ),
          input: (_) => classNames(
            '[&_input]:!border-none [&_input]:!shadow-none',
          ),
          singleValue: (_) => classNames(
            'truncate !text-grey-800 !text-16',
          ),
          placeholder: (_) => classNames(
            'truncate',
            {
              '!text-grey-500': variant === 'customer',
            },
          ),
        }}
        components={{
          LoadingIndicator: () => <LoadingIcon className="mr-10 w-18 h-18" />,
          DropdownIndicator: (state) => {
            const dropdownClassnames = classNames(
              'mx-10 transition-all duration-200',
              {
                'w-18 h-18 group-hover:text-grey-800': variant === 'admin',
                'w-16 h-16': variant === 'customer',
                'transform rotate-180': state.selectProps.menuIsOpen,
                'text-grey-500': variant === 'admin' && (state.isDisabled || !state.isFocused),
                'text-grey-800': variant === 'admin' && state.isFocused,
              },
            )
            switch (variant) {
              case 'admin':
                return <ChevronDownIcon className={dropdownClassnames} />
              case 'customer':
                return <ChevronDownFlaredIcon className={dropdownClassnames} />
            }
          },
          ClearIndicator: (state) => <button
            type="button"
            onClick={state.clearValue}
          >
            <CrossIcon
              {...state.innerProps}
              className={classNames(
                'mx-10 w-18 h-18 transition-all duration-200 group-hover:text-grey-800',
                {
                  'text-grey-500': !state.isFocused,
                  'text-grey-800': state.isFocused,
                },
              )}
            />
          </button>,
        }}
      />

      {
        error && (
          <Error error={error.message} />
        )
      }

      {
        accentIcon
          ? <span className="absolute top-0 text-14 left-5">{accentIcon}</span>
          : null
      }
    </div>
  )
}

export default AsyncSelect
