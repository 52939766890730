import React from 'react'
import PropTypes from 'prop-types'
import Link from 'components/Link'
import { useRouter } from 'next/router'
import { CheckCircleIcon } from './icons'
import { ReactChild } from 'data/types/types'
import { RestBooking } from 'data/types/booking'

interface DetailedBookingSidebarProps {
  booking: RestBooking
  children?: ReactChild
}

const DetailedBookingSidebar = ({
  booking,
  children,
}: DetailedBookingSidebarProps) => {
  const router = useRouter()

  if (!booking) {
    return null
  }

  return (
    <div className="bg-white w-full hidden lg:flex flex-col lg:max-w-[300px] border-r border-grey-200 px-30 pt-37">
      <p className="flex items-center font-bold uppercase gap-x-10 leading-20 tracking-1/2 text-14 text-grey-750">
        BOOKING #{booking.id}
        {
          booking?.status === 'confirmed'
            ? <CheckCircleIcon className="text-primary h-18 w-18" />
            : null
        }
      </p>

      <div className="flex uppercase flex-col gap-y-30 mt-[43px] items-start justify-around">
        <Link href={`/home/reservations/${booking?.id}`}>
          <p className={`text-grey-800 text-14 font-bold leading-16 tracking-[1.5px] pb-[7px] ${router.pathname === '/home/reservations/[id]' ? 'border-b-2 border-primary-dark' : ''} cursor-pointer`}>summary</p>
        </Link>

        {booking?.property?.address
          && <Link href={`/home/reservations/${booking?.id}/location`} >
            <p className={`text-grey-800 text-14 font-bold leading-16 tracking-[1.5px] pb-[7px] ${router.pathname === '/home/reservations/[id]/location' ? 'border-b-2 border-primary-dark' : ''} cursor-pointer`}>location</p>
          </Link>
        }

        {!booking?.is_shared_booking
          && <Link href={`/home/reservations/${booking?.id}/my-concierge`} >
            <p className={`text-grey-800 text-14 font-bold leading-16 tracking-[1.5px] pb-[7px] ${router.pathname === '/home/reservations/[id]/my-concierge' ? 'border-b-2 border-primary-dark' : ''} cursor-pointer`}>my concierge</p>
          </Link>
        }

        <Link href={`/home/reservations/${booking?.id}/trip-schedule`} >
          <p className={`text-grey-800 text-14 font-bold leading-16 tracking-[1.5px] pb-[7px] ${router.pathname === '/home/reservations/[id]/trip-schedule' ? 'border-b-2 border-primary-dark' : ''} cursor-pointer`}>trip itinerary</p>
        </Link>

        {!booking?.is_shared_booking
          && <Link href={`/home/reservations/${booking?.id}/payments-and-invoices`} >
            <p className={`text-grey-800 text-14 font-bold leading-16 tracking-[1.5px] pb-[7px] ${router.pathname === '/home/reservations/[id]/payments-and-invoices' ? 'border-b-2 border-primary-dark' : ''} cursor-pointer`}>{'payments & invoices'}</p>
          </Link>
        }
      </div>
      <div className="mt-auto mb-[30px]">
        {children}
      </div>
    </div>
  )
}

DetailedBookingSidebar.propTypes = {
  bookingNumber: PropTypes.number,
  booking: PropTypes.object,
  children: PropTypes.node,
}

export default DetailedBookingSidebar
