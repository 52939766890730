import { CommissionApplication } from 'data/enums/trip-schedule/commission-application'
import { CommissionType } from 'data/enums/trip-schedule/commission-type'
import { Status } from 'data/enums/payment-status'
import { ServicePartnerPrice } from 'data/enums/trip-schedule/service-partner-price'
import { TripScheduleGroup } from 'data/types/bookings/trip_schedule'
import {
  UseTripScheduleQuery,
  CommissionType as GraphQlCommissionType,
  CommissionApplication as GraphQlCommissionApplication,
  ServicePartnerPrice as GraphQlServicePartnerPrice,
  TripScheduleServiceStatusType,
} from 'gql/graphql'

const isCheckInOrOut = (serviceTitle?: string | null) => {
  return typeof serviceTitle === 'string'
    ? serviceTitle.toLowerCase() === 'check in' || serviceTitle.toLowerCase() === 'check out'
    : false
}

const calculateCommission = (tripGroup: TripScheduleGroup) => {
  return calculatePartnerCommissionIndividual({
    commissionIncluded: tripGroup?.commission_included,
    commissionType: tripGroup?.commission_type,
    partnerPrice: tripGroup?.partner_price,
    commissionAmount: tripGroup?.commission_amount,
    respectIncluded: true,
  })
}

const calculatePartnerSubtotal = (tripGroup: TripScheduleGroup) => {
  return (tripGroup?.partner_price ?? 0)
}

const calculateServiceSubtotal = (tripGroup: TripScheduleGroup) => {
  return calculateServiceSubtotalIndividual({
    serviceFee: tripGroup?.service_fee,
    serviceFeeApplication: tripGroup?.service_fee_application,
    days: tripGroup?.days?.length ?? 0,
  })
}

const hasPayments = (tripGroup: TripScheduleGroup) => {
  return tripGroup?.payment_statuses?.some((status) => status === Status.DUE || status === Status.PENDING)
}

const hasDuePayments = (tripGroup: TripScheduleGroup) => {
  return tripGroup?.payment_statuses?.some((status) => status === Status.DUE)
}

const hasPendingPayments = (tripGroup: TripScheduleGroup) => {
  return tripGroup?.payment_statuses?.some((status) => status === Status.PENDING)
}

const hasCompletedPayments = (tripGroup: TripScheduleGroup) => {
  return tripGroup?.payment_statuses?.some((status) => status === Status.COMPLETED)
}

type CommissionInput = {
  commissionIncluded?: TripScheduleGroup['commission_included'] | GraphQlServicePartnerPrice
  commissionType?: TripScheduleGroup['commission_type'] | GraphQlCommissionType
  partnerPrice?: TripScheduleGroup['partner_price']
  commissionAmount?: TripScheduleGroup['commission_amount']
  respectIncluded?: boolean
}

type SubtotalInput = {
  serviceFee: TripScheduleGroup['service_fee']
  serviceFeeApplication: TripScheduleGroup['service_fee_application'] | GraphQlCommissionApplication
  days: number
}

const calculatePartnerCommissionIndividual = (input: CommissionInput) => {
  if (!input.respectIncluded && input.commissionIncluded?.toLowerCase() === ServicePartnerPrice.INCLUDED) {
    return 0
  }

  if (input.commissionType?.toLowerCase() === CommissionType.PERCENTAGE) {
    return (input.partnerPrice ?? 0) * ((input.commissionAmount ?? 0) / 100)
  } else {
    return (input.commissionAmount ?? 0) * 100
  }
}

const calculatePartnerSubtotalIndividual = (input: CommissionInput) => {
  return (input.partnerPrice ?? 0) + calculatePartnerCommissionIndividual(input)
}

const calculateServiceSubtotalIndividual = (input: SubtotalInput) => {
  let price = input.serviceFee ?? 0

  if (input.serviceFeeApplication?.toLowerCase() === CommissionApplication.EACHDAY) {
    price = price * input.days
  }

  return price
}

const calculateCommissionIndividual = ({
  commissionInput,
  subtotalInput,
}: {
  commissionInput: Omit<CommissionInput, 'respectIncluded'>
  subtotalInput: SubtotalInput
}) => {
  return calculatePartnerCommissionIndividual({ ...commissionInput, respectIncluded: true })
    + calculateServiceSubtotalIndividual(subtotalInput)
}

const getPendingPaymentTripGroupsPrice = (tripScheduleGroups: UseTripScheduleQuery['bookingTripScheduleGroups']) => {
  return tripScheduleGroups
    .filter((group) => !isCheckInOrOut(group?.title) && (group.status === TripScheduleServiceStatusType.PendingPayment))
    .reduce((prev, service) => {
      let price = 0

      if (service.is_price_generated === false) {
        price = service.price ?? 0
      } else {
        const partnerSubtotal = calculatePartnerSubtotalIndividual({
          commissionAmount: service.commission_amount,
          commissionIncluded: service.commission_included!,
          commissionType: service.commission_type,
          partnerPrice: service.partner_price,
        })

        const serviceSubtotal = calculateServiceSubtotalIndividual({
          serviceFee: service.service_fee!,
          serviceFeeApplication: service.service_fee_application!,
          days: (service.days ?? []).length,
        })

        price = partnerSubtotal + serviceSubtotal
      }

      return prev + price - (service.payment_totals?.completed ?? 0)
    }, 0)
}

export {
  isCheckInOrOut,
  calculatePartnerSubtotal,
  calculateCommission,
  calculateServiceSubtotal,
  // Payments Page
  hasPayments,
  hasDuePayments,
  hasPendingPayments,
  hasCompletedPayments,
  // Trip Service Modal
  calculatePartnerCommissionIndividual,
  calculatePartnerSubtotalIndividual,
  calculateServiceSubtotalIndividual,
  calculateCommissionIndividual,
  // Message Templates
  getPendingPaymentTripGroupsPrice,
}
