import { Disclosure } from '@headlessui/react'
import classNames from 'classnames'
import { ReactChild } from 'data/types/types'

interface AccordionContentProps {
  children: ReactChild
  className?: string
}

const AccordionContent = ({
  children,
  ...props
}: AccordionContentProps) => {
  return (
    <Disclosure.Panel
      className={classNames(
        'py-10 pl-[6px]',
        props.className,
      )}
      {...props}
    >
      {children}
    </Disclosure.Panel>
  )
}

export default AccordionContent
