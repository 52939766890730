import { Controller } from 'react-hook-form'
import DateInput, { DateInputProps } from 'components/form/Home/DateInput'

export interface ControlledDateInputProps extends Omit<DateInputProps, 'value' | 'onChange'> {
  name: string
  control: any
}

export default function ControlledDateInput ({
  name,
  control,
  ...props
}: ControlledDateInputProps) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <DateInput
          value={value}
          onChange={onChange}
          {...props}
        />
      )}
    />
  )
}
