import { QuestionSlide, SurveyFormProps } from 'data/types/service_surveys'

export const radioGroupKeyEvents = [
  'Space',
  'Enter',
  'ArrowLeft',
  'ArrowRight',
  'ArrowUp',
  'ArrowDown',
]

const getNewQuestionSlides = (
  questionSlides: QuestionSlide[],
  newSlide: (props: SurveyFormProps) => JSX.Element,
  numberOfNewQuestions: number,
) => {
  const newQuestionSlides = [...questionSlides]

  const startIndexOfNewQuestion = newQuestionSlides.findIndex(q => q.slide === newSlide)

  if (numberOfNewQuestions > 0) {
    const lastIndexOfNewQuestion = newQuestionSlides.findLastIndex(q => q.slide === newSlide)

    for (let i = lastIndexOfNewQuestion; i < lastIndexOfNewQuestion + numberOfNewQuestions; i++) {
      newQuestionSlides.splice(
        i + 1,
        0,
        {
          slide: newSlide,
          position: i - startIndexOfNewQuestion + 1,
          validated: false,
        },
      )
    }
  } else {
    const currentNumberOfQuestions = newQuestionSlides.filter(q => q.slide === newSlide).length
    const spliceStartOffset = currentNumberOfQuestions + numberOfNewQuestions

    newQuestionSlides.splice(
      startIndexOfNewQuestion + spliceStartOffset,
      numberOfNewQuestions * -1,
    )
  }

  return newQuestionSlides
}

export {
  getNewQuestionSlides,
}
