import React from 'react'
import UserTripSummaryDayRowList from 'components/booking/trip-schedule/UserTripDayRowList'
import TripSummaryViewCard from '../TripSummaryViewCard'
import { RestBooking } from 'data/types/booking'
import { TripScheduleService } from 'data/types/bookings/trip_schedule'

interface SummaryViewTabProps {
  booking: RestBooking
  tripServices: TripScheduleService[]
}

const SummaryViewTab = ({
  booking,
  tripServices,
}: SummaryViewTabProps) => {

  return (
    <div className="flex w-full h-full">
      <div className="w-full md:border-r border-grey-200 h-full">

        <div className="xl:mt-0 sticky top-[60px] lg:top-0 z-[1] bg-white">
          <UserTripSummaryDayRowList
            booking={booking}
          />
        </div>

        <div className="flex flex-col justify-center pb-40 gap-y-20 lg:pb-80">
          <TripSummaryViewCard
            booking={booking}
            services={tripServices}
          />
        </div>
      </div>
    </div>
  )
}

export default SummaryViewTab
