import Button from 'components/home/common/Button'
import GuestDisclaimer from 'components/modals/bookings/shared/Disclaimer'
import Notification from 'components/Notification'
import ProfileImage from 'components/ProfileImage'
import PublicMobileModal from 'components/PublicMobileModal'
import useAuth from 'data/hooks/User/useAuth'
import { ModalProps } from 'data/types/modal'
import { graphql } from 'gql'
import React from 'react'
import { useNotifier } from 'react-headless-notifier'
import { useMutation } from 'urql'

interface DeleteAccountModalProps extends ModalProps { }

const REQUEST_ACCOUNT_DELETION_MUTATION = graphql(`
  mutation requestAccountDeletion($input: RequestAccountDeletionInput!) {
    requestAccountDeletion(input: $input)
  }
`)

const DeleteAccountModal = ({
  isOpen,
  onClose,
}: DeleteAccountModalProps) => {
  const { user } = useAuth()
  const { notify } = useNotifier()

  const [{ fetching: isDeleting }, requestAccountDeletion] = useMutation(REQUEST_ACCOUNT_DELETION_MUTATION)

  const handleDeleteAccount = () => {
    if (!user) {
      return
    }

    requestAccountDeletion({
      input: {
        id: user.id,
      },
    }).then((response) => {
      if (response.error) {
        notify(
          <Notification
            type="error"
            message="An error occured. Please try again later or contact support."
          />,
        )
      } else {
        notify(
          <Notification
            type="success"
            message="Your account deletion has been submitted and will be processed within 2 business days."
          />,
        )
      }

      onClose()
    })
  }

  return (
    <PublicMobileModal
      isOpen={isOpen}
      onClose={onClose}
      title="Delete Account"
      backIcon={true}
      className={{
        panel: 'px-25 pb-safe-offset-30',
      }}
    >
      <div className="space-y-30">
        <div className="flex justify-center mt-[28px]">
          <ProfileImage
            user={user}
            className="h-100 w-100"
          />
        </div>

        <p className="font-bold text-center text-grey-900 text-15 leading-24">
          Are you sure you would like to<br />delete your account?
        </p>

        <GuestDisclaimer
          className={{
            container: 'px-12 py-15',
            text: '!text-grey-800 !text-12 !leading-22 !tracking-1/4',
          }}
        >
          This will permanently delete your personal data from our system, including your bookings, messages, and any other saved information.
          <p className="underline">
            This action cannot be undone.
          </p>
          <br />
          If you are sure you want to delete your account, click the button below. Your request will be sent to our team for manual processing, and your account will be permanently deleted within 2 business days.
        </GuestDisclaimer>

        <div className="flex gap-10">
          <Button
            onClick={onClose}
            style="modal"
            variant="secondary"
            className={{
              button: 'text-grey-650 !px-12 !text-14',
            }}
            block
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteAccount}
            className={{
              button: '!px-12 w-full !text-14',
            }}
            style="modal"
            block
            isLoading={isDeleting}
          >
            Delete Account
          </Button>
        </div>
      </div>
    </PublicMobileModal>
  )
}

export default DeleteAccountModal
