import Paragraphs from 'components/home/common/typography/Paragraphs'
import { ReactChild } from 'data/types/types'
import Headers from 'components/home/common/typography/Headers'
import { FieldValues, UseFormHandleSubmit } from 'react-hook-form'
import Button, { ButtonProps } from 'components/home/common/Button'
import classNames from 'classnames'

interface PasswordResetSectionProps<T extends FieldValues> {
  children: ReactChild
  header: string
  description?: string
  handleSubmit: UseFormHandleSubmit<T>
  onSubmit: (details: T) => void
  button: ButtonProps
}

export default function PasswordResetSection<T extends FieldValues> ({
  children,
  header,
  description,
  handleSubmit,
  onSubmit,
  button,
}: PasswordResetSectionProps<T>) {
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col px-20 text-grey-800 pt-80"
    >
      <Headers.XXL className={classNames({
        'pb-80': !description,
      })}>
        {header}
      </Headers.XXL>

      {
        description && (
          <Paragraphs.MD className="mt-80">
            {description}
          </Paragraphs.MD>
        )
      }

      {children}

      <Button
        type="submit"
        block
        {...button}
      >
        {button.children}
      </Button>
    </form>
  )
}
