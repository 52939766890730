enum CommissionType {
  PERCENTAGE = 'percentage',
  CURRENCY = 'currency',
}

const getCommissionTypeSymbol = (type: CommissionType | null) => {
  switch (type) {
    case (CommissionType.PERCENTAGE):
      return '%'
    case (CommissionType.CURRENCY):
      return '€'
    default:
      return ''
  }
}

export { CommissionType, getCommissionTypeSymbol }
