import { DestinationSearchResultsQuery } from 'data/graphql/queries/client/destinations'
import { isServer } from 'data/helpers/ssr'
import { graphql } from 'gql'
import { FeaturedRegionsFilterInput } from 'gql/graphql'
import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { setSearchingDestinations } from 'store/searchbar'
import { useQuery } from 'urql'
import { useDebounce } from 'use-debounce'

const FEATURED_REGIONS_QUERY = graphql(`
  query DestinationSearchFeaturedRegions($featuredRegionsFilter: FeaturedRegionsFilterInput) {
    featuredRegions(first: 4, filters: $featuredRegionsFilter) {
      data {
        id
        value
        slug
        country {
          id
          value
          slug
        }
      }
    }
  }
`)

export const useDestinationSearch = (
  searchParam?: string,
  featuredRegionsFilters?: FeaturedRegionsFilterInput,
) => {
  const [debouncedSearchParam] = useDebounce(searchParam, 400)
  const dispatch = useDispatch()

  const [{ data, fetching }] = useQuery({
    query: DestinationSearchResultsQuery,
    variables: {
      regionsFilter: {
        value: debouncedSearchParam,
      },
      subRegionsFilter: {
        value: debouncedSearchParam,
      },
      citiesFilter: {
        value: debouncedSearchParam,
      },
    },
    pause: !debouncedSearchParam || debouncedSearchParam === '' || isServer,
  })

  const [{ data: featuredRegionsData }] = useQuery({
    query: FEATURED_REGIONS_QUERY,
    variables: {
      featuredRegionsFilter: featuredRegionsFilters,
    },
    pause: isServer || !!debouncedSearchParam,
  })

  useEffect(() => {
    dispatch(setSearchingDestinations(fetching))
  }, [fetching])

  const results = useMemo(() => {
    if (data && debouncedSearchParam && debouncedSearchParam !== '') {
      return {
        data: [
          ...data.cities.data,
          ...data.subRegions.data,
          ...data.regions.data,
        ],
        isSearchResults: true,
      }
    }

    return {
      data: featuredRegionsData?.featuredRegions.data,
      isSearchResults: false,
    }
  }, [data, featuredRegionsData, debouncedSearchParam])

  return {
    results: results.data,
    isSearchResults: results.isSearchResults,
  }
}
