import { MouseEvent } from 'react'
import Link from 'components/Link'
import classNames from 'classnames'
import { LoadingIcon } from './icons'
import { ReactChild } from 'data/types/types'
import { LinkProps } from 'next/link'

interface IconButtonProps {
  children: ReactChild
  href?: LinkProps['href']
  target?: string
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void
  disabled?: boolean
  className?: string
  propagate?: boolean
  isLoading?: boolean
  variant?: 'primary' | 'secondary' | 'medium' | 'small' | 'link' | 'navbar' | 'modal-close'
}

const IconButton = ({
  children,
  href,
  target = '_self',
  onClick,
  disabled = false,
  className = '',
  propagate = true,
  variant = 'primary',
  isLoading = false,
}: IconButtonProps) => {
  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    propagate ? null : e.stopPropagation()
    if (!disabled) {
      onClick && onClick(e)
    }
  }

  const variantClassNames = classNames({
    'text-24 rounded-12 bg-primary bg-opacity-4 p-15 hover:bg-opacity-10 items-center': variant === 'primary',
    'text-16 rounded-8 bg-primary px-15 py-10 hover:bg-primary-active': variant === 'secondary',
    'text-20 rounded-8 self-start bg-primary bg-opacity-4 p-10 hover:bg-primary-active hover:bg-opacity-10': variant === 'medium',
    'text-20 rounded-8 self-start bg-primary bg-opacity-4 p-5 hover:bg-primary-active hover:bg-opacity-10': variant === 'small',
    'p-5 rounded-4 hover:bg-grey-200 bg-transparent transition-all duration-100': variant === 'link',
    'p-2 lg:p-5 rounded-4 text-grey-750 hover:text-grey-800 transition-all duration-100': variant === 'navbar',
    'items-center justify-center bg-white rounded-full outline-none w-30 h-30 hover:bg-grey-100 shadow-modal text-grey-800 text-24': variant === 'modal-close',
    'opacity-50 cursor-default': disabled,
  })

  const button = (
    <button
      onClick={(e) => handleClick(e)}
      className={classNames(
        'flex transition cursor-pointer',
        variantClassNames,
        className,
      )}
      type="button"
    >
      {
        isLoading
          ? <LoadingIcon className="w-16 h-16"  />
          : children
      }
    </button>
  )

  if (href) {
    return (
      <Link href={href} target={target}>
        {button}
      </Link>
    )
  }

  return button
}

export default IconButton
