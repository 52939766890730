import React from 'react'
import { FieldError } from 'react-hook-form'
import ServiceRequestSlide from 'components/service-request-forms/components/ServiceRequestSlide'
import ServiceRequestQuestion from 'components/service-request-forms/components/ServiceRequestQuestion'
import ServiceRequestNextButton from 'components/service-request-forms/components/ServiceRequestNextButton'
import ServiceSurveyForm from 'components/form/ServiceSurveyForm'
import { getCarTypeLabel, QuestionText } from 'data/helpers/survey/surveys/car-rental'
import { CarRentalError, SurveyFormProps } from 'data/types/service_surveys'
import { CarTypeEnum } from 'data/enums/survey/car-rental/car-type'

const Slide4 = ({
  questionStartIndex,
  position,
  fetching,
  isSlideActive,
  control,
  formState: { errors },
  setValue,
  clearErrors,
}: SurveyFormProps) => {
  return (
    <ServiceRequestSlide
      slideNumber={4}
      position={position}
      isSlideActive={isSlideActive}
      setValue={setValue}
      clearErrors={clearErrors}
    >
      <ServiceRequestQuestion
        questionNumber={questionStartIndex}
        question={QuestionText(6)}
      />
      <ServiceSurveyForm.ControlledRadioGroup
        name="question6.car_type"
        control={control}
        options={[
          { value: CarTypeEnum.SPORT, label: getCarTypeLabel(CarTypeEnum.SPORT) },
          { value: CarTypeEnum.SEDAN, label: getCarTypeLabel(CarTypeEnum.SEDAN) },
          { value: CarTypeEnum.SUV, label: getCarTypeLabel(CarTypeEnum.SUV) },
          { value: CarTypeEnum.VAN, label: getCarTypeLabel(CarTypeEnum.VAN) },
        ]}
        disabled={!isSlideActive}
        error={((errors as CarRentalError)?.question6 as unknown as { car_type?: FieldError })?.car_type}
      />
      <ServiceRequestNextButton
        isLoading={fetching}
        disabled={!isSlideActive}
      />
    </ServiceRequestSlide>
  )
}

export default Slide4
