import { ArticleShareTwitterIcon, CopyThinIcon, FacebookIcon, GmailIcon, LinkedInIcon, MailOutlineIcon, WhatsappIcon } from 'components/icons'
import ShareModal from 'components/modals/home/ShareModal'
import { Share } from 'data/enums/share-target'
import { ModalProps } from 'data/types/modal'
import React from 'react'

interface ArticleShareModalProps extends ModalProps {
  href: string
}

const ArticleShareModal = ({
  isOpen,
  onClose,
  href,
}: ArticleShareModalProps) => {
  return (
    <ShareModal
      isOpen={isOpen}
      onClose={onClose}
      description="Select your preferred option below"
      title="Share this article"
      links={[
        {
          target: Share.FACEBOOK,
          icon: <FacebookIcon />,
          href: href,
        },
        {
          target: Share.CLIPBOARD,
          icon: <CopyThinIcon />,
          href: href,
        },
        {
          target: Share.WHATSAPP,
          icon: <WhatsappIcon />,
          href: href,
        },
        {
          target: Share.EMAIL,
          icon: <MailOutlineIcon />,
          href: href,
        },
        {
          target: Share.LINKEDIN,
          icon: <LinkedInIcon />,
          href: href,
        },
        {
          target: Share.GMAIL,
          icon: <GmailIcon />,
          href: href,
        },
        {
          target: Share.TWITTER,
          icon: <ArticleShareTwitterIcon />,
          href: href,
        },
      ]}
    />
  )
}

export default ArticleShareModal
