import React from 'react'
import Error from 'components/form/Error'
import StarRatingItem from './StarRatingItem'
import { FieldError } from 'react-hook-form'

type PropTypes = {
    disabled: boolean
    options: {value: string | number}[]
    value: string | number
    onChange: (value: string | number) => void
    error: FieldError | undefined
}

const StarRating: React.FC<PropTypes> = ({
  disabled,
  options,
  error,
  value,
  onChange,
}) => {
  return (
    <div className="flex flex-col gap-14">
      <div className="flex gap-14">
        {options.map((option) => (
          <StarRatingItem
            disabled={disabled}
            key={option.value}
            checked={option.value <= value}
            value={option.value}
            onChange={onChange}
          />
        ))}
      </div>
      <Error error={error?.message}/>
    </div>
  )
}

export default StarRating
