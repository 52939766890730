const groupBy = (arr, key, deleteUndefined = true) => {
  const obj = arr.reduce((obj, val) => {
    (obj[val[key]] = obj[val[key]] || []).push(val)
    return obj
  }, {})

  if (deleteUndefined) {
    delete obj[undefined]
  }

  return obj
}

const sortBy = (arr, key) => {
  return arr ? arr.sort(sortFunction(key)) : []
}

const sortFunction = (key) => (a, b) => {
  let aKey = a[key]
  let bKey = b[key]

  if (key === 'id') {
    aKey = Number(aKey)
    bKey = Number(bKey)
  }

  if (aKey < bKey) { return -1 }
  if (aKey > bKey) { return 1 }
  return 0
}

// Taken from https://stackoverflow.com/a/1584377
const merge = (a, b, predicate = (a, b) => a.id === b.id) => {
  const c = [...a] // copy to avoid side effects

  // add all items from B to copy C if they're not already present
  b.forEach((bItem) => (
    c.some((cItem) => predicate(bItem, cItem))
      ? null
      : c.push(bItem)
  ))

  return c
}

const findByKey = (arr, key) => {
  if (!arr || !key) {
    return null
  }

  return key.split('.').reduce((a, b) => a?.[b], arr)
}

export {
  groupBy,
  sortBy,
  merge,
  findByKey,
}
