export default class PaymentApiClient {
  constructor (http) {
    this.http = http
  }

  /**
   * Add new payments for a booking
   *
   * @param {object} data
   * @returns {Object}
   */
  async createPayments (id, data) {
    const result = await this.http.post(`/booking/${id}/payments`, data)
    return result.data.data
  }

  async getPayments (filters = {}, options = {}, sortable = {}) {
    const result = await this.http.get('/payments', {
      params: {
        ...filters,
        page: options.page ?? 1,
        per_page: options?.per_page,
        paginated: options?.paginated ?? true,
        limit: options.limit,
        sort_direction: sortable?.direction,
        sort_by: sortable?.sort_by,
      },
    })
    return {
      payments: result.data.data,
      meta: result.data.meta,
    }
  }

  async getPaymentGroups (id, data) {
    const result = await this.http.get(`/booking/${id}/payment-groups`, {
      params: data,
    })
    return result.data.data
  }

  async downloadInvoice (modelId, invoiceType) {
    const result = await this.http.get('/booking/invoice', {
      params: {
        model_id: modelId,
        invoice_type: invoiceType,
      },
    })
    return result.data.data
  }

  async createInvoice (params) {
    const result = await this.http.post('/booking/invoice', params)
    return result.data
  }

  async updatePayment (data) {
    const result = await this.http.post('/bookings', data)
    return result.data.data
  }

  /**
   * Get all payments for a given booking
   *
   * @param {integer} id The id of the booking
   */
  async getBookingPayments (id) {
    const result = await this.http.get(`/booking/${id}/payments`)
    return result.data.data
  }

  /**
   * Delete all addtional fees that are contained within the array
   *
   * @param {array} data An array of ids for additional fees to be deleted
   * @returns
   */
  async deleteAdditionalFees (data) {
    const result = await this.http.post('/payments/additional-fees/delete', data)
    return result.data.data
  }

  /**
   * Get all payments for a given user
   *
   * @param {integer} id The id of the user
   * @returns {Object} The payments for that user
   */
  async getUserPayments (userID) {
    const result = await this.http.get(`/users/${userID}/payments`)
    return result.data.data
  }

  async getServicePayments (bookingId) {
    const result = await this.http.get(`/booking/${bookingId}/service-payments`)
    return result.data.data
  }
  /**
   * Get a new payment for the provided service group ids
   *
   * @param {array} groupIds The ids of the service groups
   * @returns {Object} The new payment
   */
  async getPaymentGroupFromServiceGroups (groupIds) {
    const result = await this.http.post('/payments/service-groups/create', {
      groups: groupIds,
    })
    return result.data.data
  }

  /**
   * Completes the current payment which will retrieve the payment groups from the session
   *
   * @returns {Object} The new payment group
   */
  async confirmServiceGroupsPayment (bookingId, paymentInfo) {
    const result = await this.http.post(`/payments/service-groups/booking/${bookingId}/complete`, {
      paymentInfo: paymentInfo,
    })
    return result.data.data
  }
}
