import { Controller, FieldError } from 'react-hook-form'
import RadioGroup from './RadioGroup'
import { useEffect } from 'react'

export default function ControlledRadioGroup ({
  disabled,
  name,
  control,
  options,
  error,
  isChild,
}: {
  disabled?: boolean,
  name: string,
  control: any,
  options: {
    value: string | number,
    label?: string
    component?: JSX.Element,
  }[]
  error: FieldError | undefined,
  isChild?: boolean,
}) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, onBlur } }) => {
        useEffect(() => {
          if (Array.isArray(value)) {
            onChange(undefined)
          }
        }, [])

        return (
          <RadioGroup
            disabled={disabled}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            options={options}
            error={error}
            isChild={isChild}
          />
        )}}
    />
  )
}
