import React from 'react'
import { StarIcon } from 'components/icons'
import classNames from 'classnames'
import Button from 'components/Button'

type PropTypes = {
    disabled: boolean
    checked: boolean
    value: string | number
    onChange: (value: string | number) => void
}

const StarRatingItem: React.FC<PropTypes> = ({
  disabled,
  value,
  checked,
  onChange,
}) => {

  const onClick = () => {
    onChange(value)
  }

  return (
    <Button
      disabled={disabled}
      variant="outline-grey"
      className={classNames('flex flex-col items-center gap-10 border-0 font-normal !px-0 py-2 focus:ring-0', {
        'text-primary': checked,
        'text-grey-750': !checked,
      })}
      onClick={onClick}
    >
      <StarIcon
        width={40}
        height={40}
      />
      <>{value}</>
    </Button>
  )
}

export default StarRatingItem
