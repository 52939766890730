import React from 'react'
import { FieldError } from 'react-hook-form'
import ServiceRequestSlide from 'components/service-request-forms/components/ServiceRequestSlide'
import ServiceRequestQuestion from 'components/service-request-forms/components/ServiceRequestQuestion'
import ServiceRequestNextButton from 'components/service-request-forms/components/ServiceRequestNextButton'
import ServiceSurveyForm from 'components/form/ServiceSurveyForm'
import { QuestionText } from 'data/helpers/survey/surveys/car-rental'
import { CarRentalError, SurveyFormProps } from 'data/types/service_surveys'
import { PickUpDropOffEnum } from 'data/enums/survey/car-rental/pick-up-drop-off'
import { radioGroupKeyEvents } from 'data/helpers/survey/questions'

const Slide1 = ({
  questionStartIndex,
  position,
  fetching,
  isSlideActive,
  control,
  register,
  formState: { errors },
  setValue,
  clearErrors,
}: SurveyFormProps) => {
  return (
    <ServiceRequestSlide
      slideNumber={1}
      position={position}
      isSlideActive={isSlideActive}
      setValue={setValue}
      clearErrors={clearErrors}
    >
      <ServiceRequestQuestion
        questionNumber={questionStartIndex}
        question={QuestionText(1)}
      />
      <ServiceSurveyForm.ControlledRadioGroup
        name="question1.pick_up_drop_off"
        control={control}
        options={[
          { value: 'At the property' },
          { value: 'At the airport' },
          {
            value: PickUpDropOffEnum.OTHER,
            label: 'Other',
            component: (
              <ServiceSurveyForm.Textarea
                {...register('question1.pick_up_drop_off_other')}
                placeholder="Please specify your preference here..."
                error={((errors as CarRentalError)?.question1 as unknown as { pick_up_drop_off_other: FieldError })?.pick_up_drop_off_other}
                disabled={!isSlideActive}
                onKeyDown={(e) => {
                  if (radioGroupKeyEvents.includes(e.code)) {
                    e.stopPropagation()
                  }
                }}
              />
            ),
          },
        ]}
        disabled={!isSlideActive}
        error={((errors as CarRentalError)?.question1 as unknown as { pick_up_drop_off?: FieldError })?.pick_up_drop_off}
      />
      <ServiceRequestNextButton
        isLoading={fetching}
        disabled={!isSlideActive}
      />
    </ServiceRequestSlide>
  )
}

export default Slide1
