import DashboardModal from 'components/DashboardModal'
import { formatDateLong, formatDateReadable } from 'data/helpers/dates'
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Input from 'components/form/Input'
import { formatShortCurrency } from 'data/helpers/currency'
import Link from 'components/Link'
import Button from 'components/Button'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import ApiClient from 'data/api/api_client'
import AvatarPopover from 'components/avatarPopover'
import { useNotifier } from 'react-headless-notifier'
import Notification from 'components/Notification'
import { LoadingIcon } from 'components/icons'
import { formatEnum } from 'data/helpers/strings'

const InquiryOverviewModal = ({
  isOpen = false,
  onClose = () => { },
  inquiryId,
  initialInquiry = null,
  refetch = () => { },
}) => {
  const { notify } = useNotifier()
  const queryClient = useQueryClient()

  const { data: inquiry, isLoading, isFetching, refetch: refetchInquiry } = useQuery(['inquiry', inquiryId ?? initialInquiry?.id],
    () => ApiClient.inquiries.showInquiry(inquiryId ?? initialInquiry?.id),
    {
      initialData: initialInquiry,
      enabled: isOpen,
    },
  )

  useMemo(() => {
    if (inquiryId && inquiryId != inquiry?.id) {
      refetchInquiry()
    }
  }, [inquiryId])

  const { data: admins } = useQuery(['admins'],
    () => ApiClient.users.getUsers({
      types: ['admin'],
    }, {
      paginated: false,
    }),
  )

  const assignBookingAgent = useMutation(
    (data) => ApiClient.inquiries.assignBookingAgent({ id: data.inquiryId, agent_id: data.id }),
    {
      onSuccess: () => {
        notify(
          <Notification type="success" message="Team member assigned!" />,
        )
        refetch()
        queryClient.invalidateQueries(['newInquiryCount'])
        closeModal()
      },
      onError: () => {
        notify(
          <Notification type="error" message="Something went wrong when assigning a team member." />,
        )
      },
    },
  )

  const closeModal = () => {
    onClose()
  }

  const getTitle = () => {
    if (isLoading || isFetching) {
      return 'Loading ...'
    }

    let title = ''
    switch (inquiry?.type) {
      case 'Callback':
        title = 'Callback Inquiry'
        break
      case 'General':
        title = `General Inquiry for ${inquiry?.type_details?.sub_region_name ?? inquiry?.type_details?.region_name ?? ''} - ${formatDateLong(inquiry?.type_details?.arrival)} to ${formatDateLong(inquiry?.type_details?.departure)}`
        break
      case 'Specific':
        title = `Inquiry for ${inquiry?.type_details?.property.title} - ${formatDateLong(inquiry?.type_details?.arrival)} to ${formatDateLong(inquiry?.type_details?.departure)}`
        break
    }
    return title + ` - ${inquiry?.type_details?.first_name} ${inquiry?.type_details?.last_name}`
  }

  return (
    <DashboardModal
      title={`${inquiry?.status === 'unassigned' ? 'New ' : ''} ${getTitle()}`}
      isOpen={isOpen}
      onClose={() => closeModal()}
      size="6xl"
      className="lg:min-h-[450px]"
    >
      <div className="flex flex-col w-full h-full gap-40 lg:flex-row lg:p-20">
        {(isLoading || isFetching)
          ? <div className="flex items-center justify-center w-full h-full"><LoadingIcon /></div>
          : <>
            <div className="flex flex-col lg:w-1/3 gap-25">
              <Input
                label={'First Name'}
                value={inquiry?.type_details?.first_name}
                readOnly
              />
              <Input
                label={'Last Name'}
                value={inquiry?.type_details?.last_name}
                readOnly
              />
              <Input
                label={'Phone Number'}
                value={`(+${inquiry?.type_details?.phone_number.country_code}) ${inquiry?.type_details?.phone_number.number}`}
                readOnly
              />
              {
                inquiry?.type !== 'Callback'
                && <>
                  <Input
                    label={'Email'}
                    value={inquiry?.type_details?.email}
                    readOnly
                  />
                  {
                    inquiry?.type === 'General'
                      ? <>
                        <Input
                          label={'Guests'}
                          value={inquiry?.type_details?.guests ? inquiry?.type_details?.guests : ''}
                          readOnly
                        />
                        <Input
                          label={'Budget'}
                          value={(inquiry?.type_details?.budget)}
                          readOnly
                        />
                      </>
                      : <>
                        <div className="flex gap-25">
                          <Input
                            label={'Adults'}
                            value={inquiry?.type_details?.adults ? inquiry?.type_details?.adults : ''}
                            readOnly
                          />
                          <Input
                            label={'Children'}
                            value={inquiry?.type_details?.children ? inquiry?.type_details?.children : ''}
                            readOnly
                          />
                        </div>
                        <Input
                          label={'Quoted Price'}
                          value={formatShortCurrency(inquiry?.type_details?.quoted_price, inquiry?.type_details?.property?.currency)}
                          readOnly
                        />
                      </>
                  }
                  <Input
                    label="Inquiry From"
                    value={formatEnum(inquiry?.type_details?.client?.channel)}
                    readOnly
                  />
                </>
              }
            </div>
            <div className="flex flex-col lg:w-2/3 gap-25">
              <div className="relative flex flex-row-reverse items-center w-full gap-20">
                <AvatarPopover
                  users={admins?.users}
                  label="Booking Agent"
                  selectedId={inquiry?.booking_agent?.id}
                  onClick={(id) => assignBookingAgent.mutate({
                    id: id,
                    inquiryId: inquiry?.id,
                  })}
                  isLoading={assignBookingAgent.isLoading}
                />

                <Input
                  label="Status"
                  className="lg:w-[200px] lg:ml-auto"
                  readOnly
                  value={inquiry?.status}
                  capitalise={true}
                />
                {(inquiry?.status === 'pending' || inquiry?.status === 'lost')
                  && <Link href={'/dashboard/bookings/new?inquiry=' + inquiry?.id} className="w-full rounded-8" target="__blank">
                    <Button style="admin" className="whitespace-nowrap !px-20">
                      Make Booking
                    </Button>
                  </Link>
                }
              </div>

              {
                inquiry?.type !== 'Callback'
                && <>
                  <div className="flex flex-col lg:flex-row gap-25">
                    {
                      inquiry?.type === 'General'
                        ? <Input
                          label={'Preferred Destination'}
                          value={
                            inquiry?.type_details?.sub_region_name
                              ? `${inquiry?.type_details?.sub_region_name}, ${inquiry?.type_details?.region_name}`
                              : inquiry?.type_details?.region_name ?? 'General'
                          }
                          readOnly
                        />
                        : <Input
                          label={'Property'}
                          value={`${inquiry?.type_details?.property?.title} [${inquiry?.type_details?.property?.reference}]`}
                          readOnly
                        />
                    }
                    <div className="flex w-full gap-25">
                      <Input
                        label={'From'}
                        value={formatDateReadable(inquiry?.type_details?.arrival)}
                        readOnly
                      />
                      <Input
                        label={'To'}
                        value={formatDateReadable(inquiry?.type_details?.departure)}
                        readOnly
                      />
                    </div>
                  </div>
                </>
              }
              <div className="relative w-full h-full px-20 overflow-y-auto ring-1 lg:ring-2 ring-input-border lg:ring-primary lg:ring-opacity-8 rounded-8">
                <p className="pt-20 antialiased text-grey-800 min-h-[140px] text-14 lg:text-16">{inquiry?.message}</p>
                <div className="absolute inset-0 flex px-20 top-5">
                  <span className={'text-grey-900 text-12'}>
                    Message
                  </span>
                </div>
              </div>
            </div>
          </>}
      </div>
    </DashboardModal>
  )
}

export default InquiryOverviewModal

InquiryOverviewModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  inquiryId: PropTypes.number,
  initialInquiry: PropTypes.object,
  refetch: PropTypes.func,
}
