import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Status } from 'data/enums/payment-status'
import { v4 as uuidv4 } from 'uuid'
import { AdditionalFee, DiscountType } from 'gql/graphql'
import { RootState } from './index'
import { MethodEnum } from 'data/enums/payment-method'

export type PaymentType = {
  id: string
  name: string
  status: Status
  percentage: number
  due_date: Date
  completed_date?: Date
  amount: number | null
  method: MethodEnum | null
  amount_received?: number
  payment_group_id?: string
}

type AdditionalFeeType = (AdditionalFee & {status: Status})

export type BookingPaymentsStateType = {
  totalRent: number
  additionalFees: AdditionalFeeType[]
  membershipCredit: number
  discount: number
  discountType: DiscountType | null
  damageDeposit: number
  invoiceNumber: string
  payments: PaymentType[]
  allowPercentage: boolean
}

const initialState: BookingPaymentsStateType = {
  totalRent: 0,
  additionalFees: [],
  membershipCredit: 0,
  discount: 0,
  discountType: null,
  damageDeposit: 0,
  invoiceNumber: '',
  payments: [],
  allowPercentage: true,
}

export const bookingPaymentSlice = createSlice({
  name: 'bookingPayments',
  initialState,
  reducers: {
    initialiseBooking: (state, action) => {
      state.totalRent = action.payload.totalRent
      state.damageDeposit = action.payload.damageDeposit
      state.discount = action.payload.discount
      state.discountType = action.payload.discountType
      state.membershipCredit = action.payload.membershipCredit
      state.invoiceNumber = action.payload.invoiceNumber
    },
    setTotalRent: (state, action: PayloadAction<number>) => {
      state.totalRent = action.payload
    },
    setAdditionalFees: (state, action: PayloadAction<AdditionalFeeType[]>) => {
      state.additionalFees = action.payload

      if (
        !state.payments.some((payment: PaymentType) => payment.name.includes('Additional Fees') && payment.status !== Status.COMPLETED)
        && action.payload.some((fee) => fee.status === Status.DUE)
      ) {
        let payments = [...state.payments]
        payments = [
          ...payments,
          {
            id: uuidv4(),
            name: 'Additional Fees',
            percentage: 100,
            due_date: new Date,
            amount: null,
            status: Status.DUE,
            method: null,
          },
        ]
        state.payments = payments
      }
    },
    setDeductions: (state, action) => {
      state.discount = action.payload.discount
      state.discountType = action.payload.discountType
      state.membershipCredit = action.payload.credit
    },
    setDamageDeposit: (state, action) => {
      state.damageDeposit = action.payload
    },
    setPayments: (state, action) => {
      state.payments = action.payload
    },
    setInvoiceNumber: (state, action) => {
      state.invoiceNumber = action.payload
    },
    setAllowPercentage: (state, action) => {
      state.allowPercentage = action.payload
    },
    updatePayment: (state, action) => {
      const payments = [...state.payments]

      const index = payments.findIndex(item => item.id === action.payload.id)

      if (index > -1) {
        state.payments[index] = {
          id: action.payload.id,
          name: action.payload.name,
          percentage: action.payload.percentage,
          due_date: action.payload.due_date,
          completed_date: action.payload.completed_date,
          amount: action.payload.amount,
          amount_received: action.payload.amount_received,
          status: action.payload.status,
          method: action.payload.method,
        }
      }
    },
    deletePayment: (state, action) => {
      const newPayments = [...state.payments]
      const index = newPayments.findIndex(item => item.id === action.payload)

      if (index > -1) {
        newPayments.splice(index, 1)
      }

      state.payments = newPayments
    },
  },
})

export const { initialiseBooking, setTotalRent, setAdditionalFees, setDeductions, setDamageDeposit, setPayments, setInvoiceNumber, setAllowPercentage, updatePayment, deletePayment } = bookingPaymentSlice.actions

export const selectAddtionalFees = (state: RootState) => state.bookingPayments.additionalFees
export const selectPayments = (state: RootState) => state.bookingPayments.payments

export const getTotals = (state: RootState) => {
  const due = state.bookingPayments.payments
    .filter(item => item.status === Status.DUE)
    .reduce((sum, payment) => (sum += payment.amount ?? 0), 0)

  const pending = state.bookingPayments.payments
    .filter(item => item.status === Status.PENDING)
    .reduce((sum, payment) => (sum += payment.amount ?? 0), 0)

  const completed = state.bookingPayments.payments
    .filter(item => item.status === Status.COMPLETED)
    .reduce((sum, payment) => {
      if (payment?.amount_received) {
        return sum += payment.amount_received
      } else {
        return sum += payment.amount ?? 0
      }
    }, 0)

  return {
    due: due,
    pending: pending,
    completed: completed,
  }
}

export const getTotalAdditionalFees = (state: RootState) => state.bookingPayments.additionalFees
  .reduce((sum, payment) => (sum += payment.amount), 0)

export const getBookingTotal = (state: RootState) => {
  let total = 0

  total += state.bookingPayments.totalRent

  total += state.bookingPayments.additionalFees.reduce((sum, fee) => (sum += fee.amount), 0)

  total -= state.bookingPayments.membershipCredit
  total -= state.bookingPayments.discount

  total += state.bookingPayments.damageDeposit

  return total
}

export default bookingPaymentSlice.reducer
