import React from 'react'
import PropTypes from 'prop-types'
import { AddCircleIcon, MinusCircleIcon } from 'components/icons'

const NumberInput = ({ value, placeholder = '', min, max, onChange = () => { }, error, variant = 'property' }) => {
  const minusDisabled = min !== undefined ? (value ?? 0) <= min : false
  const addDisabled = max !== undefined ? (value ?? 0) >= max : false

  const handleMinus = () => {
    if (minusDisabled) {return}
    const newValue = parseInt((value ?? 0)) - 1
    if (min && newValue <= min) {
      return
    }
    onChange(newValue)
  }

  const handleAdd = () => {
    if (addDisabled) {return}
    const newValue = parseInt((value ?? 0)) + 1
    if (max && newValue >= max) {
      return
    }
    onChange(newValue)
  }

  const getClasses = () => {
    switch (variant) {
      case 'property':
        return 'justify-between'
      case 'modal':
        return 'gap-20 justify-end'
    }
  }

  return (
    <div className="w-full relative">
      <div className={`flex items-center h-full ${getClasses()}`}>
        <button type="button" onClick={handleMinus} className={`text-18 ${minusDisabled ? 'text-grey-250' : 'text-primary hover:bg-primary hover:text-white'} rounded-full`}>
          <MinusCircleIcon className="rounded-full" />
        </button>
        <p className={`${value ? '' : 'text-placeholder antialiased'}`}>{value ?? placeholder}</p>
        <button type="button" onClick={handleAdd} className={`text-18 ${addDisabled ? 'text-grey-250' : 'text-primary hover:bg-primary hover:text-white'} rounded-full`}>
          <AddCircleIcon className="rounded-full" />
        </button>
      </div>

      {
        error
          ? <span className="text-red text-12 absolute bottom-0">{error}</span>
          : null
      }
    </div>
  )
}

NumberInput.propTypes = {
  value: PropTypes.number,
  placeholder: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func,
  error: PropTypes.string,
  variant: PropTypes.oneOf(['property', 'modal']),
}

export default NumberInput
