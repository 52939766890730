import { Switch } from '@headlessui/react'
import { Noop } from 'react-hook-form'
import { KeyboardEvent, cloneElement, useMemo, useRef } from 'react'

export default function Checkbox ({
  disabled,
  value = [],
  onChange,
  option,
  onBlur,
}: {
  disabled?: boolean,
  value: string[] | string
  onChange: (...event: any[]) => void
  onBlur: Noop
  option: {
    value: string,
    label?: string,
    component?: JSX.Element,
  }
}) {
  const ref = useRef<HTMLButtonElement | null>(null)

  const checked = useMemo(() => value.includes(option.value), [value, option.value])

  const handleKeyDown = (event: KeyboardEvent<HTMLButtonElement>) => {
    if (event.key === 'ArrowUp') {
      event.preventDefault()
      event.stopPropagation()
      if (ref.current?.previousElementSibling) {
        (ref.current?.previousElementSibling as HTMLElement).focus()
      }
    }

    if (event.key === 'ArrowDown') {
      event.preventDefault()
      event.stopPropagation()
      if (ref.current?.nextElementSibling) {
        (ref.current?.nextElementSibling as HTMLElement).focus()
      }
    }
  }

  return (
    <>
      <Switch
        ref={ref}
        checked={checked}
        disabled={disabled}
        onChange={() => onChange(option.value)}
        onBlur={onBlur}
        className="py-2 cursor-pointer focus:outline-none group"
        onKeyDown={handleKeyDown}
      >
        <div className="flex items-center gap-12">
          <div className="flex items-center justify-center w-16 min-w-[16px] h-16 border rounded-full border-primary group-focus:ring-3 group-focus:ring-primary group-focus:ring-opacity-50">
            {checked && <div className="w-8 h-8 rounded-full bg-primary" />}
          </div>

          <p className="text-16 leading-23 text-grey-800 text-left">
            {option.label ?? option.value}
          </p>
        </div>
      </Switch>

      {
        (option.component && checked)
          && <div className="md:ml-20">
            {cloneElement(option.component, { isChild: true }) as JSX.Element}
          </div>
      }
    </>
  )
}
