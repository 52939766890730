import React, { useState } from 'react'
import DestinationsModal from 'components/modals/frontend/Destinations/DestinationModal'
import PublicNavbarLink from 'components/PublicNavbarLink'

const DestinationsPopover = () => {
  const [openDestinationsModal, setIsOpenDestinationsModal] = useState(false)

  return (
    <>
      <PublicNavbarLink onClick={() => setIsOpenDestinationsModal(true)}>
        Destinations
      </PublicNavbarLink>

      <DestinationsModal
        isOpen={openDestinationsModal}
        onClose={() => setIsOpenDestinationsModal(false)}
      />
    </>
  )
}

export default DestinationsPopover
