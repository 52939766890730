import { default as AccordionBase } from 'components/accordion/Accordion'
import Item from 'components/accordion/AccordionItem'

type AccordionComponent = typeof AccordionBase & {
  Item: typeof Item;
};

const Accordion: AccordionComponent = AccordionBase as AccordionComponent
Accordion.Item = Item

export default Accordion
