import { isServer } from 'data/helpers/ssr'
import { useEffect, useState } from 'react'
import { RequestPolicy, UseQueryArgs, useQuery } from 'urql'

export const useNetworkOnlyQuery = (query: Omit<UseQueryArgs, 'requestPolicy'>) => {
  const [requestPolicy, setRequestPolicy] = useState<RequestPolicy>('cache-first')

  const props = useQuery({
    ...query,
    requestPolicy: isServer ? 'network-only' : requestPolicy,
  })

  useEffect(() => {
    // After mount set request policy to network only
    if (requestPolicy === 'cache-first') {
      setRequestPolicy('network-only')
    }
  }, [props[0].data])

  return props
}
