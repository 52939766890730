import React from 'react'
import { LoadingIcon } from 'components/icons'
import PublicModal from 'components/PublicModal'
import { gql, useQuery } from 'urql'
import { SurveyBankInfoModalPropTypes } from 'data/types/service_surveys/bank-info'

const UserSurveyBankInfoModal: React.FC<SurveyBankInfoModalPropTypes> = ({
  isOpen = false,
  onClose = () => {},
  surveyBankInfoId,
}) => {
  const [{ data, fetching   }] = useQuery({
    query: gql`
      query getSurveyBankInfoForUser($id: ID!) {
        surveyBankInfo(id: $id) {
          id
          details {
            question
            answer
          }
        }
      }
    `,
    variables: { id: surveyBankInfoId },
    pause: !isOpen,
  })

  const closeModal = () => {
    onClose()
  }

  return (
    <PublicModal
      isOpen={isOpen}
      onClose={() => closeModal()}
      className="max-w-[650px] max-h-[800px] w-full h-full px-50"
      innerPadding="!p-0"
    >
      <div className="flex flex-col w-full h-full gap-40 lg:flex-row">
        {(fetching)
          ? <div className="flex items-center justify-center w-full h-full p-50"><LoadingIcon /></div>
          : <div className="flex flex-col lg:flex-row w-full lg:max-h-[800px] px-65">
            <div className="flex flex-col w-full pb-20 pl-20 pt-55">
              <h4 className="pl-5 pr-20 mb-20 font-bold uppercase text-14 leading-20 tracking-1/2 text-grey-750">
                Bank Info #B{data?.surveyBankInfo?.id?.padStart(6, '0')}
              </h4>

              <h2 className="font-serif font-bold text-[40px] tracking-title leading-title text-grey-900 mb-40 pr-20">
                Bank Form
              </h2>

              <div className="w-full h-full pr-20 overflow-y-auto">
                <p className="pt-20 antialiased text-grey-800 min-h-[140px] text-14 lg:text-15">
                  {(data?.surveyBankInfo?.details ?? []).map((item: {question?: string, answer?: string}, index: number) =>
                    <span key={index}>
                      <b className="block text-justify">{item?.question}</b>
                      <span className="whitespace-pre">{item?.answer}</span><br/><br/>
                    </span>,
                  )}
                </p>
              </div>
            </div>
          </div>
        }
      </div>
    </PublicModal>
  )
}

export default UserSurveyBankInfoModal
