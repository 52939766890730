import React from 'react'
import PropTypes from 'prop-types'
import DashboardModal from 'components/DashboardModal'
import Button from 'components/Button'

const ConfirmModal = ({
  isOpen,
  onClose = () => { },
  title,
  message,
  variant = 'danger',
  action = () => { },
  isLoading = false,
  closeOnSubmit = true,
}) => {
  const handleAction = () => {
    action.call()

    if (closeOnSubmit) {
      onClose()
    }
  }

  return (
    <DashboardModal isOpen={isOpen} onClose={onClose} title={title}>
      <p className="whitespace-pre-wrap mb-25 text-grey-800">{message}</p>
      <div className="flex justify-between gap-10">
        <Button style="admin" onClick={onClose} block variant="outline-grey">Cancel</Button>
        <Button style="admin" onClick={handleAction} isLoading={isLoading} block variant={variant}>Confirm</Button>
      </div>
    </DashboardModal>
  )
}

ConfirmModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.string,
  variant: PropTypes.oneOf(['danger', 'primary']),
  action: PropTypes.func,
  isLoading: PropTypes.bool,
  closeOnSubmit: PropTypes.bool,
}

export default ConfirmModal
