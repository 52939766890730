import { RestBooking } from 'data/types/booking'
import { useRouter } from 'next/router'

interface BookingAccessProviderProps {
  booking?: RestBooking
  requiresFullAccess?: boolean
  children?: JSX.Element[] | JSX.Element
}

const BookingAccessProvider = ({
  booking,
  requiresFullAccess = true,
  children,
}: BookingAccessProviderProps) => {
  const router = useRouter()

  if (!booking) {
    return <></>
  }

  if (requiresFullAccess && booking?.is_shared_booking) {
    if (typeof window !== 'undefined') {
      router.push('/forbidden')
    }

    return <></>
  }

  return (
    <>
      {children}
    </>
  )
}

export default BookingAccessProvider
