import { Controller } from 'react-hook-form'
import CheckboxGroup, { CheckboxGroupOptions, CheckboxGroupProps } from './CheckboxGroup'
import { useEffect } from 'react'

interface ControlledCheckboxGroupProps extends Omit<CheckboxGroupProps, 'value' | 'onChange' | 'onBlur'> {
  disabled?: boolean,
  name: string,
  control: any,
  options: CheckboxGroupOptions[]
  isChild?: boolean,
  multiSelect?: boolean,
}

export default function ControlledCheckboxGroup ({
  disabled,
  name,
  control,
  options,
  multiSelect = true,
  ...props
}: ControlledCheckboxGroupProps) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, onBlur } }) => {
        useEffect(() => {
          if (!Array.isArray(value)) {
            onChange([])
          }
        }, [])

        const handleOnChange = (newValue: string) => {
          if (!multiSelect) {
            return value.includes(newValue)
              ? onChange([])
              : onChange([newValue])
          }

          if (!Array.isArray(value)) {
            return onChange([newValue])
          }

          if (!value) {
            return onChange([newValue])
          }

          if (value.includes(newValue)) {
            onChange(value.filter((item: string) => item !== newValue))
          } else {
            // Ensure that value always has the same order the options are defined
            onChange([...value, newValue])
          }
        }

        return (
          <CheckboxGroup
            {...props}
            disabled={disabled}
            value={value}
            onChange={handleOnChange}
            onBlur={onBlur}
            options={options}
          />
        )}}
    />
  )
}
