import { AxiosInstance } from 'axios'
import { validateBoolean } from 'data/helpers/strings'
import { RestUser } from 'data/types/user'
import { Channel } from 'pusher-js'

export default class AuthApiClient {
  http: AxiosInstance

  constructor (http: AxiosInstance) {
    this.http = http
  }

  /**
   * Attempt to login a user with the provided details
   */
  async login (
    email: string | undefined,
    password: string | undefined,
    remember: boolean | undefined = false,
    appLoginAt: boolean | undefined = false,
  ): Promise<RestUser> {
    await this.http.get('/auth/csrf-cookie')
    const result = await this.http.post('/auth/login', {
      email,
      password,
      remember: validateBoolean(remember),
      appLoginAt,
    })
    return result.data.data
  }

  /**
   * Attempt to login a user using a social login
   */
  async socialLogin (
    provider: string | undefined,
    query: Record<string, string | boolean>,
  ): Promise<RestUser> {
    await this.http.get('/auth/csrf-cookie')
    const result = await this.http.post(`/auth/social/${provider}`, query)
    return result.data.data
  }

  /**
   * Social Registration for when the Social Provider doesn't provide the email
   */
  async socialRegister (
    provider: string | undefined,
    fields: SocialRegisterFields,
  ): Promise<RestUser> {
    const result = await this.http.post(`/auth/social/${provider}/register`, fields)
    return result.data.data
  }

  /**
   * Log out the current user
   */
  async logout () {
    await this.http.delete('/auth/logout')
  }

  /**
   * Trigger a password reset email
   */
  async triggerPasswordReset (email: string | undefined) {
    await this.http.post('/auth/login/forgot', {
      email,
    })
  }

  /**
   * Reset a user's password
   */
  async resetPassword (
    email: string | undefined,
    password: string | undefined,
    passwordConfirmation: string | undefined,
    token: string | undefined,
  ) {
    await this.http.post('/auth/login/reset', {
      email,
      password,
      password_confirmation: passwordConfirmation, // eslint-disable-line camelcase
      token,
    })
  }

  /**
   * Set a user's password
   */
  async setPassword (
    email: string | undefined,
    password: string | undefined,
    passwordConfirmation: string | undefined,
    token: string | undefined,
  ) {
    await this.http.post('/auth/login/set', {
      email,
      password,
      password_confirmation: passwordConfirmation, // eslint-disable-line camelcase
      token,
    })
  }

  /**
   * Register a new user
   */
  async register (fields: RegisterFields): Promise<RestUser> {
    const result = await this.http.post('/auth/register', fields)
    return result.data.data
  }

  /**
   * Get a list of social login urls
   */
  async getSocialLinks (): Promise<Record<string, string>> {
    const result = await this.http.get('/auth/social')
    return result.data.data
  }

  /**
   * Verify the user is not a bot with recaptcha
   */
  async verifyWithRecaptcha (token: string | undefined): Promise<{
    success: boolean
    score: 1 | 0
  }> {
    const result = await this.http.post('/auth/recaptcha/verify', { token: token })
    return result.data
  }

  /**
   * Authorise the current user for pusher
   */
  async pusherAuth (
    { channel, socketId }:
      {
        channel: Channel
        socketId: string
      },
  ): Promise<Error | object> {
    const result = await this.http.post('/broadcasting/auth', {
      socket_id: socketId,
      channel_name: channel.name,
    })

    return result.data
  }
}

interface BaseRegisterFields {
  first_name: string | undefined
  last_name: string | undefined
  email: string | undefined
  phone: string | undefined
  phone_country_code: string | undefined
  booking_channel: string | undefined
  privacy_accepted: boolean | undefined
  is_mobile_app: boolean | undefined
}

export interface SocialRegisterFields extends BaseRegisterFields {
  registration_token: string | undefined
}

export interface RegisterFields extends BaseRegisterFields {
  password: string | undefined
}
