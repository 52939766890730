import classNames from 'classnames'
import { ComponentProps } from 'react'

interface PropTypes extends ComponentProps<'p'> {
  Tag?: 'p' | 'span' | 'div'
}

const defaultClassNames = 'font-poppins tracking-1/4 leading-22'

const XS = ({ children, className = '', Tag = 'p', ...rest }: PropTypes) => (
  <Tag
    className={classNames(
      defaultClassNames,
      className,
      'text-12 lg:text-14',
    )}
    {...rest}
  >
    {children}
  </Tag>
)

export default {
  XS,
}
