import { getTripScheduleServiceStatusType } from 'data/helpers/trip-schedule-group'
import { TripScheduleService } from 'data/types/bookings/trip_schedule'
import { RestBooking } from 'data/types/booking'
import UserTripServicePill, { UserTripServicePillProps } from './UserTripServicePill'

interface RestUserTripServicePillProps extends Pick<UserTripServicePillProps, 'onClose' | 'type'> {
  service: TripScheduleService
  booking: RestBooking
}

/**
 * @deprecated Use `UserTripServicePill` instead, this component is to be used for REST services only
 */
const RestUserTripServicePill = ({
  service,
  booking,
  ...props
}: RestUserTripServicePillProps) => {
  return (
    <UserTripServicePill
      service={{
        title: service?.title,
        status: getTripScheduleServiceStatusType(service?.status),
      }}
      serviceRequestId={service.group.service_request_id}
      booking={{ id: booking?.id.toString() }}
      bookingInformation={booking?.has_booking_information}
      {...props}
    />
  )
}

export default RestUserTripServicePill
