import { Control, Controller, FieldValues, Path, PathValue } from 'react-hook-form'
import NumberInput, { NumberInputPropTypes } from './NumberInput'

interface ControlledNumberInputProps<T extends FieldValues> extends Omit<NumberInputPropTypes, 'onChange'|'value'> {
  name: Path<T>
  control: Control<T>
}

export default function ControlledNumberInput<T extends FieldValues> ({
  name,
  control,
  defaultValue,
  ...props
}: ControlledNumberInputProps<T>) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue as PathValue<T, Path<T>> | undefined}
      render={({ field: { onChange, value } }) => (
        <NumberInput
          value={value}
          onChange={onChange}
          {...props}
        />
      )}
    />
  )
}
