import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface PaginationState {
  perPage: number
}

const initialState: PaginationState = {
  perPage: 5,
}

export const paginationSlice = createSlice({
  name: 'pagination',
  initialState: initialState,
  reducers: {
    setPerPage: (state, action: PayloadAction<number | string>) => {
      state.perPage = typeof action.payload === 'string'
        ? Number.parseInt(action.payload)
        : action.payload
    },
  },
})

export const { setPerPage } = paginationSlice.actions

export default paginationSlice.reducer
