import React from 'react'

interface NotificationProps {
  title?: string
  message: string
  type: 'success' | 'error'
}

const Notification = ({
  title,
  message,
  type,
}: NotificationProps) => {
  let backgroundColor
  switch (type) {
    case 'success':
      backgroundColor = 'bg-green'
      break
    case 'error':
      backgroundColor = 'bg-red'
      break
  }

  return (
    <div className={`max-w-150 ${backgroundColor} shadow px-15 py-10 rounded-10 text-white`}>
      {
        title
          ? <p className="font-bold">{title}</p>
          : null
      }

      <p>{message}</p>
    </div>
  )
}

export default Notification
