import React, { useState, forwardRef } from 'react'
import PropTypes from 'prop-types'
import { CloudUploadIcon } from 'components/icons'
import classNames from 'classnames'

const File = forwardRef(function file ({
  label,
  accept,
  name,
  onChange = () => { },
  onBlur = () => { },
  error,
  uploadedFileName,
  className,
}, ref) {
  const [filename, setFilename] = useState()

  const handleChange = (e) => {
    if (e.target.value) {
      const nameArray = e.target.value.split('\\')
      setFilename(nameArray[nameArray.length - 1])
    } else {
      setFilename(null)
    }
    onChange(e)
  }

  return (
    <div className="w-full">
      <label className="relative flex items-center w-full cursor-pointer text-16 border-3 border-primary border-opacity-8 h-50 rounded-8">
        <div className="absolute top-0 bottom-0 left-0 flex items-center text-24 pl-15 text-primary">
          <CloudUploadIcon />
        </div>

        <div className={classNames(
          'absolute inset-0 flex items-center pl-50',
          {
            'pr-20 pt-15': label,
          },
          className,
        )}>
          {filename ?? uploadedFileName}
        </div>

        <div className="absolute inset-0 flex items-center pr-20 pointer-events-none pl-50">
          <span className={classNames(
            'transform transition-all text-grey-900',
            {
              'text-opacity-100 text-12 -translate-y-10': filename || uploadedFileName,
              'text-16': !filename && !uploadedFileName,
            },
          )}>
            {label}
          </span>
        </div>

        <input
          type="file"
          className="hidden"
          name={name}
          onBlur={onBlur}
          onChange={handleChange}
          accept={accept}
          ref={ref}
        />
      </label>

      {
        error
          ? <span className="text-red text-14">{error}</span>
          : null
      }
    </div>
  )
})

File.propTypes = {
  label: PropTypes.string,
  accept: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.string,
  uploadedFileName: PropTypes.string,
  className: PropTypes.string,
}

export default File
