enum Share {
  EMAIL = 'Email',
  FACEBOOK = 'Facebook',
  GMAIL = 'Gmail',
  LINKEDIN = 'LinkedIn',
  TWITTER = 'Twitter',
  WHATSAPP = 'WhatsApp',
  CLIPBOARD = 'Copy Link'
}

export { Share }
