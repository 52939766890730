import classNames from 'classnames'
import { LoadingIcon } from 'components/icons'
import Pill from 'components/home/Pill'
import Paragraphs from 'components/home/common/typography/Paragraphs'

export type PillSelectClassNames = {
  container?: string
  pills?: string
  title?: string
}

export interface PillSelectProps {
  className?: PillSelectClassNames
  title?: string
  fetching?: boolean
  data: {
    id: string | undefined
    value: string
  }[]
  onClick?: (id?: number) => void
  selected?: number[]
  slideOffset?: number
  variant?: 'grey' | 'blue'
}

const PillSelect = ({
  className,
  title,
  data,
  fetching,
  onClick,
  selected = [],
  variant = 'grey',
}: PillSelectProps) => {
  return (
    <div className={classNames('flex flex-col gap-15', className?.container)}>
      {
        title && (
          <Paragraphs.LG Tag="span" className={classNames('leading-24', className?.title)}>
            {title}
          </Paragraphs.LG>
        )
      }
      { fetching
        ? (
          <div className={classNames('flex flex-col items-center justify-center', className?.pills)}>
            <LoadingIcon />
          </div>
        )
        : (
          <div className={classNames('flex gap-8 flex-row flex-wrap', className?.pills)}>
            { data?.map((tag, index: number) => {
              const tagId = tag.id
                ? parseInt(tag.id)
                : undefined

              return (
                <div className="w-max" key={index}>
                  <Pill
                    variant={variant}
                    size="14"
                    isMultiselect
                    isActive={tagId
                      ? selected?.includes(tagId)
                      : (selected ?? []).length === 0
                    }
                    onClick={() => onClick?.(tagId)}
                  >
                    {tag.value}
                  </Pill>
                </div>
              )
            })}
          </div>
        )
      }
    </div>
  )
}

export default PillSelect
