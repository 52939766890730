import { formatCurrency } from '@ama-selections/ui'
import { User } from '@sentry/nextjs'
import classNames from 'classnames'
import { RestUser } from 'data/types/user'
import React from 'react'
import logoImage from 'assets/images/logo-no-tagline-white.png'
import Image from 'components/Image'

interface MembershipCardProps {
  user: RestUser | User
  className: {
    container?: string
  }
}

const MembershipCard = ({
  user,
  className,
}: MembershipCardProps) => {
  return (
    <div className={classNames(
      'relative flex flex-col justify-between',
      'rounded-[14px] relative overflow-hidden select-none',
      'h-[220px] w-[320px]',
      'pt-[17px] pr-[23px] pl-[16px] pb-25',
      'bg-gradient-to-br from-[#1e1e1e] to-[#313131] to-60%',
      className?.container,
    )}>
      <div className={classNames(
        'flex flex-col items-end',
      )}>
        <span className="font-medium text-white font-poppins text-12 leading-22 tracking-1/4">
          {user.first_name}
        </span>
      </div>

      <div className="flex justify-between">
        <Image
          src={logoImage}
          height={40}
          width={73.82}
        />
        <div className="flex flex-col items-end justify-between">
          <span className="font-semibold text-white font-poppins text-14 leading-22 tracking-1/4">
            {formatCurrency(user.credit)}
          </span>
          <span className="font-medium text-white capitalize font-poppins text-12 leading-22 tracking-1/4">
            {user.membership_type} Member
          </span>
        </div>
      </div>
    </div>
  )
}

export default MembershipCard
