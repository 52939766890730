import React, { useEffect, useState } from 'react'
import CenteredCustomerLoginModal from 'components/modals/getHelp/CenteredCustomerLoginModal'
import { ModalProps } from 'data/types/modal'
import { DestinationRegion } from 'gql/graphql'
import useAuth from 'data/hooks/User/useAuth'
import ShareModal from '../home/ShareModal'
import { InquiryType } from 'data/types/inquiry'
import GetHelpModal from './GetHelpModal'
import HelpAssets from './HelpAssets'

interface CustomerGetInTouchModalProps extends ModalProps {
  region?: DestinationRegion
  onOpen?: () => void
}

const CustomerGetInTouchModal = ({
  region,
  isOpen,
  onClose,
  onOpen,
}: CustomerGetInTouchModalProps) => {
  const { user } = useAuth()

  const [getHelpModalOpen, setGetHelpModalOpen] = useState(false)
  const [loginModalOpen, setLoginModalOpen] = useState(false)
  const [modalType, setModalType] = useState<InquiryType | null>(null)
  const [hasSent, setHasSent] = useState(false)

  const setModal = (type: InquiryType) => {
    setModalType(type)
    if (type === InquiryType.General && !user) {
      setLoginModalOpen(true)
    } else {
      setGetHelpModalOpen(true)
    }
  }

  useEffect(() => {
    if (user && modalType === InquiryType.General) {
      setTimeout(() => {
        setGetHelpModalOpen(true)
      }, 300)
    }
  }, [user])

  return (
    <ShareModal
      onClose={onClose}
      isOpen={isOpen}
      onOpen={onOpen}
      title="Get in touch with us"
      description="Any questions? We’re here to help. Reach out to us via your preferred contact method below. We look forward to hearing from you!"
      isChildModalOpen={getHelpModalOpen || loginModalOpen}
    >
      <div className="flex flex-col gap-15">
        <HelpAssets.RequestCallbackButton
          onClick={() => setModal(InquiryType.Callback)}
        />

        <HelpAssets.GiveUsACallButton />

        <HelpAssets.SendMessageButton onClick={() => setModal(InquiryType.General)} />
      </div>

      <GetHelpModal
        isOpen={getHelpModalOpen}
        onSuccess={() => setHasSent(true)}
        onClose={() => {
          setGetHelpModalOpen(false)
          !hasSent && onOpen?.()
        }}
        initialType={modalType ?? InquiryType.General}
        region={region as any}
      />

      <CenteredCustomerLoginModal
        isOpen={loginModalOpen}
        onClose={() => setLoginModalOpen(false)}
      />
    </ShareModal>
  )
}


export default CustomerGetInTouchModal
