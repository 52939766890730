import PublicMobileModalDrawer from 'components/PublicMobileModalDrawer'
import useAuth from 'data/hooks/User/useAuth'
import { ModalProps } from 'data/types/modal'
import IconButton from 'components/IconButton'
import { ArrowLeftIcon, CheckBubbleIcon } from 'components/icons'
import MembershipCard from '../items/MembershipCard'
import { capitaliseFirstLetter } from '@ama-selections/ui'
import { config } from 'data/config'
import Button from 'components/home/common/Button'
import MembershipPromotionalModal from 'components/home/membership/MembershipPromotionalModal'
import { useMemo, useState } from 'react'
import { Membership } from 'data/enums/membership-type'

interface CustomerMembershipModalProps extends ModalProps { }

const CustomerMembershipModal = ({
  isOpen,
  onClose,
}: CustomerMembershipModalProps) => {
  const { user } = useAuth()
  const [isPromotionalModalOpen, setIsPromotionalModalOpen] = useState(false)

  const membershipDetails = useMemo(() => {
    if (user?.membership_type) {
      return config.membership[user?.membership_type]
    }
  }, [user])

  return (
    <>
      <PublicMobileModalDrawer
        isOpen={isOpen}
        onClose={onClose}
        position="right"
        hasFocusTrap
        title="Membership & Rewards"
        className={{
          panel: 'flex flex-col pb-safe-offset-10 px-30',
          closeButton: '!bg-transparent z-10', // required for the gray background
          title: 'z-10', // required for the gray background
        }}
      >
        <IconButton
          className="absolute z-10 !bg-transparent top-safe-offset-20 left-20"
          onClick={onClose}
          variant="modal-close"
        >
          <ArrowLeftIcon className="stroke-2" />
        </IconButton>

        <div className="absolute inset-0 w-full h-[165px] bg-grey-150"></div>

        {user
          ? <div className="flex justify-center w-full">
            <MembershipCard
              className={{
                container: 'mt-20 shadow-membership-card',
              }}
              user={user}
            />
          </div>
          : null
        }

        <div className="overflow-y-auto mt-30">
          <span className="font-bold uppercase text-grey-650 tracking-1/2 leading-22 text-14">
            {user?.first_name},
          </span>

          <h3 className="font-serif font-bold text-26 -tracking-1/2 mt-14">
            Thank you for being<br />a {capitaliseFirstLetter(user?.membership_type ?? Membership.REGISTERED)} Member!
          </h3>

          {membershipDetails?.features
            ? <p className="text-grey-800 leading-24 mt-15">
              Perks included in your membership:
            </p>
            : null
          }

          <div className="flex flex-col mt-20">
            {membershipDetails?.features.map((feature, index) => (
              <div key={index} className="flex items-center gap-12 mt-10 text-grey-800">
                <CheckBubbleIcon className="h-15 w-15" />
                <span className="text-14 leading-22">
                  {feature}
                </span>
              </div>
            ))}
          </div>
        </div>

        <div className="flex items-end flex-1">
          <Button
            block
            style="modal"
            className={{
              button: 'mt-10 lg:mb-20 mb-10',
            }}
            onClick={() => setIsPromotionalModalOpen(true)}
          >
            Upgrade
          </Button>
        </div>

        <MembershipPromotionalModal
          isOpen={isPromotionalModalOpen}
          onClose={() => setIsPromotionalModalOpen(false)}
        />

      </PublicMobileModalDrawer>
    </>
  )
}

export default CustomerMembershipModal
