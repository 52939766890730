import { Combobox, Transition } from '@headlessui/react'
import { useState, Fragment } from 'react'
import { useDebounce } from 'use-debounce'
import PropTypes from 'prop-types'
import { LoadingIcon, MapPinIcon, SearchAltIcon } from '../../../../icons'
import Form from '../../../../form'
import { useQuery } from 'urql'
import { joinBy } from 'data/helpers/strings'
import DestinationSearchItem from './DestinationSearchItem'
import { isServer } from 'data/helpers/ssr'
import { DestinationSearchResultsQuery } from 'data/graphql/queries/client/destinations'

const DestinationSearchBar = ({
  className,
  onClose = () => { },
}) => {
  const [_, setSelectedResult] = useState(null)
  const [searchQuery, setSearchQuery] = useState(null)
  const [debouncedSearch] = useDebounce(searchQuery, 500)

  const [{ data, fetching }] = useQuery({
    query: DestinationSearchResultsQuery,
    variables: {
      regionsFilter: {
        value: debouncedSearch,
      },
      subRegionsFilter: {
        value: debouncedSearch,
      },
      citiesFilter: {
        value: debouncedSearch,
      },
    },
    pause: !debouncedSearch && isServer,
  })

  return (
    <div className={`relative flex ${className}`}>
      <Combobox onChange={setSelectedResult}>
        <Combobox.Input
          as={Fragment}
          displayValue={(result) => (result?.value ?? '')}
        >
          <Form.Input
            placeholder="Find Destination"
            prefix={
              <MapPinIcon className="w-12 h-16 text-primary" />
            }
            suffix={
              <div className="bg-primary h-full w-[60px] -mx-20 rounded-full flex items-center justify-center">
                <SearchAltIcon className="text-white text-16" />
              </div>
            }
            value={searchQuery ?? ''}
            onChange={(event) => setSearchQuery(event.target.value)}
            className="w-full text-14 border-none bg-grey-100 font-normal !rounded-full !h-40"
            withRing={false}
          />
        </Combobox.Input>

        <Combobox.Options className="absolute z-10 w-full top-full focus:outline-none">
          <Transition
            show={!!(searchQuery && (fetching || data))}
            className="cursor-pointer bg-white rounded-[1.25rem] mt-3"
            enter="transition-all ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition-all ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <div className="transition-all overflow-y-auto flex flex-col h-min max-h-[480px] border z-50 rounded-20 border-grey-200 first:pt-5 last:pb-5">
              {
                fetching
                  ? <div className="flex items-center justify-center py-20">
                    <LoadingIcon />
                  </div>
                  : ((data?.regions?.data ?? []).length === 0 && (data?.subRegions?.data ?? []).length === 0 && (data?.cities?.data ?? []).length === 0)
                    && <p className="px-10 py-5 text-center text-14 text-grey-800 leading-16">
                      No results found
                    </p>
              }

              {
                !fetching && data?.cities?.data.map((city) => (
                  <DestinationSearchItem
                    key={`destination-search-city-${city?.id}`}
                    href={'/' + joinBy([
                      city?.subRegion?.region?.country?.slug,
                      city?.subRegion?.region?.slug,
                      city?.subRegion?.slug,
                      city?.slug,
                    ], '/')}
                    value={city?.value}
                    onClick={onClose}
                  >
                    {joinBy([
                      city?.value,
                      city?.subRegion?.value,
                      city?.subRegion?.region?.value,
                      city?.subRegion?.region?.country?.value,
                    ], ', ')}
                  </DestinationSearchItem>
                ))
              }

              {
                !fetching && data?.subRegions?.data.map((subRegion) => (
                  <DestinationSearchItem
                    key={`destination-search-sub-region-${subRegion?.id}`}
                    href={'/' + joinBy([
                      subRegion?.region?.country?.slug,
                      subRegion?.region?.slug,
                      subRegion?.slug,
                    ], '/')}
                    value={subRegion?.value}
                    onClick={onClose}
                  >
                    {joinBy([
                      subRegion?.value,
                      subRegion?.region?.value,
                      subRegion?.region?.country?.value,
                    ], ', ')}
                  </DestinationSearchItem>
                ))
              }

              {
                !fetching && data?.regions?.data.map((region) => (
                  <DestinationSearchItem
                    key={`destination-search-region-${region?.id}`}
                    href={'/' + joinBy([
                      region?.country?.slug,
                      region?.slug,
                    ], '/')}
                    value={region?.value}
                    onClick={onClose}
                  >
                    {joinBy([
                      region?.value,
                      region?.country?.value,
                    ], ', ')}
                  </DestinationSearchItem>
                ))
              }

            </div>
          </Transition>
        </Combobox.Options>
      </Combobox>
    </div>
  )
}

DestinationSearchBar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
}

export default DestinationSearchBar
