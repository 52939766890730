import { graphql } from 'gql'
import { useQuery } from 'urql'

export const useWishlistCount = () => {
  const [{ data, fetching }] = useQuery({
    query: graphql(`
      query WishlistTotal {
        wishlists(page: 1, first: 1) {
          paginatorInfo {
            total
          }
        }
      }
    `),
  })

  return {
    wishlistCount: data?.wishlists?.paginatorInfo?.total ?? 0,
    fetchingWishlistCount: fetching,
  }
}
