import React from 'react'
import { FieldError } from 'react-hook-form'
import ServiceRequestSlide from 'components/service-request-forms/components/ServiceRequestSlide'
import ServiceRequestQuestion from 'components/service-request-forms/components/ServiceRequestQuestion'
import ServiceRequestNextButton from 'components/service-request-forms/components/ServiceRequestNextButton'
import ServiceSurveyForm from 'components/form/ServiceSurveyForm'
import { CarRentalError, SurveyFormProps } from 'data/types/service_surveys'
import { QuestionText } from 'data/helpers/survey/surveys/car-rental'

const Slide6 = ({
  questionStartIndex,
  position,
  fetching,
  isSlideActive,
  control,
  register,
  formState: { errors },
  setValue,
  clearErrors,
}: SurveyFormProps) => {
  return (
    <ServiceRequestSlide
      slideNumber={6}
      position={position}
      isSlideActive={isSlideActive}
      setValue={setValue}
      clearErrors={clearErrors}
    >
      <ServiceRequestQuestion
        questionNumber={questionStartIndex}
        question={QuestionText(8)}
      />
      <ServiceSurveyForm.Group>
        <ServiceSurveyForm.ControlledNumberRating
          name="question8.child_seats"
          control={control}
          options={[1, 2, 3, 4, 5].map((grade) => ({ value: grade }))}
          error={((errors as CarRentalError).question8 as unknown as {child_seats?: FieldError})?.child_seats}
          disabled={!isSlideActive}
        />
      </ServiceSurveyForm.Group>
      <ServiceRequestQuestion
        questionNumber={questionStartIndex + 1}
        question={QuestionText(9)}
      />
      <ServiceSurveyForm.Group>
        <ServiceSurveyForm.Input
          placeholder="Type your answer here..."
          {...register('question9.child_ages')}
          disabled={!isSlideActive}
          error={((errors as CarRentalError)?.question9 as unknown as {child_ages: FieldError})?.child_ages}
        />
      </ServiceSurveyForm.Group>
      <ServiceRequestNextButton
        isLoading={fetching}
        disabled={!isSlideActive}
      />
    </ServiceRequestSlide>
  )
}

export default Slide6
