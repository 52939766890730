import { graphql } from 'gql'
import { WishlistDropdownQuery, WishlistsQuery } from 'gql/graphql'
import { useQueryWithPromise } from 'data/hooks/useQueryWithPromise'
import { ActionMeta, SingleValue } from 'react-select'
import { TypedDocumentNode } from 'urql'
import AsyncSelect, { AsyncSelectProps, OptionType } from './AsyncSelect'

export interface WishlistSelectPropTypes {
  onChange: (
    value: SingleValue<OptionType> | undefined,
    meta: ActionMeta<OptionType>
  ) => void
  value: OptionType
  query?: TypedDocumentNode<any, { page: number, perPage: number, orderBy: any[], filters: any }>
  showNew?: boolean
}

export interface WishlistAsyncSelectProps extends Omit<
  AsyncSelectProps<WishlistsQuery>,
  'refetchQuery' | 'convertDataToOptions' | 'hasMorePagesLocation' | 'onChange' | 'value'
> {}

const PER_PAGE = 5

const WISHLIST_DROPDOWN_QUERY = graphql(`
  query WishlistDropdown(
    $page: Int!,
    $perPage: Int!,
  ) {
    wishlists(page: $page, first: $perPage) {
      data {
        id
        title
      }
      paginatorInfo {
        hasMorePages
        currentPage
      }
    }
  }
`)

const WishlistSelect = function ({
  onChange,
  value,
  query = WISHLIST_DROPDOWN_QUERY,
  showNew = true,
  ...props
}: WishlistSelectPropTypes) {
  const { refetchQuery } = useQueryWithPromise({
    query: query,
  })

  const convertDataToOptions = (data?: WishlistDropdownQuery) => {
    if (!data) {
      return []
    }

    return [
      ...(showNew ? [{ id: '-1', title: 'New Wishlist' }] : []),
      ...data.wishlists.data,
    ].map((wishlist) => ({
      label: wishlist.title,
      value: wishlist.id,
      extra: wishlist,
    }))
  }

  return (
    <AsyncSelect
      {...props}
      variant="customer"
      classNames={{
        menuList: (_) => '!max-h-[120px]',
      }}
      refetchQuery={({ newPage, search }) => refetchQuery({
        page: newPage,
        perPage: PER_PAGE,
        filters: {
          search: search,
        },
      })}
      convertDataToOptions={convertDataToOptions}
      hasMorePagesLocation="wishlists.paginatorInfo"
      placeholder="Select a Wishlist"
      value={value?.value ? value : []}
      onChange={(newValue, meta) => {
        switch (meta.action) {
          case 'clear':
            onChange(undefined, meta)
            break
          default:
            onChange(
              value?.value === newValue?.value
                ? undefined
                : newValue,
              meta,
            )
            break
        }
      }}
    />
  )
}

export default WishlistSelect
