import { Control, Controller, FieldValues, Path } from 'react-hook-form'
import PillSelect, { PillSelectProps } from './PillSelect'
import { useEffect } from 'react'

interface ControlledPillSelectProps<T extends FieldValues> {
  title?: string
  name: Path<T>
  control: Control<T>
  onClick?: (value?: number) => void
  multiSelect?: boolean,
}

export default function ControlledPillSelect<T extends FieldValues> ({
  title,
  data,
  name,
  control,
  fetching = false,
  className,
  onClick,
  multiSelect = true,
}: (ControlledPillSelectProps<T> & PillSelectProps)) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => {
        useEffect(() => {
          if (!Array.isArray(value)) {
            onChange([])
          }
        }, [])

        const handleOnChange = (newValue?: number) => {
          if (newValue === undefined) {
            onClick?.(newValue)
            return onChange([])
          }

          if (!multiSelect) {

            if (Array.isArray(value) && value.includes(newValue)) {
              return onChange([])
            }

            return onChange([newValue])
          }

          if (!Array.isArray(value)) {
            onClick?.(newValue)
            return onChange([newValue])
          }

          if (!value) {
            onClick?.(newValue)
            return onChange([newValue])
          }

          if (value.includes(newValue)) {
            onChange(value.filter((item: number) => item !== newValue))
          } else {
            // Ensure that value always has the same order the options are defined
            onChange([...value, newValue])
          }

          return onClick?.(newValue)
        }

        return (
          <PillSelect
            title={title}
            onClick={handleOnChange}
            data={data}
            fetching={fetching}
            selected={value}
            className={className}
          />
        )
      }}
    />
  )
}
