import { createSlice } from '@reduxjs/toolkit'

export const bookingConfirmationSlice = createSlice({
  name: 'bookingConfirmation',
  initialState: {
    privacyPolicyAccepted: false,
    contractSigned: false,
  },
  reducers: {
    setPrivacyPolicyAccepted: (state, action) => {
      state.privacyPolicyAccepted = action.payload
    },
    setContractSigned: (state, action) => {
      state.contractSigned = action.payload
    },
  },
})

export const { setPrivacyPolicyAccepted, setContractSigned } = bookingConfirmationSlice.actions

export const selectPrivacyPolicyAccepted = (state) => state.bookingConfirmation.privacyPolicyAccepted
export const selectContractSigned = (state) => state.bookingConfirmation.contractSigned

export default bookingConfirmationSlice.reducer
