import Form from 'components/form/Home'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import ApiClient from 'data/api/api_client'
import useErrorHandler from 'data/hooks/useErrorHandler'
import PublicMobileModalDrawer from 'components/PublicMobileModalDrawer'
import { ModalProps } from 'data/types/modal'
import PasswordResetSection from 'components/auth/PasswordResetSection'

interface CustomerForgottenPasswordModalProps extends ModalProps {}

type ForgottenPasswordFormFields = {
  email: string | undefined
}

const CustomerForgottenPasswordModal = ({
  isOpen,
  onClose,
}: CustomerForgottenPasswordModalProps) => {
  const handleError = useErrorHandler()

  const { register, handleSubmit, setError, formState: { errors }, reset } = useForm<ForgottenPasswordFormFields>()

  const resetMutation = useMutation(
    (data: ForgottenPasswordFormFields) => ApiClient.auth.triggerPasswordReset(data.email),
    {
      onSuccess: () => {
        onClose()
        reset()
      },
      onError: (error) => {
        handleError(error, setError)
      },
    },
  )

  const onSubmit = (data: ForgottenPasswordFormFields) => {
    resetMutation.mutate(data)
  }

  return (
    <PublicMobileModalDrawer
      isOpen={isOpen}
      onClose={onClose}
      position="right"
    >
      <PasswordResetSection
        header="Reset your password"
        description="If you've forgotten your password, enter your email here and we'll send you a link to reset it"
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        button={{
          children: 'Send Reset Link',
          isLoading: resetMutation.isLoading,
        }}
      >
        <Form.Field className="mt-20 mb-50">
          <Form.Label htmlFor="email">
            Email Address
          </Form.Label>

          <Form.Input
            id="email"
            variant="grey"
            placeholder="Your email here"
            error={errors?.email?.message}
            {...register('email')}
          />
        </Form.Field>
      </PasswordResetSection>
    </PublicMobileModalDrawer>
  )
}

export default CustomerForgottenPasswordModal
