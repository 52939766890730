import React from 'react'
import Button from 'components/Button'
import classNames from 'classnames'

type PropTypes = {
    checked: boolean,
    label: string
    error?: string
    disabled: boolean
}

const ButtonGroupItem: React.FC<PropTypes> = ({
  checked,
  label,
  error,
  disabled,
}) => {
  return (
    <Button
      disabled={disabled}
      variant="outline-grey"
      className={classNames('w-120 h-54 !p-0 font-normal capitalize ring-primary-dark', {
        'bg-primary text-white hover:bg-primary-dark !border-primary': checked,
        'ring-3 ring-red ring-opacity-30 bg-red bg-opacity-10': error,
      })}
    >
      {label}
    </Button>
  )
}

export default ButtonGroupItem
