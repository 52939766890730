import React from 'react'
import { FieldError } from 'react-hook-form'
import ServiceRequestSlide from 'components/service-request-forms/components/ServiceRequestSlide'
import ServiceRequestQuestion from 'components/service-request-forms/components/ServiceRequestQuestion'
import ServiceRequestNextButton from 'components/service-request-forms/components/ServiceRequestNextButton'
import ServiceSurveyForm from 'components/form/ServiceSurveyForm'
import { CarRentalError, SurveyFormProps } from 'data/types/service_surveys'
import { QuestionText } from 'data/helpers/survey/surveys/car-rental'

const Slide7 = ({
  questionStartIndex,
  position,
  fetching,
  isSlideActive,
  register,
  formState: { errors },
  setValue,
  clearErrors,
}: SurveyFormProps) => {
  return (
    <ServiceRequestSlide
      slideNumber={7}
      position={position}
      isSlideActive={isSlideActive}
      setValue={setValue}
      clearErrors={clearErrors}
    >
      <ServiceRequestQuestion
        questionNumber={questionStartIndex}
        question={QuestionText(10)}
      />
      <ServiceSurveyForm.Group>
        <ServiceSurveyForm.Textarea
          rows={1}
          {...register('question10.anything_else')}
          placeholder="Type your answer here..."
          error={((errors as CarRentalError)?.question10 as unknown as { anything_else?: FieldError })?.anything_else}
          disabled={!isSlideActive}
        />
      </ServiceSurveyForm.Group>
      <ServiceRequestNextButton
        isLoading={fetching}
        disabled={!isSlideActive}
      />
    </ServiceRequestSlide>
  )
}

export default Slide7
