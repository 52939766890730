import React from 'react'
import GuestDisclaimer from './Disclaimer'
import { ReactChild } from 'data/types/types'

interface PrimaryGuestDisclaimerProps {
  children: ReactChild
}

const PrimaryGuestDisclaimer = ({ children }: PrimaryGuestDisclaimerProps) => {
  return (
    <GuestDisclaimer
      className={{
        text: 'px-12 text-left py-15 flex flex-col gap-[9px]',
      }}
    >
      <p>
        A <strong>Primary Guest</strong> will be able to perform the following actions on your behalf:
      </p>

      <ul className="list-disc ml-15">
        <li>message your Dedicated Concierge</li>
        <li>request services & experiences</li>
        <li>view and process all payments</li>
      </ul>

      <div>
        {children}
      </div>

    </GuestDisclaimer>
  )
}

export default PrimaryGuestDisclaimer
