import { joinBy } from '@ama-selections/ui'
import { config } from 'data/config'
import { Share } from 'data/enums/share-target'

const getShareLink = (
  shareType,
  url = '/',
  text = '',
) => {
  let link = config.appURL + url

  switch (shareType) {
    case (Share.EMAIL):
      return `mailto:?Subject=${text}&Body=${link}`
    case (Share.FACEBOOK):
      return `https://facebook.com/sharer/sharer.php?u=${link}`
    case (Share.GMAIL):
      return `https://mail.google.com/mail/?view=cm&fs=1&su=${text}&body=${link}`
    case (Share.LINKEDIN):
      return `https://www.linkedin.com/sharing/share-offsite/?url=${link}`
    case (Share.TWITTER):
      return `https://twitter.com/intent/tweet?text=${text}&url=${link}`
    case (Share.WHATSAPP):
      return `whatsapp://send?text=${joinBy([text, link], ' ')}`
    case (Share.CLIPBOARD):
      return joinBy([text, link], ' ')
  }
}

export {
  getShareLink,
}
