import classNames from 'classnames'
import { HeaderTag } from 'components/home/common/typography/Headers'
import React, { ComponentProps } from 'react'

interface PropTypes extends ComponentProps<'h1' | 'h2' | 'h3' | 'h4'> {
  Tag?: HeaderTag
}

const defaultClassNames = 'font-sans tracking-1 leading-24 font-bold uppercase'

const H3 = ({ Tag = 'h3' , children, className = '', ...rest }: PropTypes) => (
  <Tag
    className={classNames(
      defaultClassNames,
      '!-tracking-1/2 leading-title text-24',
      className,
    )}
    {...rest}
  >
    {children}
  </Tag>
)

const H5 = ({ Tag = 'h5' , children, className = '', ...rest }: PropTypes) => (
  <Tag
    className={classNames(
      defaultClassNames,
      'text-14',
      className,
    )}
    {...rest}
  >
    {children}
  </Tag>
)

export default {
  H3,
  H5,
}
