import Button from 'components/home/common/Button'
import { MessengerIcon, PhoneCallbackIcon, PhoneIcon } from 'components/icons'
import { config } from 'data/config'
import { useRouter } from 'next/router'
import React, { useState } from 'react'

const GiveUsACallButton = () => {
  const [giveUsACallHovered, setGiveUsACallHovered] = useState(false)
  const router = useRouter()

  return (
    <Button
      variant="secondary"
      icon={<PhoneIcon />}
      style="modal"
      onMouseEnter={() => setGiveUsACallHovered(true)}
      onMouseLeave={() => setGiveUsACallHovered(false)}
      onClick={() => router.push(`tel:${config.amaSelectionsPhoneNumber}`)}
    >
      {giveUsACallHovered ? config.amaSelectionsDisplayPhoneNumber : 'Give us a call'}
    </Button>
  )
}

const SendMessageButton = ({ onClick }: { onClick: () => void }) => {
  return (<Button
    variant="secondary"
    icon={<MessengerIcon />}
    style="modal"
    onClick={onClick}
  >
    Send us a message
  </Button>)
}

const RequestCallbackButton = ({ onClick }: { onClick: () => void }) => {
  return (<Button
    variant="secondary"
    icon={<PhoneCallbackIcon />}
    style="modal"
    onClick={onClick}
    subtext="Our line is busy or it’s outside of office hours? Request a callback here."
  >
    Request a callback
  </Button>)
}

export default {
  GiveUsACallButton,
  SendMessageButton,
  RequestCallbackButton,
}
