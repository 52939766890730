import { ModalProps } from 'data/types/modal'
import PublicMobileModal from 'components/PublicMobileModal'
import useAuth from 'data/hooks/User/useAuth'
import CustomerPassportImageUpload from '../items/CustomerPassportImageUpload'

export const EditPassportImageModal = ({
  isOpen,
  onClose,
}: ModalProps) => {
  const { user } = useAuth()

  return (
    <PublicMobileModal
      hasFocusTrap
      isOpen={isOpen}
      onClose={onClose}
      className={{
        panel: 'px-30 pt-30 pb-safe-offset-30 flex flex-col gap-y-10',
      }}
    >
      <CustomerPassportImageUpload
        value={user?.passport_image}
        identityVerifiedAt={user?.identity_verified_at}
      />
    </PublicMobileModal>
  )
}
