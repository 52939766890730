import { isServer } from 'data/helpers/ssr'
import { useState } from 'react'
import { gql, useQuery } from 'urql'

export const useDestinationQuery = (isOpen) => {
  const [countryId, setCountryId] = useState(null)
  const [continentId, setContinentId] = useState(undefined)

  const [{ data: countryData, fetching: fetchingCountry }] = useQuery({
    query: gql`
      query DestinationsModalCountryDetails(
        $country_id: ID,
      ) {
        country (id: $country_id) {
          id
          value
          slug
          regions (first: 100) {
            data {
              id
              value
              slug
              subRegions {
                id
                value
                slug
              }
            }
          }
          random_region {
            id
            main_media {
              id
              url
              file_name
            }
          }
          property_count
        }
      }
    `,
    variables: {
      country_id: countryId,
    },
    pause: !isOpen || !countryId || isServer,
  })

  const [{ data: allData, fetching: fetchingAll }] = useQuery({
    query: gql`
      query DestinationsModalDetails(
        $randomRegionFilters: RandomRegionFiltersInput,
        $countryFilters: CountriesFilterInput,
        $orderBy: [OrderByClause!]
      ) {
        countries (first: 100, orderBy: $orderBy, filters: $countryFilters) {
          data {
            id
            value
            slug
            regions (first: 100) {
              data {
                id
                value
                slug
              }
            }
          }
        }

        propertyCount

        randomRegion (filters: $randomRegionFilters) {
          id
          main_media {
            id
            url
            file_name
          }
        }
      }
    `,
    variables: {
      randomRegionFilters: {
        country_ids: countryId ? [countryId] : null,
        continent_id: continentId,
      },
      countryFilters: continentId
        ? { continent_id: continentId }
        : null,
      orderBy: [{
        column: 'VALUE',
        order: 'ASC',
      }],
    },
    pause: !isOpen && countryId && isServer && continentId,
  })

  return {
    continentId,
    setContinentId,
    countryId,
    setCountryId,
    dataType: countryId ? 'country' : 'all',
    data: countryId ? countryData : allData,
    fetching: fetchingCountry || fetchingAll,
  }
}
