import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { joinBy } from './strings'

dayjs.extend(utc)
dayjs.extend(customParseFormat)

const formatDDMMYYYY = (datetime) => {
  return validateDate(datetime)
    ? parseDate(datetime).format('DD/MM/YYYY')
    : null
}

const formatDefault = (datetime) => {
  return validateDate(datetime)
    ? parseDate(datetime).format('YYYY-MM-DD')
    : null
}


/**
 * The function `formatDateReadable` formats a given date into a readable string with optional day
 * inclusion and customizable day format.
 * @param { Date | string | null | undefined } date
 * @param { Object } options
 */
const formatDateReadable = (
  date,
  {
    withDay = false,
    dayFormat = 'ddd',
  } = {
    withDay: false,
    dayFormat: 'ddd',
  }) => {
  if (!validateDate(date)) {
    return null
  }

  if (withDay) {
    return parseDate(date).format(dayFormat + ', MMM\xa0DD, YYYY')
  } else {
    return parseDate(date).format('MMM\xa0DD, YYYY')
  }
}

const formatDateReadableDatePicker = (date) => {
  return dayjs(date).format('MMM\xa0DD, YYYY')
}

const formatDateRangeReadable = (firstDate, secondDate) => {
  return (firstDate && secondDate)
    ? joinBy(
      [
        parseDate(firstDate).year() === parseDate(secondDate).year()
          ? parseDate(firstDate).format('MMM\xa0DD')
          : formatDateReadable(firstDate),
        formatDateReadable(secondDate),
      ],
      ' to ')
    : formatDateReadable(firstDate) ?? formatDateReadable(secondDate) ?? null
}

const formatDateLong = (date) => {
  return dayjs.utc(date).format('MMMM DD, YYYY')
}

const formatDateTime = (datetime) => {
  if (!datetime) {
    return null
  }

  return dayjs.utc(datetime).format('MMM\xa0DD,\xa0YYYY h:mm\xa0A')
}

const formatShort = (date) => {
  return dayjs.utc(date).format('DD MMM')
}

const getDayAsString = (date) => {
  return dayjs.utc(date).format('dddd')
}

const formatMonthDay = (date) => {
  return dayjs.utc(date).format('MMM DD')
}

const addDays = (date, days) => {
  return dayjs.utc(date).add(days, 'day')
}

const getTomorrow = (date) => {
  return addDays(date, 1)
}

const getYesterday = (date) => {
  return addDays(date, -1)
}

const parseDate = (value) => {
  if (!value) {
    return null
  }

  let dayJsFormat = dayjs(value)
  if (dayJsFormat.isValid()) {
    return dayJsFormat
  }

  let defaultFormat = dayjs(value, ['YYYY-MM-DD', 'DD/MM/YYYY', 'MMM DD, YYYY'])
  if (defaultFormat.isValid()) {
    return defaultFormat
  }

  return null
}

const asDate = (value) => {
  return (value && parseDate(value).isValid())
    ? parseDate(value).toDate()
    : undefined
}

const asDateNullable = (value) => {
  return (value && parseDate(value).isValid())
    ? parseDate(value).toDate()
    : null
}

const getDisabledDates = (calendarInfo) => {
  if (calendarInfo) {
    let dates = []
    dates = calendarInfo?.map((date) => {
      return (date.start_date === date.end_date)
        ? new Date(date.start_date)
        : {
          after: new Date(date.start_date),
          before: new Date(date.end_date),
        }
    })

    let datesBefore = dates.map(date => formatDefault(date.before))
    let datesAfter = dates.map(date => formatDefault(date.after))
    let dates2 = []

    for (let index = 0; index < datesBefore?.length; index++) {
      if (datesAfter.includes(datesBefore[index])) {
        dates2.push(new Date(datesBefore[index]))
      }
    }

    return [...dates, ...dates2]
  }
  return []
}

// Takes in the disabled dates outputted from the above function and returns just the dates for the `arrival` or `departure` fields
const getDisabledSingularDates = (disabledDates = [], isArrival) => {
  return disabledDates
    .filter(date => typeof date === 'object')
    .map(date => {
      return new Date(isArrival
        ? date.after
        : date.before)
    })
}

const getDisabledDatesFromTo = (calendarInfo) => {
  return (calendarInfo ?? []).map((date) => ({
    from: new Date(date.start_date),
    to: new Date(date.end_date),
    source: date.source,
  }))
}

const formatTime = (date, is24HourFormat = true, isLowercase = false) => {
  const format = is24HourFormat ? 'HH:mm\xa0A' : 'h:mm\xa0A'
  const formattedTime = dayjs.utc(date).format(format)
  return isLowercase ? formattedTime.toLowerCase() : formattedTime.toUpperCase()
}

const formatTimeFromTime = (time) => {
  return time
    ? dayjs('1/1/1 ' + time).format('h:mm\xa0A')
    : null
}

const numberOfNights = (firstDate, secondDate) => {
  return (firstDate && secondDate)
    ? Math.abs(dayjs.utc(firstDate).diff(dayjs.utc(secondDate), 'day'))
    : 0
}

const validateDate = (date) => {
  return parseDate(date)?.isValid() ? true : false
}

const areDatesSame = (firstDate, secondDate) => {
  return dayjs.utc(firstDate).isSame(dayjs.utc(secondDate))
}

const dateWithoutTimezone = (datetime) => {
  let date = new Date(datetime)
  let offset = date.getTimezoneOffset()

  if (offset >= 0) {
    date.setMinutes(date.getMinutes() + offset)
  }

  return date
}

export {
  formatDateLong,
  formatDateTime,
  formatDateReadable,
  formatDateReadableDatePicker,
  formatDateRangeReadable,
  formatDDMMYYYY,
  formatDefault,
  formatShort,
  formatMonthDay,
  getDayAsString,
  getDisabledDates,
  getDisabledSingularDates,
  addDays,
  getDisabledDatesFromTo,
  getTomorrow,
  getYesterday,
  parseDate,
  asDate,
  asDateNullable,
  formatTime,
  formatTimeFromTime,
  numberOfNights,
  validateDate,
  dateWithoutTimezone,
  areDatesSame,
}
