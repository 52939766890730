import { ModalProps } from 'data/types/modal'
import AuthSection from 'components/auth/AuthSection'
import PublicMobileModalDrawer from 'components/PublicMobileModalDrawer'
import useAuth from 'data/hooks/User/useAuth'

const CustomerLoginModal = ({ isOpen, onClose }: ModalProps) => {
  useAuth(() => onClose())

  return (
    <PublicMobileModalDrawer
      isOpen={isOpen}
      onClose={onClose}
      position="right"
    >
      <AuthSection
        showLogo
        initialSlide="login"
        buttonStyle="modal"
      />
    </PublicMobileModalDrawer>
  )
}

export default CustomerLoginModal
