export default class ServiceRequestsApiClient {
  constructor (http) {
    this.http = http
  }

  async getServiceRequest (serviceRequestId) {
    const result = await this.http.get(`/service-requests/${serviceRequestId}`)
    return result.data.data
  }

  async deleteServiceRequest (serviceRequestId) {
    await this.http.delete(`/service-requests/${serviceRequestId}`)
  }

  async getServiceRequests (filters = {}, options = {}) {
    const result = await this.http.get('/service-requests', {
      params: {
        ...filters,
        per_page: options.per_page,
        page: options.page ?? 1,
        paginated: options.paginated ?? true,
        limit: options.limit,
      },
    })

    return {
      data: result.data.data,
      meta: result.data.meta ?? null,
    }
  }

  async assignAgent (data) {
    const result = await this.http.post(`/service-requests/${data.service_request_id}/assign-agent`, data)
    return result.data.data
  }

  async sendToPartner (serviceRequestId, data) {
    const result = await this.http.post(`/service-requests/${serviceRequestId}/send-to-partner`, data)
    return result.data.data
  }
}
