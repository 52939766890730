export default class SearchApiClient {
  constructor (http) {
    this.http = http
  }

  /**
   * Run a search
   *
   * @param {String} query The query to search for
   * @returns {Array}
   */
  async search (query) {
    const result = await this.http.get('/search', {
      params: {
        query,
      },
    })
    return result.data.data
  }
}
