import { Controller, FieldError } from 'react-hook-form'
import NumberInput from './NumberInput'

export default function ControlledSelect ({
  name,
  control,
  label,
  defaultValue,
  placeholder,
  text,
  disabled,
  error,
  max,
  min,
}: {
  name: string
  control: any
  label?: string
  defaultValue?: number
  placeholder: string
  text?: {
    singular: string
    plural: string
  }
  disabled?: boolean
  error: FieldError | undefined
  max?: number
  min?: number
}) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => (
        <NumberInput
          label={label}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          text={text}
          disabled={disabled}
          error={error}
          max={max}
          min={min}
        />
      )}
    />
  )
}
