export default class CalendarApiClient {
  constructor (http) {
    this.http = http
  }

  /**
   * Get events for the specified property
   *
   * @param {number} propertyId The ID of the property to get events for
   * @returns {{data: Array, calendar_links: Array}}
   */
  async getEvents (propertyId) {
    const result = await this.http.get('/calendar/events', {
      params: {
        property_id: propertyId, // eslint-disable-line camelcase
      },
    })
    return result.data
  }

  /**
   * Get events for the specified property but a public version of the resource
   *
   * @param {string} propertyId The ID of the property to get events for
   * @returns {{data: Array, calendar_links: Array}}
   */
  async getEventsPublic (propertyId) {
    const result = await this.http.get('/calendar/events/public/' + propertyId)
    return result.data
  }

  /**
   *
   * @param {string} eventId
   * @returns { void }
   */
  async eventRemoval (eventId) {
    await this.http.delete(`/calendar/${eventId}`)
  }

  /**
   * Import a set of calendar links
   *
   * @param {string} propertyId The id of the property to import these links to
   *
   * @param {Object} links The links to import
   * @param {string} links.airbnb_link The link to the Airbnb calendar
   * @param {string} links.partner_link The link to the partner calendar
   * @returns {{data: Array, calendar_links: Array}}
   */
  async importLinks (propertyId, links) {
    /* eslint-disable camelcase */
    const formData = new FormData()
    formData.append('property_id', propertyId)
    formData.append('airbnb_link', links.airbnb_link)
    formData.append('partner_link', links.partner_link)
    if (links.ical_file[0]) {
      formData.append('ical_file', links.ical_file[0])
    }

    const result = await this.http.post('/calendar/import', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    /* eslint-enable camelcase */
    return result.data
  }
}
