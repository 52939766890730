import { AxiosInstance } from 'axios'

export default class LocationsApiClient {
  http: AxiosInstance

  constructor (http: AxiosInstance) {
    this.http = http
  }

  async getLocations (): Promise<PhoneLocation[]> {
    const result = await this.http.get('/locations')
    return result.data.data
  }
}

export type PhoneLocation = {
  'code': string
  'name': string
  'phone_code': string
}
