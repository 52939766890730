import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import Input from 'components/form/Home/Input'
import Label from 'components/form/Home/Label'
import Error from 'components/form/Error'
import { FieldError } from 'react-hook-form'

export interface DateInputProps {
  value: string | undefined
  label?: string | undefined
  error?: FieldError | undefined
  onChange: (date: string | null) => void
}

const DateInput = ({
  label,
  value,
  error,
  onChange,
}: DateInputProps) => {

  const [day, setDay] = useState('')
  const [month, setMonth] = useState('')
  const [year, setYear] = useState('')

  const [errorMessage, setErrorMessage] = useState(error?.message)

  useEffect(() => {
    if (day !== '' && month !== '' && year !== '') {

      const date = `${year}-${month}-${day}`

      if (dayjs(date).isValid()) {
        onChange(date)

        setErrorMessage('')
      } else {
        setErrorMessage('Invalid date provided')
      }
    }
  }, [day, month, year])

  useEffect(() => {
    setErrorMessage(error?.message)
  }, [error])

  useEffect(() => {
    if (value && dayjs(value, ['YYYY-MM-DD']).isValid()) {
      const date = dayjs(value)

      setDay(date.get('date').toString().padStart(2, '0'))
      setMonth((date.get('month') + 1).toString().padStart(2, '0'))
      setYear(date.get('year').toString())
    }
  }, [value])

  return (
    <div className="flex flex-col">
      {
        label && (
          <Label>
            {label}
          </Label>
        )
      }

      <div className="flex gap-x-10">
        <Input
          label="Day"
          inputMode="numeric"
          value={day}
          onChange={(e) => setDay(e.target.value)}
          maxLength={2}
          minLength={1}
          variant="grey"
          className={{
            wrapper: '!w-[28%]',
          }}
        />

        <Input
          label="Month"
          inputMode="numeric"
          value={month}
          onChange={(e) => setMonth(e.target.value)}
          maxLength={2}
          minLength={1}
          variant="grey"
          className={{
            wrapper: '!w-[28%]',
          }}
        />

        <Input
          label="Year"
          inputMode="numeric"
          value={year}
          onChange={(e) => setYear(e.target.value)}
          maxLength={4}
          minLength={4}
          variant="grey"
          className={{
            wrapper: '!w-[44%]',
          }}
        />
      </div>

      <div className="flex mt-5">
        {
          errorMessage && (
            <Error error={errorMessage} />
          )
        }

        <button
          onClick={() => {
            setDay('')
            setMonth('')
            setYear('')
            onChange(null)
          }}
          type="button"
          className="ml-auto text-primary hover:text-primary-active text-14 hover:underline focus:outline-none focus:text-primary-active"
        >
          Clear
        </button>
      </div>
    </div>
  )
}

export default DateInput
