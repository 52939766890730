import classNames from 'classnames'
import { ReactChild } from 'data/types/types'
import React from 'react'
import SectionHeaders from './home/common/typography/SectionHeaders'
import Paragraphs from './home/common/typography/Paragraphs'

interface StatusPanelProps {
  icon: ReactChild
  title: string
  description: ReactChild
  className?: string
  descriptionClassName?: string
  children?: ReactChild
}

const StatusPanel = ({
  icon,
  title,
  description,
  className = '',
  descriptionClassName = '',
  children,
}: StatusPanelProps) => {
  return (
    <div className={classNames('flex flex-col items-center', className)}>
      <div className="text-50 text-primary">
        {icon}
      </div>

      <SectionHeaders.LG className="text-center mt-30 text-grey-750">
        {title}
      </SectionHeaders.LG>

      <Paragraphs.XXL className={classNames('mt-15 text-center text-grey-800 max-w-[275px]', descriptionClassName)}>
        {description}
      </Paragraphs.XXL>

      {
        children && (
          <div className="mt-30">
            {children}
          </div>
        )
      }
    </div>
  )
}

export default StatusPanel
