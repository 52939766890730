import classNames from 'classnames'
import { useScreenWidth } from 'data/hooks/useScreenWidth'
import React, { useEffect, useState } from 'react'
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent'

const cookieName = 'cookieConsent'

const CookieConsentBar = () => {
  const { isMobile } = useScreenWidth(1024)

  const [cookiePreferences, setCookiePreferences] = useState<string | undefined>()

  useEffect(() => {
    setCookiePreferences(getCookieConsentValue(cookieName))
  }, [])

  return (
    <div className={classNames('fixed flex justify-center left-0 right-0 bottom-0 bg-white md:px-[70px] z-[999] border-t border-grey-250', {
      'hidden': cookiePreferences !== undefined,
    })}>
      <CookieConsent
        cookieName={cookieName}
        onAccept={() => setCookiePreferences('true')}
        onDecline={() => setCookiePreferences('false')}
        enableDeclineButton
        declineButtonText="ACCEPT ONLY NECESSARY COOKIES"
        buttonText="ACCEPT ALL COOKIES"
        buttonWrapperClasses="w-full lg:w-max"
        style={{
          background: '#FFFFFF',
          border: 'none',
          color: '#979797',
          fontSize: '14px',
          position: 'relative',
          maxWidth: '1800px',
        }}
        contentClasses="!mx-25 lg:mx-auto"
        buttonStyle={{
          background: '#A28C50',
          color: '#FFFFFF',
          fontSize: '12px',
          borderRadius: isMobile ? '4px' : '17px',
          lineHeight: '14px',
          padding: '10px 25px',
          margin: isMobile ? '0px 25px 15px 25px' : '15px 10px',
          width: isMobile ? 'calc(100% - 50px)' : 'initial',
        }}
        declineButtonStyle={{
          background: '#E5E5E5',
          color: '#535353',
          fontSize: '12px',
          borderRadius: isMobile ? '4px' : '17px',
          lineHeight: '14px',
          padding: '10px 25px',
          margin: isMobile ? '15px 25px' : '15px 10px',
          width: isMobile ? 'calc(100% - 50px)' : 'initial',
        }}
      >
        We use cookies, including third-party cookies, for functional reasons and for statistical analysis.
      </CookieConsent>
    </div>
  )
}

const getCookieConsent = () => {
  return getCookieConsentValue(cookieName) === 'true'
}

export {
  getCookieConsent,
}

export default CookieConsentBar
