import PublicMobileModal from 'components/PublicMobileModal'
import { RestBooking } from 'data/types/booking'
import { useRouter } from 'next/router'
import React, { useMemo, useState } from 'react'
import Button from './common/Button'
import Pill from './Pill'

interface BookingMenuModalProps {
  title?: string
  booking: RestBooking
}

enum BookingMenuTabs {
  Summary = 'Summary',
  Location = 'Location',
  Concierge = 'My Concierge',
  Itinerary = 'Trip Itinerary',
  Payments = 'Payments & Invoices',
}

const BookingMenuModal = ({
  title = 'Booking Menu',
  booking,
}: BookingMenuModalProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const router = useRouter()

  const activeTab = useMemo(() => {
    const pathSegments = router.pathname.split('/')
    const lastSegment = pathSegments[pathSegments.length - 1]

    switch (lastSegment) {
      case '[id]':
        return BookingMenuTabs.Summary
      case 'location':
        return BookingMenuTabs.Location
      case 'my-concierge':
        return BookingMenuTabs.Concierge
      case 'trip-schedule':
        return BookingMenuTabs.Itinerary
      case 'payments-and-invoices':
        return BookingMenuTabs.Payments
    }
  }, [router.pathname])

  return (<>
    <Pill
      onClick={() => setIsOpen(true)}
      variant="white"
      size="14"
    >
      {title}
    </Pill>

    <PublicMobileModal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      className={{
        panel: 'pt-[74px] pb-safe-offset-20 px-25 flex flex-col gap-10',
      }}
    >
      <BookingMenuTab
        tab={BookingMenuTabs.Summary}
        active={activeTab == BookingMenuTabs.Summary}
        href={`/home/reservations/${booking?.id}/`}
      />

      {booking?.property?.address && (
        <BookingMenuTab
          tab={BookingMenuTabs.Location}
          active={activeTab == BookingMenuTabs.Location}
          href={`/home/reservations/${booking?.id}/location`}
        />
      )}

      {!booking?.is_shared_booking
        ? (
          <BookingMenuTab
            tab={BookingMenuTabs.Concierge}
            active={activeTab == BookingMenuTabs.Concierge}
            href={`/home/reservations/${booking?.id}/my-concierge`}
          />
        )
        : undefined
      }

      <BookingMenuTab
        tab={BookingMenuTabs.Itinerary}
        active={activeTab == BookingMenuTabs.Itinerary}
        href={`/home/reservations/${booking?.id}/trip-schedule`}
      />

      {!booking?.is_shared_booking
        ? (
          <BookingMenuTab
            tab={BookingMenuTabs.Payments}
            active={activeTab == BookingMenuTabs.Payments}
            href={`/home/reservations/${booking?.id}/payments-and-invoices`}
          />
        )
        : undefined
      }

    </PublicMobileModal>
  </>)
}

interface BookingMenuTabProps {
  tab: BookingMenuTabs
  active: boolean
  href?: string
}

const BookingMenuTab = ({
  tab,
  active,
  href,
}: BookingMenuTabProps) => {
  return (
    <Button
      variant={active ? 'primary' : 'secondary'}
      block
      href={href}
      style="modal"
    >
      {tab}
    </Button>
  )
}

export default BookingMenuModal
