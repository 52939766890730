import PublicMobileModal from 'components/PublicMobileModal'
import { SharedUser } from './SharedUser'
import { RandomRegionQuery, User } from 'gql/graphql'
import WishlistAccessModal from 'components/modals/WishlistAccessModal'
import { useMemo, useState } from 'react'
import Button from 'components/home/common/Button'
import useAuth from 'data/hooks/User/useAuth'
import WishlistInviteModal from './WishlistInviteModal'
import { ShareModalContentProps } from 'components/modals/home/ShareModal'

interface WishlistUsersModalProps {
  wishlist: ShareModalContentProps['model']
  randomRegion?: RandomRegionQuery
  isOpen: boolean
  onClose: () => void
  refetch?: () => void
}

type WishlistUserType = NonNullable<ShareModalContentProps['model']>['users'][number]
  | NonNullable<ShareModalContentProps['model']>['userInvites'][number]

const WishlistUsersModal = ({
  wishlist,
  randomRegion,
  isOpen,
  onClose = () => { },
  refetch,
}: WishlistUsersModalProps) => {
  const [selectedUser, setSelectedUser] = useState<WishlistUserType>()
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false)
  const authenticatedUser = useAuth()

  const handleOnSubmit = (user: WishlistUserType) => {
    if (wishlist?.owner?.id == authenticatedUser?.user?.id) {
      setSelectedUser(user)
    }
  }

  const wishlistUsers = useMemo(
    () => wishlist?.users?.filter(user => user.id !== wishlist?.owner.id),
    [wishlist?.users],
  )

  return (
    <PublicMobileModal
      isOpen={isOpen}
      title="Invited Friends"
      isChildModalOpen={selectedUser !== undefined || isInviteModalOpen}
      onClose={onClose}
      backIcon={true}
      className={{
        panel: 'flex flex-col px-24 pb-safe-offset-20 lg:pb-30',
      }}
    >
      <div className="flex flex-col gap-10 mb-48 mt-25">
        {
          wishlist?.owner && (
            <SharedUser
              user={wishlist.owner as User}
              label="Owner"
              isOwner={true}
              showTick={false}
            />
          )
        }

        {
          wishlistUsers?.map((user) => (
            <SharedUser
              user={user as User}
              key={user.id}
              label="Invited"
              showTick={true}
              onClick={() => handleOnSubmit(user)}
            />
          ))
        }

        {
          wishlist?.userInvites?.map((user) => (
            <SharedUser
              user={user}
              key={user.id}
              label="Invited"
              showTick={false}
              onClick={() => handleOnSubmit(user)}
            />
          ))
        }
      </div>

      <div className="w-full max-w-[340px] flex mx-auto justify-center items-center">
        <Button
          block
          onClick={() => setIsInviteModalOpen(true)}
          style="modal"
          variant="primary"
        >
          Invite {(wishlist?.userInvites?.length || wishlistUsers?.length) ? 'More ' : null}Friends
        </Button>
      </div>

      <WishlistInviteModal
        isOpen={isInviteModalOpen}
        onClose={() => {
          refetch?.()
          setIsInviteModalOpen(false)
        }}
        wishlistId={wishlist?.id as string}
      />

      <WishlistAccessModal
        wishlist={wishlist}
        randomRegion={randomRegion}
        isOpen={selectedUser !== undefined}
        onClose={() => {
          refetch?.()
          setSelectedUser(undefined)
        }}
        refetch={refetch}
        user={selectedUser as User}
      />

    </PublicMobileModal>
  )
}

export default WishlistUsersModal
