import { Controller } from 'react-hook-form'
import Select, { SelectProps, Option } from 'components/form/Home/Select'

export interface ControlledSelectProps extends Omit<SelectProps, 'value' | 'onChange'> {
  name: string
  control: any
}

export default function ControlledSelect ({
  name,
  control,
  ...props
}: ControlledSelectProps) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <Select
          value={value}
          onChange={onChange}
          {...props}
        />
      )}
    />
  )
}

export type {
  Option,
}
