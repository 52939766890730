import { useMemo } from 'react'
import { formatShortCurrency } from 'data/helpers/currency'
import { joinBy, pluralise } from 'data/helpers/strings'
import { formatDateReadable } from 'data/helpers/dates'
import { TripScheduleServiceStatusType, UserPendingTripServicesQuery } from 'gql/graphql'
import ServiceCard from 'components/home/dashboard/ServiceCard'
import { CalendarOutlineIcon, ClockOutlineIcon } from 'components/icons'
import { getFormattedTime } from 'data/helpers/survey/tripSchedule'
import Paragraphs from 'components/home/common/typography/Paragraphs'
import SectionParagraphs from 'components/home/common/typography/SectionParagraphs'
import Pill from 'components/home/Pill'
import classNames from 'classnames'
import Button from 'components/home/common/Button'
import { useMutation } from 'react-query'
import ApiClient from 'data/api/api_client'
import ConnectedServicesModal from './ConnectedServicesModal'
import { hasDuePayments, hasPendingPayments } from 'data/helpers/trip-schedule-group'

export interface ConnectedServicesModalState {
  isOpen: boolean
  tripGroup: UserPendingTripServicesQuery['tripScheduleGroups']['data'][number] | null
}

interface UserPendingTripServiceModalItemProps {
  tripGroup: NonNullable<UserPendingTripServicesQuery['tripScheduleGroups']['data'][number]>
  action?: () => void
  connectedServicesModal: ConnectedServicesModalState
  setConnectedServicesModal: (state: ConnectedServicesModalState) => void
}

const UserPendingTripServiceModalItem = ({
  tripGroup,
  action,
  connectedServicesModal,
  setConnectedServicesModal,
}: UserPendingTripServiceModalItemProps) => {
  const divider = <hr className="mx-4 my-10 border-b-2 border-grey-200 border-opacity-70" />

  const numberOfDays = useMemo(
    () => tripGroup?.days?.length ?? 0,
    [tripGroup],
  )

  const approveMutation = useMutation(
    () => ApiClient.tripSchedule.approveTripScheduleServices(tripGroup.booking.id, {
      group_id: tripGroup.id,
    }),
    {
      onSuccess: () => action?.(),
    },
  )

  return (
    <div className="flex flex-col p-10 bg-grey-150 rounded-16">
      {
        tripGroup?.days?.map((day, index) => {
          if (index > 1) { return null }

          return (
            <>
              {index > 0 && divider}

              <ServiceCard
                key={`pending-trip-service-${day.id}`}
                heading={tripGroup.title}
                subheading={tripGroup.subtitle}
                media={{
                  src: tripGroup.main_image?.url,
                  alt: tripGroup.title,
                  size: 'min-w-[65px] max-w-[65px]',
                }}
                iconText={[
                  {
                    icon: <CalendarOutlineIcon />,
                    text: formatDateReadable(
                      tripGroup.days?.[index].date,
                      {
                        withDay: true,
                      },
                    ),
                  },
                  {
                    icon: <ClockOutlineIcon />,
                    text: getFormattedTime(
                      tripGroup.days?.[index]?.start_time,
                      tripGroup.days?.[index]?.end_time,
                    ),
                  },
                ]}
              />
            </>
          )
        })
      }

      {
        numberOfDays > 2 && (
          <>
            {divider}

            <div className="flex items-center justify-between px-4">
              <SectionParagraphs.XS>
                +{numberOfDays - 2} Connected {pluralise(numberOfDays - 2, 'service')}
              </SectionParagraphs.XS>

              <Pill
                variant="white"
                size="14"
                onClick={() => setConnectedServicesModal({
                  isOpen: true,
                  tripGroup,
                })}
              >
                View All
              </Pill>
            </div>

            <ConnectedServicesModal
              tripGroup={tripGroup}
              isOpen={connectedServicesModal.isOpen && connectedServicesModal.tripGroup?.id === tripGroup.id}
              onClose={() => setConnectedServicesModal({
                isOpen: false,
                tripGroup: null,
              })}
              booking={tripGroup.booking}
              bookingInformation={{
                arrival: tripGroup.booking.arrival_information_count > 0,
                departure: tripGroup.booking.departure_information_count > 0,
              }}
              serviceRequestId={tripGroup.service_request?.id}
            />
          </>
        )
      }

      {
        (tripGroup.customer_credit_card_fee || tripGroup.customer_service_fee || tripGroup.customer_service_quote || tripGroup.payment_totals?.completed || tripGroup.payment_totals?.pending || tripGroup.price)
          ? (
            <>
              {divider}

              <div className="flex flex-col gap-y-2">
                {
                  [
                    ...((tripGroup.customer_credit_card_fee || tripGroup.customer_service_fee || tripGroup.customer_service_quote)
                      ? [
                        {
                          label: 'Service Quote',
                          value: tripGroup.customer_service_quote,
                        },
                        {
                          label: 'Service Fee',
                          value: tripGroup.customer_service_fee,
                        },
                        {
                          label: 'Debit/Credit Card Fees',
                          value: tripGroup.customer_credit_card_fee,
                        },
                      ]
                      : []
                    ),
                    {
                      label: 'Service Total',
                      value: tripGroup.price,
                      isTotal: true,
                    },
                    ...((tripGroup.payment_totals?.completed || tripGroup.payment_totals?.pending)
                      ? (
                        [{
                          label: joinBy([
                            'Total Paid',
                            hasPendingPayments(tripGroup.payment_statuses)
                              ? 'and Pending'
                              : null,
                          ], ' '),
                          value: (tripGroup.payment_totals.completed ?? 0) + (tripGroup.payment_totals.pending ?? 0),
                          isTotal: true,
                        }]
                      )
                      : []
                    ),
                  ]
                    .map(({ label, value, isTotal }) => (
                      <div className={classNames(
                        'flex justify-between h-[22px] items-center',
                        {
                          'mt-[6px] font-bold': isTotal,
                        },
                      )}>
                        <Paragraphs.SM>
                          {label}
                        </Paragraphs.SM>

                        <Paragraphs.SM className="text-right">
                          {formatShortCurrency(value, tripGroup.booking.currency)}
                        </Paragraphs.SM>
                      </div>
                    ))
                }
              </div>
            </>
          )
          : null
      }

      <Button
        className={{
          button: 'mt-16 max-w-xs mx-auto',
        }}
        {...(tripGroup.status === TripScheduleServiceStatusType.PendingApproval
          ? { onClick: () => approveMutation.mutate() }
          : { href: `/home/reservations/${tripGroup.booking.id}/payments-and-invoices/service-groups/${tripGroup.id}` }
        )}
        isLoading={approveMutation.isLoading}
        disabled={tripGroup.status === TripScheduleServiceStatusType.PendingPayment
          && !hasDuePayments(tripGroup.payment_statuses)}
        variant="transparent"
        style="modal"
        size="sm"
        block
      >
        {
          tripGroup.status === TripScheduleServiceStatusType.PendingApproval
            ? 'Approve'
            : (
              hasDuePayments(tripGroup.payment_statuses)
                ? 'Pay & Confirm'
                : 'Payment Pending'
            )
        }
      </Button>
    </div>
  )
}

export default UserPendingTripServiceModalItem
