import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Dialog, Transition } from '@headlessui/react'
import { CrossIcon, CrossThinIcon } from './icons'
import classNames from 'classnames'
import { useSelector } from 'react-redux'

const PublicModal = ({
  isOpen = false,
  onClose = () => { },
  children = {},
  className = '',
  innerPadding = '',
  transition = 'center',
  buttonType = 'default',
  modalOverflow = 'overflow-y-auto',
  overlayCloseButton = false,
  showCloseButton = true,
  fullscreen = false,
}) => {
  const { registerModalOpen } = useSelector(state => ({
    registerModalOpen: state.auth.registerModalOpen,
  }))

  const enterFrom = classNames({
    'left translate-x-[-100%]': transition === 'left',
    'top translate-y-[-100%]': transition === 'top',
    'right translate-x-[100%]': transition === 'right',
    'bottom translate-y-[100%]': transition === 'bottom',
    'bottom translate-y-[100%] md:bottom-[initial] md:translate-y-0 md:opacity-0 md:scale-95': transition === 'center',
  })

  const enterTo = classNames({
    'translate-x-0': transition === 'left' || 'right',
    'translate-y-0': transition === 'top' || 'bottom',
    'translate-y-0 md:opacity-100 md:scale-100': transition === 'center',
  })

  const leaveFrom = classNames({
    'left': transition === 'left',
    'top': transition === 'top',
    'right': transition === 'right',
    'bottom': transition === 'bottom',
    'bottom md:bottom-[initial] md:opacity-100 md:scale-100': transition === 'center',
  })

  const leaveTo = classNames({
    'translate-x-[-100%]': transition === 'left',
    'translate-y-[-100%]': transition === 'top',
    'translate-x-[100%]': transition === 'right',
    'translate-y-[100%]': transition === 'bottom',
    'translate-y-[100%] md:translate-y-[0] md:opacity-0 md:scale-95': transition === 'center',
  })

  const buttonClasses = classNames({
    'absolute top-20 right-20': buttonType === 'default',
    'absolute top-10 right-10 p-8 bg-white border border-primary border-opacity-40 hover:border-opacity-80 hover:bg-primary-light rounded-4': buttonType === 'typeform',
    'fixed top-25 right-50 w-50 h-50 bg-white hover:bg-grey-100 rounded-full shadow-modal text-grey-800 z-10 text-22': buttonType === 'destinations',
    'hidden pointer-events-none': buttonType === 'hidden',
  })

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        onClose={() => { !registerModalOpen && onClose() }}
        className="relative z-[60]"
      >
        <div>
          {/* The backdrop, rendered as a fixed sibling to the panel container */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-10" />
          </Transition.Child>


          {/* Full-screen container to center the panel */}
          <div className={classNames('fixed inset-0 flex items-center justify-center z-[59]', fullscreen ? 'p-0' : 'p-4')}>
            {/* The actual dialog panel  */}

            {
              (isOpen && overlayCloseButton)
              && <div
                className="absolute flex items-center justify-center bg-white rounded-full cursor-pointer top-25 right-25 w-50 h-50 hover:bg-grey-100 shadow-modal text-grey-800 text-22"
                onClick={onClose}
              >
                <CrossThinIcon />
              </div>
            }

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom={enterFrom}
              enterTo={enterTo}
              leave="ease-in duration-200"
              leaveFrom={leaveFrom}
              leaveTo={leaveTo}
            >

              <Dialog.Panel className={`w-full max-h-full md:h-min mx-auto rounded-4 bg-white relative pt-80 px-25 md:px-50 pb-30 ${modalOverflow} ${className} ${innerPadding}`}>
                {children}
                { showCloseButton && <button
                  className={buttonClasses}
                  onClick={onClose}
                >
                  <CrossIcon />
                </button>}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>

      </Dialog>
    </Transition>
  )
}

PublicModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  innerPadding: PropTypes.string,
  transition: PropTypes.string,
  buttonType: PropTypes.oneOf(['default', 'typeform', 'hidden']),
  modalOverflow: PropTypes.string,
  overlayCloseButton: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  fullscreen: PropTypes.bool,
}

export default PublicModal
