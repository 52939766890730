import { RadioGroup as HeadlessRadioGroup } from '@headlessui/react'
import classNames from 'classnames'
import { FieldError, Noop } from 'react-hook-form'
import Error from 'components/form/Error'
import { cloneElement } from 'react'

export default function RadioGroup ({
  disabled,
  value = '',
  onChange,
  options,
  onBlur,
  error,
  isChild,
}: {
  disabled?: boolean,
  value: string | number
  onChange: (...event: any[]) => void
  onBlur: Noop
  options: {
    value: string | number,
    label?: string
    component?: JSX.Element,
  }[]
  error: FieldError | undefined,
  isChild?: boolean,
}) {
  return (
    <HeadlessRadioGroup disabled={disabled} value={value} onChange={onChange} onBlur={onBlur}>
      <div className={classNames(
        'flex flex-col gap-21',
        {
          'md:ml-40': !isChild,
          'ml-20 md:ml-0': isChild,
        },
      )}>
        {
          options.map((option, index) => (
            <div
              key={`radio-group-option-${index}`}
              className="flex flex-col gap-y-20"
            >
              <HeadlessRadioGroup.Option
                value={option.value}
                className="py-2 cursor-pointer focus:outline-none"
              >
                {({ active, checked }) => (
                  <div className="flex items-center gap-12">
                    <div className={classNames('flex items-center justify-center w-16 h-16 border rounded-full border-primary', {
                      'ring-3 ring-primary ring-opacity-50': active,
                    })}>
                      {checked && <div className="w-8 h-8 rounded-full bg-primary" />}
                    </div>

                    <p className="text-16 leading-23 text-grey-800">
                      {option.label ?? option.value}
                    </p>
                  </div>

                )}
              </HeadlessRadioGroup.Option>

              {
                (option.component && option.value === value)
                  && <div className="md:ml-20">
                    {cloneElement(option.component, { isChild: true }) as JSX.Element}
                  </div>
              }
            </div>
          ))}
        <Error className="-mt-10" error={error?.message} />
      </div>
    </HeadlessRadioGroup>
  )
}
