import Dinero from 'dinero.js'
import { sum } from 'lodash'

const createAllocationPayments = (payments) => {
  let allocations = payments.map((payment) => ({
    id: payment.id,
    percentage: payment.percentage,
  }))

  if (sum(allocations.map(p => p.percentage)) !== 100) {
    const remainder = 100 - sum(allocations.map(p => p.percentage))
    allocations.push({ id: null, percentage: remainder })
  }

  return allocations
}

const getAllocatedAmountFromPayments = (amount, payments) => {
  const ratios = (createRatios(payments) ?? []).filter(ratio => !(ratio < 0))

  if (getRatioTotal(ratios) > 100 || getRatioTotal(ratios) === 0) {
    return []
  }

  const allocations = Dinero({ amount }).allocate(ratios)

  // Remove the ratio that is added if there is one payment
  if (payments.length === 1) {
    allocations.splice(-1)
  }

  return allocations.map((allocation, index) => {
    return {
      id: payments[index].id,
      amount: allocation.getAmount(),
    }
  })
}

const createRatios = (payments) => {
  let ratios = []

  payments.forEach(payment => {
    ratios = [...ratios, Number(payment.percentage)]
  })

  // There has to be a minimum of two ratios
  if (payments.length === 1) {
    const percentageRemainder = (100 - ratios[0])

    ratios = [...ratios, percentageRemainder]
  }

  return ratios
}

const getRatioTotal = (ratios) => {
  return ratios.reduce((x, y) => x + y, 0)
}

/**
 * @param {number} amount
 * @param {number[]} ratios
 */
const getAllocatedAmountFromRatios = (amount, ratios) => {
  const allocations = Dinero({ amount }).allocate(ratios)

  return allocations.map(allocation => allocation.getAmount())
}

export {
  createAllocationPayments,
  getAllocatedAmountFromPayments,
  getAllocatedAmountFromRatios,
}
