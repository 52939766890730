import { createSlice } from '@reduxjs/toolkit'

export const preferencesSlice = createSlice({
  name: 'preferences',
  initialState: {
    tier: null,
  },
  reducers: {
    setTier: (state, action) => {
      state.tier = action.payload
    },
  },
})

export const { setTier } = preferencesSlice.actions

export default preferencesSlice.reducer
