import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import CenteredCustomerLoginModal from 'components/modals/getHelp/CenteredCustomerLoginModal'
import { RootState } from 'store'
import PublicMobileModal, { PublicMobileModalProps } from 'components/PublicMobileModal'

interface PublicMobileModalWithAuthProps extends PublicMobileModalProps {}

const PublicMobileModalWithAuth = ({
  isOpen,
  onClose,
  children,
  ...props
}: PublicMobileModalWithAuthProps) => {
  const user = useSelector((state: RootState) => state.auth.user)

  const [loginModalOpen, setLoginModalOpen] = useState(false)
  const [childModalOpen, setChildModalOpen] = useState(false)

  useEffect(() => {
    if (isOpen) {
      if (user) {
        setChildModalOpen(true)
      } else {
        setLoginModalOpen(true)
      }
    }
  }, [isOpen])

  useEffect(() => {
    if (user && !childModalOpen) {
      setChildModalOpen(true)
    } else {
      if (!loginModalOpen) {
        onClose()
      }
    }
  }, [loginModalOpen])

  const childModalClose = () => {
    setChildModalOpen(false)
    onClose()
  }

  return (
    <>
      {isOpen && (
        <>
          {loginModalOpen && (
            <CenteredCustomerLoginModal
              isOpen={loginModalOpen}
              onClose={() => setLoginModalOpen(false)}
            />
          )}

          {user && childModalOpen && (
            <PublicMobileModal
              isOpen={childModalOpen}
              onClose={childModalClose}
              {...props}
            >
              {children}
            </PublicMobileModal>
          )}
        </>
      )}
    </>
  )
}

export default PublicMobileModalWithAuth
