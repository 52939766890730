export default class NotificationsApiClient {
  constructor (http) {
    this.http = http
  }

  /**
     * Get all of a user notifications
     *
     * @returns {Promise<import("data/types/notifications").GetNotificationsResponse>}
     */
  async getNotifications (filters = {}, options = {}) {
    const result = await this.http.get('/users/notifications', {
      params: {
        ...filters,
        page: options.page ?? 1,
        per_page: options.per_page,
        paginated: options.paginated ?? true,
        limit: options.limit,
      },
    })

    return result.data
  }

  /**
     * Get all types of notifications that a user has
     *
     * @returns {Promise<Array>}
     */
  async getTypes () {
    const result = await this.http.get('/users/notifications/types')
    return result.data.data
  }

  /**
   * Read the given notification
   *
   * @param {RestNotification} notification the notification to be read
   * @returns {Promise<Array>}
   */
  async readNotification (notification) {
    const result = await this.http.patch(`/users/notifications/${notification.id}/read`)
    return result.data.data
  }

  /**
   * Delete the given notification
   *
   * @param {RestNotification} notification the notification to be deleted
   * @returns {Promise<Array>}
   */
  async deleteNotification (notification) {
    const result = await this.http.delete(`/users/notifications/${notification.id}`)
    return result.data.data
  }

  /**
   * Deletes all the users notifications
   *
   * @returns {Promise<Array>}
   */
  async clearNotifications () {
    const result = await this.http.delete('/users/notifications/all')
    return result.data.data
  }
}
