import { joinBy } from '@ama-selections/ui'

enum Membership {
  REGISTERED = 'registered',
  CLASSIC = 'classic',
  SILVER = 'silver',
  GOLD = 'gold',
}

function getPositionalValue (tier: Membership | undefined | null) {
  switch (tier) {
    case Membership.GOLD:
      return 3
    case Membership.SILVER:
      return 2
    case Membership.CLASSIC:
      return 1
    case Membership.REGISTERED:
    default:
      return 0
  }
}

function getUserMembershipText (
  currentMembershipTier: Membership | undefined | null,
  newMembership: {
    type: Membership,
    title: string,
  },
) {
  if (currentMembershipTier === newMembership.type) {
    switch (currentMembershipTier) {
      case Membership.SILVER:
      case Membership.GOLD:
        return 'Current Subscription'
      default:
        return 'Current Tier'
    }
  } else {
    const currentTier = getPositionalValue(currentMembershipTier)
    const newTier = getPositionalValue(newMembership.type)

    return joinBy(
      [
        currentTier > newTier ? 'Downgrade' : 'Upgrade',
        'to',
        newMembership.title,
      ],
      ' ',
    )
  }
}

export {
  Membership,
  getUserMembershipText,
}
