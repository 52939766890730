import LogoLink from 'components/logoLink'
import PublicMobileModalDrawer from 'components/PublicMobileModalDrawer'
import { ProfileModalNavigation } from 'components/user/profile/ProfileModalNavigation'
import useAuth from 'data/hooks/User/useAuth'
import { ModalProps } from 'data/types/modal'
import { useState } from 'react'

interface CustomerProfileModalProps extends ModalProps { }

const CustomerProfileModal = ({
  isOpen,
  onClose,
}: CustomerProfileModalProps) => {
  const { user } = useAuth()

  const [isChildModalOpen, setIsChildModalOpen] = useState(false)

  return (
    <PublicMobileModalDrawer
      isOpen={isOpen}
      onClose={onClose}
      position="right"
      hasFocusTrap
      title={user?.first_name
        ? `Hello, ${user.first_name}!`
        : <div className="flex justify-center w-full pt-safe-offset-16 pb-42">
          <LogoLink className="w-full max-w-[128px]" />
        </div>
      }
      className={{
        panel: '!pb-0 flex flex-col overflow-y-auto',
      }}
      isChildModalOpen={isChildModalOpen}
    >
      <ProfileModalNavigation
        onClose={onClose}
        onIsChildModalOpenChange={setIsChildModalOpen}
      />
    </PublicMobileModalDrawer>
  )
}

export default CustomerProfileModal
