import classNames from 'classnames'
import { LinkProps } from 'next/link'
import { ReactChild } from 'data/types/types'
import { RequireOnlyOneOrNone } from 'data/helpers/types/requireOnlyOne'
import Image from 'components/Image'
import SectionHeaders from 'components/home/common/typography/SectionHeaders'
import Paragraphs from 'components/home/common/typography/Paragraphs'
import PillSwiper from 'components/form/Home/PillSwiper'
import Link from 'components/Link'
import Pill from 'components/home/Pill'

interface ServiceCardProps {
  isImageLoaded?: boolean
  href: LinkProps['href']
  onClick: () => void
  media?: {
    src?: string | null
    alt?: string
    size?: string
    fallbackColour?: 'primary'
  }
  superHeading?: string | null
  heading: string
  subheading?: string | null
  iconText?: {
    icon: ReactChild
    text?: string | null
  }[]
  status?: {
    icon: ReactChild
    text?: string | null
    className?: string
    onClick?: () => void
  }
  children?: ReactChild
  pillText?: string[]
  className?: {
    container?: string
  }
}

const ServiceCard = ({
  isImageLoaded = true,
  href,
  onClick,
  media,
  superHeading,
  heading,
  subheading,
  iconText,
  status,
  pillText,
  className,
  children,
}: RequireOnlyOneOrNone<ServiceCardProps, 'onClick' | 'href'>) => {
  const content = (
    <>
      {
        superHeading && (
          <Paragraphs.XS className="w-full text-center uppercase tracking-1/4 leading-22 text-grey-700 lg:!text-12">
            {superHeading}
          </Paragraphs.XS>
        )
      }

      <div className="flex w-full gap-x-10 duration-[25ms]">
        {
          (isImageLoaded && media) && (
            <div className={classNames(
              'relative aspect-1 h-full min-h-0',
              media.size,
            )}>
              {
                media.src
                  ? (
                    <Image
                      src={media.src}
                      alt={media.alt}
                      layout="fill"
                      className="object-cover transition-all rounded-6 group-hover:brightness-95"
                    />
                  )
                  : (
                    <div className={classNames(
                      'aspect-1 w-full h-auto rounded-6 group-hover:brightness-95',
                      {
                        'bg-primary-fresh bg-opacity-70': media.fallbackColour === 'primary' || !media.fallbackColour,
                      },
                    )} />
                  )
              }
            </div>
          )
        }

        <div className="flex flex-col">
          {
            heading && (
              <SectionHeaders.LG className="!normal-case text-grey-900 !font-semibold !text-15">
                {heading}
              </SectionHeaders.LG>
            )
          }

          {
            subheading && (
              <Paragraphs.SM className="text-grey-800 !leading-22 !text-13">
                {subheading}
              </Paragraphs.SM>
            )
          }

          {
            iconText && iconText
              .filter(({ text }) => text)
              .map(({ icon, text }, index) => (
                <Paragraphs.XS
                  key={`${index}-${text}`}
                  className={classNames(
                    'flex items-center gap-x-[6px] text-grey-700 !leading-22 uppercase !text-12',
                    {
                      'mt-4': index === 0,
                    },
                  )}
                >
                  <span>
                    {icon}
                  </span>
                  {text}
                </Paragraphs.XS>
              ))
          }
        </div>
      </div>

      {
        status && (
          <Pill
            className={classNames(
              'flex gap-x-[6px] !text-11',
              status.className,
            )}
            variant="white"
            size="10"
            onClick={(e) => {
              e.stopPropagation()
              status.onClick?.()
            }}
          >
            <span className="text-12">
              {status.icon}
            </span>
            {status.text}
          </Pill>
        )
      }

      {children}

      {
        pillText && (
          <PillSwiper
            className={{
              container: 'w-full',
            }}
            data={pillText.map((text, index) => ({
              id: index.toString(),
              value: text,
            }))}
          />
        )
      }
    </>
  )

  const containerClassNames = classNames(
    'flex flex-col gap-y-10 text-left w-full',
    {
      'group': href || onClick,
    },
    className?.container,
  )

  if (href) {
    return (
      <Link
        href={href}
        className={containerClassNames}
      >
        {content}
      </Link>
    )
  }

  if (onClick) {
    return (
      <button
        type="button"
        onClick={onClick}
        className={containerClassNames}
      >
        {content}
      </button>
    )
  }

  return (
    <div className={containerClassNames}>
      {content}
    </div>
  )
}

export default ServiceCard
