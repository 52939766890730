import { createSlice } from '@reduxjs/toolkit'
import { merge } from 'data/helpers/array'
import dayjs from 'dayjs'

export const inboxSlice = createSlice({
  name: 'inbox',
  initialState: {
    conversations: [] ,
    selectedConversation: null,
    newMessageEvents: [],
    unreadCount: null,
  },
  reducers: {
    setConversations: (state, action) => {
      state.conversations = action.payload.reduce((conversations, conversation) => {
        if (conversations[conversation.id]) {
          // If the Conversation Already exists, use the most recent version
          conversations[conversation.id] = (dayjs(conversations[conversation.id].last_messaged_at).toDate() > dayjs(conversation.last_messaged_at).toDate())
            ? conversations[conversation.id]
            : conversation
        } else {
          conversations[conversation.id] = conversation
        }
        return conversations
      }, {})
    },
    addNewConversation: (state, action) => {
      state.conversations[action.payload.conversation.id] = action.payload.conversation

      state.selectedConversation = action.payload.conversation
    },
    setSelectedConversation: (state, action) => {
      if (parseInt(state.selectedConversation?.id) === parseInt(action.payload.conversation?.id)) {
        if (action.payload.conversation.messages?.paginatorInfo.currentPage === 1) {
          state.selectedConversation = action.payload.conversation
        } else {
          // Add new messages to current list
          const messages = state.selectedConversation.messages.data.slice()
          const newMessages = action.payload.conversation.messages.data.slice()

          state.selectedConversation.messages = {
            data: merge(newMessages, messages, (a, b) => a.id === b.id)
              .sort((a, b) => dayjs(b.created_at).toDate() - dayjs(a.created_at).toDate()),
            paginatorInfo: action.payload.conversation.messages.paginatorInfo,
          }
        }
      } else {
        state.selectedConversation = action.payload.conversation
      }
    },
    markConversationRead: (state, action) => {
      if (state.conversations[action.payload.conversationId]) {
        state.conversations[action.payload.conversationId].unread_count = 0
      }

      if (state.selectedConversation) {
        const messages = state.selectedConversation.messages.data.slice()
        state.selectedConversation.messages.data = messages.map((message) => ({
          ...message,
          my_read_message: action.payload.messages.data.find((m) => m.id === message.id)?.my_read_message,
        }))
      }
    },
    favouriteConversation: (state, action) => {
      state.conversations[action.payload.conversationId].is_starred = action.payload.isStarred

      if (parseInt(state.selectedConversation?.id) === parseInt(action.payload.conversationId)) {
        state.selectedConversation.is_starred = action.payload.isStarred
      }
    },
    addLatestMessage: (state, action) => {
      let conversationId = action.payload.message.conversation.id

      if (action.payload.isFullConversation === true) {
        state.conversations[conversationId] = action.payload.message.conversation
      } else {
        state.conversations[conversationId].human_time = action.payload.message.conversation.human_time
        state.conversations[conversationId].last_messaged_at = action.payload.message.conversation.last_messaged_at
        state.conversations[conversationId].last_message = action.payload.message.conversation.last_message
      }

      if (parseInt(state.selectedConversation?.id) === parseInt(conversationId)) {
        state.selectedConversation.messages.data.unshift(action.payload.message)
      }

      if (action.payload.clearMessageEvent) {
        state.newMessageEvents = state.newMessageEvents.filter((message) => message.toString() !== action.payload.message.id)
      }
    },
    newMessageEvent: (state, action) => {
      state.newMessageEvents = [...state.newMessageEvents, action.payload.message]
    },
    updateMessage: (state, action) => {
      const messages = state.selectedConversation.messages.data.slice()
      state.selectedConversation.messages.data = messages.map((message) =>
        message.id.toString() === action.payload.message.id.toString()
          ? ({
            ...message,
            my_read_message: action.payload.message?.my_read_message,
          })
          : message,
      )

      state.conversations[action.payload.message.conversation.id].unread_count = action.payload.message.conversation.unread_count
    },
    deleteMessage: (state, action) => {
      const messages = state.selectedConversation.messages.data.slice()
      state.selectedConversation.messages.data = messages.filter(item => item.id !== action.payload.messageId)

      if (state.selectedConversation.messages.length === 0) {
        state.selectedConversation = null
        delete state.conversations[action.payload.conversationId]
      }
    },
    clearInbox: (state) => {
      state.conversations = {}
      state.selectedConversation = null
      state.unreadCount = null
    },
    setUnreadCount: (state, action) => {
      state.unreadCount = action.payload
    },
  },
})

export const {
  setConversations, addNewConversation,
  setSelectedConversation, markConversationRead, favouriteConversation,
  addLatestMessage, newMessageEvent, updateMessage, deleteMessage,
  setUnreadCount,
  clearInbox,
} = inboxSlice.actions

export const selectConversations = (state) => state.inbox.conversations

export default inboxSlice.reducer
