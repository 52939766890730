import { SurveyYesNo } from 'gql/graphql'

const getValue = (value: SurveyYesNo) => {
  switch (value) {
    case SurveyYesNo.Yes:
      return 'Yes'
    case SurveyYesNo.No:
      return 'No'
  }
}

export {
  getValue,
}
