import classNames from 'classnames'
import { LoadingIcon } from 'components/icons'
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react'
import Pill from 'components/home/Pill'
import Paragraphs from 'components/home/common/typography/Paragraphs'
import { FreeMode, Mousewheel } from 'swiper/modules'

export type PillSwiperClassNames = {
  container?: string
  pills?: string
  title?: string
}

export interface PillSwiperProps extends Omit<SwiperProps, 'onClick' | 'className' | 'slideOffsetBefore' | 'slideOffsetAfter'> {
  className?: PillSwiperClassNames
  title?: string
  fetching?: boolean
  data: {
    id: string | undefined
    value: string
  }[]
  onClick?: (id?: number) => void
  selected?: number[]
  slideOffset?: number
  variant?: 'grey' | 'blue'
}

const PillSwiper = ({
  className,
  title,
  data,
  fetching,
  onClick,
  selected = [],
  slideOffset = 24,
  variant,
  ...props
}: PillSwiperProps) => {
  return (
    <div className={classNames('flex flex-col gap-15', className?.container)}>
      {
        title && (
          <Paragraphs.LG Tag="span" className={classNames('leading-24', className?.title)}>
            {title}
          </Paragraphs.LG>
        )
      }
      { fetching
        ? (
          <div className={classNames('flex flex-col items-center justify-center', className?.pills)}>
            <LoadingIcon />
          </div>
        )
        : (
          <div className={classNames('flex flex-col gap-10 lg:justify-center lg:flex-row lg:flex-wrap lg:gap-15', className?.pills)}>
            <Swiper
              modules={[Mousewheel, FreeMode]}
              slidesPerView={'auto'}
              slidesOffsetBefore={slideOffset}
              slidesOffsetAfter={slideOffset}
              mousewheel={{
                forceToAxis: true,
              }}
              freeMode={{
                enabled: true,
              }}
              {...props}
            >
              { data?.map((tag, index: number) => {
                const tagId = tag.id
                  ? parseInt(tag.id)
                  : undefined

                return (
                  <SwiperSlide className="mr-8 w-max" key={index}>
                    <Pill
                      variant={variant ?? 'grey'}
                      size="14"
                      isMultiselect
                      isActive={tagId
                        ? selected.includes(tagId)
                        : selected.length === 0
                      }
                      onClick={() => onClick?.(tagId)}
                    >
                      {tag.value}
                    </Pill>
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </div>
        )
      }
    </div>
  )
}

export default PillSwiper
