import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Button'
import DashboardSendToPartnerModal from '../DashboardSendToPartnerModal'
import { ServiceRequestQuery } from 'gql/graphql'

const DashboardSendToPartnerModalTrigger = ({
  serviceRequest,
}: {
  serviceRequest: ServiceRequestQuery['serviceRequest']
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      <DashboardSendToPartnerModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        serviceRequest={serviceRequest}
      />

      <Button
        style="admin"
        variant="outline-grey"
        className="whitespace-nowrap !px-20 text-grey-800"
        onClick={() => setIsModalOpen(true)}
        disabled={serviceRequest?.booking?.concierge === null}
      >
        {
          serviceRequest?.booking?.concierge === null
            ? 'Please assign a concierge first'
            : 'Send to Partner'
        }
      </Button>
    </>
  )
}

DashboardSendToPartnerModalTrigger.propTypes = {
  serviceRequest: PropTypes.object,
}

export default DashboardSendToPartnerModalTrigger
