import { createSlice } from '@reduxjs/toolkit'

export const bookingOwnerPaymentSlice = createSlice({
  name: 'bookingOwnerPayments',
  initialState: {
    ownerRent: 0,
    paymentsDue: [],
    paymentsProcessed: [],
  },
  reducers: {
    setOwnerRent: (state, action) => {
      state.ownerRent = action.payload
    },
    //Payments Due
    setPaymentsDue: (state, action) => {
      state.paymentsDue = action.payload
    },
    updatePaymentDue: (state, action) => {
      const payments = state.paymentsDue
      const index = payments.findIndex(item => item.id === action.payload.id)
      if (index > -1) {
        state.paymentsDue[index] = {
          date: new Date(action.payload.date).toISOString(),
          ...action.payload,
        }
      }
    },
    deletePaymentDue: (state, action) => {
      let newPayments = [...state.paymentsDue]
      const index = newPayments.findIndex(item => item.id === action.payload)

      if (index > -1) {
        newPayments.splice(index, 1)
      }

      state.paymentsDue = newPayments
    },
    //Payments Processed
    setPaymentsProcessed: (state, action) => {
      state.paymentsProcessed = action.payload
    },
    updatePaymentProcessed: (state, action) => {
      const payments = state.paymentsProcessed
      const index = payments.findIndex(item => item.id === action.payload.id)
      if (index > -1) {
        state.paymentsProcessed[index] = {
          date: new Date(action.payload.date).toISOString(),
          ...action.payload,
        }
      }
    },
    deletePaymentProcessed: (state, action) => {
      let newPayments = [...state.paymentsProcessed]
      const index = newPayments.findIndex(item => item.id === action.payload)

      if (index > -1) {
        newPayments.splice(index, 1)
      }

      state.paymentsProcessed = newPayments
    },
  },
})

export const {
  setOwnerRent,
  setPaymentsDue, updatePaymentDue, deletePaymentDue,
  setPaymentsProcessed, updatePaymentProcessed, deletePaymentProcessed,
} = bookingOwnerPaymentSlice.actions

export const getTotals = (state) => {
  let due = state.bookingOwnerPayments.paymentsDue
    .reduce((sum, payment) => (sum += payment.percentage), 0)

  let completed = state.bookingOwnerPayments.paymentsProcessed
    .reduce((sum, payment) => (sum += payment.amount), 0)

  return {
    due: state.bookingOwnerPayments.ownerRent / 100 * due,
    completed: completed,
  }
}

export default bookingOwnerPaymentSlice.reducer
