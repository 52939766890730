import { formatTimeFromTime, joinBy } from '@ama-selections/ui'
import { getCarTypeLabel } from 'data/helpers/survey/surveys/car-rental'
import { CarTypeEnum } from 'data/enums/survey/car-rental/car-type'
import { TripScheduleService } from 'data/types/bookings/trip_schedule'

export const getCarRentalServiceDetails =  (service: TripScheduleService) => {
  const serviceDetails = service.service_details ?? service.group?.service_details

  if (serviceDetails?.includes('$generate_details') && service.survey?.__typename === 'SurveyCarRentalInfo') {
    const survey = service.survey

    const line1 = joinBy(
      [
        formatTimeFromTime(service.start_time),
        formatTimeFromTime(service.end_time),
      ],
      ' - ',
    )

    const line2 = getCarTypeLabel(survey?.car_type as CarTypeEnum)

    const line3 = survey?.pick_up_drop_off

    return serviceDetails.replaceAll(
      '$generate_details',
      joinBy(
        [
          line1,
          line2,
          line3,
        ],
        '\n',
      ),
    )
  }

  return serviceDetails
}
