import { formatShortCurrency } from './currency'

const propertyPrice = (property, shortResponse = false) => {
  if (property.stay_cost) {
    return `Price for selected dates: ${formatShortCurrency(property.stay_cost, property.currency)}`
  }

  if (property.price_range && property.price_range?.max) {
    if (property.price_range.max === property.price_range.min) {
      return `${formatShortCurrency(property.price_range.max, property.currency)}/week`
    }

    return `${formatShortCurrency(property.price_range.min, property.currency)} - ${formatShortCurrency(property.price_range.max, property.currency)}/week`
  }

  return shortResponse
    ? 'Price upon request'
    : 'Price available upon request'
}

const propertyMinimumStay = (property) => {
  if (property.minimum_stay) {
    return 'Min stay: ' + property.minimum_stay
  }
}

const propertyPriceAgent = (property, isWhitelabel = false) => {
  if (property.agent_stay_cost) {
    return `Price for ${isWhitelabel ? 'selected dates' : 'agents'}: ${formatShortCurrency(property.agent_stay_cost, property.currency)}`
  }

  if (property.agent_price_range && property.agent_price_range?.max) {
    if (property.agent_price_range.max === property.agent_price_range.min) {
      return `${formatShortCurrency(property.agent_price_range.max, property.currency)}/week`
    }
    return `${formatShortCurrency(property.agent_price_range.min, property.currency)} - ${formatShortCurrency(property.agent_price_range.max, property.currency)}/week`
  }

  return 'Price available upon request'
}

const sortPropertiesByPrice = (
  properties = [],
  sortHighToLow = true
) => {
  let data = [...properties]

  data.sort(function (a, b) {
    //Sort by the stay cost
    if ((a.stay_cost ?? 0) > (b.stay_cost ?? 0)) {
      return sortHighToLow ? -1 : 1
    } else if ((a.stay_cost ?? 0) < (b.stay_cost ?? 0)) {
      return sortHighToLow ? 1 : -1
    }

    //Then sort by the price range
    if ((a.price_range?.min ?? 0) > (b.price_range?.min ?? 0)) {
      return sortHighToLow ? -1 : 1
    } else if ((a.price_range?.min ?? 0) < (b.price_range?.min ?? 0)) {
      return sortHighToLow ? 1 : -1
    } else {
      return 0
    }
  })

  return data
}

const agentPropertyTitle = (propertyReference) => {
  return `A-${propertyReference}`
}

const getPropertyMainImageUrl = (property) => {
  if (!property) {
    return null
  }

  return typeof property.main_image === 'string' ? property.main_image : property.main_image?.url
}

export {
  propertyPrice,
  propertyPriceAgent,
  sortPropertiesByPrice,
  agentPropertyTitle,
  propertyMinimumStay,
  getPropertyMainImageUrl,
}
