import { config } from 'data/config'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

export const useUserDashboard = () => {
  const router = useRouter()
  const [isOnUserDashboard, setIsOnUserDashboard] = useState(false)

  useEffect(() => {
    const exclusions = config.userDashboard.publicUrls

    const urlPrefix = router.asPath.split('/')[1]

    // only mark as onUserDashboard if the urlPrefix is 'home'
    // and the current path does not end with any of the exclusions
    if (
      urlPrefix === 'home'
      && !exclusions.some((url) => router.pathname.endsWith('home' + url),
      )
    ) {
      setIsOnUserDashboard(true)
    }
  }, [router.asPath])

  return {
    isOnUserDashboard,
  }
}
