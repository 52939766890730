import React from 'react'
import { Share } from 'data/enums/share-target'
import classNames from 'classnames'

type ShareButtonProps = {
  shareTarget: Share | Omit<Share, Share.CLIPBOARD>
  icon: JSX.Element
  href?: string
  onClick?: () => void
}

const ShareButton = ({
  shareTarget,
  href,
  icon,
  onClick,
}: ShareButtonProps) => {
  const buttonClassnames = 'flex items-center justify-between w-full h-60 bg-grey-100 hover:bg-grey-250 rounded-16 px-40 py-16 text-blue-1000 border border-grey-250'

  const buttonContent = <>
    <p className="font-bold text-15 leading-20">{shareTarget}</p>
    <div className="text-blue-1000">{icon}</div>
  </>

  return (href
    ? <a className="w-full" href={href} target="_blank" rel="noreferrer">
      <button type="button" className={buttonClassnames}>
        {buttonContent}
      </button>
    </a>
    : <button type="button" onClick={onClick} className={classNames('w-full', buttonClassnames)}>
      {buttonContent}
    </button>
  )
}

export default ShareButton
