export default class BookingApiClient {
  constructor (http) {
    this.http = http
  }
  /**
   * Create a new booking
   *
   * @param {object} data
   * @returns {RestBooking}
   */
  async createBooking (data, client, inquiryId = null) {
    var result
    if (client) {
      result = await this.http.post('/bookings', { ...data, 'inquiry_id': inquiryId ? parseInt(inquiryId) : null })
    } else {
      result = await this.http.post('/bookings/noclient', data)
    }
    return result.data.data
  }

  async getBookings (filters = {}, options = {}, sortable = {}) {
    const result = await this.http.get('/bookings', {
      params: {
        ...filters,
        arrival_date: filters.arrival_date,
        booking_date: filters.booking_date,
        departure_date: filters.departure_date,
        page: options.page ?? 1,
        per_page: options.per_page,
        paginated: options.paginated ?? true,
        limit: options.limit,
        sort_direction: sortable?.direction,
        sort_by: sortable?.sort_by,
      },
    })
    return {
      bookings: result.data.data,
      meta: result.data.meta,
    }
  }

  async updateBooking (id, data) {
    const result = await this.http.patch(`/booking/${id}`, data)
    return result.data.data
  }

  async getBookingToken (id) {
    const result = await this.http.get(`/booking/${id}/get-token`)
    return result.data.data
  }

  /**
   * Get a single booking from the API
   *
   * @param {(string|number)} id
   * @param {(Object|null)} params
   * @returns {RestBooking}
   */
  async getBooking (id, params = {}) {
    const result = await this.http.get(`/booking/${id}/show`, {
      params,
    })
    return result.data.data
  }

  async confirmBooking (token, params) {
    const result = await this.http.get(`/booking-confirmation/?token=${token}`, {
      params,
    })
    return result.data.data
  }

  /**
   * Delete a booking
   *
   * @param {number} id booking ID
   */
  async deleteBooking (id) {
    await this.http.delete(`/booking/${id}`)
  }

  /**
   * Get the bookings rental breakdown
   *
   * @param {number} id booking ID
   */
  async getRentalBreakdown (id) {
    const result = await this.http.get(`/booking/${id}/rental-breakdown`)
    return result.data.data
  }

  /**
   * Get arrival and departure bookings resource
   *
   */
  async getArrivalsAndDepartures (filters = {}, options = {}, sortable = {}) {
    const result = await this.http.get('/bookings/arrivals-departures', {
      params: {
        page: options.page ?? 1,
        per_page: options?.per_page,
        paginated: options?.paginated ?? true,
        limit: options?.limit,
        property: filters.property,
        before_date: filters.before_date,
        after_date: filters.after_date,
        digital_concierge_id: filters.booking_agent,
        search: filters.search,
        type: filters.type,
        sort_direction: sortable?.direction,
        sort_by: sortable?.sort_by,
      },
    })
    return {
      bookings: result.data.data,
      meta: result.data.meta ?? [],
    }
  }

  /**
   * Assigns the booking agent to the booking
   *
   * @param {object} data
   * @returns {Object}
   */
  async assignBookingAgent (data) {
    const result = await this.http.post(`/booking/${data.booking_id}/assign-agent`, data)
    return result.data.data
  }

  /**
 * Assigns the concierge to the booking
 *
 * @param {object} data
 * @returns {Object}
 */
  async assignDigitalConcierge (data) {
    const result = await this.http.patch(`/booking/${data.booking_id}/concierge`, {
      digital_concierge_id: data.digital_concierge_id,
    })
    return result.data.data
  }

  /**
   * Invites a user to a booking
   */
  async inviteUser (data, bookingId) {
    const result = await this.http.post(`/booking/${bookingId}/invite`, data)
    return result.data.data
  }

  async sendConfirmation (id) {
    const result = await this.http.post(`/booking-confirmation/${id}/send`)
    return result.data.data
  }

  async shareFullAccess (bookingId, userId) {
    const result = await this.http.post(`/booking/${bookingId}/shared-users/${userId}/full-access`)
    return result.data.data
  }

  /**
   * Invites a user to a booking
   */
  async updateBookingInformation (bookingId, data) {
    const result = await this.http.patch(`/booking/${bookingId}/booking-information`, data)
    return result.data.data
  }

}
