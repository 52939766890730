import classNames from 'classnames'
import { ComponentProps } from 'react'

type HeaderTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'

interface PropTypes extends ComponentProps<HeaderTag> {
  Tag?: HeaderTag | 'p'
}

const defaultClassNames = 'font-poppins tracking-1/4 font-medium uppercase leading-22'

const XXL = ({ Tag = 'h1' , children, className, ...rest }: PropTypes) => (
  <Tag
    className={classNames(
      defaultClassNames,
      'text-26 leading-[33px] sm:leading-54 sm:text-48 font-serif sm:-tracking-1',
      className,
    )}
    {...rest}
  >
    {children}
  </Tag>
)

const LG = ({ Tag = 'h3' , children, className, ...rest }: PropTypes) => (
  <Tag
    className={classNames(
      defaultClassNames,
      'text-14',
      className,
    )}
    {...rest}
  >
    {children}
  </Tag>
)

const MD = ({ Tag = 'h4' , children, className = '', ...rest }: PropTypes) => (
  <Tag
    className={classNames(
      defaultClassNames,
      'text-12',
      className,
    )}
    {...rest}
  >
    {children}
  </Tag>
)

const SM = ({ Tag = 'h5' , children, className = '', ...rest }: PropTypes) => (
  <Tag
    className={classNames(
      defaultClassNames,
      'text-10',
      className,
    )}
    {...rest}
  >
    {children}
  </Tag>
)

const Caption = ({ Tag = 'p' , children, className = '', ...rest }: PropTypes) => (
  <Tag
    className={classNames(
      defaultClassNames,
      'text-12 sm:text-14 sm:leading-20 sm:tracking-1/2',
      className,
    )}
    {...rest}
  >
    {children}
  </Tag>
)

const Magazine = ({ Tag = 'h2' , children, className = '', ...rest }: PropTypes) => (
  <Tag
    className={classNames(
      defaultClassNames,
      'text-26 md:text-32 lg:text-46 tracking-title normal-case md:leading-[50px]',
      className,
    )}
    {...rest}
  >
    {children}
  </Tag>
)


export default {
  XXL,
  LG,
  MD,
  SM,
  Caption,
  Magazine,
}
