import React, { createRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Button'
import { BinIcon, UploadIcon } from 'components/icons'
import ApiClient from 'data/api/api_client'
import { useNotifier } from 'react-headless-notifier'
import Notification from 'components/Notification'
import Image from 'components/Image'
import classNames from 'classnames'

const MultiImageItem = ({ url, onRemove }) => {
  return (
    <div className="relative max-h-[145px] max-w-[160px]">
      <div className="relative h-[145px] w-[160px]">
        <Image
          src={url}
          className="object-cover rounded-4 w-[160px]"
          layout="fill"
          fill
          priority
          sizes="10vw"
          height="145"
          width="160"
        />
      </div>

      <button type="button" className="absolute p-5 bg-white top-5 right-5 bg-opacity-70 text-12 rounded-8" onClick={onRemove}>
        <BinIcon />
      </button>
    </div>
  )
}

MultiImageItem.propTypes = {
  url: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
}

const ImageUpload = ({
  label,
  accentIcon,
  value = [],
  onChange,
  multiple = false,
  error,
  model = {},
  onLoadingChange = () => { },
}) => {
  const { notify } = useNotifier()
  const fileRef = createRef()
  const [files, setFiles] = useState(multiple ? value.map(i => i.id) : [])
  const [ids, setIds] = useState(multiple ? value.map(i => i.id) : [])
  const [previewUrls, setPreviewUrls] = useState(multiple ? value.map(i => i.url) : [])

  const handleClick = () => {
    fileRef.current.click()
  }

  useEffect(() => {
    if (multiple) {
      if (value.length === 0 && previewUrls.length > 0) {
        setPreviewUrls([])
      } else if (value.length > 0) {
        setPreviewUrls(value.map(i => i.url))
      }
    }
  }, [value?.length])

  useEffect(() => {
    if (!multiple) {
      onChange(files[0])
    }

    if (files.length !== ids.length) {
      if (multiple) {
        let urls = value.map(i => i.url)

        Array.from(files) // Copy the array
          .slice((files.length - urls.length) * -1) // Get the last selected files
          .forEach(file => { // Generate preview urls
            if (file instanceof File) {
              let url = URL.createObjectURL(file)
              urls.push(url)
            }
          })

        setPreviewUrls(urls)

        const newFiles = [...files]
        newFiles.splice(0, ids.length)
        onLoadingChange(true)
        Promise.all(newFiles.map(file => ApiClient.files.uploadFile(file, model)))
          .then(newIds => {
            onChange([...ids, ...newIds])
            setIds([...ids, ...newIds])
            notify(
              <Notification type="success" message="Images Uploaded" />,
            )
            onLoadingChange(false)
          })
      } else {
        setPreviewUrls([URL.createObjectURL(files[0])])
      }
    } else {
      //This is a fix for an error that happens if you add an image, delete it and then save the property
      onChange([...ids])
    }

    return () => {
      previewUrls.map(url => URL.revokeObjectURL(url))
    }
  }, [files])

  const removeFile = (index, temporary = false) => {
    const newIds = [...ids]
    const newFiles = [...files]
    const newPreviewUrls = [...previewUrls]
    const idToDelete = newIds.splice(index, 1)
    newFiles.splice(index, 1)
    newPreviewUrls.splice(index, 1)
    ApiClient.files.deleteFile(idToDelete[0], temporary)
    setIds(newIds)
    setFiles(newFiles)
    setPreviewUrls(newPreviewUrls)
  }

  const isTemporary = (index) => {
    return index instanceof File
  }

  const ErrorMessage = error
    ? <span className="text-red text-14">{error}</span>
    : null

  return (
    <div>
      {
        label
          ? <p className={classNames('mb-10 w-max', { 'relative': accentIcon !== undefined })}>
            {label}

            {accentIcon && <span className="absolute text-14 -top-4 -right-14">{accentIcon}</span>}
          </p>
          : null
      }

      <div className={classNames(
        'w-full relative',
        {
          'aspect-w-2 aspect-h-1': !multiple && previewUrls.length,
        },
      )}>
        <div>

          {
            previewUrls.length || (value && value.length)
              ? (
                multiple
                  ? (
                    <>
                      <div className="flex flex-wrap gap-20">
                        {previewUrls.map((url, index) => (
                          <MultiImageItem key={index} url={url} onRemove={() => removeFile(index, isTemporary(files[index]))} />
                        ))}
                        <button type="button" className="max-h-[145px] max-w-[160px] w-full border border-dashed border-primary rounded-20 text-50 text-grey-750" onClick={handleClick}>
                          <div className="flex items-center justify-center h-full">
                            <UploadIcon />
                          </div>
                        </button>
                      </div>
                      {ErrorMessage}
                    </>
                  )
                  : (
                    <>
                      <Image
                        src={previewUrls[previewUrls.length - 1] ?? value}
                        className="object-cover w-[160px] h-full"
                        layout="fill"
                        sizes="10vw"
                        fill
                        priority
                      />
                      <img src={previewUrls[previewUrls.length - 1] ?? value} alt="" className="object-cover w-full h-full rounded-4" />
                      <button type="button" className="absolute p-10 bg-white top-10 right-10 bg-opacity-70 text-18 rounded-8" onClick={handleClick}>
                        <UploadIcon />
                      </button>
                      {ErrorMessage}
                    </>
                  )
              )
              : (
                <>
                  <div className={`h-full flex flex-col items-center justify-center gap-20 p-20 border border-dashed ${error ? 'border-red' : 'border-primary'} rounded-20`}>
                    <span className="text-50 text-grey"><UploadIcon /></span>
                    <Button style="admin" onClick={handleClick} variant="outline">Choose File{multiple ? 's' : ''}</Button>
                    {ErrorMessage}
                  </div>
                </>
              )
          }

        </div>
      </div>

      <input
        type="file"
        ref={fileRef}
        className="hidden"
        multiple={multiple}
        onChange={e => setFiles(multiple ? [...files, ...Array.from(e.target.files)] : Array.from(e.target.files))}
        accept="image/*"
      />
    </div>
  )
}

ImageUpload.propTypes = {
  label: PropTypes.string,
  accentIcon: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
  error: PropTypes.string,
  model: PropTypes.object,
  onLoadingChange: PropTypes.func,
}

export default ImageUpload
