import React from 'react'
import PropTypes from 'prop-types'

const Option = ({ children, value, hidden = false, disabled = false, selected = false }) => {
  return (
    <option value={value} hidden={hidden} disabled={disabled} selected={selected}>
      {children}
    </option>
  )
}

Option.propTypes = {
  children: PropTypes.node,
  value: PropTypes.node,
  hidden: PropTypes.bool,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
}

export default Option
