import React, { useState } from 'react'
import PublicModal from 'components/PublicModal'
import ApiClient from 'data/api/api_client'
import { useMutation } from 'react-query'
import { useNotifier } from 'react-headless-notifier'
import Notification from 'components/Notification'
import { Widget } from '@typeform/embed-react'
import { config } from 'data/config'
import SuccessModal from './modals/SuccessModal'
import { CheckCircleIcon, LoadingIcon } from './icons'
import Script from 'next/script'
import PropTypes from 'prop-types'

const TypeformModal = ({
  isOpen,
  onClose = () => { },
  formLink,
  type,
}) => {
  const notify = useNotifier()

  const [successModalOpen, setSuccessModalOpen] = useState(false)
  const [isRecaptchaLoading, setIsRecaptchaLoading] = useState(!!config.recaptchaSiteKey)

  const requestMutation = useMutation(
    () => ApiClient.users.partnerInquiry({ type }),
    {
      onSuccess: () => {
        setSuccessModalOpen(true)
        notify(
          <Notification message="Your request has been successfully sent!" type="success" />,
        )
      },
      onError: () => {
        notify(
          <Notification message="There was a problem sending your request, please get in contact" type="error" />,
        )
      },
      onSettled: () => onClose(),
    },
  )

  const recaptchaVerifyMutation = useMutation(
    (token) => ApiClient.auth.verifyWithRecaptcha(token),
    {
      onSuccess: (data) => {
        if (data && data?.success === true && data?.score >= 0.5) {
          requestMutation.mutate()
        } else {
          onClose()
          notify(
            <Notification type="error" message="There was an error submitting this request, please try again" />,
          )
        }
      },
      onError: () => {
        onClose()
        notify(
          <Notification type="error" message="There was an error submitting this request, please try again" />,
        )
      },
    },
  )

  const onSubmit = () => {
    onClose()
    if (config.recaptchaSiteKey) {
      if (!isRecaptchaLoading) {
        window.grecaptcha.ready(function () {
          window.grecaptcha.execute(config.recaptchaSiteKey, { action: 'open_partner_inquiry' }).then(function (token) {
            recaptchaVerifyMutation.mutate(token)
          })
        })
      }
    } else {
      requestMutation.mutate()
    }
  }

  return (
    <>
      <PublicModal
        isOpen={isOpen}
        onClose={() => onClose()}
        innerPadding="!p-0"
        className="antialiased transition-all duration-500 rounded-10 max-w-full md:max-w-[90vw]"
        buttonType="typeform"
      >
        <div className={`${isRecaptchaLoading ? 'w-100 h-100' : 'w-full md:w-[90vw] h-[90vh]'}`}>
          {config.recaptchaSiteKey
            && <Script
              id="grecaptcha"
              src={`https://www.google.com/recaptcha/api.js?render=${config.recaptchaSiteKey}`}
              onLoad={() => {
                setIsRecaptchaLoading(false)
              }}
            />
          }
          {
            isRecaptchaLoading
              ? <div className="flex items-center justify-center h-full">
                <LoadingIcon />
              </div>
              : isOpen
              && <div className="w-full h-full max-h-screen rounded-10 md:max-h-full">
                <div className="h-55 rounded-t-10 lg:hidden" />

                <div className="h-[calc(100%-55px)] lg:h-full">
                  <Widget
                    id={formLink}
                    style={{
                      width: '100%',
                      height: '100%',
                    }}
                    onSubmit={onSubmit}
                    keepSession={false}
                  />
                </div>
              </div>
          }
        </div>
      </PublicModal>

      <SuccessModal
        isOpen={successModalOpen}
        onClose={() => setSuccessModalOpen(false)}
        icon={<CheckCircleIcon />}
        title="Application Sent"
        description={<span>Thanks for applying, <br /> we will be in touch shortly!</span>}
      />
    </>
  )
}

TypeformModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  formLink: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
}

export default TypeformModal
