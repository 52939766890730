export default class SiteSettingsApiClient {
  constructor (http) {
    this.http = http
  }

  /**
   * Clear the site cache
   *
   * @returns {Array}
   */
  async clearCache () {
    const result = await this.http.delete('/cache')
    return result.data
  }
}
