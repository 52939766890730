import { forwardRef, useState } from 'react'
import { CheckIcon } from 'components/icons'
import classNames from 'classnames'
import { ReactChild } from 'data/types/types'
import { default as CustomerError } from 'components/form/Home/Error'
import { default as AdminError  } from 'components/form/Error'

interface CheckboxPropTypes {
  name: HTMLInputElement['name']
  onChange: (e: {
    type: undefined,
    target: {
      name: string,
      value: string | number | boolean,
    },
  }) => void
  children?: ReactChild,
  className?: string,
  error?: string,
  useValue?: boolean,
  value?: string | number,
  accentIcon?: ReactChild,
  checked?: boolean,
  defaultChecked?: boolean,
  style?: 'admin' | 'customer' | 'admin-trip-schedule',
  disabled?: boolean,
  reverseLabel?: boolean,
  block?: boolean,
}

const Checkbox = forwardRef<HTMLInputElement, CheckboxPropTypes>(function checkbox ({
  name,
  onChange = () => { },
  children,
  className = '',
  error,
  useValue = false,
  value,
  accentIcon,
  checked = false,
  defaultChecked = false,
  style = 'admin',
  disabled = false,
  reverseLabel = false,
  block = false,
}, ref) {
  const [isChecked, setIsChecked] = useState(checked || defaultChecked)

  const handleChange = () => {
    setIsChecked(!isChecked)

    onChange({
      type: undefined,
      target: {
        name: name,
        value: !isChecked ? (useValue ? value! : true) : false,
      },
    })
  }

  const classnames = classNames({
    'border-2 border-primary border-opacity-10 rounded-6': style === 'admin',
    'border border-grey-900 rounded-4': style === 'customer',
    'border-2 border-primary border-opacity-10 rounded-4': style === 'admin-trip-schedule',
  })

  const sizeClassname = classNames({
    'w-30 h-30': style === 'admin',
    'min-w-[18px] w-18 h-18': style === 'customer' || style === 'admin-trip-schedule',
  })

  const getCheckIcon = () => {
    switch (style) {
      case ('admin'):
      case ('admin-trip-schedule'):
        return <div className="text-primary"><CheckIcon className="w-24 h-24" /></div>
      case ('customer'):
        return <div className="w-8 h-8 bg-grey-900"></div>
    }
  }

  return (
    <div className={`flex flex-col ${className}`}>
      <label className={classNames('flex items-center gap-10', {
        'flex-row-reverse': reverseLabel,
        'justify-between': block,
      })}>
        <div className={`relative ${sizeClassname}`}>
          <input
            type="checkbox"
            className={`${sizeClassname} hidden`}
            name={name}
            ref={ref}
            value={value}
            defaultChecked={isChecked}
            onChange={handleChange}
            disabled={disabled}
          />
          <div className={classNames(
            'absolute inset-0 flex items-center justify-center',
            classnames,
            {
              'bg-grey-100': disabled,
              'bg-white': !disabled,
            },
          )}>
            {
              isChecked
                ? getCheckIcon()
                : null
            }
          </div>
        </div>
        <div className="relative">
          {children}

          {accentIcon && <span className="absolute text-14 -top-4 -left-12">{accentIcon}</span>}
        </div>
      </label>

      {
        style === 'customer'
          ? <CustomerError error={error} />
          : <AdminError error={error} />
      }
    </div>
  )
})

export default Checkbox
