export default class PreferencesApiClient {
  constructor (http) {
    this.http = http
  }

  /**
   * Get preferences gets all preferences for the questions and answers
   *
   * @returns All questions and all answers
   */
  async getPreferences () {
    const result = await this.http.get('/preferences')
    return result.data.data
  }

  /**
   * Update a users preferences
   *
   * @param {object} data
   * @returns {Object}
   */
  async storeAnswers (data, user) {
    const result = await this.http.post(`/preferences/${user.id}`, data)
    return result.data.data
  }
}
