import { ClickableServiceSlide_ServiceFragmentFragment } from 'gql/graphql'
import Link from 'components/Link'
import { ReactChild } from 'data/types/types'
import { graphql } from 'gql'

export const ClickableServiceSlide_ServiceFragment = graphql(`
  fragment ClickableServiceSlide_ServiceFragment on GeneralService {
    id
    slug
    survey_slug
    form_link
  }
`)

interface ClickableServiceSlideProps {
  children: ReactChild
  service: ClickableServiceSlide_ServiceFragmentFragment
  bookingId?: number | string
  onClick: (service: ClickableServiceSlide_ServiceFragmentFragment) => void
}

const ClickableServiceSlide = ({
  children,
  service,
  bookingId,
  onClick,
}: ClickableServiceSlideProps) => {
  if (service.slug) {
    return (
      <Link
        href={{
          pathname: '/services/[service]',
          query: {
            service: service.slug,
          },
        }}
        target="_blank"
      >
        {children}
      </Link>
    )
  }

  if (service.survey_slug && bookingId) {
    return (
      <Link
        href={`/home/reservations/${bookingId}/service-request/${service.survey_slug}`}
        target="_blank"
      >
        {children}
      </Link>
    )
  }

  return (
    <button type="button" onClick={() => onClick(service)}>
      {children}
    </button>
  )
}

export default ClickableServiceSlide
