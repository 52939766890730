enum MethodEnum {
  CARD = 'Debit/Credit Card',
  BANK = 'Bank Transfer'
}

const Method = {
  CARD: 'Debit/Credit Card',
  BANK: 'Bank Transfer',
}

export { Method, MethodEnum }
