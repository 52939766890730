import { useState } from 'react'
import { useEffect } from 'react'
import { IntersectionOptions, useInView } from 'react-intersection-observer'

export interface UseHasLoadedProps extends IntersectionOptions {}

export const useHasLoaded = (props?: UseHasLoadedProps) => {
  const { ref, inView } = useInView({
    ...props,
    fallbackInView: true, // Always display if there is an error
  })

  const [hasLoaded, setHasLoaded] = useState(false)
  useEffect(() => {
    if (hasLoaded) { return }

    setHasLoaded(hasLoaded || inView)
  }, [inView, hasLoaded])

  return { ref, hasLoaded }
}
