import { Listbox, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { ChevronDownFlaredIcon } from 'components/icons'
import { FieldError } from 'react-hook-form'
import Error from 'components/form/Home/Error'
import { ReactChild } from 'data/types/types'
import { PhoneLocation } from 'data/api/locations_api_client'

export type Option<T = string> = {
  label: string
  data?: never
  value: T
} | {
  label?: never
  data: PhoneLocation
  value: T
}

export interface SelectProps {
  label?: string
  innerLabel?: string
  value: string | number | {label: string, value: string}
  placeholder?: string
  onChange: (...event: any[]) => void
  options: string[] | Option[]
  renderOption?: (option: Option) => ReactChild
  renderValue?: (option: Option) => ReactChild
  disabled?: boolean
  error?: FieldError
  className?: {
    listbox?: string
    container?: string
    options?: {
      transition?: string
      container?: string
    }
  }
}

export default function Select ({
  label,
  innerLabel,
  value = '',
  placeholder,
  onChange,
  options,
  renderOption,
  renderValue,
  disabled,
  error,
  className,
}: SelectProps) {
  return (
    <Listbox
      as="div"
      className={classNames(className?.listbox, 'flex flex-col')}
      value={value}
      onChange={onChange}
      disabled={disabled}
    >
      {({ open }) => (
        <>
          <div className="flex flex-col group">
            {
              label && (
                <Listbox.Label className="mb-10 text-14 leading-20 text-grey-800">
                  {label}
                </Listbox.Label>
              )
            }

            <div className={classNames(className?.container, 'relative')}>
              <Listbox.Button
                className={classNames(
                  'min-h-[56px] relative rounded-16 flex items-center justify-between w-full text-left pl-20 pr-40 border focus:outline-none transition-colors bg-grey-100 gap-20',
                  {
                    'border-red border-opacity-50 focus:border-red': error,
                    'border-grey-100 border-opacity-100 focus:border-primary focus:border-opacity-50': !error,
                    'pt-26 pb-[6px]': innerLabel,
                    'py-16': !innerLabel,
                  },
                )}
              >
                <span className={classNames('text-14 lg:text-15 leading-22 flex gap-10 items-center w-full', {
                  'text-grey-500': !value,
                  'text-grey-800': value,
                })}>
                  {value
                    ? (
                      typeof(value) !== 'object'
                        ? value
                        : (
                          renderValue
                            ? renderValue(value)
                            : (
                              renderOption
                                ? renderOption(value)
                                : value.label
                            )
                        )
                    )
                    : placeholder}
                </span>

                <ChevronDownFlaredIcon className={classNames('absolute inset-y-0 right-20 my-auto text-grey-800 text-18 transition', {
                  'transform rotate-180': open,
                })} />

                {
                  innerLabel && (
                    <Listbox.Label className="absolute left-0 flex items-center px-20 text-opacity-50 pointer-events-none top-10 text-grey-900 text-12">
                      {innerLabel}
                    </Listbox.Label>
                  )
                }
              </Listbox.Button>

              <Transition
                show={open}
                as="div"
                leave="transition ease-in duration-50"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className={classNames(
                  className?.options?.container,
                  'absolute top-[calc(100%+10px)] z-10 flex flex-col w-full border cursor-pointer bg-grey-100 rounded-16 border-grey-100 focus:outline-none max-h-[40vh] overflow-y-auto',
                )}>
                  {
                    options.map((option, index) => (
                      <Listbox.Option
                        key={`listbox-option-${index}`}
                        value={option}
                      >
                        {({ active, selected }) => {
                          const itemSelected = selected || (
                            typeof (value) === 'object'
                            && typeof(option) === 'object'
                            && value.value === option.value
                          )

                          return (
                            <div className={classNames('px-20 py-5 text-14 lg:text-15 leading-23 text-grey-800 flex items-center gap-10', {
                              'bg-primary-fresh-light': active,
                              'rounded-b-16': index === options.length - 1,
                              'font-semibold text-primary-fresh bg-primary-fresh-light': itemSelected,
                            })}>
                              {
                                typeof(option) !== 'object'
                                  ? option
                                  : (
                                    renderOption
                                      ? renderOption(option)
                                      : option.label
                                  )
                              }
                            </div>
                          )}}
                      </Listbox.Option>
                    ))}
                </Listbox.Options>
              </Transition>
            </div>
          </div>

          <Error error={error?.message} />
        </>
      )}
    </Listbox>
  )
}
