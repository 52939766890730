import { RestProperty } from './property'
import { InquiryStatus } from 'data/enums/inquiry-status'

export enum InquiryType {
    Specific = 'specific',
    Callback = 'callback',
    General = 'general',
}

export type InquiryTypeBase = {
    first_name: string
    last_name: string
    phone_number: {
        number: string
        country_code: string
    }
}

export type InquiryTypeCallbackType = InquiryTypeBase & {
    // Other Types
    arrival: never
    departure: never
    guests: never
    budget: never
    email: never
    region_name: never
    sub_region_name: never
    client: never
    client_id: never
    property: never
    quoted_price: never
    adults: never
    children: never
}

export type InquiryTypeGeneralType = InquiryTypeBase & {
    arrival: string
    departure: string
    guests: string
    budget: number
    email: string
    region_name: string
    sub_region_name: string
    client: {channel: string}

    // Specific
    adults: never
    children: never
    client_id: never
    property: never
    quoted_price: never
}

export type InquiryTypeSpecificType = InquiryTypeBase & {
    arrival: string
    departure: string
    adults: number
    children: number
    email: string
    client_id: number
    property: RestProperty
    quoted_price: number
    client: {channel: string}

    // General
    guests: never
    budget: never
    region_name: never
}

export type RestInquiry = {
    id: number
    booking_agent: {
        id?: number
        email?: string
    }
    status: InquiryStatus
} & (
    {
        type: 'Specific'
        type_details: InquiryTypeSpecificType
    } | {
        type: 'Callback'
        type_details: InquiryTypeCallbackType
    } | {
        type: 'General'
        type_details: InquiryTypeGeneralType
    }
)
