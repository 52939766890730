import { ReactChild } from 'data/types/types'

const SubLabel = ({ children }: { children: ReactChild }) => {
  return (
    <label className={'ml-5 text-12 text-grey-600'}>
      {children}
    </label>
  )
}

export default SubLabel
