import DashboardModal from 'components/DashboardModal'
import { formatDateReadable } from 'data/helpers/dates'
import Form from 'components/form'
import { LoadingIcon } from 'components/icons'
import DashboardSendToPartnerModalTrigger from './triggers/DashboardSendToPartnerModalTrigger'
import Link from 'components/Link'
import Button from 'components/Button'
import { useQuery } from 'urql'
import { ServiceRequestDetail } from 'gql/graphql'
import { graphql } from 'gql'

const DashboardServiceRequestModal = ({
  isOpen = false,
  onClose = () => { },
  serviceRequestId,
}: {
  isOpen: boolean,
  onClose: () => void,
  serviceRequestId?: string
}) => {
  const [{ data, fetching }] = useQuery({
    query: graphql(`
      query ServiceRequest($id: ID!) {
        serviceRequest (id: $id) {
          id
          request_id
          trip_service {
            id
          }
          status
          details {
            question
            answer
          }
          service {
            id
            title
          }
          user {
            id
            first_name
            last_name
          }
          booking {
            id
            concierge {
              id
            }
            client {
              id
              first_name
              last_name
            }
            event {
              id
              start_date
              end_date
              property {
                id
                title
              }
            }
          }
          created_at
        }
    }
  `),
    variables: {
      id: serviceRequestId!,
    },
    pause: !serviceRequestId || !isOpen,
  })

  return (
    <DashboardModal
      title={(fetching || !data?.serviceRequest)
        ? 'Loading ...'
        : `Request for ${data.serviceRequest.service.title} - ${data.serviceRequest.user.first_name} ${data.serviceRequest.user.last_name} - ${data.serviceRequest.service.id}`
      }
      isOpen={isOpen}
      onClose={() => onClose()}
      size="6xl"
      className="lg:min-h-[450px]"
    >
      <div className="flex flex-col w-full h-full gap-40 lg:flex-row lg:p-20">
        {(fetching || !data?.serviceRequest)
          ? <div className="flex items-center justify-center w-full h-full"><LoadingIcon /></div>
          : <>
            <div className="flex flex-col gap-16 lg:w-1/3">
              <Form.Input
                label="First Name"
                value={data.serviceRequest.user.first_name}
                readOnly
              />
              <Form.Input
                label="Last Name"
                value={data.serviceRequest.user.last_name}
                readOnly
              />
              <Form.Input
                label="Booking ID"
                value={data.serviceRequest.booking.id}
                readOnly
              />
              <Form.Input
                label="Service ID"
                value={data.serviceRequest.service.id}
                readOnly
              />
              <Form.Input
                label="Request Date"
                value={formatDateReadable(data.serviceRequest.created_at) ?? undefined}
                readOnly
              />
              <Form.Input
                label="Request ID"
                value={data.serviceRequest.request_id}
                readOnly
              />
            </div>
            <div className="flex flex-col lg:w-2/3 gap-25">
              <div className="flex justify-end gap-20">
                <DashboardSendToPartnerModalTrigger
                  serviceRequest={data.serviceRequest}
                />

                {
                  (data.serviceRequest.trip_service)
                    ? <Link href={`/dashboard/bookings/${data.serviceRequest.booking.id}/trip-schedule?groupId=${data.serviceRequest.trip_service.id}`}
                      className="rounded-8"
                      target="__blank">
                      <Button style="admin" className="whitespace-nowrap !px-20">
                        View Service
                      </Button>
                    </Link>
                    : <Link href={`/dashboard/bookings/${data.serviceRequest.booking.id}/trip-schedule?serviceId=new&serviceRequestId=${data.serviceRequest.id}&generalServiceId=${data.serviceRequest.service.id}`}
                      className="rounded-8"
                      target="__blank">
                      <Button style="admin" className="whitespace-nowrap !px-20">
                        Create Service
                      </Button>
                    </Link>
                }
              </div>

              <div className="relative w-full h-full px-20 overflow-y-auto max-h-[566px] bg-soft ring-1 lg:ring-2 ring-input-border lg:ring-primary lg:ring-opacity-8 rounded-8">
                <p className="pt-20 antialiased text-grey-800 min-h-[140px] text-14 lg:text-16 whitespace-pre">
                  {(data.serviceRequest.details ?? []).map((item: ServiceRequestDetail, index: number) =>
                    <span key={index}>
                      <b>{item.question}</b><br />{item.answer}<br /><br />
                    </span>,
                  )}
                </p>
                <div className="absolute inset-0 flex px-20 pointer-events-none top-5">
                  <span className={'text-grey-900 text-12'}>
                    Request Details
                  </span>
                </div>

              </div>
            </div>
          </>
        }
      </div>
    </DashboardModal>
  )
}

export default DashboardServiceRequestModal
