import PublicMobileModal from 'components/PublicMobileModal'
import { InviteUserToWishlistInput, Wishlist } from 'gql/graphql'
import { useEffect, useState } from 'react'
import Button from 'components/home/common/Button'
import { ModalProps } from 'data/types/modal'
import ConfirmPublicModal from 'components/modals/ConfirmPublicModal'
import Form from 'components/form/Home'
import { useNotifier } from 'react-headless-notifier'
import { useForm } from 'react-hook-form'
import { useMutation } from 'urql'
import useErrorHandlerGraphQl from 'data/hooks/useErrorHandlerGraphQl'
import Notification from 'components/Notification'
import { graphql } from 'gql'

interface WishlistInviteModalProps extends ModalProps {
  wishlistId: Wishlist['id']
}

type WishlistInviteModalFormFields = Omit<InviteUserToWishlistInput, 'wishlistId'>

const WishlistInviteModal = ({
  wishlistId,
  isOpen,
  onClose = () => { },
}: WishlistInviteModalProps) => {
  const handleError = useErrorHandlerGraphQl()

  const { notify } = useNotifier()
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)

  const { handleSubmit, register, clearErrors, setError, setValue, reset, formState: { errors } } = useForm<WishlistInviteModalFormFields>({
    defaultValues: {
      confirm_share: false,
    },
  })

  const [{ fetching: invitingUserFetching }, inviteUser] = useMutation(graphql(`
    mutation inviteUserToWishlist($input: InviteUserToWishlistInput!) {
      inviteUserToWishlist(input: $input) {
        id
        users {
          id
          first_name
          last_name
          email
          profile_image {
            id
            url
            file_name
          }
        }
        userInvites {
          id
          email
          name
        }
      }
    }
  `))

  const onSubmit = async (details: WishlistInviteModalFormFields) => {
    clearErrors()
    const result = await inviteUser({
      input: {
        wishlistId: wishlistId,
        email: details.email,
        name: details.name,
        confirm_share: details.confirm_share,
      },
    })

    if (result.error) {
      handleError(result.error, setError)
    } else {
      notify(
        <Notification message="User has been invited" type="success" />,
      )
      reset()
      onClose()
    }
  }

  useEffect(() => {
    if (errors?.email?.message == 'This email has already been invited to this wishlist.') {
      clearErrors('email')
      setConfirmModalOpen(true)
    }
  }, [errors?.email?.message])

  return (
    <>
      <PublicMobileModal
        isOpen={isOpen}
        onClose={onClose}
        className={{
          panel: 'flex flex-col px-24 pb-safe-offset-30 max-w-lg',
        }}
        title="Invite Friend"
      >
        <form onSubmit={handleSubmit(onSubmit)} className="pt-25">
          <Form.Input
            {...register('name')}
            type="text"
            className={{
              container: 'mb-10',
            }}
            error={errors?.name?.message}
            variant="grey"
            label="Name"
            required
          />

          <Form.Input
            {...register('email')}
            type="email"
            className={{
              container: 'mb-10',
            }}
            error={errors?.email?.message}
            variant="grey"
            label="Email"
            required
          />
          <hr className="mb-20 border-none"/>
          <div className="flex justify-center">
            <Button
              type="submit"
              className={{
                button: 'max-w-[340px] w-full',
              }}
              style="modal"
              isLoading={invitingUserFetching}
            >
              SEND INVITE
            </Button>
          </div>
        </form>

      </PublicMobileModal>

      <ConfirmPublicModal
        isOpen={confirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
        title="Send confirmation message"
        message="Did you want to share your link to this user again?"
        action={() => {
          setValue('confirm_share', true)
          handleSubmit(onSubmit)()
        }}
      />
    </>
  )
}

export default WishlistInviteModal
