import { createSlice } from '@reduxjs/toolkit'
import { Method } from 'data/enums/payment-method'
import { Status } from 'data/enums/payment-status'
import { TripScheduleStatus, isDraft } from 'data/enums/trip-schedule/trip-schedule-status'
import { TripSchedule } from 'data/enums/trip-schedule/trip-schedule-type'
import { createAllocationPayments, getAllocatedAmountFromPayments } from 'data/helpers/allocation'
import { addDays, formatDefault } from 'data/helpers/dates'
import { v4 as uuidv4 } from 'uuid'

export const bookingServicePaymentSlice = createSlice({
  name: 'bookingServicePayments',
  initialState: {
    servicePayments: [],
    completedPayments: [],
    names: {},
  },
  reducers: {
    initialiseServicePayments: (state, action) => {
      state.servicePayments = action.payload.service ?? []
      state.completedPayments = action.payload.completed ?? []
      state.names = action.payload.names ?? {}
    },
    initialiseTripServicePayments: (state, action) => {
      let services = action.payload
      let payments = [...state.servicePayments]

      services.filter(service =>
        service.price > 0 && !isDraft(service.status) && service.status !== TripScheduleStatus.CANCELLED,
      ).forEach(service => {
        if (service.group && service.price) {
          if (!payments.some(payment => payment.trip.id === service.group.id
            && payment.trip.type === TripSchedule.GROUP)
          ) {
            payments = [
              ...payments, {
                id: uuidv4(),
                name: service.title,
                amount: service.price,
                num_parts: 1,
                parts: [
                  {
                    id: uuidv4(),
                    percentage: 100,
                    due_date: formatDefault(addDays(new Date(), 2)),
                    status: Status.DUE,
                    method: null,
                  },
                ],
                parent_id: -1,
                trip: {
                  id: service.group.id,
                  type: TripSchedule.GROUP,
                },
              },
            ]
          }
        } else {
          if (!payments.some(payment => payment.trip.id === service.id
            && payment.trip.type === TripSchedule.SERVICE)
          ) {
            payments = [...payments, {
              id: uuidv4(),
              name: service.title,
              amount: service.price,
              num_parts: 1,
              parts: [
                {
                  id: uuidv4(),
                  percentage: 100,
                  due_date: formatDefault(addDays(new Date(), 2)),
                  status: Status.DUE,
                  method: null,
                },
              ],
              parent_id: -1,
              trip: {
                id: service.id,
                type: TripSchedule.SERVICE,
              },
            }]
          }
        }
      })

      state.servicePayments = payments
    },
    //Payments
    setPayments: (state, action) => {
      state.servicePayments = action.payload
    },
    //Names
    setServiceNames: (state, action) => {
      state.names = action.payload
    },
    //Completed Payments
    confirmPaymentParts: (state, action) => {
      let newPayments = [...state.servicePayments]
      let date = action.payload.date
      let status = action.payload.status
      let amount = 0
      let parts = []

      newPayments.forEach(payment => payment.parts.forEach(part => {
        if (formatDefault(part.due_date) === date && part.status === status) {
          const allocationPayments = createAllocationPayments(payment.parts)

          if (allocationPayments.length > 0) {
            const amounts = getAllocatedAmountFromPayments(payment.amount, allocationPayments)

            const index = amounts.findIndex(amount => amount.id === part.id)

            if (index > -1) {
              amount += amounts[index].amount
            }
          }

          part.status = Status.COMPLETED
          part.method = Method.BANK

          parts = [...parts, {
            id: part.id,
            name: `${Number(part.percentage) === 100 ? '' : part.percentage + '%'} ${payment.name}`,
          }]
        }
      }))

      state.completedPayments = [...state.completedPayments, {
        id: uuidv4(),
        name: state.names[date],
        due_date: date,
        completed_date: formatDefault(new Date()),
        amount: amount,
        amount_received: null,
        status: Status.COMPLETED,
        method: Method.BANK,
        parts: parts,
      }]
      state.servicePayments = newPayments
    },
    updateCompletedPayment: (state, action) => {
      const payments = [...state.completedPayments]
      const index = payments.findIndex(item => item.id === action.payload.id)

      if (index > -1) {
        payments[index] = {
          ...action.payload,
        }
      }

      state.completedPayments = payments
    },
  },
})

export const {
  initialiseServicePayments, initialiseTripServicePayments, setPayments, setServiceNames, confirmPaymentParts, updateCompletedPayment,
} = bookingServicePaymentSlice.actions

export const getTotals = (state) => {
  let due = 0
  let pending = 0
  let completed = 0
  let received = 0

  state.bookingServicePayments.servicePayments
    .forEach(payment => payment.parts.forEach(part => {
      const allocationPayments = createAllocationPayments(payment.parts)

      let amount = payment.amount

      if (allocationPayments.length > 0) {
        const amounts = getAllocatedAmountFromPayments(amount, allocationPayments)

        const index = amounts.findIndex(amount => amount.id === part.id)

        if (index > -1) {
          amount = amounts[index].amount
        }
      }

      switch (part.status) {
        case (Status.DUE):
          due += amount
          break
        case (Status.PENDING):
          pending += amount
          break
        case (Status.COMPLETED):
          completed += amount
          if (part.method === Method.CARD) {
            received += amount
          }
          break
      }
    }))

  received += state.bookingServicePayments.completedPayments
    .reduce((sum, payment) => sum += Number(payment.amount_received), 0)

  return {
    due: due,
    pending: pending,
    completed: completed,
    received: received,
    total: due + pending + completed,
  }
}

export default bookingServicePaymentSlice.reducer
