import { FieldValues } from 'react-hook-form'
import { DateRangeInput } from './DateRangePicker'
import ControlledDateRangePicker, { ControlledDateRangePickerProps } from './ControlledDateRangePicker'
import { useEffect, useMemo, useState } from 'react'
import PublicMobileModal, { PublicMobileModalProps } from 'components/PublicMobileModal'
import { CalendarSearchBarIcon } from 'components/icons'
import Paragraphs from 'components/home/common/typography/Paragraphs'
import { formatDateRangeReadable, formatDateReadable } from '@ama-selections/ui'
import classNames from 'classnames'
import Button from 'components/home/common/Button'
import { ReactChild } from 'data/types/types'
import SearchFilter from 'components/searchpage/SearchFilter'
import { ModalProps } from 'data/types/modal'
export type { DateRangeInput }


interface ControlledDateRangePickerModalProps<T extends FieldValues> extends Pick<ModalProps, 'onIsOpenChange'> {
  datePickerProps: ControlledDateRangePickerProps<T>
  error?: string
  selectedDates?: DateRangeInput
  onConfirm?: () => void
  onClear?: () => void
  renderButton?: ({
    dates,
    onClick,
  }: {
    dates?: DateRangeInput
    onClick: () => void
  }) => ReactChild
  className?: {
    modal?: PublicMobileModalProps['className']
  }
}

export default function ControlledDateRangePickerModal<T extends FieldValues> ({
  datePickerProps,
  error,
  selectedDates,
  onConfirm,
  onClear,
  renderButton,
  className,
  onIsOpenChange,
}: ControlledDateRangePickerModalProps<T>) {
  const [isOpen, setIsOpen] = useState(false)

  const startDate = useMemo(() => formatDateReadable(selectedDates?.startDate), [selectedDates?.startDate])
  const endDate = useMemo(() => formatDateReadable(selectedDates?.endDate), [selectedDates?.endDate])

  useEffect(
    () => onIsOpenChange?.(isOpen),
    [isOpen],
  )

  return (
    <>
      {
        renderButton
          ? renderButton({ dates: selectedDates, onClick: () => setIsOpen(!isOpen) })
          : (
            <SearchFilter
              open={false}
              placeholder="Choose travel dates"
              label="Dates"
              value={formatDateRangeReadable(startDate, endDate) ?? undefined}
              onClick={() => setIsOpen(!isOpen)}
              error={error}
            />
          )
      }

      <PublicMobileModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        className={{
          panel: className?.modal?.panel,
          ...className?.modal,
        }}
        hasFocusTrap
      >
        <div className="flex flex-col h-[92.5vh]">
          <div className="px-24 pt-18">
            <div className="flex gap-12 pb-18">
              <CalendarSearchBarIcon className="text-18 text-primary" />

              <Paragraphs.MD className="text-grey-650">Dates</Paragraphs.MD>
            </div>

            <div className="grid grid-cols-2 pb-15">
              <Paragraphs.XL className={classNames(
                'font-bold text-grey-900',
                {
                  '!text-grey-650': !startDate,
                },
              )}>
                {startDate ?? 'Start Date'}
              </Paragraphs.XL>
              <Paragraphs.XL className={classNames(
                'font-bold text-grey-900',
                {
                  '!text-grey-650': !endDate,
                },
              )}>
                {endDate ?? 'End Date'}
              </Paragraphs.XL>
            </div>
          </div>

          <ControlledDateRangePicker
            {...datePickerProps}
          />

          <div className="flex flex-col gap-8 px-24 pt-10 pb-12">
            <Button
              style="modal"
              onClick={() => {
                if (onConfirm) {
                  onConfirm()
                }

                setIsOpen(false)
              }}
            >
              Confirm Dates
            </Button>

            {
              onClear && (
                <Button
                  style="modal"
                  variant="underline"
                  onClick={onClear}
                >
                  Clear selection
                </Button>
              )
            }
          </div>
        </div>
      </PublicMobileModal>
    </>
  )
}
