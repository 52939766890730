import { useMutation } from 'urql'
import { graphql } from 'gql'
import { UpdateUserInput } from 'gql/graphql'
import { useNotifier } from 'react-headless-notifier'
import Notification from 'components/Notification'
import { useDispatch } from 'react-redux'
import { setUser } from 'store/auth'
import useAuth from './useAuth'
import { MobileMessageActions } from 'data/helpers/mobile/messageChannels'

export enum NotificationPreferenceType {
  Email = 'via_email',
  Push = 'via_push',
}

const useNotificationPreferences = (type: NotificationPreferenceType) => {
  const { notify } = useNotifier()
  const dispatch = useDispatch()

  const { user } = useAuth()

  const [{ fetching: isUpdating }, updateUserPreferences] = useMutation(
    graphql(`
      mutation UpdateUserNotificationPreferences($input: UpdateUserInput!) {
        updateUser(input: $input) {
          id
          via_email
          via_push
        }
      }
    `),
  )

  const updateNotificationPreferences = (data: Pick<UpdateUserInput, NotificationPreferenceType>) => {
    updateUserPreferences({ input: {
      id: user!.id,
      ...data,
    } })
      .then((response) => {
        if (response.error) {
          notify(
            <Notification type="error" message="Failed to update notification preferences, please try again" />,
          )
        } else if (response.data?.updateUser) {
          dispatch(setUser({
            ...user!,
            ...response.data.updateUser,
          }))

        }
      })
  }

  const toggle = () => {

    // Run permissions check on mobile
    if (type === NotificationPreferenceType.Push) {
      AmaMobileApp.postMessage(MobileMessageActions.CHECK_PUSH_PERMISSIONS)
    }

    updateNotificationPreferences({
      [type]: !user![type],
    })
  }

  return {
    isUpdating,
    toggle,
  }
}

export default useNotificationPreferences
