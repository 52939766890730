const useErrorHandler = () => {
  return (
    error,
    setError,
    keyOverrides = {},
  ) => {
    if (error?.response?.data?.errors) {
      Object
        .entries(error.response.data.errors)
        .map(([field, errors]) => setError(
          keyOverrides[field] || field,
          { message: errors[0] },
        ))
    } else if (error?.response?.status === 429) {
      setError('email', { message: 'Too many requests' })
    } else {
      throw error
    }
  }
}

export default useErrorHandler
