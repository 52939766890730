import classNames from 'classnames'

interface NotificationDotProps {
  number: number
  offset?: string
  position?: 'absolute' | 'block'
  size?: 'sm' | 'lg'
}

const NotificationDot = ({
  number,
  offset,
  position = 'absolute',
  size = 'sm',
}: NotificationDotProps) => {
  if (!number) {
    return null
  }

  return (
    <div className={classNames(
      'flex items-center justify-center ring-2 ring-white text-white bg-primary-fresh rounded-full',
      position,
      size === 'sm' && {
        'w-16 h-16': true,
        'text-10': number < 10,
        'text-[9px]': number >= 10 && number < 100,
        'text-8': number >= 100,
      },
      size === 'lg' && {
        'w-[23px] h-[23px]': true,
        'text-12': number < 10,
        'text-11': number >= 10 && number < 100,
        'text-10': number >= 100,
      },
      offset ?? '-top-8 -right-10',
    )}>
      {number}
    </div>
  )
}

export default NotificationDot
