import Headers from 'components/home/common/typography/Headers'
import Paragraphs from 'components/home/common/typography/Paragraphs'

const AuthToAccessSection = () => {
  return (
    <div className="flex flex-col gap-5 my-auto text-center text-grey-500">
      <Headers.H4 className="!font-sans text-grey-900 mb-20">
          Please sign in to access this feature
      </Headers.H4>

      <Paragraphs.MD>To access this feature you must have an account with us.</Paragraphs.MD>
      <Paragraphs.MD>Simply log in below or sign up now;</Paragraphs.MD>
      <Paragraphs.MD>this will only take 10 seconds.</Paragraphs.MD>
    </div>
  )
}

export default AuthToAccessSection
