import React from 'react'
import { Noop } from 'react-hook-form'
import classNames from 'classnames'
import Button from 'components/Button'

type PropTypes = {
    disabled: boolean
    checked: boolean
    value: number | string
    onChange: (value: number | string) => void
    onBlur: Noop
}

const NumberRatingItem: React.FC<PropTypes> = ({
  disabled,
  value,
  checked,
  onChange,
  onBlur,
}) => {

  const onClick = () => {
    onChange(value)
  }

  return (
    <Button
      disabled={disabled}
      variant="outline-grey"
      className={classNames(
        'sm:w-[50px] xs:h-[50px] h-[44px] !p-0 font-normal w-full [&:nth-child(n+7)]:ms-[calc(50%+3px)] [&:nth-child(n+7)]:sm:ms-0 ring-primary-dark',
        {
          'bg-primary text-white hover:bg-primary-dark !border-primary': checked,
        },
      )}
      onBlur={onBlur}
      onClick={onClick}
    >
      <>{value}</>
    </Button>
  )
}

export default NumberRatingItem
