import classNames from 'classnames'
import { ReactChild } from 'data/types/types'
import React, { useState } from 'react'
import { EditCustomerProfileSection } from '../modals/EditCustomerProfileModal'
import { formatEnum } from '@ama-selections/ui'
import { EditCustomerModal } from '../modals/EditCustomerModal'
import { EditPassportImageModal } from '../modals/EditPassportImageModal'

export interface EditItemProps {
  field: EditCustomerProfileSection
  value: ReactChild
}

export const EditItem = ({
  field,
  value,
}: EditItemProps) => {
  const [isEditing, setIsEditing] = useState(false)

  return (
    <>
      <button
        type="button"
        onClick={() => setIsEditing(true)}
        className={classNames(
          'flex flex-col pt-20 focus:outline-none',
          'tracking-1/2 leading-28 group text-left',
          {
            'pb-15': value,
            'pb-20': !value,
          },
        )}
      >
        <div className="flex justify-between w-full">
          <h3 className="text-blue-1000 text-16">
            {formatEnum(field)}
          </h3>

          <p className="text-right underline text-14 text-primary-fresh group-hover:text-primary-active focus:outline-none focus:text-primary-active">
            Edit
          </p>
        </div>

        <p className="text-grey-650 text-15">
          {value}
        </p>
      </button>

      <EditCustomerModal
        isOpen={field !== EditCustomerProfileSection.Passport && isEditing}
        onClose={() => setIsEditing(false)}
        fields={[field]}
      />

      <EditPassportImageModal
        isOpen={field === EditCustomerProfileSection.Passport && isEditing}
        onClose={() => setIsEditing(false)}
      />
    </>
  )
}
