const ServiceRequestQuestion = ({
  className,
  questionNumber,
  question,
  subtitle,
}: {
  className?: string
  questionNumber: number
  question: string
  subtitle?: string
}) => {
  return (
    <div className={`flex flex-col gap-10 ${className}`}>
      <div className="flex">
        <span className="w-[30px] text-right mr-10 font-serif font-bold leading-40 tracking-title text-28 lg:text-32 text-grey-900">
          {questionNumber}.
        </span>

        <h2 className="w-full font-serif font-bold leading-40 tracking-title text-28 lg:text-32 text-grey-900">
          {question}
        </h2>
      </div>

      {subtitle && (
        <p className="w-full pl-40 text-grey-750 text-16 leading-23">
          {subtitle}
        </p>
      )}
    </div>
  )
}

export default ServiceRequestQuestion
