import { ReactChild } from 'data/types/types'

const Accordion = ({
  children,
}: {
  children: ReactChild | (ReactChild | null)[],
}) => {
  return (
    <>
      {children}
    </>
  )
}

export default Accordion
