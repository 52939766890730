import React from 'react'
import classNames from 'classnames'

type PropType = {
  text: string
  className?: string
}

const Label = ({ text, className }: PropType) => {
  return (
    <p className={classNames('text-16 leading-20 grey-800 font-semibold', className)}>
      {text}
    </p>
  )
}

export default Label
