import classNames from 'classnames'
import { ReactChild } from 'data/types/types'

const Label = ({
  children,
  className = '',
  variant = 'customer',
  accentIcon,
}: {
  children: ReactChild
  className?: string
  variant?: 'customer' | 'admin' | 'survey'
  accentIcon?: ReactChild
}) => {
  return (
    <label className={classNames(
      'inline-block mb-10',
      {
        'ml-5 text-16': variant === 'admin' || variant === 'customer',
        'leading-20': variant === 'customer',
        'text-14 text-grey-800': variant === 'survey',
        'relative': accentIcon !== undefined,
      },
      className,
    )}>
      {children}

      {accentIcon && <span className="absolute text-14 -top-4 -right-14">{accentIcon}</span>}
    </label>
  )
}

export default Label
