import Notification from 'components/Notification'
import { useNotifier } from 'react-headless-notifier'

export const useCopyToClipboard = () => {
  const { notify } = useNotifier()

  const copyToClipboard = (text?: string) => {
    if (!text) { return }

    navigator.clipboard.writeText(text)
    notify(
      <Notification message="Copied to clipboard" type="success" />,
    )
  }

  return {
    copyToClipboard,
  }
}
