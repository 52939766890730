import { getLoadedImagesArray, getNumberArray } from '@ama-selections/ui'
import { useState } from 'react'

interface useCarouselLoadedImagesProps {
  imagesEitherSide: number
  imagesLength: number
  isLoop?: boolean
}

export const useCarouselLoadedImages = ({
  imagesEitherSide,
  imagesLength,
  isLoop = false,
}: useCarouselLoadedImagesProps) => {
  const numberArray = getNumberArray(imagesLength)
  const [loadedImages, setLoadedImages] = useState([
    ...numberArray.slice(0, imagesEitherSide + 1),
    ...(isLoop ? numberArray.slice(-imagesEitherSide) : []),
  ])

  const onRealIndexChange = (realIndex: number) => {
    if (loadedImages.length !== imagesLength) {
      setLoadedImages(getLoadedImagesArray(
        realIndex,
        imagesEitherSide,
        imagesLength,
        numberArray,
        loadedImages,
      ))
    }
  }

  const isImageLoaded = (index: number) => loadedImages.includes(index)

  return {
    loadedImages,
    onRealIndexChange,
    isImageLoaded,
  }
}
