import { configureStore } from '@reduxjs/toolkit'
import authReducer from 'store/auth'
import bookingSharedUsersReducer from 'store/booking-shared-users'
import bookingOwnerPaymentsReducer from 'store/booking-owner-payments'
import bookingPaymentsReducer from 'store/booking-payments'
import bookingRequestReducer from 'store/booking-request'
import bookingServicePaymentsReducer from 'store/booking-service-payments'
import bookingPaymentDeductionsReducer from 'store/booking-payment-deductions'
import inboxReducer from 'store/inbox'
import serviceSearchbarReducer from 'store/service-searchbar'
import paginationReducer from 'store/pagination'
import preferencesReducer from 'store/preferences'
import tripScheduleReducer from 'store/trip-schedule'
import searchbarReducer from 'store/searchbar'
import bookingConfirmationReducer from 'store/booking-confirmation'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    searchbar: searchbarReducer,
    bookingSharedUsers: bookingSharedUsersReducer,
    bookingOwnerPayments: bookingOwnerPaymentsReducer,
    bookingPayments: bookingPaymentsReducer,
    bookingRequest: bookingRequestReducer,
    bookingServicePayments: bookingServicePaymentsReducer,
    bookingPaymentDeductions: bookingPaymentDeductionsReducer,
    inbox: inboxReducer,
    pagination: paginationReducer,
    preferences: preferencesReducer,
    tripSchedule: tripScheduleReducer,
    bookingConfirmation: bookingConfirmationReducer,
    serviceSearchbar: serviceSearchbarReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
