import { useState } from 'react'
import { useEffect } from 'react'
import { useMobileApp } from './MobileApp/useMobileApp'

export const useHasScrolled = (scrollDistance?: number, isPagePublic?: boolean) => {
  const [hasScrolled, setHasScrolled] = useState(false)
  const { isMobileApp, safeAreaInsets } = useMobileApp()

  const logoBannerHeight = 145 + safeAreaInsets.top

  const scrollDistanceToCheck = (isMobileApp || !isPagePublic)
    ? scrollDistance
    : (logoBannerHeight ?? scrollDistance)

  useEffect(() => {
    const handleScroll = () => {
      setHasScrolled(window.scrollY > (scrollDistanceToCheck ?? 0))
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [scrollDistanceToCheck])

  return hasScrolled
}
