import React, { useMemo, useState } from 'react'
import TripSummaryViewCardItem from './TripSummaryViewCardItem'
import { TripScheduleService } from 'data/types/bookings/trip_schedule'
import dayjs from 'dayjs'
import { RestBooking } from 'data/types/booking'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import Button from 'components/home/common/Button'
import ServiceSpecificSearchModal from 'components/modals/home/search/services/ServiceSpecificSearchModal'

interface TripSummaryViewCardProps {
  services: TripScheduleService[]
  booking: RestBooking
}

const TripSummaryViewCard = ({
  services,
  booking,
}: TripSummaryViewCardProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { selectedDay } = useSelector((state: RootState) => ({
    selectedDay: state.tripSchedule.selectedDay,
  }))

  const splitTime = (time: string) => {
    const splitTime = time.split(':')

    return [
      parseInt(splitTime[0]),
      parseInt(splitTime[1]),
    ]
  }

  const tripServices = useMemo(
    () => services
      ?.filter(service => dayjs(service.date).format('YYYY-MM-DD') === selectedDay)
      ?.sort((a, b) => {
        const [aHour, aMinute] = a.start_time ? splitTime(a.start_time) : []
        const [bHour, bMinute] = b.start_time ? splitTime(b.start_time) : []
        return aHour === bHour
          ? aMinute - bMinute
          : aHour - bHour
      }),
    [services, selectedDay],
  )

  return (
    <div className="flex flex-col px-16 mb-20 lg:mb-0 gap-y-20">
      {tripServices.length > 0
        ? (
          tripServices?.map(service => (
            <TripSummaryViewCardItem
              key={service.id}
              service={service}
              booking={booking}
            />
          ))
        )
        : (
          <div className="flex flex-col gap-y-24 mx-8 px-30 lg:px-36 py-40 bg-blue-30 items-center rounded-10 lg:max-w-[380px] lg:m-auto lg:mt-100">
            <div className="flex flex-col w-full text-center text-13 leading-22 text-grey-900 tracking-1/4">
              <p className="font-semibold">A day with no plans?</p>
              <p>&nbsp;</p>
              <p>Get inspired and view the most popular holiday additions among our guests.</p>
            </div>

            <Button
              variant="transparent"
              className={{
                button: 'max-w-[250px] !text-grey-750 hover:!text-white',
              }}
              style="modal"
              block
              onClick={() => setIsModalOpen(true)}
            >
              Book a service
            </Button>
          </div>
        )
      }

      <ServiceSpecificSearchModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        booking={booking}
        showDisclaimer={true}
      />
    </div>
  )
}

export default TripSummaryViewCard
