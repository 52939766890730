import classNames from 'classnames'
import Link from 'components/Link'

const Pill = ({
  variant = 'grey',
  isActive = false,
  className,
  text,
  href,
  target,
  icon = null,
  action,
}: {
  variant?: 'public' | 'none' | 'light-grey' | 'grey' | 'admin-grey' | 'red' | 'admin-red' | 'gold' | 'admin-light-grey' | 'admin-gold' | 'draft' | 'green' | 'admin-green' | 'admin-inbox' | 'admin-pending' | 'rounded-square',
  isActive?: boolean,
  className?: string,
  text: string,
  href?: string,
  target?: string,
  icon?: any,
  action?: () => void,
}) => {
  const classes = classNames(
    'px-16 py-[9px]',
    {
      'text-grey-900': ['light-grey', 'grey', 'red', 'gold'].includes(variant),
      'text-grey-500': variant === 'draft',

      'bg-grey-230': variant.includes('light-grey'),
      'bg-grey-200': variant.includes('grey') || variant === 'draft',
      'bg-light-red': variant.includes('red'),
      'bg-primary-fresh-light': variant.includes('gold'),
      'bg-green-500 bg-opacity-30': variant.includes('green'),
      'bg-light-yellow text-warning': variant.includes('pending'),

      //Public
      'border bg-grey-100 leading-title leading-14 tracking-1/2 capitalize': variant === 'public',
      'border-grey-800 text-grey-800': isActive && variant === 'public',
      'border-grey-225 text-grey-750': !isActive && variant === 'public',

      //Admin
      '!text-[11px] text-grey-800': variant.includes('admin'),

      // Rounding
      'rounded-17': !variant.includes('admin'),
      'rounded-8': variant.includes('admin'),

      // Inbox
      'border border-grey-650 rounded-8 !py-5 focus:border-primary focus:text-primary hover:border-primary hover:text-primary': variant === 'admin-inbox',

      // Rounded Square
      'border border-grey-100 rounded-[14px] bg-grey-100 text-grey-650 font-poppins !py-[6px] font-medium box-border transition-all': variant === 'rounded-square',
      '!border-grey-800 text-grey-800': isActive && variant === 'rounded-square',
    },
    className,
    'w-max flex gap-5 items-center whitespace-nowrap focus:outline-none text-12',
  )

  const getIcon = () => {
    return icon
      ? <span className="flex items-center w-14 h-14">{icon}</span>
      : null
  }

  if (action !== undefined) {
    return (
      <button
        className={classNames(classes, className)}
        onClick={() => action()}
        type="button"
      >
        {text}{getIcon()}
      </button>
    )
  }

  if (href !== undefined) {
    return (
      <Link
        href={href}
        target={target}
        className={classes}
      >
        {text}{getIcon()}
      </Link>
    )
  }

  return (
    <div className={classes}>
      {text}{getIcon()}
    </div>
  )
}

export default Pill
