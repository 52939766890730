import { useMobileApp } from 'data/hooks/MobileApp/useMobileApp'
import { ReactChild } from 'data/types/types'
import NextLink, { LinkProps as NextLinkProps } from 'next/link'
import React from 'react'

export interface LinkProps extends Omit<NextLinkProps, 'onClick'> {
  children: ReactChild | ReactChild[]
  className?: string
  target?: string
  about?: string
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
}

const Link = ({
  className,
  children,
  target,
  about,
  onClick,
  ...props
}: LinkProps) => {
  const { isMobileApp } = useMobileApp()

  return (
    <NextLink {...props} about={about}>
      <a
        target={isMobileApp ? '_self' : target}
        className={className}
        onClick={onClick}
      >
        {children}
      </a>
    </NextLink>
  )
}

export default Link
