import { ReactChild } from 'data/types/types'
import classNames from 'classnames'

const Field = ({
  children,
  className = '',
  error,
  errorClassName = '',
}: {
  children: ReactChild | ReactChild[]
  className?: string
  error?: string
  errorClassName?: string
}) => {
  return (
    <div className={classNames('w-full', className)}>
      {children}
      {error && <p className={classNames('mt-5 text-red text-16', errorClassName)}>{error}</p>}
    </div>
  )
}

export default Field
