import { Control, Controller, FieldValues, Path } from 'react-hook-form'
import DateRangePicker, { DateRangePickerProps } from './DateRangePicker'

export interface ControlledDateRangePickerProps<T extends FieldValues> extends Omit<DateRangePickerProps, 'value' | 'onChange'> {
  name: Path<T>
  control: Control<T>
}

export default function ControlledDateRangePicker<T extends FieldValues> ({
  name,
  control,
  ...props
}: ControlledDateRangePickerProps<T>) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <DateRangePicker
          value={value}
          onChange={(startDate, endDate) => onChange({
            startDate,
            endDate,
          })}
          {...props}
        />
      )}
    />
  )
}
