import PublicMobileModal from 'components/PublicMobileModal'
import React from 'react'
import MiniServiceSearch, { MiniServiceSearchClassNames } from './MiniServiceSearch'
import { useForm, useFormContext } from 'react-hook-form'
import { DashboardSearchFormFields } from 'data/types/home/search'
import classNames from 'classnames'
import { RestBooking } from 'data/types/booking'

interface ServiceSpecificSearchModalProps {
  isOpen: boolean
  onClose: () => void
  booking?: RestBooking
  selectedRegion?: string
  showDisclaimer?: boolean
  className?: {
    panel?: string
    content?: MiniServiceSearchClassNames
  }
}

const ServiceSpecificSearchModal = ({
  isOpen,
  onClose,
  booking,
  selectedRegion,
  showDisclaimer = true,
  className,
}: ServiceSpecificSearchModalProps) => {
  const { setValue } = useFormContext<DashboardSearchFormFields>() ?? useForm<DashboardSearchFormFields>()

  return (
    <PublicMobileModal
      isOpen={isOpen}
      onClose={onClose}
      className={{
        panel: classNames(
          'h-full flex flex-col w-full !max-w-[600px] !pb-0',
          className?.panel,
        ),
      }}
      title="Book a Service"
    >
      <MiniServiceSearch
        booking={booking}
        showDisclaimer={showDisclaimer}
        onSearchChange={(search) => setValue('search', search)}
        className={{
          ...className?.content,
          container: 'pt-24 flex flex-col min-h-0',
          searchbar: 'px-24 lg:px-50',
          disclaimer: {
            container: 'mt-20',
          },
          grid: {
            container: '!overflow-y-auto pt-10 lg:pt-40 pb-40 px-24 lg:px-50',
          },
        }}
        selectedRegion={selectedRegion}
      />

    </PublicMobileModal>
  )
}

export default ServiceSpecificSearchModal
