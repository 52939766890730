import classNames from 'classnames'
import Link from 'components/Link'
import { ReactChild } from 'data/types/types'
import { LinkProps } from 'next/link'
import { useMemo } from 'react'

export type PillProps = {
  children: ReactChild,
  isActive?: boolean,
  isMultiselect?: boolean,
  className?: string,
  prefix?: ReactChild,
  suffix?: ReactChild,
} & (
  {
    variant: 'grey' | 'blue' | 'white' | 'grey-sans' | 'gold' | 'danger' | 'blank' | 'primary' | 'translucent-dark',
    size: '8' | '10' | '14',
  } | {
    variant: 'translucent-dark',
    size?: never,
  }
) & (
  {
    href: LinkProps['href'],
    target?: string,
    onClick?: never,
  } | {
    href?: never,
    target?: never,
    onClick: (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => void,
  } | {
    href?: never,
    target?: never,
    onClick?: never,
  }
)

const Pill = ({
  variant,
  size = '14',
  isActive = false,
  isMultiselect = false,
  className,
  children,
  href,
  target,
  prefix = null,
  suffix = null,
  onClick,
}: PillProps) => {
  const isDefaultVariant = useMemo(
    () => ['grey', 'blue', 'white'].includes(variant),
    [variant],
  )

  const classes = classNames(
    'leading-22 text-12 tracking-1/4',
    // Base
    {
      'px-15 py-5 border font-medium font-poppins': isDefaultVariant,
      'px-12 py-[3px] font-medium font-poppins': variant === 'gold',
      'px-16 py-[6px] font-medium font-poppins': variant === 'primary',
      'px-12 py-4 font-medium font-poppins': ['danger', 'blank'].includes(variant),
      'px-12 py-5 border-none font-sans w-max': variant.includes('sans'),
      'px-16 py-4 border-0 font-sans text-white rounded-24 h-max text-12 !leading-22': variant === 'translucent-dark',
    },
    // Rounding
    {
      'rounded-[14px]': size === '14',
      'rounded-10': size === '10',
      'rounded-8': size === '8',
    },
    // Base Colours
    {
      'bg-grey-100 border-grey-100': variant === 'grey',
      'bg-primary-200 text-grey-900': variant === 'gold',
      'bg-primary-fresh text-white': variant === 'primary',
      'bg-grey-150': variant === 'grey-sans',
      'bg-blue-50 border-blue-50': variant === 'blue',
      'bg-white border-white': variant === 'white',
      'text-white bg-grey-900 bg-opacity-50 h-max': variant === 'translucent-dark',
      'text-grey-800 bg-white': variant === 'blank',
      'text-red-800 bg-white': variant === 'danger',
    },
    className,
    'w-max flex gap-5 items-center whitespace-nowrap focus:outline-none',
  )

  const noActionClasses = classNames(
    // Base Colours
    {
      'text-grey-800': isDefaultVariant,
      'text-grey-750': variant === 'grey-sans',
    },
  )

  const actionClasses = classNames(
    // Base Colours
    {
      'text-grey-650': isMultiselect && isDefaultVariant,
      'text-grey-800': !isMultiselect && isDefaultVariant,
      'text-grey-750': variant === 'grey-sans',
    },
    // Hover
    {
      'hover:border-grey-250 focus:border-grey-250': isDefaultVariant,
      'hover:bg-primary-dark active:ring-2 active:ring-primary-200': variant === 'primary',
      'hover:bg-grey-100 active:ring-2 active:ring-red-100': variant === 'danger',
    },
    // Active
    {
      'text-grey-800 border-grey-800 hover:!border-grey-800 focus:!border-grey-800': isActive && isDefaultVariant,
      'hover:bg-grey-200 focus:bg-grey-200': isActive && variant === 'grey',
      'hover:bg-blue-100 focus:bg-blue-100': isActive && variant === 'blue',
    },
  )

  const getIcon = (icon: ReactChild) => {
    return icon
      ? <span className="flex items-center w-14 h-14">{icon}</span>
      : null
  }

  if (onClick !== undefined) {
    return (
      <button
        className={classNames(classes, actionClasses)}
        onClick={onClick}
        type="button"
      >
        {getIcon(prefix)}{children}{getIcon(suffix)}
      </button>
    )
  }

  if (href !== undefined) {
    return (
      <Link
        href={href}
        target={target}
        className={classNames(classes, actionClasses)}
      >
        {getIcon(prefix)}{children}{getIcon(suffix)}
      </Link>
    )
  }

  return (
    <div className={classNames(classes, noActionClasses)}>
      {getIcon(prefix)}{children}{getIcon(suffix)}
    </div>
  )
}

export default Pill
