import React, { forwardRef, useEffect } from 'react'
import { useState } from 'react'
import { EyeIcon } from 'components/icons'
import classNames from 'classnames'
import { ReactChild } from 'data/types/types'

interface InputPropTypes extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'dangerouslySetInnerHTML'|'children'|'prefix'|'style'> {
  error?: string
  label?: string
  prefix?: ReactChild
  suffix?: ReactChild
  style?: 'admin' | 'customer' | 'search'
  capitalise?: boolean
  withRing?: boolean
  preventDecimals?: boolean
}

const Input = forwardRef<HTMLInputElement, InputPropTypes>(function input ({
  type = 'text',
  className = '',
  onChange,
  onBlur,
  onFocus,
  onKeyUp,
  error,
  name,
  label,
  prefix,
  suffix,
  placeholder,
  value,
  defaultValue,
  step,
  style = 'admin',
  capitalise = false,
  withRing = true,
  disabled = false,
  readOnly = false,
  preventDecimals = false,
  ...props
}, ref) {
  const [focused, setFocused] = useState(false)
  const [hasContent, setHasContent] = useState(!!value || !!defaultValue)
  const [showPassword, setShowPassword] = useState(false)
  const isAdmin = style === 'admin'

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocused(false)
    setHasContent(Boolean(e.target.value))
    if (onBlur) {onBlur(e)}
  }

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocused(true)
    if (onFocus) {onFocus(e)}
  }

  useEffect(() => {
    setHasContent(!!value || !!defaultValue || value === 0 || defaultValue === 0)
  }, [value, defaultValue])

  const ringColor = error ? 'ring-red' : (isAdmin ? 'ring-input-border lg:ring-primary' : 'ring-input-border')
  const ringOpacity = error ? 'ring-opacity-50' : (isAdmin ? 'ring-opacity-100 lg:ring-opacity-8' : 'ring-opacity-100')

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (preventDecimals) {
      // Only numbers
      if (e.charCode >= 48 && e.charCode <= 57) {
        return
      } else {
        e.preventDefault()
      }
    }
  }

  const inputClassnames = classNames({
    'focus:ring-2 focus:ring-primary focus:ring-opacity-20 rounded-8': style === 'admin',
    'rounded-4': style === 'customer',
    'rounded-[45px] xl:rounded-4 focus:!ring-grey-300 xl:focus:!ring-primary': style === 'search',

    'focus:outline-none focus:ring focus:ring-primary': withRing,
    'outline-none !ring-0': !withRing,

    'pt-15': label,
    'pr-50': suffix,
    'pl-50': prefix,
    'capitalize': capitalise,

    '!bg-grey-100': disabled && isAdmin,
  })

  return (
    <div className={`w-full ${className.split(' ').filter(c => c.match(/^!?(max-w-|w-|h-|peer)/)).join(' ')}`}>
      <div className={`${className} w-full h-50 text-16 ${withRing ? `${isAdmin ? 'ring-1 lg:ring-2' : 'ring-1'}` : ''} ${withRing ? `${ringColor} ${ringOpacity} focus:ring-opacity-50` : 'outline-none !ring-0'}  ${isAdmin ? 'rounded-4 lg:rounded-8' : 'rounded-4'} flex items-center relative`}>

        {
          prefix
            ? <div className="absolute top-0 bottom-0 left-0 flex items-center px-20">
              {prefix}
            </div>
            : null
        }

        <input
          ref={ref}
          type={type === 'password' ? showPassword ? 'text' : 'password' : type}
          className={`absolute h-full inset-0 text-grey-800 placeholder-grey-500 text-14 lg:text-16  w-full border-none bg-transparent px-20 ${inputClassnames} transition `}
          name={name}
          onChange={onChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onKeyUp={onKeyUp}
          onKeyPress={handleKeyPress}
          placeholder={placeholder}
          value={value}
          defaultValue={defaultValue}
          step={step}
          disabled={disabled}
          readOnly={readOnly}
          {...props}
        />

        {
          type === 'password'
            ? <button
              aria-label="Show password"
              type="button"
              className="absolute top-0 bottom-0 right-0 flex items-center px-20 cursor-pointer"
              onClick={() => setShowPassword(!showPassword)}
            >
              <EyeIcon />
            </button>
            : null
        }

        {
          suffix
            ? <div className="absolute top-0 bottom-0 right-0 flex items-center px-20 text-grey-800">
              {suffix}
            </div>
            : null
        }

        <div className={`absolute inset-0 px-20 ${prefix ? 'pl-50' : ''} pointer-events-none flex items-center`}>
          <span className={`transform transition-all text-grey-900 ${focused || hasContent ? 'text-opacity-100 text-12 -translate-y-10' : `text-opacity-50 ${(className.match(/(text-\d*)/) ?? ['text-18'])[0]}`}`}>
            {label}
          </span>
        </div>
      </div>

      {
        error
          ? <span className="text-red text-14">{error}</span>
          : null
      }
    </div>
  )
})

export default Input
