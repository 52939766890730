import { Controller, FieldError } from 'react-hook-form'
import Select from './Select'

export default function ControlledSelect ({
  name,
  control,
  label,
  placeholder,
  options,
  disabled,
  error,
  variant = 'thick',
}: {
  name: string
  control: any
  label?: string
  placeholder: string
  options: string[] | {label: string, value: string}[]
  disabled?: boolean
  error: FieldError | undefined
  variant?: 'thin' | 'thick'
}) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <Select
          label={label}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          options={options}
          disabled={disabled}
          error={error}
          variant={variant}
        />
      )}
    />
  )
}
