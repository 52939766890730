import { joinBy } from './strings'
import { UrlObject } from 'url'
import { formatDefault, parseDate } from './dates'

const getDestinationSearchURL = (
  countrySlug?: string,
  regionSlug?: string,
  subregionSlug?: string,
  arrival?: string | Date,
  departure?: string | Date,
  bedrooms?: number,
  adults?: number,
  children?: number,
): UrlObject => {
  const searchLink = countrySlug
    ? joinBy([countrySlug, regionSlug, subregionSlug], '/')
    : 'search'

  const searchUrl = {
    pathname: `/${searchLink}`,
    query: {
      ...(parseDate(arrival)?.isValid() && { arrival: parseDate(arrival)!.format('YYYY-MM-DD') }),
      ...(parseDate(departure)?.isValid() && { departure: parseDate(departure)!.format('YYYY-MM-DD') }),
      ...(bedrooms && { bedrooms }),
      ...(adults && { adults }),
      ...(children && { children }),
    },
  }

  return searchUrl
}

const getPropertyTypeFromSlug = (
  slug?: string | null,
): string => {
  if (slug) {
    if (slug.toLowerCase().includes('apartment')) {
      return 'Apartments'
    } else if (slug.toLowerCase().includes('chalet')) {
      return 'Chalets'
    }
  }
  return 'Villas'
}

const getNumericQueryParameter = (value: string | string[] | null | undefined) => {
  if (Array.isArray(value) || !value) {
    return undefined
  }

  const number = parseInt(value)
  return isNaN(number) ? undefined : number
}

const getNumericArrayQueryParameter = (value: string | null) => {
  if (!value) {
    return undefined
  }

  return decodeURIComponent(value).split(', ').map((v) => parseInt(v))
}

const getStringArrayQueryParameter = (value: string | null) => {
  if (!value) {
    return undefined
  }

  return decodeURIComponent(value).split(', ')
}

type SearchQueryParameterInput = Record<string, string | number | Date | undefined | null | number[] | string[]>
type SearchQueryParameters = Record<string, string>

const getSearchURLQueryParameters = (
  searchQuery: SearchQueryParameterInput,
): SearchQueryParameters => {
  const query: SearchQueryParameters  = {}

  for (const [key, value] of Object.entries(searchQuery)) {
    if (value) {
      if (Array.isArray(value)) {
        query[key] = joinBy(value)
      } else if (value instanceof Date) {
        const date = formatDefault(value)

        if (date) {
          query[key] = date
        }
      } else {
        query[key] = value.toString()
      }
    }
  }

  return query
}

export {
  getDestinationSearchURL,
  getPropertyTypeFromSlug,
  getNumericQueryParameter,
  getSearchURLQueryParameters,
  getNumericArrayQueryParameter,
  getStringArrayQueryParameter,
}

export type {
  SearchQueryParameterInput,
  SearchQueryParameters,
}
