import React from 'react'
import { Controller, FieldError } from 'react-hook-form'
import StarRating from './StarRating'

type PropTypes = {
    disabled: boolean
    error: FieldError | undefined
    control: any
    options: {value: string | number}[]
    name: string
}

const ControlledStarRating: React.FC<PropTypes> = ({
  disabled,
  error,
  control,
  options,
  name,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <StarRating
          disabled={disabled}
          options={options}
          value={value}
          onChange={onChange}
          error={error}
        />
      )}
    />
  )
}

export default ControlledStarRating
