import { ModalProps } from 'data/types/modal'
import AuthSection from 'components/auth/AuthSection'
import PublicMobileModalDrawer from 'components/PublicMobileModalDrawer'

const CustomerRegisterModal = ({ isOpen, onClose }: ModalProps) => {
  return (
    <PublicMobileModalDrawer
      isOpen={isOpen}
      onClose={onClose}
      position="right"
    >
      <AuthSection
        showLogo
        initialSlide="register"
        buttonStyle="modal"
      />
    </PublicMobileModalDrawer>
  )
}

export default CustomerRegisterModal
