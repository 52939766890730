import { joinBy, pluralise } from '@ama-selections/ui'
import { getStartTime } from 'data/helpers/survey/tripSchedule'
import { TripScheduleService } from 'data/types/bookings/trip_schedule'

const getBoatCharterServiceDetails =  (service: TripScheduleService) => {

  const serviceDetails = service.service_details ?? service.group?.service_details

  if (serviceDetails?.includes('$generate_details') && service.survey?.__typename === 'SurveyBoatCharterInfo') {
    const survey = service?.survey

    const line1 = `(${getStartTime(service.start_time, true)})`

    const adults = pluralise(survey?.adults, 'adult', 's', '', true)
    const children = survey?.children
      ? pluralise(survey?.children, 'child', 'ren', '', true)
      : null

    const line2 = joinBy(
      [
        adults,
        children,
      ],
      ' + ',
    )

    let line3 = null
    if (survey && ('budget' in survey)) {
      line3 = survey.budget
    }

    let line4 = null
    if (survey && ('boat_type' in survey)) {
      line4 = survey.boat_type
    }

    {return serviceDetails.replaceAll(
      '$generate_details',
      joinBy(
        [
          line1,
          line2,
          line3,
          line4,
        ],
        '\n',
      ),
    )}
  }

  return serviceDetails
}

export { getBoatCharterServiceDetails }
