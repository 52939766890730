export default {
  featuredDestinations: ['featuredDestinations'],
  socialLinks: ['socialLinks'],
  locations: ['locations'],
  destinations: ['destinations'],
  featuredProperties: ['featuredProperties'],
  reviews: ['reviews'],
  recentArticles: ['recentArticles'],
  featuredServices: ['featuredServices'],
  propertyFilters: ['propertyFilters'],
  properties: ({ page, country, region, subregion, search }) => ['properties', page, country, region, subregion, search],
  events: ({ selectedProperty }) => ['events', selectedProperty],
  destination: (id) => ['destination', id],
  propertiesForRegion: (id) => ['propertiesForRegion', id],
  wishlists: ['wishlists'],
  property: (idOrSlug) => ['property', idOrSlug],
  reviewsForProperty: (idOrSlug) => ['reviewsForProperty', idOrSlug],
  magazineCategories: ['magazineCategories'],
  numberOfBookings: ['numberOfBookings'],
}
