import { useState } from 'react'
import {
  UserDashboardCalendar,
  UserDashboardHome,
  UserDashboardMessages,
  UserDashboardSearch,
  UserDashboardAccount,
} from 'components/icons'
import { useRouter } from 'next/router'
import CustomerProfileModal from 'components/modals/CustomerProfileModal'
import LogoLink from 'components/logoLink'
import Link from 'components/Link'
import NotificationDot from 'components/NotificationDot'
import { useUnreadCount } from 'data/hooks/useUnreadCount'
import classNames from 'classnames'
import UserProfileTrigger from 'components/home/navbar/triggers/UserProfileTrigger'
import useAuth from 'data/hooks/User/useAuth'
import CenteredCustomerLoginModal from 'components/modals/getHelp/CenteredCustomerLoginModal'

const UserDashboardNavbar = () => {
  const route = useRouter()
  const { unreadCount, unreadNotificationCount } = useUnreadCount()
  const { user } = useAuth()

  const [showAccountModal, setShowAccountModal] = useState(false)
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false)

  const NavigationLinks: {
    name: string
    icon: JSX.Element
    href?: string
    onClick?: () => void
    hideDesktop?: boolean
    hideMobile?: boolean
    notificationCount?: number | null
    requireAuth?: boolean
  }[] = [
    {
      name: 'Home',
      icon: <UserDashboardHome />,
      href: '/home',
      requireAuth: true,
    },
    {
      name: 'Bookings',
      icon: <UserDashboardCalendar />,
      href: '/home/reservations',
      requireAuth: true,
    },
    {
      name: 'Search',
      icon: <UserDashboardSearch className="text-20" />,
      href: '/home/search',
      hideDesktop: true,
      requireAuth: false,
    },
    {
      name: 'Wishlists',
      icon: <UserDashboardSearch className="text-24" />,
      href: '/home/wishlists',
      hideMobile: true,
    },
    {
      name: 'Inbox',
      icon: <UserDashboardMessages />,
      href: '/home/inbox',
      notificationCount: unreadCount,
      requireAuth: true,
    },
    {
      name: 'Account',
      icon: <UserDashboardAccount />,
      onClick: () => setShowAccountModal(true),
      hideDesktop: true,
      notificationCount: unreadNotificationCount,
    },
  ]

  return (
    <>
      <div
        className={classNames(
          'h-40 bg-white z-40 lg:border-b lg:border-grey-200',
          'lg:sticky lg:top-0 lg:h-[70px] lg:py-10 lg:pt-safe-offset-10 lg:flex lg:px-30 lg:items-center max-w-full justify-between',
        )}
      >
        <div className="hidden lg:flex basis-1/5">
          <LogoLink className="!w-full !max-w-[82px]" variant="no-tag-line" />
        </div>

        <nav className={classNames(
          'flex xxs:grid xxs:grid-cols-5 items-center',
          'lg:flex lg:gap-30 lg:h-full basis-3/5 justify-center',
        )}>
          {
            NavigationLinks.map((link, index) => {
              const navigationStyles = classNames(
                'flex flex-col items-center justify-between outline-none hover:text-primary-fresh',
                'lg:border-b-2 border-b-transparent lg:pb-4 lg:h-auto lg:focus:text-primary-fresh',
                {
                  'text-grey-800 lg:border-primary-fresh': route.pathname === link.href,
                  'text-grey-300 lg:text-grey-800': route.pathname !== link.href,
                  'lg:hidden': link.hideDesktop,
                  'hidden lg:flex': link.hideMobile,
                },
              )

              const navigationContent = (
                <>
                  <div className="relative lg:hidden">
                    {link.icon}

                    {
                      link.notificationCount
                        ? <NotificationDot number={link.notificationCount} />
                        : null
                    }
                  </div>
                  <p className={classNames(
                    'font-normal text-10 tracking-[1.5px] leading-20',
                    'lg:text-12 lg:font-bold lg:uppercase lg:leading-16',
                  )}>
                    {link.name}
                  </p>
                </>
              )

              return link.href && !link.requireAuth
                ? (
                  <Link
                    key={index}
                    href={link.href}
                    className={navigationStyles}
                  >
                    {navigationContent}
                  </Link>
                )
                : (
                  <button
                    key={index}
                    className={navigationStyles}
                    onClick={() => {
                      if (link.requireAuth && !user) {
                        setIsLoginModalOpen(true)
                      } else {
                        link.onClick
                          ? link.onClick?.()
                          : link.href ? route.push(link.href) : null
                      }
                    }}
                    type="button"
                  >
                    {navigationContent}
                  </button>
                )
            })
          }
        </nav>

        <div className="hidden lg:block basis-1/5">
          <UserProfileTrigger />
        </div>

        <CustomerProfileModal
          isOpen={showAccountModal}
          onClose={() => setShowAccountModal(false)}
        />
      </div>

      <CenteredCustomerLoginModal
        isOpen={isLoginModalOpen}
        onClose={() => setIsLoginModalOpen(false)}
      />
    </>
  )
}

export default UserDashboardNavbar
