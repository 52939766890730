import React from 'react'
import PropTypes from 'prop-types'
import { CheckIcon } from 'components/icons'
import ApiClient from 'data/api/api_client'
import queryCache from 'data/queryCache'
import { useQuery } from 'react-query'
import { Listbox } from '@headlessui/react'
import { countries } from 'countries-list'
import { classNames } from 'data/helpers/html'

const CountryCodeSelect = (props) => {
  const { data: locations } = useQuery(
    queryCache.locations,
    () => ApiClient.locations.getLocations(),
    {
      placeholderData: [],
    },
  )
  const isAdmin = props.style === 'admin'
  const ringColor = props.error ? 'ring-red ring-opacity-50' : (isAdmin ? 'ring-primary ring-opacity-8' : 'ring-input-border ring-opacity-100')

  return (
    <div className="relative h-full">
      <Listbox
        onChange={props.onChange}
        value={props.value}
      >
        <Listbox.Button
          className={`whitespace-nowrap appearance-none h-full ${props.buttonClassName} w-full px-10 text-left ${isAdmin ? 'rounded-8 ring-2 focus:ring focus:ring-opacity-50' : 'ring focus:outline-none focus:ring-primary rounded-4'} ${props.value ? 'text-grey-800' : 'text-placeholder'} ${ringColor} focus:outline-none transition`}>
          {props.value ? '+ ' + props.value : 'Calling Code'}
        </Listbox.Button>
        <Listbox.Options className={`z-[999] ${props.className} absolute h-300 w-[400px] focus:outline-none overflow-auto bg-white top-60 ${isAdmin ? 'rounded-8 ring-2 ring-primary ring-opacity-8' : 'border border-grey-300 focus:ring focus:ring-primary focus:ring-opacity-50'} `}>
          {locations.map((location) => (
            <Listbox.Option
              key={location.id}
              className={({ active }) =>
                classNames(
                  active ? 'text-primary bg-primary-light' : 'text-grey',
                  'cursor-default select-none relative py-2 pl-3 pr-9',
                )
              }
              value={location.phone_code}
            >
              {({ selected, active }) => (
                <>
                  <div className="flex flex-row-reverse items-center justify-end gap-10 px-10 py-5">
                    <span
                      className={classNames(selected ? 'font-semibold text-primary' : 'font-normal', 'ml-3 block truncate')}
                    >
                      {location.name} (+{countries[location.code].phone})
                    </span>
                    <span>{countries[location.code].emoji}</span>
                  </div>

                  {selected
                    ? (
                      <span
                        className={classNames(
                          active ? 'text-primary' : 'text-grey',
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                        )}
                      >
                        <CheckIcon className="pr-5 h-15 w-15" aria-hidden="true" />
                      </span>
                    )
                    : null}
                </>
              )}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Listbox>
      <div className="text-red text-14">
        {props.error?.message}
      </div>
    </div>
  )
}

CountryCodeSelect.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any,
  error: PropTypes.object,
  style: PropTypes.string,
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
}

export default CountryCodeSelect
