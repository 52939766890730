import React from 'react'
import PropTypes from 'prop-types'
import Link from 'components/Link'

const PublicNavbarLink = ({ children, href, about, onClick }) => {
  const classNames = 'text-14 uppercase font-bold tracking-[1.5px] text-grey-800 whitespace-nowrap'

  if (href) {
    return (
      <Link href={href} about={about} className={classNames}>
        {children}
      </Link>
    )
  } else if (onClick) {
    return (
      <button onClick={onClick} className={classNames}>
        {children}
      </button>
    )
  }

  return <span className={classNames}>
    {children}
  </span>
}

PublicNavbarLink.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string,
  about: PropTypes.string,
  onClick: PropTypes.func,
}

export default PublicNavbarLink
