import Link from 'components/Link'
import PropTypes from 'prop-types'
import { findByKey } from 'data/helpers/array'
import { joinBy } from '@ama-selections/ui'

const DestinationModalItem = ({
  parent,
  childLocationKey,
}) => {
  return (
    <div className="flex flex-col gap-y-8 pb-[28px] w-full">
      <Link
        className="text-grey-900/90 hover:underline max-w-max"
        href={`/${parent?.slug}`}
      >
        {parent?.value}
      </Link>
      {
        findByKey(parent, childLocationKey)?.map(regionOrSubregion => (
          <Link
            key={`destination-child-${regionOrSubregion?.id}`}
            href={'/' + joinBy([
              parent?.country?.slug,
              parent?.slug,
              regionOrSubregion?.slug,
            ], '/')}
            className="text-grey-650/90 hover:underline max-w-max"
          >
            {regionOrSubregion?.value}
          </Link>
        ))
      }
    </div>
  )
}

DestinationModalItem.propTypes = {
  parent: PropTypes.object,
  childLocationKey: PropTypes.string,
}

export default DestinationModalItem
