import DashboardModal from 'components/DashboardModal'
import { formatDateRangeReadable } from 'data/helpers/dates'
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Form from 'components/form'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import ApiClient from 'data/api/api_client'
import { LoadingIcon } from 'components/icons'
import { useForm } from 'react-hook-form'
import { groupBy } from 'data/helpers/array'
import { formatEnum, validateBoolean } from 'data/helpers/strings'
import Button from 'components/Button'
import { useNotifier } from 'react-headless-notifier'
import Notification from 'components/Notification'
import useErrorHandler from 'data/hooks/useErrorHandler'
import ConfirmModal from 'components/modals/ConfirmModal'
import { useSelector } from 'react-redux'

const DashboardSendToPartnerModal = ({
  isOpen = false,
  onClose = () => { },
  serviceRequest,
}) => {
  const { notify } = useNotifier()
  const queryClient = useQueryClient()
  const handleError = useErrorHandler()
  const user = useSelector(state => state.auth.user)

  const { register, handleSubmit, formState: { errors }, setError, clearErrors, watch, setValue } = useForm()

  const [partners, setPartners] = useState([])

  const sendMutation = useMutation(
    (details) => ApiClient.serviceRequests.sendToPartner(serviceRequest?.id, details),
    {
      onSuccess: () => {
        notify(
          <Notification type="success" title="Message sent to Service Provider Contact" />,
        )
        queryClient.invalidateQueries('serviceRequests')
        onClose()
      },
      onError: (error) => {
        clearErrors()
        handleError(error, setError)
        notify(
          <Notification type="error" title="Issue sending message to Service Provider Contact" />,
        )
      },
    },
  )

  const { data: partnersData, isLoading: isPartnersLoading } = useQuery(
    ['partners'],
    () => ApiClient.users.getUsers({ types: ['owner_partner'], status: 1 }, { paginated: false }, { sort_by: 'first_name', direction: 'asc' }),
    {
      select: (data) => data?.users,
      onSuccess: (data) => {
        setPartners(groupBy(data ?? [], 'sub_type'))
        setValue('partner_id', data?.[0]?.id)
      },
    },
  )

  useEffect(() => {
    setValue('contact_id',
      partnersData
        ?.find(partner => partner?.id == watch('partner_id'))
        ?.team_contacts
        ?.filter(contact => validateBoolean(contact?.active))
        ?.[0]?.id
      ?? null,
    )

    clearErrors('contact_id')
  }, [watch('partner_id')])

  useEffect(() => {
    if (watch('partner_id') && watch('contact_id') && serviceRequest) {
      let teamContact = partnersData
        ?.find(partner => partner?.id == watch('partner_id'))
        ?.team_contacts
        ?.find(contact => contact?.id == watch('contact_id'))

      let booking = serviceRequest?.booking

      let message = `Dear ${teamContact?.first_name} <br /><br />`
        + 'I hope you are well!<br /><br />'
        + `We have a new request for additional services from our guest ${booking?.client?.first_name} ${booking?.client?.last_name} staying at ${booking?.event?.property?.title} `
        + `from ${formatDateRangeReadable(booking?.event?.start_date, booking?.event?.end_date)}. Please see the request below - can you please send me a quote asap? Thanks!<br /><br />`
        + '-'

      serviceRequest?.details
        ?.filter(item => item?.question && item?.answer)
        ?.map((item) => {
          message = message + `<br /><b>${item?.question}</b><br />${item?.answer}<br />`
        })

      message = message
        + '-<br /><br />'
        + 'I look forward to hearing back from you soon!<br /><br />'
        + `Sincerely,<br />${user?.first_name}`

      setValue('message', message)
    }
  }, [watch('contact_id')])

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)

  return (
    <DashboardModal
      title={`Send sevice request ${serviceRequest?.request_id} to Partner`}
      isOpen={isOpen}
      onClose={() => onClose()}
      size="6xl"
    >
      <form
        onSubmit={handleSubmit(sendMutation.mutate)}
        className="flex flex-col gap-20"
      >
        {isPartnersLoading
          ? <LoadingIcon className="mx-auto h-50" />
          : <div className="flex gap-20">
            <Form.Field>
              <Form.Select
                label="Partner"
                {...register('partner_id')}
                error={errors?.partner_id?.message}
                textColour="text-grey-800"
              >
                {Object.values(partners)?.map((partnersOfType, i) => (
                  <optgroup
                    key={i}
                    className="capitalize"
                    label={formatEnum(Object.keys(partners)?.[i])}
                  >
                    {partnersOfType?.map((partner, y) => (
                      <Form.Option
                        key={y}
                        value={partner?.id}
                      >
                        {partner?.first_name} {partner?.last_name}
                      </Form.Option>
                    ))}
                  </optgroup>
                ))}
              </Form.Select>
            </Form.Field>

            <Form.Field>
              <Form.Select
                label="Contact"
                {...register('contact_id')}
                error={errors?.contact_id?.message}
                textColour="text-grey-800"
              >
                {partnersData && partnersData
                  ?.find(partner => partner?.id == watch('partner_id'))
                  ?.team_contacts
                  ?.filter(contact => validateBoolean(contact?.active))
                  ?.map((contact, index) => (
                    <Form.Option
                      key={index}
                      value={contact?.id}
                      disabled={!contact?.email}
                    >
                      {contact?.first_name ?? 'No name'} - {contact?.email ?? 'No email'}
                    </Form.Option>
                  ))}
              </Form.Select>
            </Form.Field>
          </div>
        }

        <Form.Field className="border-3 border-primary border-opacity-8 rounded-8">
          <Form.HtmlEditor
            onChange={v => setValue('message', v)}
            value={watch('message')}
            error={errors?.message?.message}
          />
        </Form.Field>

        <div className="ml-auto">
          <Button
            className="!capitalize !font-normal h-50"
            onClick={() => setIsConfirmModalOpen(true)}
            isLoading={sendMutation.isLoading}
          >
            Send
          </Button>

          <ConfirmModal
            isOpen={isConfirmModalOpen}
            onClose={() => setIsConfirmModalOpen(false)}
            title="Send to Contact?"
            message={'Are you sure you would like to send this message to '
              + partnersData
                ?.find(partner => partner?.id == watch('partner_id'))
                ?.team_contacts
                ?.find(contact => contact?.id == watch('contact_id'))
                ?.email
            }
            variant="primary"
            action={handleSubmit(sendMutation.mutate)}
            isLoading={sendMutation.isLoading}
          />
        </div>
      </form>
    </DashboardModal>
  )
}

export default DashboardSendToPartnerModal

DashboardSendToPartnerModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  serviceRequest: PropTypes.object,
}
