export default class FilesApiClient {
  constructor (http) {
    this.http = http
  }

  /**
   *
   * @param {File} file The file to upload
   * @param {string} details.property_id The property id to attach this file to
   * @param {string} details.service_id The service id to attach this file to
   * @param {boolean} [fullResource=false] If true, the full resource will be returned
   * @param {number} [order=null] The order of the file
   * @returns
   */
  async uploadFile (file, details, fullResource = false, order = null) {
    const data = new FormData()
    data.append('file', file)

    if (details != undefined) {
      data.append('model_id', details.id)
      data.append('model_type', details.type)
    }

    if (order != null) {
      data.append('order', order)
    }

    const result = await this.http.post('/files', data)

    if (fullResource) {
      return result.data.data
    } else {
      return result.data.data.id
    }
  }

  async deleteFile (id, temporary = false) {
    await this.http.delete(`/files/${id}`, {
      data: {
        temporary: temporary,
      },
    })
  }
}
