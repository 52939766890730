import React from 'react'
import { AddCircleIconLarge, MinusCircleIconLarge } from 'components/icons'
import { FieldError } from 'react-hook-form'
import classNames from 'classnames'

export interface NumberInputPropTypes extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'onChange'|'dangerouslySetInnerHTML'|'className'> {
  value?: number
  onChange: (value?: number) => void
  placeholder?: string
  label?: string
  sublabel?: string
  min?: number
  max?: number
  text?: {
    singular: string
    plural: string
  }
  error?: FieldError
  className?: {
    container?: string
    header?: {
      container?: string
      label?: string
      error?: string
    },
    content?: {
      container?: string
      button?: string
      text?: string
    }
  }
}

const NumberInput = ({
  value,
  onChange,
  placeholder,
  label,
  sublabel,
  min = 0,
  max,
  text,
  error,
  className,
  ...props
}: NumberInputPropTypes) => {
  const minusDisabled = min !== undefined ? (value ?? 0) <= min : false
  const addDisabled = max !== undefined ? (value ?? 0) >= max : false

  const handleMinus = () => {
    if (minusDisabled) {return}

    const newValue = (value ?? 0) - 1

    if (min && newValue < min) {
      return
    }
    onChange(newValue)
  }

  const handleAdd = () => {
    if (addDisabled) {return}

    const newValue = (value ?? 0) + 1

    if (max && newValue > max) {
      return
    }
    onChange(newValue)
  }

  const getClassNames = (disabled: boolean) => {
    return classNames(
      'rounded-full focus:outline-none focus:ring-2 focus:ring-primary focus:ring-opacity-30 text-26 lg:text-22 stroke-1',
      {
        'text-grey-250': disabled,
        'text-primary hover:bg-primary hover:text-white': !disabled,
      },
      className?.content?.button,
    )
  }

  return (
    <div className={classNames('flex w-full', className?.container)}>
      {label
        && <div className={classNames('flex flex-col flex-1', className?.header?.container)}>
          { label && (
            <span className={classNames(
              'text-14 text-grey-900',
              {
                'text-red font-bold': error,
              },
              className?.header?.label,
            )}>
              {label}
            </span>
          )}

          { sublabel && (
            <p className={classNames(
              'text-grey-400 text-12 leading-[13px]',
              {
                'text-red': error,
              },
              className?.header?.error,
            )}>
              {error ? error.message : sublabel}
            </p>
          )}
        </div>
      }

      <div className={classNames('flex items-center gap-20', className?.content?.container)}>
        <button
          type="button"
          onClick={handleMinus}
          className={getClassNames(minusDisabled)}
          {...props}
        >
          <MinusCircleIconLarge className="rounded-full" />
        </button>

        <p className={classNames(
          {
            'text-placeholder': value === undefined,
            'text-grey-800': value !== undefined,
            'text-red': error,
          },
          className?.content?.text,
        )}>
          {value !== undefined
            ? (
              text
                ? `${value} ${value === 1 ? text.singular : text.plural}`
                : value
            )
            : placeholder
          }
        </p>

        <button
          type="button"
          onClick={handleAdd}
          className={getClassNames(addDisabled)}
          {...props}
        >
          <AddCircleIconLarge className="rounded-full" />
        </button>
      </div>
    </div>
  )
}

export default NumberInput
