import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from 'react-query'
import { useNotifier } from 'react-headless-notifier'
import ApiClient from 'data/api/api_client'
import Notification from 'components/Notification'
import ConfirmPublicModal from 'components/modals/ConfirmPublicModal'
import { useDispatch } from 'react-redux'
import { setSharedUsersAndInvites } from 'store/booking-shared-users'
import GuestDisclaimer from 'components/modals/bookings/shared/Disclaimer'
import PublicMobileModal from 'components/PublicMobileModal'
import Button from 'components/home/common/Button'
import { ModalProps } from 'data/types/modal'
import Input from 'components/form/Home/Input'
import { AxiosError } from 'axios'

interface UserTripServiceShareModalProps extends ModalProps {
  booking: any
}

export interface UserTripServiceShareModalFormFields {
  confirm_share: boolean
  name: string
  email: string
}

const UserTripServiceShareModal = ({
  booking,
  isOpen,
  onClose,
  ...modalProps
}: UserTripServiceShareModalProps) => {
  const dispatch = useDispatch()
  const { notify } = useNotifier()
  const queryClient = useQueryClient()

  const [confirmModalOpen, setConfirmModalOpen] = useState(false)

  const { handleSubmit, register, setValue, reset, formState: { errors }, getValues } = useForm<UserTripServiceShareModalFormFields>({
    defaultValues: {
      confirm_share: false,
    },
  })

  const handleSendClick = () => {
    setConfirmModalOpen(true)
    onClose()
  }

  const inviteUser = (values: UserTripServiceShareModalFormFields) => {
    if (values.confirm_share) {
      handleSendClick()
      return
    }

    mutate(values)
  }

  const { isLoading, error, mutate } = useMutation(
    (details: UserTripServiceShareModalFormFields) => ApiClient.bookings.inviteUser(details, booking.id),
    {
      onSuccess: (response) => {
        dispatch(setSharedUsersAndInvites(response))
        queryClient.invalidateQueries(['bookings'])
        notify(
          <Notification message="User invite sent" type="success" />,
        )
        reset()
        onClose()
      },
      onError: (error: AxiosError) => {
        if (error.response?.status !== 422) {
          notify(
            <Notification message="There was an issue inviting this user, please try again" type="error" />,
          )
        } else {
          handleSendClick()
        }
      },
    },
  )
  return (
    <>
      <PublicMobileModal
        isOpen={isOpen}
        onClose={onClose}
        className={{
          panel: 'flex flex-col px-24 pb-safe-offset-18',
        }}
        title="Invite Guests"
        {...modalProps}
      >
        <GuestDisclaimer
          className={{
            text: 'text-center px-30 py-14',
          }}
        >
          Invited guests will be able to view your trip itinerary but won’t be able to request services. All price and payment information can only be viewed by you.
        </GuestDisclaimer>

        <form onSubmit={handleSubmit(inviteUser)}>
          <Input
            {...register('name')}
            type="text"
            className={{
              container: 'mb-10',
            }}
            error={errors?.name?.message}
            variant="grey"
            label="Name"
            required
          />

          <Input
            {...register('email')}
            type="email"
            className={{
              container: 'mb-10',
            }}
            error={errors?.email?.message}
            variant="grey"
            label="Email"
            required
          />

          <hr className="my-40 border-none" />

          <div className="flex justify-center">
            <Button
              type="submit"
              className={{
                button: 'max-w-[340px] w-full',
              }}
              style="modal"
              isLoading={isLoading && !error}
            >
              SEND INVITE
            </Button>
          </div>
        </form>

      </PublicMobileModal>

      <ConfirmPublicModal
        isOpen={confirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
        title="Send confirmation message"
        message="Did you want to share your link to this user again?"
        action={() => {
          setValue('confirm_share', true)
          inviteUser(getValues())
        }}
      />
    </>
  )
}

export default UserTripServiceShareModal
