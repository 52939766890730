import { useEffect } from 'react'
import PublicMobileModal from 'components/PublicMobileModal'
import AuthSection from 'components/auth/AuthSection'
import { ModalProps } from 'data/types/modal'
import useAuth from 'data/hooks/User/useAuth'
import AuthToAccessSection from 'components/auth/AuthToAccessSection'
import { RestUser } from 'data/types/user'

interface CenteredCustomerLoginModalProps extends Omit<ModalProps, 'onClose'> {
  onClose: (User?: RestUser) => void
}

const CenteredCustomerLoginModal = ({
  isOpen,
  onClose,
}: CenteredCustomerLoginModalProps) => {
  const { user } = useAuth()

  useEffect(() => {
    if (user && isOpen) {
      onClose(user)
    }
  }, [user])

  return (
    <PublicMobileModal
      isOpen={isOpen}
      onClose={onClose}
      className={{
        panel: 'lg:!h-full !max-h-[765px]',
      }}
    >
      <AuthSection
        showLogo
        placeholderPanel={<AuthToAccessSection />}
        buttonStyle="modal"
        initialSlide="login"
      />
    </PublicMobileModal>
  )
}

export default CenteredCustomerLoginModal
