import React, { forwardRef } from 'react'
import classNames from 'classnames'
import { ReactChild } from 'data/types/types'

interface SelectPropTypes extends Omit<React.SelectHTMLAttributes<HTMLSelectElement>, 'dangerouslySetInnerHTML'|'style'|'children'> {
  error?: string
  label?: string
  accentIcon?: ReactChild
  style?: 'admin' | 'customer'
  subClassName?: string
  textColour?: string
  children: ReactChild
}

const Select = forwardRef<HTMLSelectElement, SelectPropTypes>(function select ({
  label,
  accentIcon,
  onChange = () => { },
  children,
  className = '',
  subClassName,
  name,
  error,
  value,
  style = 'admin',
  textColour = 'text-placeholder',
  disabled = false,
  ...props
}, ref) {
  const isAdmin = style === 'admin'

  return (
    <div className={`w-full ${className}`}>
      <div className="relative">
        <select
          name={name}
          value={value}
          className={classNames(
            'appearance-none h-50 w-full px-25 border-none focus:outline-none transition',
            {
              'pt-15': label,

              'rounded-6 ring-2': isAdmin,
              'rounded-4 ring-1': !isAdmin,

              'ring-red ring-opacity-50': error,
              'ring-primary focus:ring-primary ring-opacity-8 focus:ring-opacity-20': !error && isAdmin,
              'ring-input-border focus:ring-primary ring-opacity-100': !error && !isAdmin,

              'bg-grey-100': disabled && isAdmin,
            },
            subClassName,
            textColour,
          )}
          onChange={onChange}
          ref={ref}
          disabled={disabled}
          {...props}
        >
          {children}
        </select>

        {
          label
            ? <div className="absolute text-12 top-5 left-25">{label}</div>
            : null
        }

        {
          accentIcon
            ? <span className="absolute text-14 top-3 left-5">{accentIcon}</span>
            : null
        }
      </div>

      {
        error
          ? <span className="text-red text-14">{error}</span>
          : null
      }
    </div>
  )
})

export default Select
