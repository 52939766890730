import { Disclosure } from '@headlessui/react'
import { ReactTag } from '@headlessui/react/dist/types'
import classNames from 'classnames'
import { ChevronDownIcon, ExclaimCircleIcon } from 'components/icons'
import { ReactChild } from 'data/types/types'
import { Fragment } from 'react'

const AccordionItem = ({
  title,
  children,
  style = {},
  defaultOpen = false,
  error = false,
}: {
  title: string,
  children: ReactChild | null,
  style?: {
    container?: string,
    items?: string,
  },
  defaultOpen?: boolean,
  error?: boolean,
}) => {
  return (
    <Disclosure
      as={Fragment as ReactTag}
      defaultOpen={defaultOpen}
    >
      {({ open }) => (
        <>
          <Disclosure.Button className={classNames(
            'flex justify-between text-left gap-10 leading-26 w-full border-t sm:border-t-0 sm:border-b border-grey-750 border-opacity-20 focus:outline-none pt-15 lg:py-15',
            style.container,
          )}>
            <span className="inline-flex items-center gap-10">
              {error
                && <ExclaimCircleIcon className="text-red" />
              }
              {title}
            </span>
            <span className={classNames('my-auto transition transform', {
              'rotate-180': open,
            },
            )}>
              <ChevronDownIcon />
            </span>
          </Disclosure.Button>
          <Disclosure.Panel className={classNames(
            'py-20 pl-25 sm:text-grey-750 sm:opacity-90',
            style.items,
          )}>
            {children}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}

export default AccordionItem
