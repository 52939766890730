import React from 'react'
import { Controller, FieldError } from 'react-hook-form'
import NumberRating from './NumberRating'

type PropTypes = {
    options: {value: string | number}[]
    control: any
    disabled: boolean
    name: string
    error: FieldError | undefined
    optionDescriptions?: {[key: string | number]: string}
}

const ControlledNumberRating: React.FC<PropTypes> = ({
  options,
  optionDescriptions,
  control,
  name,
  error,
  disabled,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value: selectedValue } }) => (
        <NumberRating
          disabled={disabled}
          options={options}
          optionDescriptions={optionDescriptions}
          selectedValue={selectedValue}
          error={error}
          onChange={onChange}
          onBlur={onBlur}
        />
      )}
    />
  )
}

export default ControlledNumberRating
