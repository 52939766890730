import { Control, Controller, FieldError, FieldValues, Path } from 'react-hook-form'
import DatePicker from './DatePicker'
import { DayPickerInputProps } from 'react-day-picker'
import { formatDateReadableDatePicker } from '@ama-selections/ui'

interface ControlledDatePickerProps<T extends FieldValues> extends DayPickerInputProps {
  name: Path<T>
  control: Control<T>
  label?: string
  disabled?: boolean
  error: FieldError | undefined
}

export default function ControlledDatePicker<T extends FieldValues> ({
  name,
  control,
  label,
  placeholder,
  disabled,
  error,
  ...props
}: ControlledDatePickerProps<T>) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <DatePicker
          label={label}
          placeholder={placeholder}
          value={value}
          onDayChange={onChange}
          formatDate={formatDateReadableDatePicker}
          inputProps={{
            disabled: disabled,
            ...props.inputProps,
          }}
          dayPickerProps={{
            ...(value ? { selectedDays: value } : {}),
            ...props.dayPickerProps,
          }}
          error={error}
          {...props}
        />
      )}
    />
  )
}
