import { createSlice } from '@reduxjs/toolkit'

export const bookingPaymentDeductionsSlice = createSlice({
  name: 'bookingPaymentDeductions',
  initialState: {
    deductions: [],
    deposit_refunded_at: null,
    is_deposit_refunded: false,
  },
  reducers: {
    initialiseDeductions: (state, action) => {
      state.deductions = action.payload.deductions
    },
    updateDeductions: (state, action) => {
      state.deductions = JSON.parse(JSON.stringify(action.payload))
    },
    updateDeduction: (state, action) => {
      state.deductions[state.deductions?.findIndex(deduction => deduction?.id === action.payload?.id)] = action.payload
    },
    setDepositRefundedAt: (state, action) => {
      state.deposit_refunded_at = action.payload
    },
    setIsDepositRefunded: (state, action) => {
      state.is_deposit_refunded = action.payload
    },

  },
})

export const { initialiseDeductions, updateDeductions, updateDeduction, setIsDepositRefunded, setDepositRefundedAt } = bookingPaymentDeductionsSlice.actions

export default bookingPaymentDeductionsSlice.reducer
