import { useState, useEffect } from 'react'
import LogoLink from 'components/logoLink'
import ApiClient from 'data/api/api_client'
import { useMutation, useQuery } from 'react-query'
import BookingSelectListbox from 'components/form/BookingSelectListbox'
import { useSelector } from 'react-redux'
import { useNotifier } from 'react-headless-notifier'
import Notification from 'components/Notification'
import { Widget } from '@typeform/embed-react'
import { BookingSearch } from 'data/enums/booking-search-filters'
import { RootState } from 'store'
import PublicMobileModal from 'components/PublicMobileModal'
import Button from 'components/home/common/Button'
import classNames from 'classnames'
import { GeneralService } from 'data/types/services'
import { Booking } from 'data/types/bookings'
import { RestBooking } from 'data/types/booking'
import { ModalProps } from 'data/types/modal'

export interface ServiceRequestModalProps extends ModalProps {
  service?: Pick<GeneralService, 'id' | 'form_link' | 'survey_slug'>
  booking?: RestBooking | null
}

const ServiceRequestModal = ({
  service,
  booking = null,
  isOpen,
  onClose = () => { },
  ...modalProps
}: ServiceRequestModalProps) => {
  const user = useSelector((state: RootState) => state.auth.user)
  const { notify } = useNotifier()
  const [selectedBooking, setSelectedBooking] = useState<Booking | RestBooking | null>(null)

  const [firstPage, setFirstPage] = useState(true)

  const { data: bookings, refetch } = useQuery(['user-bookings'],
    () => ApiClient.users.getUserBookings({
      includes: [
        'property.destinationRegion',
        'property.destinationCity',
      ],
      search_filter: BookingSearch.UPCOMING,
      service_id: service?.id,
    }),
    {
      enabled: isOpen && !!user,
      keepPreviousData: true,
    },
  )

  useEffect(() => {
    if (booking) {
      setSelectedBooking(booking)
      setFirstPage(false)
    }
  }, [booking, bookings])

  const serviceRequestMutation = useMutation(
    (details: { responseId: string }) => ApiClient.services.requestService(service?.id, selectedBooking?.id, {
      response_id: details.responseId,
    }),
    {
      onSuccess: () => {
        notify(
          <Notification message="Your request has been successfully sent!" type="success" />,
        )
      },
      onError: () => {
        notify(
          <Notification message="There was a problem sending your request, please get in contact" type="error" />,
        )
      },
    },
  )

  const getFormID = () => {
    const formLink = service?.form_link ?? ''

    if (formLink?.includes('.typeform.com/to/')) {
      // https://form.typeform.com/to/<form-id> gets the <form-id>
      const parts = formLink.split('/')
      return parts.pop() as string
    } else if (formLink?.includes('https://admin.typeform.com/')) {
      // https://admin.typeform.com/form/<form-id>/* gets the <form-id>
      const parts = formLink.split('/')
      return parts[parts.length - 2]
    } else {
      return formLink
    }
  }

  useEffect(() => {
    if (service) {
      if (user) {
        refetch()
      }
    }
  }, [user, isOpen])

  return (
    <PublicMobileModal
      isOpen={isOpen}
      onClose={onClose}
      {...modalProps}
      className={{
        panel: classNames({
          'px-25 pb-safe-offset-20': firstPage, // first page, no booking
          'max-h-[90vh] !h-full md:max-w-[90vw] pt-0 !px-0': !firstPage, // second page and booking
        }),
      }}
    >
      {firstPage && !booking
        ? <div className="flex flex-col items-center my-auto">
          <LogoLink className="max-w-[130px] w-full mb-35 mt-90" />
          <p className="mb-10 font-bold text-16 leading-24 -tracking-1/2">Which booking is this request for?</p>
          <p className="font-normal text-center text-grey-500 text-14 leading-24 -tracking-1/2 mb-50">
            This request will be sent directly to your dedicated concierge who will follow up with you if needed. Once confirmed, your trip itinerary will be automatically updated.
          </p>

          <BookingSelectListbox
            bookings={bookings?.data}
            selectedBooking={selectedBooking}
            setSelectedBooking={setSelectedBooking}
            emptyStateText={bookings?.meta && bookings?.meta?.booking_count > 0
              ? 'This isn\'t available in the location you booked'
              : 'You must have a booking to send this request'
            }
          />

          <div className="w-full px-2 mt-90 md:mt-[140px]">
            <Button
              {...service?.survey_slug
                ? { href: `/home/reservations/${selectedBooking?.id}/service-request/${service.survey_slug}` }
                : { onClick: () => setFirstPage(false) }
              }
              disabled={!selectedBooking}
              style="modal"
              block
            >
              NEXT
            </Button>
          </div>
        </div>
        : <>
          {isOpen && (
            <div className="w-full h-full max-h-screen-safe rounded-10 md:max-h-full">
              <div className="h-55 rounded-t-10 lg:hidden" />

              <div className="h-[calc(100%-55px)] lg:h-full">
                <Widget
                  id={getFormID()}
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                  hidden={{
                    ...(user && { email: user?.email }),
                    ...(selectedBooking && { booking_id: selectedBooking?.id.toString() }),
                  }}
                  onSubmit={(event) => {
                    serviceRequestMutation.mutate(event)
                    onClose()
                  }}
                  keepSession={false}
                />
              </div>
            </div>
          )}
        </>
      }

    </PublicMobileModal>
  )
}

export default ServiceRequestModal
