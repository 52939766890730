import { graphql } from 'gql'

export const CREATE_WISHLIST_MUTATION = graphql(`
  mutation createWishlist($input: CreateWishlistInput!) {
    createWishlist(input: $input) {
      id
      title
    }
  }  
`)

export const ADD_PROPERTY_TO_WISHLIST_MUTATION = graphql(`
  mutation addToWishlist($input: AddToWishlistInput!) {
    addToWishlist(input: $input) {
      id
    }
  }
`)
