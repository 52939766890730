import React from 'react'
import NumberRatingItem from './NumberRatingItem'
import { FieldError, Noop } from 'react-hook-form'
import { ArrowRightIcon } from 'components/icons'
import Error from 'components/form/Error'

type PropTypes = {
    disabled: boolean
    options: {value: string | number}[]
    optionDescriptions?: {[key: string | number]: string}
    selectedValue: string | number
    error: FieldError | undefined
    onChange: (value: number | string) => void
    onBlur: Noop
}

const NumberRating: React.FC<PropTypes> = ({
  disabled,
  options,
  optionDescriptions,
  selectedValue,
  error,
  onBlur,
  onChange,
}) => {
  return (
    <div className="flex flex-col gap-21">
      <div className="flex flex-col-reverse gap-20 md:flex-col">
        <div className="grid grid-cols-6 sm:flex flex-wrap justify-center sm:justify-evenly gap-[6px] sm:w-full">
          {options.map((option) => (
            <NumberRatingItem
              disabled={disabled}
              key={option.value}
              checked={option.value === selectedValue}
              value={option.value}
              onBlur={onBlur}
              onChange={onChange}
            />
          ))}
        </div>
        {!!optionDescriptions && (
          <div className="flex flex-col justify-between md:flex-row">
            {Object.entries(optionDescriptions ?? []).map(([key, value]) => (
              <div key={key} className="flex items-center gap-4 text-grey-750">
                <span className="md:hidden">{key}</span>
                <ArrowRightIcon className="md:hidden text-14"/>
                {value}
              </div>
            ))}
          </div>
        )}
      </div>
      <Error error={error?.message}/>
    </div>
  )
}

export default NumberRating
