import React from 'react'
import { ArrowRightIcon } from 'components/icons'
import Button, { ButtonProps } from 'components/home/common/Button'
import classNames from 'classnames'

const ServiceRequestNextButton = ({
  isLoading,
  disabled,
  className,
}: {
  isLoading?: boolean,
  disabled?: boolean,
  className?: ButtonProps['className']
}) => {
  return (
    <Button
      variant="primary"
      icon={<ArrowRightIcon className="mt-2 text-18" />}
      className={{
        ...className,
        button: classNames('max-w-[150px] lg:ml-40 mt-auto lg:mt-0 hidden lg:flex h-50', className?.button),
      }}
      block
      type="submit"
      isLoading={isLoading}
      disabled={disabled}
    >
      Next
    </Button>
  )
}

export default ServiceRequestNextButton
