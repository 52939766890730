import { Listbox, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { CheckIcon, ChevronDownFlaredIcon } from 'components/icons'
import { FieldError } from 'react-hook-form'
import Error from 'components/form/Error'
import { countries } from 'countries-list'
import React, { Fragment } from 'react'
import { useQuery } from 'react-query'
import queryCache from 'data/queryCache'
import ApiClient from 'data/api/api_client'
import { PhoneLocation } from 'data/api/locations_api_client'
import { ReactTag } from '@headlessui/react/dist/types'

export default function CountryCodeSelect ({
  label,
  value = '',
  placeholder,
  onChange,
  disabled,
  error,
}: {
    label?: string
    value?: string
    placeholder: string
    onChange: (...event: any[]) => void
    disabled?: boolean
    error?: FieldError
}) {

  const { data: locations } = useQuery(
    queryCache.locations,
    () => ApiClient.locations.getLocations(),
    {
      placeholderData: [],
    },
  )


  return (
    <div className="flex flex-col gap-21 min-w-[225px]">
      <Listbox
        as={Fragment as ReactTag}
        value={value}
        onChange={onChange}
        disabled={disabled}
      >
        {({ open }) => (
          <div className="flex flex-col">
            {
              label && (
                <Listbox.Label className="mb-10 text-14 leading-20 text-grey-800">
                  {label}
                </Listbox.Label>
              )
            }

            <div className="relative">
              <Listbox.Button
                className={classNames(
                  'flex items-center justify-between w-full text-left border rounded-16 p-20 bg-grey-100 focus:outline-none',
                  {
                    'border-red border-opacity-50 focus:border-red': error,
                    'border-grey-100 focus:border-primary focus:border-opacity-50': !error,
                  },
                )}
              >
                <span className={classNames('text-16 leading-20', {
                  'text-placeholder': !value,
                  'text-grey-800': value,
                })}>
                  {
                    value
                      ? `+${value}`
                      : placeholder
                  }
                </span>
                <ChevronDownFlaredIcon className={classNames('text-grey-800 text-18 transition', {
                  'transform rotate-180': open,
                })}/>
              </Listbox.Button>

              <Transition
                show={open}
                as="div"
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 flex flex-col w-full border cursor-pointer bg-grey-100 rounded-16 border-primary-fresh-light focus:outline-none max-h-[150px] overflow-x-auto">
                  {
                    locations?.map((location: PhoneLocation, index) => {
                      const country = countries[location.code as keyof typeof countries]

                      return (
                        <Listbox.Option
                          key={`listbox-option-${index}`}
                          value={country.phone}
                        >
                          {({ active, selected }) => (
                            <>
                              <div className="flex flex-row-reverse items-center justify-end gap-10 px-10 py-5">
                                <span
                                  className={classNames(selected ? 'font-semibold text-primary' : 'font-normal', 'ml-3 block truncate')}
                                >
                                  {location.name} (+{country.phone})
                                </span>
                                <span>{country.emoji}</span>
                              </div>

                              {selected
                                ? (
                                  <span
                                    className={classNames(
                                      active ? 'text-primary-fresh' : 'text-grey-750',
                                      'absolute inset-y-0 right-0 flex items-center pr-4',
                                    )}
                                  >
                                    <CheckIcon className="pr-5 h-15 w-15" aria-hidden="true" />
                                  </span>
                                )
                                : null}
                            </>
                          )}
                        </Listbox.Option>
                      )
                    })}
                </Listbox.Options>
              </Transition>
            </div>
          </div>
        )}
      </Listbox>

      <Error className="-mt-10" error={error?.message}/>
    </div>
  )
}
