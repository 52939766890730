import React from 'react'
import { ImageLoaderProps, ImageProps, default as NextImage } from 'next/image' // eslint-disable-line no-restricted-imports
export type { StaticImageData } from 'next/image' // eslint-disable-line no-restricted-imports

const normaliseSrc = (src: ImageLoaderProps['src']) => {
  return src.startsWith('/') ? src.slice(1) : src
}

const loader = process.env.NODE_ENV === 'production' && process.env.NEXT_PUBLIC_ENABLE_IMAGE_LOADER === 'true'
  ? ({ src, width, quality }: ImageLoaderProps) => {
    const params = [
      `width=${width}`, // specify the width to resize to
      'format=auto',    // serve in avif or webp if possible
      'fit=scale-down',  // prevent upscaling if the image is smaller than the requested size
    ]

    if (quality) {
      params.push(`quality=${quality}`)
    }

    const paramsString = params.join(',')
    return `/cdn-cgi/image/${paramsString}/${normaliseSrc(src)}`
  }
  : ({ src }: ImageLoaderProps) => {
    return src
  }

const Image = (props: ImageProps) => {
  return (
    <NextImage
      loader={loader}
      unoptimized={process.env.NODE_ENV !== 'production'}
      {...props}
    />
  )
}


export default Image
