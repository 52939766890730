import React, { forwardRef } from 'react'
import classNames from 'classnames'
import { FieldError } from 'react-hook-form'
import Label from '../Label'
import Error from '../Error'

interface TextareaPropTypes extends Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'dangerouslySetInnerHTML'|'children'> {
  className?: string
  label?: string
  prefixIcon?: JSX.Element
  suffixIcon?: JSX.Element
  capitalise?: boolean
  error?: FieldError
}

const Textarea = forwardRef<HTMLTextAreaElement, TextareaPropTypes>(function Textarea ({
  className,
  label,
  prefixIcon,
  suffixIcon,
  capitalise = false,
  error,
  ...props
}, ref) {
  return (
    <div className="flex flex-col w-full">
      {
        label && (
          <Label variant="survey">{label}</Label>
        )
      }

      <div className={classNames(
        className,
        'w-full text-16 ring-1 flex items-center bg-grey-100 rounded-16',
        {
          'ring-red ring-opacity-50': error,
          'ring-grey-100': !error,
        },
      )}>
        {
          prefixIcon
            ? <div className="absolute top-0 bottom-0 flex items-center left-21">
              {prefixIcon}
            </div>
            : null
        }

        <textarea
          ref={ref}
          rows={2}
          className={classNames(
            'text-15 text-grey-800 placeholder-grey-500 leading-20 w-full border-none bg-transparent px-20 py-15 focus:outline-none focus:ring focus:ring-primary-fresh focus:ring-opacity-30 rounded-16',
            {
              'pr-50': suffixIcon,
              'pl-50': prefixIcon,
              'capitalize': capitalise,
            },
          )}
          {...props}
        />

        {
          suffixIcon
            ? (
              <div className="absolute top-0 bottom-0 flex items-center right-21 text-grey-800">
                {suffixIcon}
              </div>
            )
            : null
        }
      </div>

      <Error className="mt-10" error={error?.message} />
    </div>
  )
})


export default Textarea
