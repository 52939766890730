import { Switch } from '@headlessui/react'
import classNames from 'classnames'
import { FieldError, Noop } from 'react-hook-form'
import Error from 'components/form/Error'
import Checkbox from './Checkbox'

export default function CheckboxGroup ({
  disabled,
  value,
  onChange,
  options,
  onBlur,
  error,
  isChild,
}: {
  disabled?: boolean,
  value: string[]
  onChange: (...event: any[]) => void
  onBlur: Noop
  options: {
    value: string,
    label?: string,
    component?: JSX.Element,
  }[]
  error: FieldError | undefined,
  isChild?: boolean,
}) {
  return (
    <Switch.Group>
      <div className={classNames(
        'flex flex-col gap-21',
        {
          'md:ml-40': !isChild,
          'ml-20 md:ml-0': isChild,
        },
      )}>
        {
          options.map((option, index) => (
            <Checkbox
              key={`checkbox-group-option-${index}`}
              disabled={disabled}
              value={value}
              onChange={onChange}
              option={option}
              onBlur={onBlur}
            />
          ))
        }
        <Error className="-mt-10" error={error?.message} />
      </div>
    </Switch.Group>
  )
}
