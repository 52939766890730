const useErrorHandlerGraphQl = () => {
  return async (error, setError) => {
    if (error.graphQLErrors) {
      const fields = []

      error.graphQLErrors.forEach((error) => {
        if (error.extensions.validation) {
          Object.entries(error.extensions.validation).forEach(([field, messages]) => {
            let name = field.substring(field.indexOf('.') + 1)

            if (!fields.includes(name)) {
              setError?.call(null, name, { message: messages[0] })
              fields.push(name)
            }
          })
        }
      })
    } else {
      throw error
    }
  }
}

export default useErrorHandlerGraphQl
