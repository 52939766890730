import { useState, useEffect } from 'react'
import CenteredCustomerLoginModal from 'components/modals/getHelp/CenteredCustomerLoginModal'
import ServiceRequestModal, { ServiceRequestModalProps } from 'components/modals/ServiceRequestModal'
import useAuth from 'data/hooks/User/useAuth'

interface ServiceRequestModalWithAuthProps extends ServiceRequestModalProps { }

const ServiceRequestModalWithAuth = ({
  isOpen,
  onClose,
  service,
  booking,
}: ServiceRequestModalWithAuthProps) => {
  const { user } = useAuth()

  const [loginModalOpen, setLoginModalOpen] = useState(false)
  const [serviceModalOpen, setServiceModalOpen] = useState(false)

  useEffect(() => {
    if (isOpen) {
      if (user) {
        setServiceModalOpen(true)
      } else {
        setLoginModalOpen(true)
      }
    }
  }, [isOpen])

  useEffect(() => {
    if (user && !serviceModalOpen) {
      setServiceModalOpen(true)
    } else {
      if (!loginModalOpen) {
        onClose()
      }
    }
  }, [loginModalOpen])

  const serviceModalClose = () => {
    setServiceModalOpen(false)
    onClose()
  }

  if (!isOpen) {
    return null
  }

  return (
    <>
      <CenteredCustomerLoginModal
        isOpen={loginModalOpen}
        onClose={() => setLoginModalOpen(false)}
      />

      <ServiceRequestModal
        isOpen={!!user && serviceModalOpen}
        onClose={() => serviceModalClose()}
        service={service}
        booking={booking}
      />
    </>
  )
}

export default ServiceRequestModalWithAuth
