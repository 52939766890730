import { useRouter } from 'next/router'
import { useMemo } from 'react'

interface CustomURLSearchParams extends URLSearchParams {
  size: number
  exclude: (key: string) => CustomURLSearchParams
}

const useSearchParams = () => {
  const { query } = useRouter()

  // return a new set of params with the specificed key removed
  const exclude = (key: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newParams = new URLSearchParams(query as any) as CustomURLSearchParams
    newParams.delete(key)
    return newParams
  }

  const params = useMemo(() => {
    const params = new URLSearchParams() as CustomURLSearchParams

    for (const [key, value] of Object.entries(query)) {
      if (typeof value === 'string') {
        params.set(key, value)
      }
    }

    params.exclude = exclude

    return params
  }, [query])

  return { params }
}

export { useSearchParams }
