import { useEffect, useState } from 'react'
import { config } from 'data/config'
import { useCookies } from 'react-cookie'

export type EdgeInsets = {
  top: number
  right: number
  bottom: number
  left: number
}

export const useMobileApp = () => {
  const [isMobileApp, setIsMobileApp] = useState<boolean | null>(null)
  const [cookies] = useCookies([config.isMobileAppCookie, 'safeAreaInsets'])

  const [safeAreaInsets, setSafeAreaInsets] = useState<EdgeInsets>({
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  })

  useEffect(() => {
    if (cookies.safeAreaInsets) {
      const [top, right, bottom, left] = cookies.safeAreaInsets.split(',').map(Number)
      setSafeAreaInsets({
        top,
        right,
        bottom,
        left,
      })
    }
  }, [cookies])

  useEffect(() => {
    const mobileAppCookie = cookies?.[config.isMobileAppCookie]

    setIsMobileApp(mobileAppCookie === true)
  }, [cookies])

  return {
    isMobileApp,
    safeAreaInsets,
  }
}
