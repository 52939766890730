import React from 'react'
import { Controller, FieldError } from 'react-hook-form'
import ButtonGroup from './ButtonGroup'

type PropTypes = {
    disabled: boolean
    name: string
    control: any
    options: { value: number | string, label: string }[]
    error: FieldError | undefined
}

const ControlledButtonGroup: React.FC<PropTypes> = ({
  disabled,
  name,
  control,
  options,
  error,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange, onBlur } }) => (
        <ButtonGroup
          disabled={disabled}
          options={options}
          error={error}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
        />
      )}
    />
  )
}

export default ControlledButtonGroup
