enum notificationType {
  INQUIRY_CALLBACK = 'App\\Notifications\\Admin\\CallbackRequest',
  INQUIRY_GENERAL = 'App\\Notifications\\Admin\\GeneralInquiry',
  INQUIRY_PROPERTY = 'App\\Notifications\\Admin\\PropertyInquiry',
  INQUIRY_AGENT_ASSIGNED = 'App\\Notifications\\Admin\\InquiryAssignedBookingAgent',
  SERVICE_REQUEST_USER = 'App\\Notifications\\User\\ServiceRequestToUser',
  SURVEY_BANK_INFO_ADMIN = 'App\\Notifications\\Admin\\SurveyBankInfo',
  SURVEY_BANK_INFO_USER = 'App\\Notifications\\User\\SurveyBankInfoToUser',
  SERVICE_REQUEST_ADMIN = 'App\\Notifications\\Admin\\ServiceRequest',
  TIP_SENT_TO_CONCIERGE = 'App\\Notifications\\Admin\\TipSentToConcierge',
  PAYMENT_RECEIVED_USER = 'App\\Notifications\\User\\PaymentReceived',
}

enum simpleNotificationType {
  INQUIRY_CALLBACK = 'inquiry_callback',
  INQUIRY_GENERAL = 'inquiry_general',
  INQUIRY_PROPERTY = 'inquiry_property',
  INQUIRY_AGENT_ASSIGNED = 'inquiry_agent_assigned',
  SERVICE_REQUEST_USER = 'service_request_user',
  SURVEY_BANK_INFO_ADMIN = 'survey_bank_info_admin',
  SURVEY_BANK_INFO_USER = 'survey_bank_info_user',
  SERVICE_REQUEST_ADMIN = 'service_request_admin',
  TIP_SENT_TO_CONCIERGE = 'tip_sent_to_concierge',
  PAYMENT_RECEIVED_USER = 'payment_received_user',
}

export { notificationType, simpleNotificationType }
