import { createSlice } from '@reduxjs/toolkit'
import { RestUser } from 'data/types/user'
import { VerificationSessionStatus } from 'gql/graphql'

export interface AuthState {
  user: RestUser | null,
  checked: boolean
  loginModalOpen: boolean
  centeredLoginModalOpen: boolean
  registerModalOpen: boolean
  forgotPasswordModalOpen: boolean
  verificationSession: {
    id: string
    status: VerificationSessionStatus
    lastError: {
      code: string
      reason: string
    } | null
    hasPassportUpdated: boolean
  } | null
}

const initialState: AuthState = {
  user: null,
  checked: false,
  loginModalOpen: false,
  centeredLoginModalOpen: false,
  registerModalOpen: false,
  forgotPasswordModalOpen: false,
  verificationSession: null,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload
      state.checked = true
    },
    clearUser: (state) => {
      state.user = null
      state.checked = true
      state.verificationSession = null
    },
    setLoginModalOpen: (state, action) => {
      state.loginModalOpen = action.payload
    },
    setCenteredLoginModalOpen: (state, action) => {
      state.centeredLoginModalOpen = action.payload
    },
    setRegisterModalOpen: (state, action) => {
      state.registerModalOpen = action.payload
    },
    setForgotPasswordModalOpen: (state, action) => {
      state.forgotPasswordModalOpen = action.payload
    },
    setAuthModal: (state, action: { payload: setAuthModalPayload }) => {
      switch (action.payload.modal) {
        case 'login':
          state.loginModalOpen = action.payload.open
          break
        case 'centeredLogin':
          state.centeredLoginModalOpen = action.payload.open
          break
        case 'register':
          state.registerModalOpen = action.payload.open
          break
        case 'forgotPassword':
          state.forgotPasswordModalOpen = action.payload.open
          break
      }
    },
    setVerificationSession: (state, action) => {
      state.verificationSession = action.payload
    },
  },
})

type setAuthModalPayload = {
  modal: 'login' | 'centeredLogin' | 'register' | 'forgotPassword',
  open: boolean
}

export const {
  setUser, clearUser,
  setLoginModalOpen, setCenteredLoginModalOpen, setRegisterModalOpen, setForgotPasswordModalOpen,
  setAuthModal,
  setVerificationSession,
} = authSlice.actions

export default authSlice.reducer
