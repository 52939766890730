import React from 'react'
import { Controller, FieldError } from 'react-hook-form'
import CountryCodeSelect from './CountryCodeSelect'

type PropTypes = {
    name: string
    control: any
    label?: string
    placeholder: string
    disabled?: boolean
    error: FieldError | undefined
}

const ControlledCountryCodeSelect: React.FC<PropTypes> = ({
  name,
  control,
  label,
  placeholder,
  disabled,
  error,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <CountryCodeSelect
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          error={error}
          disabled={disabled}
          label={label}
        />
      )}
    />
  )
}

export default ControlledCountryCodeSelect
