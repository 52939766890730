import { SurveyYesNo } from 'gql/graphql'
import { formatEnum, formatTimeFromTime, joinBy, pluralise } from '@ama-selections/ui'
import { TripScheduleService } from 'data/types/bookings/trip_schedule'

const getPrivateChefServiceDetails = (service: TripScheduleService) => {
  const serviceDetails = service.service_details ?? service.group?.service_details

  if (serviceDetails?.includes('$generate_details') && service.survey?.__typename === 'SurveyPrivateChefMeal') {
    const survey = service?.survey

    const time = joinBy(
      [
        formatTimeFromTime(service.start_time),
        formatTimeFromTime(service.end_time),
      ],
      ' - ',
    )

    let line1 = null
    if (survey?.format) {
      line1 = joinBy(
        [
          survey?.format,
          `(${time})`,
        ],
        ' ',
      )
    }

    const adults = pluralise(survey?.adults, 'adult', 's', '', true)
    const children = survey?.children
      ? pluralise(survey?.children, 'child', 'ren', '', true)
      : null

    const line2 = joinBy(
      [
        adults,
        children,
      ],
      ' + ',
    )

    let line4 = null
    if (survey?.survey?.has_allergies) {
      line4 = survey?.survey?.has_allergies === formatEnum(SurveyYesNo.No.toLowerCase())
        ? 'No allergies'
        : `Allergies: ${survey?.survey?.allergies}`
    }

    let line3 = null
    if (survey?.cuisine) {
      line3 = survey.cuisine
    }

    return serviceDetails.replaceAll(
      '$generate_details',
      joinBy(
        [
          line1,
          line2,
          line3,
          line4,
        ],
        '\n',
      ),
    )
  }

  return serviceDetails
}

export {
  getPrivateChefServiceDetails,
}
