import React, { useState } from 'react'
import PropTypes from 'prop-types'
import placeholderImage from 'assets/images/placeholder.png'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import Image from 'components/Image'
import { getLoadedImagesArray, getNumberArray } from 'data/helpers/carousel'
import { useOnScreen } from 'data/hooks/useOnScreen'
import WishlistModalTrigger from 'components/modals/triggers/WishlistModalTrigger'
import classNames from 'classnames'
import { defaultCarouselProps } from '@ama-selections/ui'

const CarouselPropertyImagesSmall = ({
  propertyMainImage,
  propertyImages,
  propertyId,
  variant = 'none',
  numberOfWishlists,
}) => {
  const componentRef = React.useRef()

  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)
  const buttonClasses = classNames(
    'absolute top-1/2 cursor-pointer -mt-14',
    'transition-all lg:pointer-events-none',
    {
      'opacity-0': propertyImages?.length === 0,
      'group-hover:pointer-events-auto': propertyImages?.length > 0,
    },
  )

  let prevButtonClasses = 'swiper-button-property-images-prev lg:opacity-0 lg:group-hover:opacity-100'
  let nextButtonClasses = 'swiper-button-property-images-next lg:opacity-0 lg:group-hover:opacity-100'

  const { initialLoad } = useOnScreen(componentRef)

  switch (variant) {
    case 'admin':
      prevButtonClasses = 'swiper-button-admin-property-images-prev'
      nextButtonClasses = 'swiper-button-admin-property-images-next'
  }

  const imagesEitherSide = 1
  const imagesLength = (propertyImages?.length ?? 0) + (propertyMainImage ? 1 : 0)
  const numberArray = getNumberArray(imagesLength)
  const [loadedImages, setLoadedImages] = useState([...numberArray.slice(0, imagesEitherSide), ...numberArray.slice(-1)])

  return (
    <div className="w-full h-full" ref={componentRef}>
      {
        initialLoad && (
          <Swiper
            {...defaultCarouselProps}
            modules={[Navigation]}
            className="h-full rounded-10 group"
            loop={true}
            slidesPerView={1}
            spaceBetween={20}
            enabled={propertyImages?.length > 0}
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
            }}
            onSwiper={(swiper) => {
              setTimeout(() => {
                // Timeout until prevEl & nextEl refs are defined
                swiper.params.navigation.prevEl = navigationPrevRef.current
                swiper.params.navigation.nextEl = navigationNextRef.current

                // Re-initialise navigation
                swiper.navigation.destroy()
                swiper.navigation.init()
                swiper.navigation.update()
              })
            }}
            onSlideChange={(swiper) => {
              if (loadedImages.length !== imagesLength) {
                setLoadedImages(getLoadedImagesArray(
                  swiper.realIndex,
                  imagesEitherSide,
                  imagesLength,
                  numberArray,
                  loadedImages,
                ))
              }
            }}
            observer={true}
          >
            {(propertyMainImage.media || !propertyMainImage.media && propertyImages?.length === 0) && (
              <SwiperSlide>
                <div className="relative w-full h-full rounded-10">
                  <Image
                    className="object-cover w-full h-full rounded-10"
                    src={propertyMainImage.media ?? placeholderImage}
                    alt={propertyMainImage.alt_text}
                    layout="fill"
                    sizes="(max-width: 400px) 400px,
                          (max-width: 640px) 600px,
                          (max-width: 1024px) 480px,
                          400px"
                    priority
                  />
                </div>
              </SwiperSlide>
            )}

            {
              propertyImages !== undefined && propertyImages?.length > 0
                ? propertyImages.map((image, index) =>
                  <SwiperSlide key={index}>
                    <div className="relative w-full h-full rounded-10">
                      {loadedImages.includes(index) && (
                        <Image
                          className="object-cover w-full h-full rounded-10"
                          src={image.media ?? placeholderImage}
                          alt={image.alt_text}
                          layout="fill"
                          sizes="(max-width: 400px) 400px,
                                (max-width: 640px) 600px,
                                (max-width: 1024px) 480px,
                                400px"
                          priority
                        />
                      )}
                    </div>
                  </SwiperSlide>,
                )
                : null
            }

            <div className={classNames(
              'absolute top-10 right-10 flex transition-all justify-end z-10 group-hover:opacity-100',
              {
                'hidden': variant === 'admin',
              },
            )}>
              {!!propertyId && (
                <WishlistModalTrigger
                  variant="whiteHeart"
                  initialActive={numberOfWishlists > 0}
                  propertyId={propertyId}
                />
              )}
            </div>
            <div
              ref={navigationPrevRef}
              className={classNames(prevButtonClasses, buttonClasses)}
            />
            <div
              ref={navigationNextRef}
              className={classNames(nextButtonClasses, buttonClasses)}
            />
          </Swiper>
        )
      }
    </div>

  )
}

CarouselPropertyImagesSmall.propTypes = {
  propertyMainImage: PropTypes.object,
  propertyImages: PropTypes.array,
  propertyId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  showWishlistedItem: PropTypes.bool,
  variant: PropTypes.oneOf(['none', 'admin']),
  imageWidth: PropTypes.string,
  imageHeight: PropTypes.string,
  numberOfWishlists: PropTypes.number,
}

export default CarouselPropertyImagesSmall
