import classNames from 'classnames'

export default function Group ({
  children,
  className = '',
}: {
  children: JSX.Element | JSX.Element[]
  className?: string
}) {
  return (
    <div className={classNames('flex flex-col gap-y-20 md:flex-row gap-x-[17px] md:ml-40', className)}>
      {children}
    </div>
  )
}
