import Image from 'components/Image'
import PlaceholderImage from 'assets/images/placeholder.png'
import Paragraphs from 'components/home/common/typography/Paragraphs'
import Headers from 'components/home/common/typography/Headers'
import Pill, { PillProps } from 'components/home/Pill'
import classNames from 'classnames'
import { ReactChild } from 'data/types/types'
import { joinBy, pluraliseWithNumber } from '@ama-selections/ui'
import { Maybe } from 'gql/graphql'
import { ClassNames } from 'data/types/classnames'

export interface ServiceSlideItemProps {
  image?: {
    media?: string | null
    alt_text?: string | null
  }
  superHeading?: string | null
  heading: string
  subheading?: string | null
  iconText?: {
    count: Maybe<number>
    base: Maybe<string>
    icon: ReactChild
  }[]
  pillText?: (string | null)[]
  className?: ClassNames & {
    superHeading?: string
    heading?: string
    subheading?: string
    iconText?: string
    pill?: {
      variant: PillProps['variant']
      className?: PillProps['className']
      size?: PillProps['size']
    }
  }
}

interface ServiceSlideProps extends ServiceSlideItemProps {
  isImageLoaded: boolean
  variant?: 'default' | 'mobile' | 'centered'
}

const ServiceSlide = ({
  isImageLoaded,
  variant = 'default',
  image,
  superHeading,
  heading,
  subheading,
  iconText,
  pillText,
  className,
}: ServiceSlideProps) => {

  return (
    <div className="flex flex-col w-full group">
      <div className={classNames(
        'relative h-[250px] w-full mb-10',
        {
          'lg:h-[375px]': ['default', 'centered'].includes(variant),
        },
        className?.image?.container,
      )}>
        <Image
          src={(isImageLoaded
            ? image?.media
            : undefined
          )
            ?? PlaceholderImage
          }
          alt={image?.alt_text ?? heading}
          sizes="(max-width: 640px) 100vw,
                (max-width: 1024px) 50vw,
                33vw"
          className="object-cover rounded-6 group-hover:brightness-90"
          layout="fill"
        />
      </div>

      <div className={classNames(
        'flex flex-col gap-10 text-left',
        className?.serviceCard?.container,
      )}>
        {superHeading && (
          <Paragraphs.XXS className={classNames('text-primary-fresh', className?.serviceCard?.superHeading)}>
            {superHeading}
          </Paragraphs.XXS>
        )}

        <Headers.H3 className={classNames(
          'font-serif font-bold text-grey-900',
          className?.serviceCard?.heading,
        )}>
          {heading}
        </Headers.H3>

        <div className="flex flex-col gap-8">
          {subheading && (
            <Paragraphs.SM
              Tag="span"
              className={classNames(
                'text-grey-500 tracking-1/4 line-clamp-2',
                className?.serviceCard?.subheading,
              )}
            >
              {subheading}
            </Paragraphs.SM>
          )}

          {pillText && (
            pillText
              .filter(pill => pill !== null)
              .map((pill, index) => (
                <Pill
                  key={index}
                  variant={className?.pill?.variant ?? 'grey-sans'}
                  size={className?.pill?.size ?? '8'}
                  {...className?.pill}
                >
                  {pill}
                </Pill>
              ))
          )}
        </div>

        {iconText && (
          <>
            <Paragraphs.SM
              Tag="span"
              className={classNames(
                'hidden text-grey-500 leading-22 tracking-1/4',
                className?.serviceCard?.iconText,
                {
                  'lg:block': variant === 'default',
                },
              )}
            >
              {joinBy(iconText.map(({ count, base }) => pluraliseWithNumber(count, base, { nullable: true })), ' - ')}
            </Paragraphs.SM>

            <div className={classNames(
              'flex gap-10',
              {
                'lg:hidden': variant === 'default',
              },
            )}>
              {
                iconText
                  .filter(({ count }) => count !== null)
                  .map(({ count, icon }, index) => (
                    <div key={index} className="flex items-center gap-5 text-grey-800 text-24">
                      {icon}
                      <Paragraphs.XXS Tag="span" className="font-light leading-1/2 font-poppins">
                        {count}
                      </Paragraphs.XXS>
                    </div>
                  ))
              }
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default ServiceSlide
