import React from 'react'
import ComponentLink, { LinkProps as ComponentLinkProps } from 'components/Link'
import classNames from 'classnames'

interface LinkProps extends Omit<ComponentLinkProps, 'children'> {
  text?: string
  block?: boolean
}

const Link = ({
  text,
  className,
  block = false,
  ...props
}: LinkProps) => (
  <ComponentLink
    {...props}
    className={classNames(
      'focus:outline-none group',
      {
        'w-full': block,
        'inline-flex': !block,
      },
    )}
  >
    <p className={classNames(
      'group-focus:text-primary group-hover:underline',
      className,
    )}>
      {text}
    </p>
  </ComponentLink >
)

interface ButtonProps extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'dangerouslySetInnerHTML' | 'style' | 'children' | 'type'> {
  text?: string
}

const Button = ({
  text,
  className,
  ...props
}: ButtonProps) => (
  <button
    type="button"
    className={classNames(
      'inline-flex cursor-pointer focus:outline-none focus:text-primary hover:underline',
      className,
    )}
    {...props}
  >
    {text}
  </button>
)

export default {
  Link,
  Button,
}
