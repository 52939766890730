import { useState, useEffect } from 'react'

export enum BasicOrientation {
  Portrait = 'portrait',
  Landscape = 'landscape'
}

const useOrientation = () => {
  const [orientation, setOrientation] = useState<BasicOrientation>(BasicOrientation.Portrait)

  useEffect(() => {
    const handleOrientationChange = () => {
      const isPortrait = window.innerHeight > window.innerWidth
      setOrientation(isPortrait ? BasicOrientation.Portrait : BasicOrientation.Landscape)
    }

    window.addEventListener('resize', handleOrientationChange)

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener('resize', handleOrientationChange)
    }
  }, [])

  return { orientation }
}

export default useOrientation
