import { serialize } from 'object-to-formdata'

export default class ServicesApiClient {
  constructor (http) {
    this.http = http
  }

  async getGeneralServices (filters = {}, options = {}) {
    const result = await this.http.get('/generalServices', {
      params: {
        ...filters,
        page: options.page ?? 1,
        per_page: options.per_page,
        limit: options.limit,
        paginated: options?.paginated ?? false,
        in_random_order: options?.in_random_order ?? true,
        includes: options.includes,
      },
    })
    return {
      items: result.data.data,
      meta: result.data.meta ?? null,
    }
  }

  async getServices (filters = {}, options = {}) {
    const result = await this.http.get('/services', {
      params: {
        ...filters,
        page: options.page ?? 1,
        per_page: options?.per_page,
        paginated: options?.paginated ?? true,
        limit: options.limit,
      },
    })
    return {
      items: result.data.data,
      meta: result.data.meta ?? null,
    }
  }

  async updateService (id, details = {}) {
    const result = await this.http.patch(`/generalServices/${id}`, serialize(details, { indices: true }))
    return result.data.data
  }

  async deleteService (id) {
    const result = await this.http.delete(`/generalServices/${id}`)
    return result.data.data
  }

  async createService (details = {}) {
    const result = await this.http.post('/generalServices', serialize(details, { indices: true }))
    return result.data.data
  }

  async getServiceCategories () {
    const result = await this.http.get('/services/categories')
    return result.data
  }

  async getServiceSubCategories () {
    const result = await this.http.get('/services/subcategories')
    return result.data
  }

  /**
   * This is currently just for notifying the users that a service has been sent. However in phase
   * 2 it will be for when the service is requested.
   *
   * @param {Integer} serviceId The Id of the service that has been requested.
   * @param {Integer} bookingId The Id of the booking that the service is being requested for.
   * @returns
   */
  async requestService (serviceId, bookingId, data) {
    const result = await this.http.post(`/services/${serviceId}/request/booking/${bookingId}`, data)
    return result.data
  }
}
