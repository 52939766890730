import { Disclosure, DisclosureProps } from '@headlessui/react'
import { ReactChild } from 'data/types/types'

export type AccordionRenderProps = {
  open: boolean
  close: () => void
}

interface AccordionProps extends Omit<DisclosureProps<'div'>, 'children'> {
  children: ({ open, close }: AccordionRenderProps) => ReactChild
}

const Accordion = ({
  defaultOpen = false,
  children,
  ...props
}: AccordionProps) => {
  return (
    <Disclosure
      as="div"
      defaultOpen={defaultOpen}
      {...props}
    >
      {({ open, close }) => <>
        {
          children({ open: open, close: close })
        }
      </>
      }
    </Disclosure>
  )
}

export default Accordion
