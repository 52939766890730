import React, { useMemo } from 'react'
import { Switch } from '@headlessui/react'
import classNames from 'classnames'
import { LoadingIcon } from 'components/icons'

export interface ToggleProps {
  checked: boolean
  propagate?: boolean
  onChange: (checked: boolean) => void
  isLoading?: boolean
  disabled?: boolean
}

const Toggle = ({
  checked,
  propagate = true,
  onChange,
  isLoading,
  disabled,
}: ToggleProps) => {
  const handleClick = (e: React.MouseEvent<HTMLLabelElement>) => {
    propagate ? null : e.stopPropagation()
  }

  const displayChecked = useMemo(
    () => isLoading
      ? !checked
      : checked,
    [checked, isLoading],
  )

  return (
    <Switch
      checked={checked}
      onChange={onChange}
      onClick={handleClick}
      disabled={disabled || isLoading}
      as="label"
      className={classNames(
        'relative inline-flex items-center h-25 rounded-full w-50 transition ease-in-out duration-200',
        'focus:outline-none focus:ring ring-primary ring-opacity-30',
        {
          'bg-grey': !displayChecked,
          'bg-primary': displayChecked,
          'opacity-50': disabled,
          'cursor-pointer': !disabled && !isLoading,
        },
      )}
    >
      <span
        className={classNames(
          'relative inline-block w-[19px] h-[19px] transform rounded-full transition ease-in-out duration-200',
          {
            'translate-x-[27px]': displayChecked,
            'translate-x-[3px]': !displayChecked,
            'bg-white': !isLoading,
          },
        )}
      >
        {
          isLoading && (
            <div className="absolute inset-0 flex items-center justify-center w-full h-full">
              <LoadingIcon className="text-white stroke-2" />
            </div>
          )

        }
      </span>

    </Switch>
  )
}

export default Toggle
