import { Stripe, loadStripe } from '@stripe/stripe-js'
import { config } from 'data/config'
import { graphql } from 'gql'
import { useEffect, useState } from 'react'
import { useNotifier } from 'react-headless-notifier'
import Notification from 'components/Notification'
import { useDispatch, useSelector } from 'react-redux'
import { setUser, setVerificationSession } from 'store/auth'
import { RootState } from 'store'
import { useNetworkOnlyQuery } from '../useNetworkOnlyQuery'

let stripe: Stripe | null = null
loadStripe(config.stripePK).then((stripeResolved) => {
  stripe = stripeResolved
})

export const useIdentityUpload = ({
  pause = false,
  onComplete = () => {},
}: {
  pause?: boolean
  onComplete?: () => void
}): {
  loading: boolean
  verificationSessionLoading: boolean
  stripeIdentityModalOpen: boolean
  handleClick: () => void
  isCurrentUserVerified: boolean
} => {
  const { notify } = useNotifier()
  const dispatch = useDispatch()

  const [stripeIdentityModalOpen, setStripeIdentityModalOpen] = useState(false)
  const { verificationSessionState, authUser } = useSelector((state: RootState) => ({
    verificationSessionState: state.auth.verificationSession,
    authUser: state.auth.user,
  }))

  const [{ data: currentUser, fetching: userLoading }, refetchCurrentUser] = useNetworkOnlyQuery({
    query: graphql(`
      query CurrentUserIdentityVerifiedAt {
        currentUser {
          id
          identity_verified_at
          passport_image {
            id
            url
            file_name
          }
        }
      }
    `),
    pause: pause,
  })

  useEffect(() => {
    refetchCurrentUser({ requestPolicy: 'network-only' })
  }, [verificationSessionState?.status])

  const [{ data: verificationSession, fetching: verificationSessionLoading }, GetVerificationSession] = useNetworkOnlyQuery({
    query: graphql(`
      query GetVerificationSession {
        verificationSession {
          id
          client_secret
          status
        }
      }
    `),
    pause: true,
  })

  const handleClick = async () => {
    if (!stripe) {return}

    GetVerificationSession()
  }

  const handleStripeVerification = async () => {
    if (!verificationSession?.verificationSession?.client_secret || !stripe) {return}

    setStripeIdentityModalOpen(true)

    const { error } = await stripe.verifyIdentity(verificationSession.verificationSession.client_secret)

    setStripeIdentityModalOpen(false)

    if (error) {
      if (error.code !== 'session_cancelled') {
        notify(
          <Notification
            type="error"
            message="There was an error verifying your identity. Please try again."
          />,
        )
      }
    } else {
      if (onComplete) {
        onComplete()
      }
    }
  }

  useEffect(() => {
    if (!verificationSession?.verificationSession) { return }

    dispatch(setVerificationSession({
      id: verificationSession?.verificationSession?.id,
      status: verificationSession?.verificationSession?.status ?? null,
    }))

    handleStripeVerification()
  }, [verificationSession?.verificationSession])

  useEffect(() => {
    if (currentUser?.currentUser?.passport_image?.url && (currentUser?.currentUser?.passport_image?.url !== authUser?.passport_image?.url)) {
      dispatch(setUser({
        ...authUser,
        ...currentUser.currentUser,
      }))
    }
  }, [currentUser?.currentUser?.passport_image?.url])

  return {
    loading: userLoading || !stripe || verificationSessionLoading,
    verificationSessionLoading,
    stripeIdentityModalOpen,
    handleClick,
    isCurrentUserVerified: currentUser?.currentUser?.identity_verified_at !== null,
  }
}
