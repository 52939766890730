enum TripScheduleStatus {
  DRAFT = 'draft',
  CONCIERGE_DRAFT = 'concierge_draft',
  PENDING_APPROVAL = 'pending_approval',
  PENDING_PAYMENT = 'pending_payment',
  CANCELLED = 'cancelled',
  CONFIRMED = 'confirmed',
}

const isDraft = (status: TripScheduleStatus) => {
  return status === TripScheduleStatus.DRAFT || status === TripScheduleStatus.CONCIERGE_DRAFT
}

const isPending = (status: TripScheduleStatus) => {
  return status === TripScheduleStatus.PENDING_APPROVAL || status === TripScheduleStatus.PENDING_PAYMENT
}

export { TripScheduleStatus, isDraft, isPending }
