import { CarRentalInput, SurveyYesNo } from 'gql/graphql'
import { QuestionSlide } from 'data/types/service_surveys'
import { SurveyReviewAnswer } from 'components/service-request-forms/review/ServiceSurveyReviewSlide'
import CarRentalSlide1 from 'components/service-request-forms/services/car-rental/Slide1'
import CarRentalSlide2 from 'components/service-request-forms/services/car-rental/Slide2'
import CarRentalSlide3 from 'components/service-request-forms/services/car-rental/Slide3'
import CarRentalSlide4 from 'components/service-request-forms/services/car-rental/Slide4'
import CarRentalSlide5 from 'components/service-request-forms/services/car-rental/Slide5'
import CarRentalSlide6 from 'components/service-request-forms/services/car-rental/Slide6'
import CarRentalSlide7 from 'components/service-request-forms/services/car-rental/Slide7'
import { PickUpDropOffEnum } from 'data/enums/survey/car-rental/pick-up-drop-off'
import { CarTypeEnum } from 'data/enums/survey/car-rental/car-type'
import { getValue } from 'data/enums/survey/general'
import { formatDateLong, formatTimeFromTime } from 'data/helpers/dates'

type questionNumberType = 1|2|3|4|5|6|7|8|9|10

const QuestionText = (questionNumber: questionNumberType) => {
  switch (questionNumber) {
    case 1:
      return 'Where would you like the car pick up & drop off point to be?*'
    case 2:
      return 'What is the start date for the rental?*'
    case 3:
      return 'Your preferred time for start of rental?*'
    case 4:
      return 'What is the end date for the rental?*'
    case 5:
      return 'Your preferred time for end of rental?*'
    case 6:
      return 'Select your preference.*'
    case 7:
      return 'Will you need a child safety seat?*'
    case 8:
      return 'How many child safety seats are needed?*'
    case 9:
      return 'Specify the ages of the children.*'
    case 10:
      return 'Anything else we should know?'
  }
}

const getAnswers = (
  data: CarRentalInput,
  questionSlides: QuestionSlide[],
) => {
  const answers = [] as SurveyReviewAnswer[]

  answers.push({
    question: QuestionText(1),
    answer: <>
      {
        data.question1?.pick_up_drop_off === PickUpDropOffEnum.OTHER
          ? data.question1?.pick_up_drop_off_other
          : data.question1?.pick_up_drop_off
      }
    </>,
    slideIndex: questionSlides.findIndex((question) => question.slide === CarRentalSlide1),
  })

  answers.push({
    question: QuestionText(2),
    answer: <>{formatDateLong(data.question2?.start_date)}</>,
    slideIndex: questionSlides.findIndex((question) => question.slide === CarRentalSlide2),
  })

  answers.push({
    question: QuestionText(3),
    answer: <>{formatTimeFromTime(data.question3?.start_time)}</>,
    slideIndex: questionSlides.findIndex((question) => question.slide === CarRentalSlide2),
  })

  answers.push({
    question: QuestionText(4),
    answer: <>{formatDateLong(data.question4?.end_date)}</>,
    slideIndex: questionSlides.findIndex((question) => question.slide === CarRentalSlide3),
  })

  answers.push({
    question: QuestionText(5),
    answer: <>{formatTimeFromTime(data.question5?.end_time)}</>,
    slideIndex: questionSlides.findIndex((question) => question.slide === CarRentalSlide3),
  })

  answers.push({
    question: QuestionText(6),
    answer: <>{getCarTypeLabel(data.question6?.car_type)}</>,
    slideIndex: questionSlides.findIndex((question) => question.slide === CarRentalSlide4),
  })

  answers.push({
    question: QuestionText(7),
    answer: <>{data.question7?.need_child_seat}</>,
    slideIndex: questionSlides.findIndex((question) => question.slide === CarRentalSlide5),
  })

  if (data.question7?.need_child_seat === getValue(SurveyYesNo.Yes)) {
    answers.push({
      question: QuestionText(8),
      answer: <>{data.question8?.child_seats}</>,
      slideIndex: questionSlides.findIndex((question) => question.slide === CarRentalSlide6),
    })

    answers.push({
      question: QuestionText(9),
      answer: <>{data.question9?.child_ages}</>,
      slideIndex: questionSlides.findIndex((question) => question.slide === CarRentalSlide6),
    })
  }

  if (data.question10?.anything_else) {
    answers.push({
      question: QuestionText(10),
      answer: <>{data.question10?.anything_else}</>,
      slideIndex: questionSlides.findIndex((question) => question.slide === CarRentalSlide7),
    })
  }

  return answers
}

const getQuestionCount = (question: QuestionSlide) => {
  switch (question.slide) {
    case CarRentalSlide2:
    case CarRentalSlide3:
    case CarRentalSlide6:
      return 2
    default:
      return 1
  }
}

const getCarTypeLabel = (carType: CarTypeEnum) => {
  switch (carType) {
    case CarTypeEnum.SPORT:
      return 'Sports car (2 seats)'
    case CarTypeEnum.SEDAN:
      return 'Sedan (5 seats)'
    case CarTypeEnum.SUV:
      return 'SUV (5 seats)'
    case CarTypeEnum.VAN:
      return 'Van (8 seats)'
  }
}

export {
  CarRentalSlide1,
  CarRentalSlide2,
  CarRentalSlide3,
  CarRentalSlide4,
  CarRentalSlide5,
  CarRentalSlide6,
  CarRentalSlide7,
  getCarTypeLabel,
  getQuestionCount,
  QuestionText,
  getAnswers,
}
