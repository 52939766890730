import { useEffect, useRef, useState } from 'react'
import { UploadIcon } from 'components/icons'
import placeholderImage from 'assets/images/placeholder.png'
import Image from 'components/Image'
import classNames from 'classnames'
import { GeneralService } from 'data/types/services'

const ServiceImageUpload = ({
  className = '',
  value,
  onChange = () => { },
  service,
}: {
  className?: string
  value?: string
  onChange: (file?: File) => void
  service?: GeneralService
}) => {
  const fileRef = useRef<HTMLInputElement>(null)

  const [file, setFile] = useState<File | undefined>()
  const [previewUrl, setPreviewUrl] = useState<string | undefined>(value)

  const handleButtonClick = () => {
    if (!fileRef.current) { return }

    fileRef.current.click()
  }

  useEffect(() => {
    if (!file) { return }

    onChange(file)
    setPreviewUrl(URL.createObjectURL(file))

    return () => {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl)
      }
    }
  }, [file])

  const styles = 'absolute flex items-center justify-center transition bg-white bg-opacity-60 hover:bg-opacity-10 rounded-10 w-45 h-45 focus:outline-none focus:ring ring-primary ring-opacity-50'

  return (
    <div className={`relative w-[250px] h-full ${className}`}>
      <Image
        src={previewUrl ?? service?.service_image ??  service?.image ?? placeholderImage}
        alt=""
        className="object-cover rounded-10"
        layout="fill"
      />

      <button
        type="button"
        className={classNames(
          styles,
          'right-5 bottom-5',
        )}
        onClick={handleButtonClick}
      >
        <UploadIcon />
      </button>

      <input
        type="file"
        accept="image/*"
        className="hidden"
        ref={fileRef}
        onChange={e => setFile(e.target.files?.[0])}
      />
    </div>
  )
}

export default ServiceImageUpload
