import {
  DayPickerRangeController,
  DayPickerRangeControllerShape,
  FocusedInputShape,
  toMomentObject,
} from 'react-dates'
import { useMemo, useState } from 'react'
import Pill from 'components/Pill'

export type DateRangeInput = {
  startDate: Date | undefined
  endDate: Date | undefined
}

export type DateRangePickerProps = {
  disabledDates?: (Date | { before: Date, after: Date})[]
  initialMonth?: Date
  minDate?: Date
  value: DateRangeInput
  onChange: (startDate: Date | undefined, endDate: Date | undefined) => void
} & Omit<
  DayPickerRangeControllerShape,
  | 'onDatesChange'
  | 'onFocusChange'
  | 'focusedInput'
  | 'startDate'
  | 'endDate'
  | 'initialVisibleMonth'
  | 'minDate'
  | 'renderMonthElement'
>

const DateRangePicker = ({
  disabledDates = [],
  initialMonth = new Date(),
  minDate = new Date(),
  value,
  onChange,
  ...props
}: DateRangePickerProps) => {
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(null)

  const startDateMomentObject = useMemo(() => toMomentObject(value?.startDate)!, [value?.startDate])

  const minDateMomentObject = useMemo(() => toMomentObject(minDate)!, [minDate])
  const initialMonthMomentObject = useMemo(() => toMomentObject(initialMonth)!, [initialMonth])

  return (
    <div className="flex flex-col h-full">
      <DayPickerRangeController
        onDatesChange={({ startDate, endDate }) => {
          let overlap = false

          if (focusedInput === null && startDate && endDate) {
            // Check if the dates overlap a disabled date

            disabledDates.forEach(disabledDate => {
              if (disabledDate instanceof Date) {
                if (toMomentObject(disabledDate)!.isBetween(startDate, endDate, 'day', '[]')) {
                  overlap = true
                  return
                }
              } else {
                if (toMomentObject(disabledDate.after)!.isBetween(startDate, endDate, 'day', '[]') || toMomentObject(disabledDate.before)!.isBetween(startDate, endDate, 'day', '[]')) {
                  overlap = true
                  return
                }
              }
            })
          }

          onChange(
            startDate ? startDate.toDate() : undefined,
            overlap
              ? undefined
              : (endDate
                ? endDate.toDate()
                : undefined
              ),
          )
        }}
        onFocusChange={(focusedInput) => { setFocusedInput(focusedInput) }}
        focusedInput={focusedInput ?? 'startDate'}
        startDate={toMomentObject(value?.startDate)}
        endDate={toMomentObject(value?.endDate)}
        orientation="verticalScrollable"
        hideKeyboardShortcutsPanel
        numberOfMonths={6}
        navNext={
          <div className="flex justify-center py-3">
            <Pill
              text="Load More"
              variant="gold"
              className="hover:bg-primary hover:bg-opacity-30"
            />
          </div>
        }
        initialVisibleMonth={() => initialMonthMomentObject}
        minDate={minDateMomentObject}
        noNavPrevButton={minDateMomentObject.isSameOrAfter(initialMonthMomentObject)}
        navPrev={
          <div className="flex justify-center py-3">
            <Pill
              text="Load Previous"
              variant="gold"
              className="hover:bg-primary hover:bg-opacity-30"
            />
          </div>
        }
        verticalBorderSpacing={3}
        isDayBlocked={(day) => {
          let isBlocked = false

          disabledDates.forEach(disabledDate => {
            if (disabledDate instanceof Date) {
              if (day.isSame(disabledDate, 'day')) {
                isBlocked = true
                return
              }
            } else {
              if (day.isBetween(disabledDate.after, disabledDate.before, 'day', '()')) {
                isBlocked = true
                return
              } else if (startDateMomentObject && focusedInput === 'endDate' && toMomentObject(disabledDate.after)!.isSameOrAfter(startDateMomentObject, 'day') && day.isAfter(disabledDate.after, 'day')) {
                isBlocked = true
                return
              }
            }
          })

          return isBlocked
        }}
        isOutsideRange={(day) => day.isBefore(minDateMomentObject)}
        noBorder
        {...props}
      />
    </div>
  )
}

export default DateRangePicker
