import { parseDate } from '@ama-selections/ui'
import Link from 'components/Link'
import { LoadingIcon } from 'components/icons'
import ApiClient from 'data/api/api_client'
import { config } from 'data/config'
import { useIdentityUpload } from 'data/hooks/User/useIdentityUpload'
import { RestPassportImage } from 'data/types/user'
import { VerificationSessionStatus } from 'gql/graphql'
import { useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { setUser } from 'store/auth'
import { useDebounce } from 'use-debounce'
import classNames from 'classnames'
import Button from 'components/home/common/Button'

const CustomerPassportImageUpload = ({
  value,
  identityVerifiedAt,
}: {
  value?: RestPassportImage,
  identityVerifiedAt?: Date,
}) => {
  const dispatch = useDispatch()
  const verificationSessionState = useSelector((state: RootState) => state.auth.verificationSession)

  const [hasUploaded, setHasUploaded] = useState(false)

  const {
    handleClick,
    verificationSessionLoading,
    stripeIdentityModalOpen,
  } = useIdentityUpload({})

  const { refetch: fetchCurrentUser, isLoading: fetchingCurrentUser } = useQuery(
    ['currentUser'],
    () => ApiClient.users.getCurrentUser(),
    {
      enabled: false,
      onSuccess (data) {
        dispatch(setUser(data))
      },
    },
  )

  useEffect(() => {
    if (hasUploaded
      && verificationSessionState?.hasPassportUpdated === true
      && verificationSessionState?.status === VerificationSessionStatus.Verified
    ) {
      fetchCurrentUser()
    }
  }, [
    verificationSessionState?.status,
    verificationSessionState?.hasPassportUpdated,
    hasUploaded,
    stripeIdentityModalOpen,
  ])

  const loading = useMemo(() => (verificationSessionState?.status === VerificationSessionStatus.Processing
    || (hasUploaded
        && verificationSessionState?.hasPassportUpdated === false
        && verificationSessionState?.status === VerificationSessionStatus.Verified
    )
    || verificationSessionLoading
    || stripeIdentityModalOpen
    || fetchingCurrentUser
  ), [
    verificationSessionState?.status,
    verificationSessionLoading,
    stripeIdentityModalOpen,
    fetchingCurrentUser,
  ])
  const isLoading = useDebounce(loading, 10)?.[0]

  const displayValue = () => {
    if (isLoading) {
      return (
        <div className="flex items-center">
          Processing
          <LoadingIcon className="inline-block h-16" />
        </div>
      )
    }

    if (verificationSessionState?.status === VerificationSessionStatus.RequiresInput && hasUploaded) {
      return 'Identity verification failed.\nPlease get in touch or'
    }

    if (value?.url) {
      return (
        <Link
          href={value.url}
          target="_blank"
        >
          {value.file_name}
        </Link>
      )
    }

    return 'None Found'
  }

  const enableUpload = useMemo(
    () => identityVerifiedAt
      ? parseDate(identityVerifiedAt)!.add(config.stripePassportUploadDays, 'days').isBefore(new Date())
      : true,
    [identityVerifiedAt],
  )

  const [showTooltip, setShowTooltip] = useState(false)

  useEffect(() => {
    if (showTooltip) {
      setTimeout(() => setShowTooltip(false), 8000)
    }
  }, [showTooltip])

  return (
    <>
      <div className="whitespace-pre">
        {displayValue()}
      </div>

      <div className={classNames(
        'flex-col gap-5 text-center absolute top-50 right-0 h-max text-14 text-grey-800 border border-primary border-opacity-30 rounded-4 xl:w-max xl:max-w-[400px] p-5 bg-primary-light transition-opacity duration-200',
        {
          'opacity-100 flex': showTooltip,
          'opacity-0 hidden': !showTooltip,
        })}
      >
        <p>There's a {config.stripePassportUploadDays}-day waiting period after your last identity verification attempt to upload a new passport.</p>
        <p>Should you need to re-upload your passport sooner, please contact your Villa Specialist.</p>
      </div>

      <Button
        className={{
          button: 'mt-20',
        }}
        style="modal"
        isLoading={isLoading}
        disabled={!enableUpload}
        onClick={() => {
          if (enableUpload) {
            if (!hasUploaded) {
              setHasUploaded(true)
            }

            handleClick()
          } else {
            setShowTooltip(true)
          }
        }}
      >
        {
          (hasUploaded && verificationSessionState?.status === VerificationSessionStatus.RequiresInput)
            ? 'Try again'
            : 'Upload Passport'
        }
      </Button>
    </>

  )
}

export default CustomerPassportImageUpload
